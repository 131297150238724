/* ==========================================================
 base
========================================================== */
html {
  font-size: 62.5% !important;
}

body {
  font-size: 1.4rem;
  font-family: sans-serif;
  line-height: 2.2rem;
  letter-spacing: .05rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "palt";
  word-wrap: break-word;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: black;
}

/* ==========================================================
 独自のリセット
========================================================== */
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  text-align: left;
}

/* ==========================================================
 break-points
========================================================== */
$tab:     "screen and (min-width: 768px)";
$pc:      "screen and (min-width: 920px)";
$smallPc: "screen and (min-width: 1160px)";
$largePc: "screen and (min-width: 1230px)";

$maxWidth: 1230px;

/* ==========================================================
 color
========================================================== */
$colorHead: black;
$colorGray: #404040;
$colorDarkGray: #808080;
$colorLightGray: #919191;
$colorWhitesmoke: whitesmoke;
$colorText: #555;
$colorRed: #A40000;
$colorMosgreen: #00A161;
$colorOrange: #ee5500;
$colorCinnamon: #C65D1C;

/* ==========================================================
 font-family
========================================================== */
$gothic: sans-serif;
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "Sawarabi Mincho", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$garamond: 'EB Garamond', serif;
$garaAndMin: 'EB Garamond', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "Sawarabi Mincho", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$roboto: 'Roboto', sans-serif;
$lato:   'Lato', sans-serif;
$fontAwesome: "Font Awesome 5 Free";

/* ==========================================================
 font-size // フォントサイズの見通しをつけるため
 line-height // 単位は指定しない 参考）https://qiita.com/4cres/items/5ab4b07e6b5cdfc8f799
========================================================== */
// heading lv2
@mixin fS_lv2 {
  font-size: 2.6rem;
  line-height: 1.5; @include lineHeightCrop(1.5);
  @media #{$pc} {
    font-size: 3.6rem;
    line-height: 1.8; @include lineHeightCrop(1.8);
  }
}
// heading lv3
@mixin fS_lv3 {
  font-size: 2.4rem; // 11/6 lv2のスマホと同じになってた、新デザインで2.2に変わった？2.2に落とした
  line-height: 1.6; @include lineHeightCrop(1.6);
  @media #{$pc} {
    font-size: 3.2rem;
    line-height: 1.8; @include lineHeightCrop(1.8);
  }
}
// heading lv4
@mixin fS_lv4 {
  font-size: 2.2rem; // 11/6 2.4remから変更
  line-height: 1.6; @include lineHeightCrop(1.6); // 11/6 2.2から変更
  @media #{$pc} {
    font-size: 2.6rem; // 11/6 2.8remから変更
  }
}
// heading lv5
@mixin fS_lv5 {
  font-size: 2rem; // 11/6 1.9remから変更
  line-height: 1.7; @include lineHeightCrop(1.7);
  @media #{$pc} {
    font-size: 2.2rem;
  }
}
// heading lv6
@mixin fS_lv6 {
  font-size: 1.8rem;
  line-height: 1.7; @include lineHeightCrop(1.7);
  @media #{$pc} {
    font-size: 1.9rem;
  }
}
// heading lv7
@mixin fS_lv7 {
  font-size: 1.4rem;
  line-height: 1.5; @include lineHeightCrop(1.5);
  @media #{$pc} {
    font-size: 1.6rem;
  }
}
// paragraph
@mixin fS_p {
  font-size: 1.3rem;
  @media #{$pc} {
    font-size: 1.5rem;
  }
}

/* ==========================================================
 half-reading fix
========================================================== */

/* 使い方 */
/* @include lineHeightCrop(x); */
/* xにline-height値を入れる */

@mixin lineHeightCrop($line-height) {
  width: 100%; // 指定なしだとマージンが入る場合あり
  display: inline-block; // 指定なしだとマージンが入る場合あり
  &::before {
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((1 - #{$line-height}) * .5em);
    content: "";
  }
  &::after {
    display: block;
    width: 0;
    height: 0;
    margin-bottom: calc((1 - #{$line-height}) * .5em);
    content: "";
  }
}

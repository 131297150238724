/* ==========================================================
 cf logo
========================================================== */
.el_largeLogo {
  width: 90%;
  margin: auto;
  @media #{$pc} {
    width: auto;
  }
  img {
    @media #{$pc} {
      object-fit: contain;
      width: 520px;
      height: 200px;
    }
  }
}

/* ==========================================================
 icon
========================================================== */
.el_icon {

  &_plus {
    display: inline-block;
    width: 36px;
    height: 36px;
    background: url(/assets/images/common/icon_plus-round.svg) no-repeat center / 100%;
    vertical-align: middle;
    &__large {
      @media #{$pc} {
        width: 52px;
        height: 52px;
      }
    }
  }

  &_powerSupply {
    display: inline-block;
    width: 21px;
    height: 21px;
    background: url(/assets/images/common/icon_power-supply.svg) no-repeat center / 100%;
    vertical-align: middle;
    &.is_available {
      background-color: #FFEF24;
    }
    &__large {
      width: 30px;
      height: 30px
    }
  }

  &_jyubako {
    display: inline-block;
    width: 40px;
    height: 30px;
    background: url(/assets/images/common/icon_jyubako.svg) no-repeat center / 100%;
    vertical-align: middle;
  }

  &_tent {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: url(/assets/images/common/icon_tent.svg) no-repeat center / 100%;
    vertical-align: middle;
  }

  &_tentSec {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: url(/assets/images/common/icon_tentSec.svg) no-repeat center / 100%;
    vertical-align: middle;
  }

  &_shoppingbag {
    display: inline-block;
    width: 20px;
    height: 28px;
    background: url(/assets/images/common/icon_shoppingbag.svg) no-repeat center / 100%;
    vertical-align: middle;
    @media #{$pc} {
      width: 34px;
      height: 42px;
    }
  }

  &_facWater {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: url(/assets/images/common/icon_facWater.svg) no-repeat center / 100%;
    vertical-align: middle;
  }

  &_facToilet {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: url(/assets/images/common/icon_facToilet.svg) no-repeat center / 100%;
    vertical-align: middle;
  }

  &_facShower {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: url(/assets/images/common/icon_facShower.svg) no-repeat center / 100%;
    vertical-align: middle;
  }

  &_facBath {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: url(/assets/images/common/icon_facBath.svg) no-repeat center / 100%;
    vertical-align: middle;
  }

  &_facParking {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: url(/assets/images/common/icon_facParking.svg) no-repeat center / 100%;
    vertical-align: middle;
  }

  &_facLaundry {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: url(/assets/images/common/icon_facLaundry.svg) no-repeat center / 100%;
    vertical-align: middle;
  }

  &_facVending {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: url(/assets/images/common/icon_facVending.svg) no-repeat center / 100%;
    vertical-align: middle;
  }

  &_facStation {
    display: inline-block;
    width: 23px;
    height: 28px;
    background: url(/assets/images/common/icon_facStation.svg) no-repeat center / 100%;
    vertical-align: middle;
  }

  &_car {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url(/assets/images/common/icon_car.svg) no-repeat center / contain;
    vertical-align: middle;
    @media #{$pc} {
      width: 32px;
      height: 32px;
    }
  }

  &_train {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url(/assets/images/common/icon_train.svg) no-repeat center / contain;
    vertical-align: middle;
    @media #{$pc} {
      width: 32px;
      height: 32px;
    }
  }

  &_asterisk {
    display: inline-block;
    width: 30px;
    height: 31px;
    background: url(/assets/images/common/icon_asterisk.svg) no-repeat center / contain;
    vertical-align: middle;
    &__wht {
      background-image: url(/assets/images/common/icon_asterisk_white.svg);
    }
    @media #{$pc} {
      width: 40px;
      height: 41px;
    }
    &__m {
      @media #{$pc} {
        width: 68px;
        height: 70px;
      }
    }
  }

  &_next {
    display: inline-block;
    width: 48px;
    height: 48px;
    background: url(/assets/images/common/icon_next.svg) no-repeat center / 100%;
    vertical-align: middle;
    &__rev {
      transform: scale(-1, 1);
    }
  }

  &_arrow {
    display: inline-block;
    width: 48px;
    height: 48px;
    background: url(/assets/images/common/icon_arrow.svg) no-repeat center / 100%;
    vertical-align: middle;
    &__rev {
      transform: scale(-1, 1);
    }
  }

  &_marker {
    display: inline-block;
    width: 12px;
    height: 16px;
    background: url(/assets/images/common/icon_marker.svg) no-repeat center / contain;
    vertical-align: middle;
    @media #{$pc} {
      width: 14px;
      height: 19px;
    }
    &__wht {
      background-image: url(/assets/images/common/icon_marker_white.svg);
    }
  }
}
/* ==========================================================
 page / contents / hq10th
========================================================== */

// main visual
.un_hq10thMv {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background: url(/assets/images/contents/hq10th/mv_02.jpg) no-repeat center;
  background-size: cover;
  @media #{$pc} {
    background-attachment: fixed;
  }
  &:before {
    content: " ";
    display: block;
    position: absolute;
    background: rgba(0,0,0,.3);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &_hq10th {
    margin: 0 auto;
    width: 84%;
    max-width: 660px;
    position: relative;
  }
  &_message {
    color: white;
    text-align: center;
    font-family: $mincho;
    font-size: 2.4rem;
    font-weight: normal;
    margin-top: 2em;
    line-height: 1.4;
    letter-spacing: .15em;
    position: relative;
    @media #{$pc} {
      font-size: 3.6rem;
    }
  }
  &_scroll {
    position: absolute;
    width: 30px;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    @media #{$pc} {
      width: 46px;
    }
  }
}

// message
.un_hq10thLead {
  padding: 100px 0;
  @media #{$pc} {
    padding: 200px 0;
  }
  &_message {
    text-align: center;
    //color: white;
    font-family: $mincho;
    font-weight: normal;
    font-size: 2.4rem;
    letter-spacing: .1em;
    line-height: 1.8;
    @media #{$pc} {
      font-size: 3.6rem;
    }
    span {
      display: block;
      font-family: $garamond;
      font-size: 1.8rem;
      margin-bottom: 1em;
      @media #{$pc} {
        font-size: 2.4rem;
      }
    }
  }
}
.un_hq10thLeadPres {
  margin-top: 100px;
  //color: white;
  font-family: $mincho;
  letter-spacing: .1em;
  @media #{$pc} {
    margin-top: 200px;
    display: flex;
    align-items: center;
  }
  &_pic {
    @media #{$pc} {
      width: 63.57%;
    }
  }
  &_detail {
    padding: 75px 35px;
    @media #{$pc} {
      width: (100% - 63.57%);
    }
  }
  &_mes {
    font-size: 1.4rem;
    line-height: 2; //(28/16)
    letter-spacing: .15em;
    @media #{$pc} {
      font-size: 1.6rem;
      max-width: 330px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &_ttl {
    margin-top: 2em;
    font-size: 1.2rem;
    @media #{$pc} {
      max-width: 330px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &_name {
    margin-top: .3em;
    font-size: 1.8rem;
    letter-spacing: .2em;
    @media #{$pc} {
      max-width: 330px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  & + .un_hq10thLeadPres {
    margin-top: 0;
  }
  &__rev {
    .un_hq10thLeadPres_pic {
      @media #{$pc} {
        order: 2;
      }
    }
    .un_hq10thLeadPres_detail {
      @media #{$pc} {
        order: 1;
      }
    }
  }
}
.un_hq10thSpw {
  margin-bottom: 100px;
  @media #{$pc} {
    margin-bottom: 200px;
  }
}
.un_hq10thLeadLogo {
  @media #{$pc} {
    margin-top: 200px;
  }
  img {
    margin: auto;
    max-width: 150px;
    @media #{$pc} {
      max-width: 200px;
    }
  }
}

.un_hq10thEventIntroBg {
  background: #d8d8d8;
}
.un_hq10thEventIntro {
  padding: 100px 35px;
  @media #{$pc} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 200px 25px;
  }
  > figure {
    @media #{$pc} {
      width: 47%;
    }
  }
  &_detail {
    margin-top: 50px;
    text-align: center;
    @media #{$pc} {
      margin-top: 0;
      width: 50%;
    }
  }
  &_subTtl {
    font-family: $mincho;
    letter-spacing: .1em;
    font-size: 1.8rem;
    @media #{$pc} {
      font-size: 2.1rem;
    }
  }
  &_ttl {
    margin-top: .5em;
    font-family: $garamond;
    letter-spacing: .1em;
    font-size: 2.8rem;
    font-weight: normal;
    @media #{$pc} {
      margin-top: .7em;
      font-size: 5.2rem;
    }
    span {
      margin-top: 1em;
      display: block;
      font-size: 1.3rem;
      letter-spacing: .05em;
      @media #{$pc} {
        margin-top: 2.5em;
        font-size: 1.4rem;
      }
    }
  }
  &_date {
    margin-top: 2.5em;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.4;
    @media #{$pc} {
      margin-top: 1.4em;
      font-size: 3.4rem;
      letter-spacing: .05em;
    }
    em {
      font-style: normal;
      font-size: 2.1rem;
      @media #{$pc} {
        font-size: 4.8rem;
      }
    }
    &__small {
      text-align: center;
      font-size: 1.1rem;
      @media #{$pc} {
        font-size: 1.7rem;
      }
      em {
        font-size: 1.4rem;
        @media #{$pc} {
          font-size: 2.4rem;
        }
      }
    }
  }
  &_period {
    margin-top: 1em;
    font-size: 1.3rem;
    font-weight: bold;
    @media #{$pc} {
      font-size: 1.6rem;
    }
    span {
      margin-bottom: .5em;
      display: inline-block;
      border: solid 1px #707070;
      border-radius: 4px;
      padding: 0 .5em;
      @media #{$pc} {
        margin-right: .7em;
        padding: .2em .8em;
      }
    }
  }
  &_subPeriod {
    margin-top: .5em;
    font-size: 1.2rem;
    @media #{$pc} {
      font-size: 1.4rem;
    }
  }
  &_txt {
    text-align: left;
    font-size: 1.3rem;
    margin-top: 2.5em;
    line-height: (27/15);
    @media #{$pc} {
      font-size: 1.5rem;
      max-width: 530px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &_link {
    margin-top: 3em;
  }
}

// items
.un_hq10thItems {
  //background: #d8d8d8;
  padding: 100px 0;
  @media #{$pc} {
    padding: 200px 0;
  }
  .un_hq10thLead_message {
    color: black;
  }
  &_wrap {
    margin-top: 100px;
    margin-bottom: -50px;
    @media #{$pc} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: -100px;
    }
  }
  &_item {
    margin-bottom: 50px;
    @media #{$pc} {
      width: 47%;
      margin-bottom: 100px;
    }
  }
  &_fig {

  }
  &_name {
    font-family: $mincho;
    font-weight: normal;
    font-size: 1.8rem;
    letter-spacing: .1em;
    line-height: 1.4;
    padding-bottom: .7em;
    margin: .7em 0;
    border-bottom: solid 1px #9A9A9A;
    @media #{$pc} {
      font-size: 2.4rem;
    }
  }
  &_detail {
    @media #{$pc} {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  &_dateTxt {
    background: black;
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1;
    padding: .3em .7em;
    border: solid 1px black;
    border-radius: 4px;
    display: inline-block;
    @media #{$pc} {
      display: block;
      font-size: 1.4rem;
    }
  }
  &_prod {

  }
  &_prodNum {
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1;
    padding: .3em .7em;
    border-radius: 4px;
    border: solid 1px #9A9A9A;
    font-family: $roboto;
    color: #767676;
    @media #{$pc} {
      font-size: 1.4rem;
    }
  }
  &_prodPrice {
    margin-left: .3em;
    display: inline-block;
    font-family: $garamond;
    font-size: 2.1rem;
    @media #{$pc} {
      font-size: 2.8rem;
    }
    &Tax {
      font-family: $gothic;
      font-weight: bold;
      font-size: 1.2rem;
      @media #{$pc} {
        font-size: 1.4rem;
      }  
    }
    &Yen {
      font-size: 70%;
    }
  }
  &_text {
    margin-top: 1em;
    font-size: 1.4rem;
    line-height: (28.8/16);
    @media #{$pc} {
      font-size: 1.6rem;
    }
  }
  .el_btn {
    max-width: none;
    background-color: #C1C1C1;
    border-color: #C1C1C1;
    margin-top: 1.5em;
  }
}
.un_hq10thItemsComingEn {
  text-align: center;
  font-family: $roboto;
  font-weight: 500;
  font-size: 4rem;
  line-height: 1;
  text-decoration: underline;
  @media #{$pc} {
    font-size: 6rem;
  }
}
.un_hq10thItemsComingJa {
  text-align: center;
  font-size: 2rem;
  line-height: 1;
  @media #{$pc} {
    font-size: 3rem;
  }
}

// bottom banner
.un_hq10thBottomBanner {
  .bl_banner {
    @media #{$pc} {
      height: 400px;
    }
  }
}

// instagram
.un_hq10thInstaWrap {
  background: #F8F7E9;
  h2 {
    color: #666;
  }
}

// hashtag
.un_hq10thHashtag {
  text-align: center;
  div {
    display: inline-block;
    background: white;
    color: #666;
    font-family: $garamond;
    font-size: 4.8rem;
    line-height: 1;
    letter-spacing: .05em;
    padding: .2em .4em;
    img {
      width: 36px;
      display: inline-block;
      margin-right: 10px;
    }
  }
  p {
    color: #666;
    font-size: 1.4rem;
    font-weight: bold;
  }
}
.un_hq10thHashtagSub {
  font-size: 1.2rem;
  line-height: (24/14);
  color: #666;
  padding-left: 1em;
  text-indent: -1em;
  @media #{$pc} {
    font-size: 1.4rem;
  }
}

// notice
.un_hq10thNoticeWrap {
  background: #666;
  color: white;
}
.un_hq10thNotice {
  &_title {
    text-align: center;
    span {
      display: inline-block;
      font-family: $mincho;
      font-size: 2.4rem;
      line-height: 1.5;
      letter-spacing: .1em;
      padding: 0 .5em;
      background: white;
      color: #666;
      @media #{$pc} {
        font-size: 3.6rem;
      }
    }
  }
  &_heading {
    text-align: center;
    font-family: $mincho;
    font-weight: normal;
    font-size: 3.2rem;
    line-height: 1.4;
    letter-spacing: .1em;
    @media #{$pc} {
      font-size: 4.8rem;
    }
  }
  &_copy {
    text-align: center;
  }
  &_date {
    text-align: center;
    font-family: $garamond;
    font-size: 3.6rem;
    line-height: 1.4;
    letter-spacing: .1em;
    @media #{$pc} {
      font-size: 4.8rem;
    }
  }
  &_after {
    text-align: center;
  }
}

// page
.un_hq10thPageLogo {
  max-width: 200px;
  margin: auto;
  @media #{$pc} {
    max-width: 300px;
  }
}

.un_hq10thPageTT {
  width: 100%;
  border-top: solid 1px #D5D5D5;
  border-collapse: collapse;
  tr {
    background: url(/assets/images/contents/hq10th/timetable_01.jpg) no-repeat right top;
    background-size: 126px auto;
    @media #{$pc} {
      background-size: 23%;
      background-position: right center;
    }
    &:nth-child(1) {
      background-position: right top;
      @media #{$pc} {
        background-position: right bottom;
      }
    }
    &:nth-child(2) {
      background-image: url(/assets/images/contents/hq10th/timetable_02.jpg);
    }
    &:nth-child(3) {
      background-image: url(/assets/images/contents/hq10th/timetable_03.jpg);
    }
    &:nth-child(4) {
      background-image: url(/assets/images/contents/hq10th/timetable_04.jpg);
    }
    &:nth-child(5) {
      background-image: url(/assets/images/contents/hq10th/timetable_05.jpg);
    }
    &:nth-child(6) {
      background-image: url(/assets/images/contents/hq10th/timetable_06.jpg);
    }
    &:nth-child(7) {
      background-image: url(/assets/images/contents/hq10th/timetable_07.jpg);
    }
    &:nth-child(8) {
      background-image: url(/assets/images/contents/hq10th/timetable_08.jpg);
    }
    th {
      display: block;
      text-align: left;
      vertical-align: top;
      font-family: $garamond;
      font-weight: normal;
      font-size: 1.4rem;
      padding-top: 10px;
      @media #{$pc} {
        display: table-cell;
        white-space: nowrap;
        padding: 30px 30px 30px 0;
        border-bottom: solid 1px #D5D5D5;
        font-size: 2.1rem;
      }
    }
    td {
      display: block;
      vertical-align: top;
      border-bottom: solid 1px #D5D5D5;
      font-family: $mincho;
      font-size: 1.6rem;
      padding-bottom: 12px;
      padding-right: 140px;
      @media #{$pc} {
        display: table-cell;
        padding: 30px 240px 30px 0;
        font-size: 2.4rem;
      }
      span {
        display: inline-block;
        font-family: $gothic;
        font-size: 1rem;
        line-height: 1.5;
        margin-top: .8em;
        @media #{$pc} {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.un_hq10thPageCflogo {
  max-width: 200px;
  display: block;
  margin: auto;
}


// 限定アイテム注意書き
.un_hq10thItemsAtt {
  padding-top: 1px;
  padding-left: 25px;
  padding-right: 25px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  &_title {
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
    line-height: 1.6;
    text-decoration: underline;
    color: #a40000;
  }
  &_list {
    list-style-type: disc !important;
    padding-left: 20px !important;
    font-weight: bold;
    font-size: 1.6rem;
    color: #a40000;
    li {
      margin-top: .5em;
    }
    &__ul {
      text-decoration: underline;
    }
  }
}

.un_hq10thLabelCancel {
  display: inline-block;
  background-color: gray;
  color: white;
  font-family: $gothic;
  font-weight: bold;
  font-size: 70%;
  padding: 0 .5em;
  border-radius: 4px;
  margin-left: .5em;
  vertical-align: text-top;
}

.un_hq10thRdBtn {
  display: inline-block;
  background: #D93177;
  color: white;
  font-family: $gothic;
  font-weight: bold;
  font-size: 1.2rem;
  padding: .2em .8em;
  border-radius: 1.4rem;
  @media #{$pc} {
    transform: translate(0,-4px);
    margin-bottom: -4px;
    margin-left: 10px;
  }
  small {
    font-family: normarl;
    font-size: 1rem;
  }
  &__yt {
    background: #cd201f;
  }
}

.un_hq10thLinkLive {
  a {
    display: block;
    background: #cd201f;
    text-align: center;
    color: white;
    @media #{$pc} {
      font-size: 1.8rem;
    }
    font-weight: bold;
    padding: .8em;
  }
}

// countdown
/*.un_hq10thCountdown {
  font-family: $lato;
  font-weight: 700;
  display: flex;
  justify-content: center;
  &_num {
    &:nth-child(3),
    &:nth-child(4) {
      position: relative;
      &:before {
        position: absolute;
        bottom: 12px;
        left: -2px;
        display: block;
        content: '';
        background: url(/assets/images/contents/hq10th/parts_colon.svg) no-repeat center;
        background-size: contain;
        width: 4px;
        height: 30px;
        @media #{$pc} {
          bottom: 56px;
          left: -9px;
          width: 18px;
          height: 80px;
        }
      }
    }
    p {
      text-align: center;
      opacity: .5;
      font-size: 1.6rem;
      letter-spacing: .1em;
      @media #{$pc} {
        font-size: 2.6rem;
      }
    }
    div {
      background: url(/assets/images/contents/hq10th/bg_countdownnum.svg) no-repeat center;
      background-size: contain;
      color: #666;
      font-size: 3.2rem;
      padding: 6px;
      line-height: 1.4;
      margin: 0 8px;
      @media #{$pc} {
        font-size: 11.5rem;
        padding: 16px;
        margin: 0 36px;
      }
      span {
        letter-spacing: 12px;
        margin-right: -12px;
        white-space: nowrap;
        @media #{$pc} {
          letter-spacing: 32px;
          margin-right: -32px;
        }
      }
    }
  }
}

// footer
.un_hq10thFooter {
  &_logo {
    width: 240px;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    @media #{$pc} {
      margin-top: 250px;
    }
  }
  &_sns {
    display: flex;
    justify-content: center;
    li {
      width: 40px;
      margin: 0 10px;
    }
  }
}*/


// animation

@keyframes hq10thLogo {
  from {
    opacity: 0;
    transform: scale(1.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.un_anim_hq10thLogo {
  animation-name: hq10thLogo;
  animation-duration: 5s;
  animation-timing-function: ease-out;
}

@keyframes hq10thMessage {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.un_anim_hq10thMessage {
  animation-name: hq10thMessage;
  animation-duration: 9s;
}

@keyframes hq10thScrollDown {
  from {
    opacity:1;
    transform: translateY(-20px);
  }
  to {
    opacity:0;
    transform: translateY(0);
  }
}
.un_anim_scrollDown {
  opacity: 0;
  animation-name: hq10thScrollDown;
  animation-delay: 7s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

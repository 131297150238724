/* ==========================================================
 block header
========================================================== */
.bl_header {
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  @media #{$pc} {
    height: 90px;
  }
  &_menu,
  &_store {
    font-family: $lato;
    font-size: 1rem;
    width: 70px;
    height: 80px;
    @media #{$pc} {
      width: 90px; // 60px
      height: 100%;
    }
    a, button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      color: inherit;
    }
  }
  &_menu {
    //margin-left: 9px;
    @media #{$pc} {
      //margin-left: 30px;
    }
  }
  &_store {
    //margin-right: 9px;
    background: rgba(0,0,0,.75);
    @media #{$pc} {
      //margin-right: 30px;
    }
    img, svg {
      width: 26px;
      height: auto;
      margin: 0 auto;
      padding-right: 3px;
      @media #{$pc} {
        width: 32px;
        padding-right: 4px;
      }
    }
    .bl_header_btnName {
      color: white;
    }
  }
  &_btnName {
    line-height: 1;
    margin: 8px auto 20px;
    letter-spacing: .02em;
    @media #{$pc} {
      margin: 8px auto 23px;
    }
  }
  &_hamburger {
    position: relative;
    width: 24px;
    height: 18px;
    margin: 0 auto 2px;
    @media #{$pc} {
      width: 32px;
      height: 22px;
      margin-bottom: 4px;
    }
    span {
      position: absolute;
      display: inline-block;
      transition: all .1s;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: black;
      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        top: calc(50% - 1px);
      }
      &:nth-of-type(3) {
        bottom: 0;
      }
    }
  }
  &.is_active {
    .js_header_menu {
      background-color: #575757;
      @media #{$pc} {
        background-color: inherit;
      }
    }
    .js_header_menu .bl_header_btnName {
      color: white;
      @media #{$pc} {
        color: inherit;
      }
    }
    .js_header_menu .bl_header_hamburger {
      span {
        background-color: white;
        @media #{$pc} {
          background-color: black;
        }
        &:nth-of-type(1) {
          -webkit-transform: translateY(8px) rotate(-45deg);
          transform: translateY(8px) rotate(-45deg);
          @media #{$pc} {
            -webkit-transform: translateY(10px) rotate(-45deg);
            transform: translateY(10px) rotate(-45deg);
          }
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          -webkit-transform: translateY(-8px) rotate(45deg);
          transform: translateY(-8px) rotate(45deg);
          @media #{$pc} {
            -webkit-transform: translateY(-10px) rotate(45deg);
            transform: translateY(-10px) rotate(45deg);
            }
        }
      }
    }
  }
  &_logo {
    width: 120px;
    flex-shrink: 0;
    margin-right: 10px;
    svg {
      display: block;
      width: 100%;
      height: auto;
    }
    @media #{$pc} {
      width: 180px;
      margin-right: 0;
    }
  }
  &.is_active {
    background: white; // メニューオープン時は背景を白にする
  }

  &_experience {
    display: none;
    position: absolute;
    justify-content: center;
    right: 130px;
    top: 34px;
    padding-left: 30px;
    font-size: 1.3rem;
    font-weight: bold;
    @media #{$pc} {
      display: flex;
    }
    a {
      display: flex;
      color: #3E3E3E;
    }
    svg {
      width: 22px;
      height: 22px;
      margin-right: 7px;
    }
    span {
      display: block;
    }
  }
}

// white version
.ly_header.white,
.ly_header.whiteChg,
.ly_header.top {
  .bl_header {
    &_btnName {
      color: white;
    }
    &_hamburger {
      span {
        background-color: white;
      }
    }
    &_logo,
    &_store {
      svg {
        fill: white;
        transition: fill .3s linear;
      }
    }
    &_experience {
      a {
        color: white;
        svg {
          g, path {
            stroke: white;
          }
        }
      }
    }
    &.is_active { // 以下、メニューオープン時は強制的に要素を白に
      .bl_header_menu {
        .bl_header_btnName {
          color: black;
        }
      }
      .bl_header_hamburger {
        span {
          background-color: black;
        }
      }
      .bl_header_logo,
      .bl_header_store {
        svg {
          fill: black;
        }
      }
    }
  }
}

// black version (MVが白の場合に強制的に黒に)
.ly_header_impBlack {
  .bl_header {
    .bl_header_menu {
      .bl_header_btnName {
        color: black !important;
      }
    }
    &_hamburger {
      span {
        background-color: black !important;
      }
    }
    &_logo,
    &_store {
      svg {
        fill: black !important;
      }
    }
    &_experience {
      a {
        color: inherit !important;
        svg {
          g {
            stroke: #b8b8b8 !important;
          }
          path {
            stroke: #3e3e3e !important;
          }
        }
      }
    }
  }
}


/* ==========================================================
 sub header
========================================================== */
.bl_subHeader {
  border-top: solid 1px rgba(0,0,0,.08);
  border-bottom: solid 1px rgba(0,0,0,.08);
  padding-left: 20px;
  padding-right: 18px;
  background: white;
  position: relative;
  left: 0;
  right: 0;
  z-index: 1001;
  transition: all ease-out .3s;
  position: fixed;
  @media #{$pc} {
    //width: 100vw; // ガクガクズレ対策
    padding-left: 25px;
    padding-right: 25px;
  }
  &_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_title {
    font-family: $mincho;
    font-size: 1rem;
    letter-spacing: .12em;
    @media #{$pc} {
      font-size: 1.1rem;
    }
    span {
      font-family: $garaAndMin;
      font-size: 1.5rem;
      letter-spacing: .1em;
      margin-right: 1em;
      @media #{$pc} {
        font-size: 2.2rem;
      }
    }
  }
  &_btn {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    span {
      position: absolute;
      display: inline-block;
      transition: all .1s;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: black;
      &:nth-of-type(1) {
        top: 12px;
      }
      &:nth-of-type(2) {
        bottom: 13px;
      }
    }
  }
  &_links {
    display: none;
    padding-top: 20px;
    padding-bottom: 48px;
    overflow-y: scroll;
    max-height: calc(100vh - 200px);
    -webkit-overflow-scrolling: touch;
    @media #{$pc} {
      margin-left: -25px; // スクロールバーズレ対策
      margin-right: -25px; // スクロールバーズレ対策
    }
    @media #{$pc} {
      max-height: calc(100vh - 148px);
    }
    li {
      //font-size: 1.3rem;
      a {
        &.is_active {
          opacity: .4;
          pointer-events: none;
        }
      }
      &:first-child {
        //margin-left: 0;
      }
    }
    &__min {
      @media #{$pc} {
        /*li {
          font-size: 1.9rem;
          font-family: $mincho;
          margin-left: .9em;
        }*/
      }
    }
  }
  &__wht {
    color: white;
    background-color: transparent;
    border-top: solid 1px rgba(255,255,255,.33);
    border-bottom: solid 1px rgba(255,255,255,.33);
    @media #{$pc} {
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
    .bl_subHeader_inner {
      transition: all .5s;
      @media #{$pc} {
        border-bottom: solid 1px white;
      }
    }
    .bl_subHeader_btn {
      span {
        background-color: white;
      }
    }
    .bl_subHeader_links {
      a {
        color: white;
        border-bottom-color: rgba(255,255,255,.27)
      }
    }
  }
  &__whtChg {
    color: white;
    background-color: transparent;
    border-top: solid 1px rgba(255,255,255,.27);
    border-bottom: solid 1px rgba(255,255,255,.27);
    @media #{$pc} {
      border-top: solid 1px rgba(255,255,255,0);
      border-top-color: transparent;
      border-bottom: none;
    }
    .bl_subHeader_inner {
      transition: all .5s;
      @media #{$pc} {
        border-bottom: solid 1px white;
      }
    }
    .bl_subHeader_btn {
      span {
        background-color: white;
      }
    }
    .bl_subHeader_links {
      a {
        color: white;
        border-bottom-color: rgba(255,255,255,.27)
      }
    }
  }
  &.is_active {
    border-top-color: transparent;
    border-bottom-color: transparent;
    .bl_subHeader_links {
      display: block;
    }
    .bl_subHeader_btn {
      span:nth-of-type(1) {
        -webkit-transform: translateY(3px) rotate(-45deg);
        transform: translateY(3px) rotate(-45deg);
      }
      span:nth-of-type(2) {
        -webkit-transform: translateY(-3px) rotate(45deg);
        transform: translateY(-3px) rotate(45deg);
      }
    }
  }
}


/* ==========================================================
 header scroll control
========================================================== */

.ly_header.is_down {
  .bl_subHeader {
    border-top-color: transparent;
    border-bottom-color: transparent;
  }
  .bl_subHeader__whtChg {
    color: black;
    //border-top-color: white;
    //border-bottom-color: white;
    //background-color: white;
    .bl_subHeader_inner {
      @media #{$pc} {
        border-bottom: transparent;
      }
    }
    .bl_subHeader_btn {
      span {
        background-color: black;
      }
    }
    .bl_subHeader_links {
      a {
        //color: black;
        //border-bottom-color: rgba(0,0,0,.1)
      }
    }
  }
  &.whiteChg {
    .ly_header_wrap {
      background-color: white !important;
    }
  }
  .bl_subHeader_links {
    max-height: calc(100vh - 50px);
    @media #{$pc} {
      max-height: calc(100vh - 58px);
    }
  }
}

.ly_header.is_up {
  .bl_subHeader__wht {
    border-top: solid 1px rgba(255,255,255,.33) !important;
  }
  &.whiteChg {
    .ly_header_wrap {
      background-color: white !important;
      button {
        .bl_header_btnName {
          color: black;
        }
      }
      .bl_header {
        &_btnName {
          //color: black;
        }
        &_hamburger {
          span {
            background-color: black;
          }
        }
        &_logo {
          svg {
            fill: black;
          }
        }
        &_experience {
          a {
            color: inherit !important;
            svg {
              g {
                stroke: #b8b8b8 !important;
              }
              path {
                stroke: #3e3e3e !important;
              }
            }
          }
        }
      }
    }
  }
  .bl_subHeader__whtChg {
    color: black;
    @media #{$pc} {
      border-top: solid 1px rgba(0,0,0,.1);
      border-bottom: solid 1px rgba(0,0,0,.1);
    }
    .bl_subHeader_inner {
      @media #{$pc} {
        border-bottom: none;
      }
    }
    .bl_subHeader_btn span {
      background: black;
    }
  }
}

.ly_header.is_down, .ly_header.is_up {
  &.white {
    .ly_header_wrap {
      background-color: rgba(0,0,0,.75);
      backdrop-filter: blur(2px);
    }
  }
  &.whiteChg {
    .ly_header_wrap {
      background-color: transparent;
      backdrop-filter: none;
    }
  }
  .bl_subHeader__wht {
    border-top-color: transparent;
    border-bottom-color: transparent;
    .bl_subHeader_inner {
      @media #{$pc} {
        border-bottom: transparent;
      }
    }
  }
}

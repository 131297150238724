/* ==========================================================
 layout header
========================================================== */
.ly_header {
  height: 80px;
  @media #{$pc} {
    height: 90px;
  }
  &__wSubH {
    height: (80px + 50px);
    @media #{$pc} {
      height: (90px + 58px);
    }
  }
  &_wrap {
    position: fixed;
    width: 100%;
    background: white;
    transform: translateY(0);
    transition: all ease-out .3s;
    width: 100%;
    z-index: 1001;
      @media #{$pc} {
      // スクロールバー表示非表示でのガタツキ解消
      // overflow-x: hidden;
      .bl_header {
        // width: 100vw; // 一旦解除、他の解決方法を考える
      }
    }
  }
  &.white,
  &.black,
  &.top { // ページ側変数でわかりやすく指定するため、ここではmodifier記法を変更
    height: 0;
    @media #{$pc} {
      height: 0;
    }
    .ly_header_wrap {
      background: transparent;
    }
  }
  &.transparent {
    .ly_header_wrap {
      background: transparent;
      .bl_header:not(.is_active) {
        .bl_header_logo,
        .bl_header_store {
          display: none;
        }
      }
    }
  }
  &.whiteChg { // ページ側変数でわかりやすく指定するため、ここではmodifier記法を変更
    //height: 0;
    @media #{$pc} {
      //height: 0;
    }
    .ly_header_wrap {
      background: transparent;
    }
  }
  &.is_up {
    .ly_header_wrap {
      transform: translateY(0);
    }
  }
  &.is_down {
    .ly_header_wrap {
      transform: translateY(-80px);
      @media #{$pc} {
        transform: translateY(-90px);
      }
    }
  }
}
.ly_subHeader {
  height: 50px;
  @media #{$pc} {
    height: 58px;
    overflow: hidden;
  }
  .bl_subHeader_inner {
    width: 100%;
    max-width: ($maxWidth - 50px);
    margin: auto;
    height: 48px;
    @media #{$pc} {
      height: 56px;
    }
  }
  .bl_subHeader_links {
    .bl_nav_sub2nd_ul {
      max-width: 690px; // 640px
      margin: auto;
    }
    @media #{$pc} {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}


/* ==========================================================
 layout page
========================================================== */
.ly_small {
  max-width: 500px;
  margin: auto;
}
.ly_micro {
  max-width: 400px;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
  @media #{$pc} {
    max-width: 400px;
    padding-left: 0;
    padding-right: 0;
  }
}
.ly_narrow {
  max-width: 440px;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
  @media #{$pc} {
    max-width: 540px;
    padding-left: 0;
    padding-right: 0;
  }
}
.ly_narrowWide {
  max-width: 730px; //(680px)
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
}
.ly_middle {
  max-width: 830px; //(730px)
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
  @media #{$pc} {
    padding-left: 25px;
    padding-right: 25px;
  }
  &__spFull {
    padding-left: 0;
    padding-right: 0;
    @media #{$pc} {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
.ly_large {
  max-width: 1030px; //(980px)
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
  @media #{$pc} {
    padding-left: 25px;
    padding-right: 25px;
  }
  &__spWide {
    padding-left: 25px;
    padding-right: 25px;
  }
  &__spFull {
    padding-left: 0;
    padding-right: 0;
    @media #{$pc} {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
.ly_xlarge {
  max-width: 1220px; //(1170px)
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
  @media #{$pc} {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.ly_superLarge {
  max-width: 1330px; //(1280px)
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
}
.ly_full {
  width: 100%;
}

.ly_content {
  width: 100%;
  max-width: $maxWidth;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
  @media #{$pc} {
    padding-left: 25px;
    padding-right: 25px;
  }
  &__2col {
    @media #{$pc} {
      padding-left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .ly_content_inner {
      @media #{$pc} {
        width: 35.71%;
        .el_lv3Heading {
          margin-top: 0;
        }
      }
      &:first-child {
        @media #{$pc} {
          width: 53.57%;
        }
      }
      &:last-child {
        margin-top: 70px;
        @media #{$pc} {
          margin-top: 0;
        }
      }
    }
  }
  &__spWide {
    padding-left: 25px;
    padding-right: 25px;
  }
  &__spNoPd {
    padding-left: 0;
    padding-right: 0;
    @media #{$pc} {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

.ly_wideContent {
  width: 100%;
  max-width: $maxWidth;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
  &__spFull {
    padding-left: 0;
    padding-right: 0;
  }
}

.ly_rightContent {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  max-width: $maxWidth;
  margin: auto;
  @media #{$pc} {
    padding-left: 25px;
    padding-right: 25px;
  }
  &_inner {
    @media #{$pc} {
      margin-left: auto;
      width: calc(100% - 224px);
    }
    .bl_card figure, .bl_cfPlan .bl_imgSlide {
      margin-left: -25px;
      margin-right: -25px;
      @media #{$pc} {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  &__wide {
    padding-left: 25px;
    padding-right: 25px;
    .bl_cfPlan {
      margin-left: -25px;
      margin-right: -25px;
      @media #{$pc} {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  &__smpFull { // スマホ時のみ全幅
    padding-left: 0;
    padding-right: 0;
    @media #{$pc} {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  > .bl_pageSideLinks {
    display: none;
    @media #{$pc} {
      width: 16.95%;
      display: block;
      position: sticky;
      top: 100px;
      height: 100%;
      transition: all ease-out .3s;
    }
  }
  &__wPSL { // ページサイドリンク付きの場合は指定する
    @media #{$pc} {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.is_up { // 上方向へスクロールしたときにヘッダが現れる分下方向にオフセット
  .ly_rightContent {
    > .bl_pageSideLinks {
      @media #{$pc} {
        //transform: translateY(90px); // ヘッダの高さ分オフセット
        top: (100px + 90px);
      }
    }
  }  
}

.ly_sideShoulder {
  width: 100%;
  margin: auto;
  padding-right: 25px;
  @media #{$pc} {
    padding-left: 86px;
    padding-right: calc( calc(100% - 780px) / 2);
  }
}

.ly_sideShoulderWide {
  width: 100%;
  margin: auto;
  padding-right: 25px;
  @media #{$pc} {
    width: 90%;
    //min-width: 1200px; //横方向にoverflowしてしまうので外す
    padding-right: 0;
    margin-left: 0;
  }
  &__rev {
    padding-right: 0;
    padding-left: 25px;
    @media #{$pc} {
      //padding-left: 86px;
      padding-left: 0;
      margin-left: auto;
      margin-right: 0;
    }
  }
  &__spFull {
    padding-left: 0;
    margin-left: 0;
    @media #{$pc} {
      margin-left: auto;
    }
  }
}


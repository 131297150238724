/* ==========================================================
 footer
========================================================== */
footer {
  background-color: white;
}

/* ==========================================================
 footer / partnership
========================================================== */
.bl_ft_relatedLinksUnit {
  padding: 80px 0;
  @media #{$pc} {
    padding: 100px 0;
    display: flex;
    //align-items: flex-start;
    align-items: stretch;
    .bl_ft_relatedLinks {
      width: 50%;
      border-right: solid 1px rgba(0,0,0,.07);
      padding-right: 8%;
      &:last-child {
        padding-right: 0;
        padding-left: 8%;
      }
      &_ul {
        li {
          width: calc(100% / 3);
        }
      }
      &__3col {
        border: none;
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}
.bl_ft_relatedLinks {
  h2 {
    text-align: center;
    font-family: $garamond;
    font-weight: normal;
    font-size: 2.4rem;
    letter-spacing: .08em;
    line-height: 1;
    span {
      display: block;
      font-size: 1.2rem;
      color: #1C1C1C;
      margin-top: 8px;
    }
  }
  &_ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 38px 0 -32px !important;
    li {
      width: 50%;
      text-align: center;
      margin-bottom: 32px;
      img {
        margin: auto;
        mix-blend-mode: multiply;
      }
    }
  }
  &__3col {
    border-top: solid 1px rgba(0,0,0,.07);
    margin-top: 80px;
    padding-top: 80px;
    .bl_ft_relatedLinks_ul {
      justify-content: center;
      @media #{$pc} {
        gap: 40px;
      }
    }
  }
}

/* ==========================================================
 footer / contact / global
========================================================== */
.bl_ft_cntGlbUnit {
  padding: 60px 0;
  .bl_ft_cntGlb {
    &:last-child {
      border-top: solid 1px rgba(255,255,255,.25);
      margin-top: 50px;
      padding-top: 50px;
      @media #{$pc} {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
  @media #{$pc} {
    padding: 90px 0;
    display: flex;
    align-items: flex-start;
    .bl_ft_cntGlb {
      flex: 1;
      border-right: solid 1px  rgba(255,255,255,.25);
      padding-right: 4%;
      justify-content: space-between;
      &:last-child {
        flex: none;
        width: auto;
        border: none;
        padding-right: 0;
        padding-left: 4%;
        h2 {
          width: 100%;
        }
      }
    }
  }
}
.bl_ft_cntGlb {
  @media #{$pc} {
    display: flex;
  }
  h2 {
    text-align: center;
    font-family: $garamond;
    font-weight: normal;
    font-size: 2.4rem;
    letter-spacing: .08em;
    line-height: 1;
    color: white;
    @media #{$pc} {
      text-align: left;
      font-size: 3.2rem;
      //width: 43.26%;
      width: 22%;
      min-width: 190px;
      flex-shrink: 0;
    }
    a {
      display: inline-block;
      color: white;
      background: url(/assets/images/common/icon_arrow-narrow_white.svg) no-repeat right .3em;
      padding: 0 .5em;
      background-size: auto 12px;
      @media #{$pc} {
        background-position: right .35em;
        padding-left: 0;
      }
    }
    span {
      display: block;
      font-size: 1.2rem;
      color:rgba(255,255,255,.75);
      margin-top: 8px;
    }
  }
  &_cnt {
    color: white;
    margin-top: 34px;
    @media #{$pc} {
      margin-top: 0;
      display: flex;
    }
  }
  &_tel {
    display: flex;
    justify-content: center;
    align-items: center;
    figure {
      width: 29px;
      margin-right: 15px;
      @media #{$pc} {
        width: 35px;
      }
    }
    a {
      display: inline-block;
      font-family: $garamond;
      font-size: 3.2rem;
      letter-spacing: .05em;
      line-height: 1;
      color: white;
      white-space: nowrap;
    }
    p {
      font-size: 1.2rem;
    }
  }
  &_detail {
    text-align: center;
    @media #{$pc} {
      text-align: left;
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.5;
      text-align: center;
      margin: 22px auto;
      @media #{$pc} {
        text-align: left;
        margin: auto;
        width: 23vw;
        max-width: 350px;
        padding-right: 10px;
      }
    }
    .el_btn {
      max-width: 170px;
      margin: auto;
    }
  }
  &_glb {
    //display: flex;
    display: none;
    justify-content: space-between;
    margin-top: 34px !important;
    width: 100%;
    @media #{$pc} {
      justify-content: flex-end;
      margin-top: 0 !important;
      flex-wrap: wrap;
      margin-bottom: -40px;
    }
    li {
      @media #{$pc} {
        width: calc(100% / 3);
        margin-bottom: 40px;
      }
      a {
        font-size: 1.2rem;
        font-family: $lato;
        line-height: 1;
        color: white;
      }
    }
  }
}

/* ==========================================================
 footer / text links
========================================================== */
.bl_ft_links {
  padding: 40px 0;
  @media #{$pc} {
    padding: 80px 0;
  }
  &_ul {
    margin-bottom: -12px !important;
    text-align: center;
    @media #{$pc} {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    > li {
      margin-bottom: 12px;
      @media #{$pc} {
        margin: 0 60px;
      }
      > button {
        font-family: $garamond;
        font-size: 1.4rem;
        letter-spacing: .05em;
        line-height: 1;
        color: inherit;
        @media #{$pc} {
          font-size: 1.6rem;
          line-height: 2.5;
          pointer-events: none;
        }
        i {
          font-size: .6rem;
          margin-left: 6px;
          color: #707070;
          vertical-align: middle;
          @media #{$pc} {
            display: none;
          }
        }
      }
      .bl_ft_linksChild_ul {
        display: none;
        text-align: center;
        font-size: 1.2rem;
        color: #444;
        @media #{$pc} {
          display: block !important;
          text-align: left;
        }
        li {
          line-height: 2.5;
        }
      }
      &.bl_ft_links_ul__hrz {
        @media #{$pc} {
          width: 100%;
          margin: 0 0 30px;
          border-top: solid 1px rgba(0,0,0,.15);
          border-bottom: solid 1px rgba(0,0,0,.15);
          button {
            display: none;
          }
          .bl_ft_linksChild_ul {
            display: flex !important;
            font-family: $mincho;
            li {
              text-align: center;
              width: calc(100% / 5);
              padding: 15px 0;
              a {
                display: block;
                border-right: solid 1px rgba(0,0,0,.15);
                line-height: 3;
              }
              &:last-child {
                a {
                  border-right: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ==========================================================
 footer / sns links
========================================================== */
.bl_ft_snsLinks {
  &_ul {
    display: flex;
    justify-content: center;
    li {
      font-size: 2rem;
      margin: 0 8px;
      a {
        color: rgba(0,0,0,.5);
        .fa-twitter:before {
          content: '𝕏';
          font-family: unset;
          font-weight: bold;
        }
      }
    }
  }
}

/* ==========================================================
 footer / copyright
========================================================== */
.bl_ft_copyright {
  font-family: $lato;
  font-size: 1rem;
  text-align: center;
  color: rgba(0,0,0,.5);
  line-height: 1;
  margin-top: 15px;
  padding-bottom: 50px;
  @media #{$pc} {
    border-top: solid 1px rgba(0,0,0,.15);
    font-size: 1.2rem;
    margin-top: 30px;
    padding: 30px 0 60px;
  }
}

/* ==========================================================
 footer / experience link (スマホ用)
========================================================== */
.bl_ft_experience {
  position: fixed;
  right: 0%;
  top: 50vh;
  transform: translateY(-50%) translateX(50px);
  display: flex;
  background: white;
  border-radius: 6px 0 0 6px;
  box-shadow: 0 0 8px rgba(0,0,0,.16);
  transition: transform .5s;
  z-index: 999;
  @media #{$pc} {
    display: none;
  }
  &.displayIn {
    transform: translateY(-50%);
  }
  a {
    padding: 30px 8px;
    img {
      width: 16px;
      height: 16px;
    }
    span {
      display: block;
      margin: 8px auto 0;
      text-align: center;
      line-height: 1;
      font-size: 1rem;
      font-weight: bold;
      width: 12px;
      word-break: break-all;
    }
  }
}
/* ==========================================================
 helper animation / ここで定義するのは初期表示のアニメーションのみ、動的なものはjsでコントロールする
========================================================== */
@keyframes fadeIn {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
.hp_anim_fadeIn {
  animation-name: fadeIn;
  animation-duration: 3s;
}

@keyframes zoomOut {
  0% {
    opacity:0;
    transform: scale(1.2);
  }
  50% {
    opacity:1;
  }
  100% {
    opacity:1;
    transform: scale(1);
  }
}
.hp_anim_zoomOut {
  animation-name: zoomOut;
  animation-duration: 5s;
  animation-timing-function: ease-out;
}



/* ==========================================================
 js animation / jsで発火するアニメーション

.js_inview_fadeIn {
  初期値を入れる
  &.is_fired {
    発火後の状態を入れる
  }
}
========================================================== */

.js_inview_fadeIn {
  opacity: 0;
  transition: opacity 2s;
  &.is_fired {
    opacity: 1.0;
  }
}
.js_inview_fadeUp {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 2s, transform 2s ease-out;
  &.is_fired {
    transform: translateY(0);
    opacity: 1.0;
  }
}
/*
.inviewfadeBlur {
  opacity: 0;
  filter: blur(10px);
  transition: opacity 2s, filter 2s;
}
.fadeBlur {
  opacity: 1.0;
  filter: blur(0);
}
.inviewfadeZoomout {
  opacity: 0;
  transform: scale(1.3);
  transition: opacity 6s, transform 10s;
}
.fadeZoomout {
  opacity: 1.0;
  transform: scale(1);
}
*/
/*
命名規則
aa_bbbcccddd_eee__fff
aa: レイアウト、ブロック
bbb: アイテム定義（画像ならimg、リンクならlink、カードならcard）
ccc: サイズ類（大きいならlarge、中くらいならmedium）
ddd: 単位（unit等）
fff: モデファイア
*/

/* ==========================================================
 jumbotron
========================================================== */
.bl_jumbotron {
  height: calc(100vw * .64);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media #{$pc} {
    height: 540px;
  }
  &__tagline {
    height: calc(100vw * .57);
    @media #{$pc} {
      height: 620px;
    }
    figure {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      img {
        width: 56%;
        @media #{$pc} {
          width: 500px;
        }
      }
    }
  }
}

/* ==========================================================
 breadCrumnb
========================================================== */
.bl_breadCrumb {
  ul {
    font-size: 1.0rem;
    list-style: none;
    padding: 0;
    margin: 0 0 -5px 0;
    li {
      display: inline-block;
      color: $colorLightGray;
      line-height: 1.2em;
      a {
        color: inherit;
      }
      &::after {
        content: "/";
        margin: 0 .4em 0 .6em;
      }
      &:last-child {
        pointer-events: none;
        a {
          color: $colorGray;
          font-weight: bold;
        }
        &::after {
          content: normal;
          margin: 0 .5em;
        }
      }
    }
  }
  &__wht {
    ul {
      li {
        color: rgba(255,255,255,.8);
        &:last-child {
          a {
            //color: rgba(255,255,255,.5);
            color: rgba(255,255,255,1);
          }
        }
      }
    }
  }
  &__2col {
    @media #{$pc} {
      display: flex;
      justify-content: space-between;
    }
  }
}
.bl_subHeader + .bl_breadCrumb {
  margin-top: 14px;
}

/* ==========================================================
 label
========================================================== */
.el_labelCategory {
  display: inline-block;
  color: #3e3e3e;
  border: solid 1px #b5b5b5;
  border-radius: 4px;
  font-family: $mincho;
  font-size: 1rem;
  letter-spacing: .05em;
  line-height: 1.8em;
  padding: 0 1em;
  @media #{$pc} {
    font-size: 1.2rem;
  }
  span {
    font-family: $garamond;
    font-size: 1.1rem;
    @media #{$pc} {
      font-size: 1.3rem;
    }
  }
  &__wht {
    color: white;
    border-color: white;
  }
  &__gray {
    color: white;
    background: #888;
    border-color: #888;
  }
}

.el_labelArticle {
  position: absolute;
  display: inline-block;
  padding: 0 1.3em;
  right: 10px;
  top: 10px;
  background: $colorRed;
  color: white;
  font-family: $garamond;
  font-size: 1.2rem;
  letter-spacing: .08em;
  border-radius: 3px;
  line-height: 2em;
}

.el_labelProdNum {
  display: inline-block;
  color: #3e3e3e;
  border: solid 1px #b5b5b5;
  border-radius: 4px;
  font-family: $roboto;
  font-size: .9rem;
  letter-spacing: .05em;
  line-height: 1.6em;
  padding: 0 1em;
  vertical-align: middle;
  margin-right: 1em;
  margin-bottom: .3em;
  @media #{$pc} {
    font-size: 1rem;
  }
  &__wht {
    color: white;
    border-color: white;
  }
}


/* ==========================================================
 image
========================================================== */
.el_img {
}
.el_imgFullSq {
  img {
    height: 100vw;
    object-fit: cover;
    @media #{$pc} {
      height: auto;
    }
  }
}
.bl_bgFullImg {
  display: flex;
  align-items: flex-end;
  height: 100vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media #{$pc} {
    height: 66.6666vw;
  }
  &_inner {
    margin-left: 25px;
    margin-bottom: 25px;
    @media #{$pc} {
      margin-left: 86px;
      margin-bottom: 86px;
    }
    .el_lv6Hd {
      font-size: 1.3rem;
      @media #{$pc} {
        font-size: 1.6rem;
      }
    }
    .el_pg {
      font-size: 1rem;
      @media #{$pc} {
        font-size: 1.3rem;
        margin-top: 15px;
      }
    }
  }
}
.el_imgWide {
  margin-left: -25px;
  margin-right: -25px;
  @media #{$pc} {
    margin-left: 0;
    margin-right: 0;
  }
}
.el_bgSqImg { // 背景画像として表示する場合に使う / スクエアサイズ
  width: 100%;
  padding-top: 100%;
  background-color: gray;
  background-size: cover;
  background-position: center;
}

/* ==========================================================
 imgCarousel
========================================================== */
.bl_imgCarousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  &_inner {
    display: flex;
    li {
      display: block;
      //width: 80vw;
      width: calc(100vw - 72px);
      margin: 0 2px;
      pointer-events: none; // slickでフォーカスが当たらないようにする
      @media #{$pc} {
        //width: 54.16vw;
        width: 780px;
      }
    }
    &.swiper-wrapper {
      transition-timing-function: linear !important;
    }
  }
}
.bl_imgSlide {
  &_inner {
  }
  &_item {
  }
}

/* ==========================================================
 link
========================================================== */
.el_cateProject {
  display: inline-block;
  font-family: $mincho;
  font-size: 4.8rem;
  span {
    display: inline-block;
    font-family: $garamond;
    font-size: 1.3rem;
    vertical-align: middle;
    padding-bottom: 1em;
    letter-spacing: .18em;
    margin-left: .5em;
  }
  img {
    width: auto;
    height: 100%;
    max-height: 120px;
    @media #{$pc} {
      max-height: none;
    }
  }
  &__superLarge {
    img {
      @media #{$pc} {
        max-width: 380px;
      }
    }
    /*font-size: 11.2rem;
    font-weight: normal;
    line-height: 1em;
    @media #{$pc} {
      font-size: 15rem;
    }
    span {
      font-size: 1.8rem;
      padding-bottom: 2em;
      line-height: 1em;
      @media #{$pc} {
        font-size: 2.6rem;
      }
    }*/
  }
  &__hyperLarge {
    display: flex;
    align-items: center;
    height: 70vh;
    @media #{$pc} {
    }
  }
}
.bl_cateProjects {
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    li {
    }
  }
}
.bl_pageSideLinks {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.4;
  letter-spacing: 0;
  p {
    font-family: $garamond;
    font-weight: normal;
    font-size: 1.7rem;
    letter-spacing: 0.06em;
    padding-bottom: .6em;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    //transform: translateY(-.2em);
  }
  li {
    margin-bottom: 1.2em;
  }
  a {
    color: #BCBCBC;
    //color: black; // ひとまず全部黒に
    &.is_active {
      color: black;
    }
  }
  &.is_fixed {
    position: fixed;
  }
}
.el_textLink {
  display: inline-block;
  &::after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 9px;
    background-size: cover;
    background-image: url(/assets/images/common/icon_arrow-bold.svg);
    margin-left: .6em;
    font-size: 1.4rem;
  }
  &__mincho {
    font-family: $garamond;
    color: #717171;
    letter-spacing: .05em;
  }
  &__ul {
    border-bottom: solid 1px black;
  }
  &__up {
    &::after {
      display: none;
    }
    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 9px;
      background-size: cover;
      background-image: url(/assets/images/common/icon_arrow-bold.svg);
      margin-right: .4em;
      font-size: 1.4rem;
      transform: rotate(-90deg);
    }
  }
  &__ext {
    &:after {
      background-image: none;
      content: "\f360";
      width: auto;
      height: auto;
      font-family: $fontAwesome;
      font-weight: bold;
      margin-left: .4em;
      color: $colorText;
      opacity: .5;
    }
  }
  &__underline {
    text-decoration: underline;
  }
}
.bl_linkSection {
  border: solid 1px #A7A7A7;
  padding: 8px 18px;
  margin-bottom: 64px;
  @media #{$pc} {
    padding: 15px;
  }
  &_list {
    @media #{$pc} {
      display: flex;
      justify-content: center;
    }
    li {
      font-family: $mincho;
      font-size: 1.4rem;
      margin: .5em 0;
      @media #{$pc} {
        margin: 0 1em;
        font-size: 1.6rem;
      }
      a {
        &::before {
          content: '';
          display: inline-block;
          width: 9px;
          height: 10px;
          background: url(/assets/images/common/icon_arrow-bold.svg) no-repeat center / contain;
          margin-right: .6em;
          transform: rotate(90deg);
          opacity: .6;
        }
      }
    }
  }
}
.bl_linkUl {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.9rem;
  margin-bottom: -10px;
  @media #{$pc} {
    font-size: 2.4rem;
  }
  li {
    line-height: 1.2em;
    margin-right: .8em;
    margin-bottom: 10px;
    @media #{$pc} {
      margin-right: 1.4em;
      line-height: 1.4em;
    }
    a,
    button {
      display: inline-block;
      border-bottom: solid 2px transparent;
      opacity: .2;
      &.is_active {
        border-bottom-color: black;
        opacity: 1;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__alpha {
    font-family: $garamond;
    letter-spacing: .08em;
  }
  &__small {
    font-size: 1.3rem;
    font-weight: bold;
    @media #{$pc} {
      font-size: 1.5rem;
    }
  }
}

/* ==========================================================
 block media
========================================================== */
.bl_mediaUnit {
  list-style: none;
  padding: 0;
  .bl_media {
    margin-bottom: 25px;
    @media #{$pc} {
      margin-bottom: 48px;
    }
  }
  .bl_media:last-child {
    margin-bottom: 0;
  }
}
.bl_media {
  display: flex;
  background-color: $colorWhitesmoke;
  flex-direction: column-reverse;
  @media #{$pc} {
    align-items: center;
    flex-direction: row;
  }
  &_desc {
    width: 100%;
    padding: 35px 25px;
    @media #{$pc} {
      width: 50%;
      padding: 0 55px;
    }
    &_subTitle {
      font-family: $mincho;
      font-size: 1.2rem;
      color: #6e6e6e;
      margin-bottom: .5em;
      @media #{$pc} {
        font-size: 1.6rem;
        margin-bottom: .7em;
      }
    }
    &_title {
      font-family: $mincho;
      font-weight: normal;
      letter-spacing: 0.02em;
      font-size: 2.4rem;
      line-height: 2.2; @include lineHeightCrop(2.2);
      margin-bottom: .4em;
      @media #{$pc} {
        font-size: 2.8rem;
        margin-bottom: .6em;
      }
    }
    .el_pg {
      margin-top: 30px;
      @media #{$pc} {
        margin-top: 25px;
      }
    }
    .el_btn {
      margin-top: 1.8em;
      @media #{$pc} {
        margin-top: 35px;
      }
    }
  }
  &_img {
    width: 100%;
    @media #{$pc} {
      width: 50%;
    }
  }
  &__rev {
    @media #{$pc} {
      flex-direction: row-reverse;
    }
  }
}

.bl_mediaEnvUnit {
  .bl_mediaEnv {
    margin-bottom: 64px;
    @media #{$pc} {
      margin-bottom: 112px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.bl_mediaEnv {
  &_title {
    //-border-bottom: solid 1px #707070;
    //-padding-bottom: 14px;
    @media #{$pc} {
      position: relative;
      padding-bottom: 0;
      border-bottom: none;
      .el_lv5Hd {
        position: relative;
        width: auto;
        padding-right: 20px;
        background: white;
        z-index: 2;
      }
      &:before {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        top: 45%;
        border-top: solid 1px #707070;
        z-index: 1;
      }
    }
  }
  &_inner {
    @media #{$pc} {
      display: flex;
      justify-content: space-between;
      margin-top: 52px;
    }
  }
  &_head {
    margin-top: 20px;
    @media #{$pc} {
      width: 37.95%;
      margin-top: 0;
    }
  }
  &_detail {
    margin-top: 26px;
    @media #{$pc} {
      margin-top: 0;
    }
    .el_btn {
      margin-top: 26px;
      @media #{$pc} {
        margin-top: 72px;
      }
    }
    @media #{$pc} {
      width: 53.06%;
    }
  }
}

/* ==========================================================
 block heading media
========================================================== */
.bl_hdMedia {
  display: flex;
  flex-direction: column-reverse;
  @media #{$pc} {
    flex-direction: row;
    justify-content: space-between;
  }
  &_detail {
    @media #{$pc} {
      width: 54.08%;
    }
    .el_pg {
      margin-top: 2.5em;
    }
    .el_btn {
      margin-top: 1.8em;
    }
  }
  &_figure {
    margin-bottom: 35px;
    @media #{$pc} {
      width: 41.22%;
      margin-bottom: 0;
    }
  }
  &__onlyDetail {
    .bl_hdMedia_detail {
      @media #{$pc} {
        width: 100%;
      }
    }
  }
}
* + .bl_hdMedia {
  margin-top: 56px; // 直前に何かしらのアイテムがあったときだけマージン設定
  @media #{$pc} {
    margin-top: 140px; // 直前に何かしらのアイテムがあったときだけマージン設定
  }
}

/* ==========================================================
 block visual
========================================================== */
.bl_visualUnit {
  display: flex;
  width: 100%;
  &__2col {
    .bl_visual {
      width: 50%;
    }
  }
}

/* ==========================================================
 block camp field plan
========================================================== */
.bl_cfPlan {
  &_title {
    .el_lv6Hd + .el_lv4Hd {
      margin-top: .4em;
    }
    &__bdr {
      position: relative;
      &::before {
        position: absolute;
        bottom: 14px;
        width: 100%;
        display: block;
        content: '';
        border-top: solid 1px #888;
        z-index: 1;
        @media #{$pc} {
          bottom: 16px;
        }
      }
      .el_lv4Hd {
        position: relative;
        width: auto;
        padding-right: 15px;
        background: white;
        z-index: 2;
        @media #{$pc} {
          padding-right: 20px;
        }
      }
    }
    &__bdrBgWs {
      .el_lv4Hd {
        background: whitesmoke;
      }
    }
  }
  &_inner {
    margin-top: 25px;
    @media #{$pc} {
      padding: 0;
      display: flex;
      justify-content: space-between;
      //-margin-top: 74px;
      margin-top: 56px;
    }
  }
  &_head {
    @media #{$pc} {
      //-width: 45.91%;
      width: calc(100% / 2 - 20px);
    }
    &_symbol {
      @media #{$pc} {
      }
    }
    &_subTitle {
      color: #6E6E6E;
      margin-bottom: 12px;
    }
    &_subText {
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.9em;
      margin-top: 1.4em;
      color: #292929;
      @media #{$pc} {
        font-size: 1.4rem;
      }
    }
    &__full {
      @media #{$pc} {
        width: 100%;
      }
    }
    &__80p {
      @media #{$pc} {
        width: 79.18%;
      }
    }
    .el_lv5Hd + .el_pg {
      margin-top: 1.5em;
    }
  }
  &_detail {
    margin-top: 25px;
    @media #{$pc} {
      margin-top: 0;
      //-width: 46%;
      width: calc(100% / 2 - 5%);
    }
    .el_btn {
      margin-top: 26px;
      @media #{$pc} {
        margin-top: 40px;
        margin-right: 10px;
      }
      &.second {
        margin-top: 16px;
        @media #{$pc} {
          margin-top: 12px;
          margin-right: 10px;
        }
      }
    }
  }
  &_mapLink {
    display: inline-block;
    margin-top: 16px;
    font-size: 1rem;
    i {
      margin-right: 8px;
    }
    @media #{$pc} {
      margin-top: 24px;
      font-size: 1.2rem;
    }
  }
  .bl_tagList {
    margin-top: 16px;
    @media #{$pc} {
      margin-top: 32px;
    }
  }
  p.el_pg {
    margin-top: 1.2em;
    font-size: 1.4rem;
    @media #{$pc} {
      font-size: 1.5rem;
    }
    &:first-child {
      margin-top: 0;
    }
    &.companyInfo {
      letter-spacing: 0.04em;
      font-size: 1.3rem;
      @media #{$pc} {
        font-size: 1.5rem;
      }
    }
  }
  &__tiny {
    .bl_imgSlide {
      display: none;
    }
    .bl_cfPlan_inner {
      margin-top: 0;
    }
  }
}
.bl_cfPlan + .bl_cfPlan,
.bl_cardUnit + .bl_cfPlan {
  margin-top: 72px;
  @media #{$pc} {
    margin-top: 140px;
  }
}

/* ==========================================================
 block base infomation
========================================================== */
.bl_baseInfo {
  margin: 0 -25px 16px;
  padding: 48px 25px;
  background-color: white;
  @media #{$pc} {
    margin: 0;
    padding: 0;
    background-color: whitesmoke;
  }
  &_title {
    .el_lv6Hd + .el_lv4Hd {
      margin-top: .4em;
    }
    p.el_lv6Hd {
      font-size: 1.4rem;
      @media #{$pc} {
        font-size: 1.7rem;
      }
    }
    &__bdr {
      position: relative;
      &::before {
        position: absolute;
        bottom: 14px;
        width: 100%;
        display: block;
        content: '';
        border-top: solid 1px #888;
        z-index: 1;
        @media #{$pc} {
          bottom: 16px;
        }
      }
      .el_lv4Hd {
        position: relative;
        width: auto;
        padding-right: 15px;
        background: white;
        z-index: 2;
        @media #{$pc} {
          padding-right: 20px;
        }
      }
    }
    &__bdrBgWs {
      .el_lv4Hd {
        background: white;
        @media #{$pc} {
          background: whitesmoke;
        }
      }
    }
  }
  &_inner {
    margin-top: 25px;
    @media #{$pc} {
      padding: 0;
      display: flex;
      justify-content: space-between;
      //-margin-top: 74px;
      margin-top: 56px;
    }
  }
  &_head {
    @media #{$pc} {
      //-width: 45.91%;
      width: calc(100% / 2 - 20px);
    }
    &_symbol {
      @media #{$pc} {
      }
    }
    &_subTitle {
      color: #6E6E6E;
      margin-bottom: 12px;
    }
    &_subText {
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.9em;
      margin-top: 1.4em;
      color: #292929;
      @media #{$pc} {
        font-size: 1.4rem;
      }
    }
    &__full {
      @media #{$pc} {
        width: 100%;
      }
    }
    &__80p {
      @media #{$pc} {
        width: 79.18%;
      }
    }
    .el_lv5Hd + .el_pg {
      margin-top: 1.5em;
    }
  }
  &_detail {
    margin-top: 25px;
    @media #{$pc} {
      margin-top: 0;
      //-width: 46%;
      width: calc(100% / 2 - 5%);
    }
    .el_btn {
      margin-top: 26px;
      @media #{$pc} {
        margin-top: 40px;
        margin-right: 10px;
      }
      &.second {
        margin-top: 16px;
        @media #{$pc} {
          margin-top: 12px;
          margin-right: 10px;
        }
      }
    }
  }
  &_mapLink {
    display: inline-block;
    margin-top: 16px;
    font-size: 1rem;
    i {
      margin-right: 8px;
    }
    @media #{$pc} {
      margin-top: 24px;
      font-size: 1.2rem;
    }
  }
  .bl_tagList {
    margin-top: 16px;
    @media #{$pc} {
      margin-top: 32px;
    }
  }
  p.el_pg {
    margin-top: 1.2em;
    font-size: 1.4rem;
    @media #{$pc} {
      font-size: 1.5rem;
    }
    &:first-child {
      margin-top: 0;
    }
    &.companyInfo {
      letter-spacing: 0.04em;
      font-size: 1.3rem;
      @media #{$pc} {
        font-size: 1.5rem;
      }
    }
  }
}
.bl_baseInfo + .bl_baseInfo {
  margin-top: 0;
  @media #{$pc} {
    margin-top: 140px;
  }
}

/* ==========================================================
 list
========================================================== */
.bl_listYear {
  ul {
    list-style: none;
    padding: 0;
    border-top: solid 1px black;
    li {
      display: table;
      width: 100%;
      border-bottom: solid 1px black;
      padding: 35px 0;
    }
  }
  &_year {
    display: table-cell;
    width: 30%;
    font-family: $garamond;
    font-weight: normal;
    font-size: 4.2rem;
    letter-spacing: .02em;
    vertical-align: middle;
  }
  &_desc {
    display: table-cell;
    width: 70%;
    font-size: 1.5rem;
    line-height: 2.1em;
    vertical-align: middle;
  }
}

ul.el_listBasic {
  list-style: disc;
  padding-left: 1.5em;
  li {
    span.cfCap {
      display: inline-block;
      color: #787878;
    }
  }
}

.bl_listNumLarge {
  padding-bottom: 45px !important;
  @media #{$pc} {
    padding-bottom: 0 !important;
  }
  &_ol {
    @media #{$pc} {
      display: block;
    }
    li {
      width: 100%;
      position: relative;
      @media #{$pc} {
        padding-left: 50px;
        padding-top: 50px;
        padding-bottom: 50px;
        border-bottom: solid 1px #484848;
      }
      span {
        position: absolute;
        left: 0;
        top: -7px;
        @media #{$pc} {
          top: 39px;
        }
      }
      .el_lv5Hd {
        margin-bottom: 2em;
        padding-left: 1.9em;
        @media #{$pc} {
          padding-left: 0;
        }
      }
    }
  }
}

/* ==========================================================
 banner
========================================================== */
.el_imgBannerLink {
  display: flex;
  background-color: black;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  p {
    text-align: center;
    color: white;
    font-family: $mincho;
    font-size: 1.4rem;
    letter-spacing: .03em;
    line-height: 2.4em;
    margin-bottom: 16px;
    @media #{$pc} {
      font-size: 1.5rem;
    }
    span {
      font-family: $garamond;
      font-size: 2.4rem;
      letter-spacing: .08em;
      @media #{$pc} {
        font-size: 3.0rem;
      }
    }
  }
}
.bl_imgBannerLinkUnit {
  display: flex;
  flex-direction: column;
  @media #{$pc} {
    flex-direction: row;
    height: 400px;
  }
  li {
    height: 250px;
    @media #{$pc} {
      width: 100%; // 幅100%にしていくつになっても均等に
      height: auto;
    }
  }
  // カラム幅が一定なので指定しない
  /*&__2col {
    li {
      height: 250px;
      @media #{$pc} {
        width: calc(100% / 2);
        height: auto;
      }
    }
  }
  &__3col {
    li {
      height: 250px;
      @media #{$pc} {
        width: calc(100% / 3);
        height: auto;
      }
    }
  }*/
}
.bl_bannerUnit {
  .bl_banner {
    margin-bottom: 30px;
  }
  margin-bottom: -30px;
}
.bl_banner {
  background: gray center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 240px;
  padding: 60px 0;
  height: auto;
  @media #{$pc} {
    padding: 120px 0;
    min-height: 600px;
  }
  &.tosaNoasobi {
    background-image: url(/assets/images/campfield/bnr_experience_tosashimizu.jpg);
  }
  &.minden {
    background: url(/assets/images/campfield/minden_visual.jpg) bottom center;
    background-size: cover;
  }
  &__top {
    justify-content: flex-start;
    padding-top: 7vw;
    height: 100vw;
    @media #{$pc} {
      padding-top: 60px;
      height: 560px;
    }
  }
  &_img {
    min-width: 200px;
    max-width: 600px;
    max-height: 80px;
    margin: 0 auto 30px auto;
    padding: 0 25px;
    @media #{$pc} {
      min-width: 450px;
      max-height: 144px;
      margin: 0 auto 45px auto;
    }
    img {
      height: 100%;
    }
  }
  &_text {
    color: black;
    text-align: center;
    margin: 0 auto 20px;
    @media #{$pc} {
      margin: 0 auto 30px;
    }
    &_indexTitle {
      font-size: 1.5rem;
      line-height: 1;
      padding: .8rem;
      background: #fff;
      color: #000;
      display: inline-block;
      margin-bottom: .8em;
      @media #{$pc} {
        font-size: 1.7rem;
      }
    }
    &_subTitle {
      font-size: 1.3rem;
      font-weight: bold;
      line-height: 1.6em;
      padding: 0 25px;
      @media #{$pc} {
        font-size: 1.5rem;
      }
      &_energy {
        font-size: 1.3rem;
        line-height: 1.8em;
        font-weight: 600;
        @media #{$pc} {
          font-size: 1.8rem;
        }
      }
    }
    &_title {
      font-size: 2.4rem;
      letter-spacing: .1em;
      line-height: 1.6em;
      @media #{$pc} {
        font-size: 3.4rem;
      }
      &_expo {
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 1.4;
        margin-top: .1em;
        @media #{$pc} {
          font-size: 3.4rem;
        }
      }
      img {
        padding: 10px 25px 0;
        margin: 0 auto;
        width: 100%;
        @media #{$pc} {
          padding: 20px 25px 10px;
          width: 600px;
        }
      }
    }
    &.whTxt {
      color: white;
    }
  }
  &_lead {
    font-family: $mincho;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: .1em;
    line-height: 1.6;
    margin-bottom: 20px;
    @media #{$pc} {
      font-size: 2.1rem;
      margin-bottom: 30px;
    }
    &__wht {
      color: white;
    }
  }
  &__overlay {
    position: relative;
    div, a, p {
      position: relative;
    }
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: '';
      background: black;
      opacity: .3;
    }
  }
}

.bl_tileBannerUnit {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .bl_tileBanner {
    margin-bottom: 24px;
    @media #{$pc} {
      width: calc(100% / 2 - calc(32px / 2));
      margin-bottom: 32px;
    }
  }
  margin-bottom: -24px;
  @media #{$pc} {
    margin-bottom: -32px;
  }
  &__gapless {
    .bl_tileBanner {
      margin-bottom: 8px;
      @media #{$pc} {
        width: calc(100% / 3 - 2px);
        margin-bottom: 2px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    margin-bottom: -8px;
    @media #{$pc} {
      margin-bottom: -2px;
    }
  }
}
.bl_tileBanner {
}

/* ==========================================================
 banner_bg_video
========================================================== */
.bl_banner_bgmov {
  width: 100%;
  max-width: 1800px;
  margin-right: auto;
  margin-left: auto;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 6em 0;
  @media #{$pc} {
    padding: 14em 0;
  }
  .bl_video {
    text-align: center;
    video {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: auto;
      height: 100%;
      min-width: 100%;
      min-height: 100%;
      background-size: cover;
      z-index: -1;
      @media #{$pc} {
        height: auto;
      }
      &.expo2021{
        background: url(/assets/images/business/top_lifeexpo2021.jpg) center center;
      }
      
    }
  }
  .el_btn__middle {
    display: block;
    margin: 0 auto;
  }
}

/* ==========================================================
 staff
========================================================== */
.bl_tileStaffUnit {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .bl_tileStaff {
    margin-bottom: 32px;
    width: 100%;
    width: calc(100% / 2 - 2px);
    @media #{$pc} {
      width: calc(100% / 3 - 2px);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 54px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__profile {
    padding: 20px 25px 0;
    @media #{$pc} {
      margin-top: 25px;
      padding: 0
    }
    p.el_pg {
      font-size: 1.5rem;
      font-weight: bold;
      @media #{$pc} {
        font-size: 1.7rem;
      }
      span {
        display: block;
        font-size: 1.1rem;
        font-weight: normal;
        color: #898989;
        @media #{$pc} {
          font-size: 1.4rem;
        }
      }
      &__staffClass {
        font-size: 1.1rem;
        font-weight: bold;
        @media #{$pc} {
          font-size: 1.2rem;
        }
      }
    }
  }
}
.bl_tileBanner {
}

/* ==========================================================
 card
========================================================== */
.bl_card {
  figure {
    margin-bottom: 30px;
    @media #{$pc} {
      margin-bottom: 36px;
    }
    &.bl_card_fig_mbS {
      margin-bottom: 12px;
      @media #{$pc} {
        margin-bottom: 22px;
      }
    }
  }
  &_desc {
    margin-top: 1.5em;
    @media #{$pc} {
      margin-top: 1.8em;
    }
  }
  &_subTitle + .bl_card_title {
    margin-top: 9px;
  }
  .bl_tagList {
    margin-top: 15px;
  }
  .el_btn {
    //margin-top: 45px;
  }
  .el_textLink__ul {
    margin-top: 18px;
  }
  &__gothic {
    font-family: $gothic;
    .bl_card_title {
      font-family: $gothic;
      font-weight: bold;
      i {
        margin-right: .6em;
      }
    }
  }
  &__wht {
    color: white;
  }
}

.bl_cardUnit {
  margin-bottom: -48px;
  @media #{$pc} {
    margin-bottom: -72px;
  }
    .bl_card {
    margin-bottom: 48px;
    @media #{$pc} {
      margin-bottom: 72px;
    }
  }
  &__mbS {
    margin-bottom: -20px;
    @media #{$pc} {
      margin-bottom: -50px;
    }
    .bl_card {
      margin-bottom: 20px;
      @media #{$pc} {
        margin-bottom: 50px;
      }
    }
  }
  &__2col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .bl_card {
      width: 100%;
      @media #{$pc} {
        //-width: 45%;
        width: calc(100% / 2 - 25px);
      }
    }
  }
  &__2colL {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .bl_card {
      width: 100%;
      @media #{$pc} {
        width: 46.61%;
      }
    }
  }
  &__3col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media #{$pc} {
      &:after {
        display: block;
        content: '';
        width: 31.52%;
      }
    }
    .bl_card {
      width: 100%;
      @media #{$pc} {
        width: 31.52%;
      }
      &_desc {
        //-margin-top: 1.1em;
      }
      figure {
        margin-bottom: 26px;
        @media #{$pc} {
          margin-bottom: 26px;
        }
      }    
      .cfTemp {
        font-size: 1.5rem;
        color: #7b7f8d;
        font-weight: 700;
        border-top: 2px solid #b4b4b4;
        border-bottom: 1px solid #e3e3e3;
        padding: 20px 0;
        strong {
          color: #000;
        }
      }
    }
    .bl_card__vessel {
      .bl_card_title {
        margin-top: 1.5em;
        @media #{$pc} {
          margin-top: 2.2em;
        }
      }
    }
  }
  &__4col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .bl_card {
      width: 100%;
      @media #{$pc} {
        width: 22.88%;
      }
      figure {
        margin-bottom: 14px;
        @media #{$pc} {
          margin-bottom: 26px;
        }
      }    
      &_title {
        font-size: 1.7rem !important;
      }
      &_desc {
        font-size: 1.4rem;
      }
    }
  }
  &__5col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .bl_card {
      width: 45%;
      @media #{$pc} {
        width: 18%;
      }
      figure {
        margin-bottom: 14px;
        @media #{$pc} {
          margin-bottom: 26px;
        }
      }    
      &_title {
        font-size: 1.7rem !important;
      }
      &_desc {
        font-size: 1.4rem;
      }
    }
  }
}

.bl_cardSwpUnit {
  margin-bottom: -90px;
  display: flex;
  justify-content: space-between;
}
.bl_cardSwp {
  margin-bottom: 90px;
  width: calc(100% - 72px) !important;
  margin-left: 9px;
  margin-right: 9px;
  @media #{$pc} {
    width: 25.83% !important;
    background: rgba(255,255,255,.07);
    padding: 0 30px 30px;
    margin-left: 16px;
    margin-right: 16px;
    figure {
      margin: 0 -30px;
    }
  }
  &_title {
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.2em;
    margin-top: 1.5em;
    @media #{$pc} {
      margin-top: 1.6em;
      font-size: 1.9rem;
    }
    span {
      font-size: .9rem;
      @media #{$pc} {
        font-size: 1.1rem;
      }
    }
  }
  &_desc {
    margin-top: 1.1em;
  }
}

.bl_cardLarge {
  &_link {
    color: white;
    display: block;
    background: whitesmoke no-repeat center;
    background-size: cover;
    padding: 46.9vw 0 9.33vw 25px;
    position: relative;
    @media #{$pc} {
      padding: 295px 22px 36px 22px;
    }
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: '';
      background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,.67) 100%);
    }
  }
  &_title {
    position: relative;
    font-family: $mincho;
    font-weight: normal;
    font-size: 5.5rem;
    line-height: 1em;
    margin-bottom: -.05em;
    span {
      font-family: $garamond;
      font-size: 2.4rem;
      letter-spacing: 0.04em;
      margin-left: .4em;
    }
  }
  &_subTitle {
    position: relative;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.6em;
    letter-spacing: 0.08em;
  }
  &_label {
    position: relative;
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    color: black;
    //-border: solid 1px white;
    //-border-radius: 4px;
    //-background-color: rgba(0,0,0,.75);
    background-color: white;
    padding: 0 2em;
    line-height: 2.4em;
    margin-top: 1.4em;
  }
  &_js_navi {
    display: none;
    @media #{$pc} {
      display: block;
    }
    position: absolute;
    top: 0;
    left: 25px;
    right: 25px;
    bottom: 0;
    .el_icon_next {
      right: -84px;
    }
    .el_icon_next__rev {
      left: -84px;
    }
  }
}
.bl_cardLargeUnit {
  @media #{$pc} {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
  }
  &_inner {
    @media #{$pc} {
      display: flex;
    }
  }
  .bl_cardLarge {
    width: 100%;
    margin-bottom: 12px;
    @media #{$pc} {
      width: 31.52%;
      //width: 400px;
      margin: 0 1.36%;
    }
  }
}

.bl_cardPolaUnit {
  border-top: solid 1px #C4C4C4;
  @media #{$pc} {
    border-top: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -34px;
    .bl_cardPola {
      width: 31.52%;
      margin-bottom: 34px;
    }
  }
}
.bl_cardPola {
  border-bottom: solid 1px #C4C4C4;
  @media #{$pc} {
    border: solid 1px #C4C4C4;
  }
  a {
    display: block;
    background: url(../images/common/icon_rightarrowhead.svg) no-repeat center right 14px;
    background-size: 19px auto;
    padding: 16px 0;
    @media #{$pc} {
      background-size: auto;
      background-position: right 26px bottom 32px;
      padding: 26px;
      height: 100%;
    }
  }
  figure {
    display: none;
    @media #{$pc} {
      display: block;
    }
  }
  &_subTitle {
    font-family: $garamond;
    font-size: 1.3rem;
    letter-spacing: .08em;
    color: #6E6E6E;
    line-height: 1;
    @media #{$pc} {
      font-size: 1.5rem;
      margin-top: 30px;
    }
  }
  &_title {
    font-family: $mincho;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.37;
    @include lineHeightCrop(1.37);
    margin-top: .5em;
    @media #{$pc} {
      font-size: 2rem;
      line-height: 1.6;
      @include lineHeightCrop(1.6);
    }
  }
}

.bl_cardAppUnit {
  .bl_cardApp {
    margin-bottom: 64px;
    @media #{$pc} {
      margin-bottom: 180px;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.bl_cardLocationUnit {
  margin-bottom: -25px;
  @media #{$pc} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -56px;
  }
  .bl_cardLocation {
    margin-bottom: 25px;
    @media #{$pc} {
      width: 47.62%;
      margin-bottom: 56px;
    }
    &__full {
      @media #{$pc} {
        width: 100%;
        &::before {
          padding-top: 47.45%;
        }
      }
    }
    &__fullBtm {
      margin-left: -25px;
      margin-right: -25px;
      width: auto;
      &::before {
        padding-top: 46.4%;
      }
      @media #{$pc} {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
          &::before {
          padding-top: 30.50%;
        }
      }
      .bl_cardLocation_inner {
        bottom: 0;
        justify-content: center;
      }
    }
  }
}
.bl_cardLocation {
  display: block;
  position: relative;
  width: 100%;
  background: whitesmoke no-repeat center;
  background-size: cover;
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,.75) 100%);
  }
  &_inner {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 26px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: white;
    z-index: 2;
    @media #{$pc} {
      bottom: 48px;
    }
  }
  &_subTitle {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: .05em;
    @media #{$pc} {
      font-size: 1.4rem;
    }
  }
  &_title {
    font-family: $garamond;
    font-size: 2.6rem;
    font-weight: normal;
    letter-spacing: .08em;
    line-height: 1;
    margin-top: 8px;
    text-align: center;
    @media #{$pc} {
      font-size: 3.2rem;
    }
  }
  &_place {
    font-size: .9rem;
    font-weight: bold;
    line-height: 1;
    margin-top: 14px;
    @media #{$pc} {
      font-size: 1.2rem;
      margin-top: 18px;
    }
    i {
      margin-right: 6px;
    }
  }
  &_btn {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 2;
    border: solid 1px white;
    padding: 0 2.4em;
    border-radius: 2em;
    margin-top: 12px;
    @media #{$pc} {
      margin-top: 28px;
    }
    background: url(../images/common/icon_rightarrowhead-small_white.svg) no-repeat center right 8px;
    &__anchor {
      background-image: url(../images/common/icon_down-small_white.svg);
    }
  }
}



/* ==========================================================
 video
========================================================== */
.bl_videoLink {
  &_thumbnail {
    padding: 100px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @media #{$pc} {
      padding: 240px 0;
    }
  }
  &_title {
    font-family: $mincho;
    font-size: 3.2rem;
    font-weight: normal;
    letter-spacing: 0.02em;
    color: white;
    margin-bottom: 1.5em;
  }
  &_playIcon {
    img {
      display: inline-block;
      width: 34px;
      height: auto;
      vertical-align: middle;
      @media #{$pc} {
        width: 80px;
      }
    }
    span {
      margin-left: 1em;
      display: inline-block;
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: 0.05em;;
      color: white;
      @media #{$pc} {
        font-size: 2rem;
      }
    }
  }
}

/* ==========================================================
 hear line
========================================================== */
.el_hr {
  border: none;
  border-top: solid 1px;
}

/* ==========================================================
 tags
========================================================== */
.bl_tagList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -4px !important;
  @media #{$pc} {
    margin-bottom: -8px !important;
  }
  li {
    margin-right: 4px;
    margin-bottom: 4px;
    @media #{$pc} {
      margin-right: 5px;
      margin-bottom: 8px;
    }
  }
}
.el_tagUline {
  display: inline-block;
  font-size: 1rem;
  font-family: $garamond;
  letter-spacing: .1em;
  line-height: 1.4;
  color: $colorDarkGray;
  border-bottom: solid 1px #B9B9B9;
  @media #{$pc} {
    font-size: 1.5rem;
  }
}
.el_tagRound {
  display: inline-block;
  font-size: .9rem;
  font-weight: bold;
  color: $colorText;
  //-background-color: #d5d5d5;
  border: solid 1px #d5d5d5;
  line-height: 2.4em;
  padding: 0 1em;
  border-radius: 2.4em;
  @media #{$pc} {
    font-size: 1.2rem;
  }
  &.is_active {
    border-color: black;
    background-color: black;
    color: white;
  }
}


/* ==========================================================
 head price
========================================================== */
.bl_headPrice {
  &_wrap {
    border: solid 10px #e3e3e3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    @media #{$pc} {
      flex-wrap: nowrap;
    }
  }
  &_title {
    width: 100%;
    font-size: 1.5rem;
    border-bottom: 1px solid #E3E3E3;
    text-align: center;
    line-height: 2em;
    padding-bottom: 12px;
    margin: -3px 0 16px 0;
    @media #{$pc} {
      width: 45%;
      font-size: 2rem;
      padding-bottom: 0;
      margin: 0;
      border-bottom: none;
      border-right: 2px solid #E3E3E3;
    }
    &.border_rb_none {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &_detail {
    width: 100%;
    font-size: 1.4rem;
    font-family: $mincho;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$pc} {
      width: 55%;
      font-size: 2rem;
    }
    &_iconPlus {
      display: inline-block;
      width: 22px;
      height: 22px;
      background-size: cover;
      background-image: url(/assets/images/common/icon_plus-round.svg);
      margin: 0 10px;
      @media #{$pc} {
        width: 36px;
        height: 36px;
        margin: 0 16px;
      }
    }
    &_iconTimes {
      display: inline-block;
      width: 22px;
      height: 22px;
      background-size: 100%;
      background-image: url(/assets/images/common/icon_plus-round.svg);
      margin: 0 10px;
      transform: rotate(-45deg);
      @media #{$pc} {
        width: 36px;
        height: 36px;
        margin: 0 16px;
      }
    }
  }
  &_option {
    text-align: center;
    color: #7B7F8D;
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 1em;
    @media #{$pc} {
      margin-top: 1.6em;
      font-size: 1.6rem;
    }
  }
}

/* ==========================================================
 table
========================================================== */
.bl_tableBasic {
  &_caption {
    .el_lv5Hd {
      margin-bottom: 24px;
      @media #{$pc} {
        margin-bottom: 28px;
      }
    }
    .el_pg {
      margin-bottom: 32px;
      @media #{$pc} {
        margin-bottom: 40px;
      }
    }
  }
  &_table {
    border-top: solid 2px #E3E3E3;
    //-border-bottom: solid 2px #E3E3E3;
    table {
      text-align: left;
      width: 100%;
      border-spacing: 0;
      @media #{$pc} {
        margin-left: auto;
        width: 83.05%;
      }
      tbody {
        tr {
          th {
            display: block;
            padding: 34px 0 14px;
            vertical-align: top;
            @media #{$pc} {
              display: table-cell;
              padding: 56px 0;
              width: 30%;
              border-bottom: solid 1px #E3E3E3;
            }
          }
          td {
            display: block;
            border-bottom: solid 1px #E3E3E3;
            padding: 30px 0;
            vertical-align: top;
            > * {
              margin-top: 1.7em;
              &:first-child {
                margin-top: 0;
              }
            }
            .el_textQa_a {
              margin-top: .9em;
              margin-bottom: -.5em;
              .el_btn {
                margin-top: 1.7em;
              }
            }
            @media #{$pc} {
              display: table-cell;
              padding: 56px 0;
            }
          }
          th + td {
            padding-top: 0;
            @media #{$pc} {
              padding-top: 56px;
            }
          }
          &:last-child {
            th, td {
              border-bottom: none;
            }
          }
        }
      }
    }
    .wide {
      width: 100%;
      @media #{$pc} {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
    }
  }
}

.bl_tablePrice {
  table {
    min-width: 600px;
    width: 100%;
    border-top: solid 2px #B4B4B4;
    border-spacing: 0;
    @media #{$pc} {
      min-width: 800px;
    }
    th, td {
      border-bottom: solid 1px #E3E3E3;
      border-right: solid 1px #E3E3E3;
      padding: 10px 8px;
      line-height: 1.6em;
      //-width: 19%;
      @media #{$pc} {
        padding: 20px 18px;
      }
      &:last-child {
        border-right: none;
      }
    }
    thead {
      th {
        text-align: left;
        font-size: 1.2rem;
        color: #7B7F8D;
        @media #{$pc} {
          font-size: 1.4rem;
        }
        &:first-child {
          padding-left: 0;
          width: 25%;
          @media #{$pc} {
            width: 24%;
          }
        }
      }
    }
    tbody {
      tr {
        &.bgGray {
          td {
            background: #F2F3F4;
          }
        }
      }
      th, td {
        height: 80px; // min-height効かない
        @media #{$pc} {
          //-height: 160px; // min-height効かない
        }
      }
      th {
        text-align: left;
        vertical-align: top;
        font-family: $mincho;
        font-weight: normal;
        font-size: 1.8rem;
        padding-left: 0;
        @media #{$pc} {
          font-size: 2.2rem;
        }
        &.rental {
          vertical-align: middle;
          width: 14%;
        }
      }
      td {
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        @media #{$pc} {
          font-size: 1.8rem;
        }
        &.left {
          text-align: left;
        }
        &.rborder {
          border-right: solid 1px #E3E3E3;
        }
        &.rentalPrice {
          width: 20%;
        }
      }
    }
  }
  &_rentalTitle {
    display: block;
    font-family: $gothic;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: .1em;
    line-height: 1.4em;
    @media #{$pc} {
      font-size: 1.6rem;
    }
  }
  &_subTitle {
    display: block;
    font-size: 1.1rem;
    color: #7B7F8D;
    margin-bottom: .1em;
    line-height: 1.4em;
    @media #{$pc} {
      font-size: 1.6rem;
    }
  }
  &_subComment {
    display: block;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #7B7F8D;
    font-family: $gothic;
    font-weight: bold;
    margin-top: .3em;
    @media #{$pc} {
      font-size: 1.3rem;
      //-line-height: 2.2;
      //-margin-top: 0;
    }
    &.ttlCap {
      margin-top: 1em;
      line-height: 1.8;
    }
  }
  &_subEquip {
    display: block;
    font-size: 1.1rem;
    opacity: .4;
    margin-top: .2em;
    @media #{$pc} {
      font-size: 1.4rem;
    }
    &.is_available {
      opacity: 1;
    }
    i {
      margin-right: 8px;
    }
  }
  &_subOption {
    display: table;
    padding: .5em 1em;
    margin-bottom: 6px;
    font-size: 1rem;
    font-family: $gothic;
    font-weight: bold;
    color: white;
    line-height: 1;
    background-color: #7b7f8d;
    @media #{$pc} {
      font-size: 1rem;
      padding: .6em 1em;
    }
  }
}

.bl_tableData {
  &_table {
    border-spacing: 0;
    width: 100%;
    border-top: 1px solid #C4C4C4;
    tr {
      th, td {
        display: block;
        width: 100%;
        padding: 0;
        text-align: left;
        @media #{$pc} {
          display: table-cell;
        }
        &.el_pg {
          letter-spacing: 0.04em;
          font-size: 1.3rem;
          @media #{$pc} {
            font-size: 1.5rem;
          }
        }
      }
      th {
        padding-top: 28px;
        padding-bottom: 20px;
        @media #{$pc} {
          width: 20.40%;
          vertical-align: top;
          padding: 40px 0;
          border-bottom: 1px solid #C4C4C4;
          .el_hdNum {
            margin-top: -.2em;
          }
        }
      }
      td {
        padding-bottom: 28px;
        border-bottom: 1px solid #C4C4C4;
        @media #{$pc} {
          vertical-align: top;
          padding: 40px 0;
          width: auto;
        }
        > p + p {
          margin-top: 20px;
          @media #{$pc} {
            margin-top: 26px;
          }
        }
        a {
          &.awLink {
            display: table;
            font-size: 1.3rem;
            letter-spacing: 0;
            border-bottom: 1px solid black;
            display: inline-block;
            margin-bottom: .4em;
            line-height: 1.3;
            @media #{$pc} {
              font-size: 1.4rem;
            }
          }
          i {
            margin-left: .4em;
            color: #a9a9a9;
          }
        }
        &:first-child {
          padding-top: 28px;
          @media #{$pc} {
            padding-top: 40px;
          }
        }
        &.bl_tableData_cnt {
          border-bottom: none;
          padding-bottom: 18px;
          @media #{$pc} {
            padding-bottom: 40px;
            border-bottom: 1px solid #C4C4C4;
          }
        }
        &.bl_tableData_btn {
          @media #{$pc} {
            width: 20.61%;
          }
        }
      }
    }
  }
  &__noTopBdr {
    .bl_tableData_table {
      border-top: none;
      tr {
        &:first-child {
          th {
            padding-top: 0;
          }
          td {
            padding-top: 0;
          }
        }
      }
    }
  }
  // 鹿沼SPAページ使用
  &__boldTopBdr { 
    .bl_tableData_table {
      border-top: 2px solid #000;
      tr {
        th {
          @media #{$pc} {
            border-bottom: 1px dotted #C4C4C4;
          }
        }
        td {
          border-bottom: 1px dotted #C4C4C4;
          &.bl_tableData_cnt {
            @media #{$pc} {
              border-bottom: 1px dotted #C4C4C4;
            }
          }
        }
      }
    }
  }
}

.bl_tableGeneral { // 採用のその他「採用割合」でしかまだ使ってない。汎用で使えるように育てたい。
  table {
    min-width: 600px;
    width: 100%;
    border-top: solid 2px #B4B4B4;
    border-spacing: 0;
    @media #{$pc} {
      min-width: 800px;
    }
    th, td {
      border-bottom: solid 1px #E3E3E3;
      border-right: solid 1px #E3E3E3;
      padding: 10px 8px;
      line-height: 1.6em;
      @media #{$pc} {
        padding: 20px 18px;
      }
      &:last-child {
        border-right: none;
      }
    }
    thead {
      th {
        font-size: 1.2rem;
        color: #7B7F8D;
        @media #{$pc} {
          font-size: 1.4rem;
        }
        &.noDisp {
          display: none;
        }
      }
    }
    tbody {
      th, td {
        height: 80px; // min-height効かない
        &.noDisp {
          display: none;
        }
        a {
          text-decoration: underline;
          i {
            margin-left: .3em;
            opacity: .25;
          }
          &.clBlue {
            color: #0062c3;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      th {
        vertical-align: top;
        font-family: $mincho;
        font-weight: normal;
        font-size: 1.8rem;
        padding-left: 0;
        @media #{$pc} {
          font-size: 2.2rem;
        }
      }
      td {
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        @media #{$pc} {
          font-size: 1.8rem;
        }
        &.left {
          text-align: left;
        }
        &.right {
          text-align: right;
        }
      }
    }
  }
  &__small {
    table {
      tbody {
        th, td {
          height: auto;
        }
        td {
          font-size: 1.2rem;
          font-weight: normal;
          @media #{$pc} {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  &__everyOther {
    table {
      tbody {
        tr:nth-child(odd) {
          td {
            background: #F2F3F4;
          }
        }
      }
    }
  }
}

/* ==========================================================
 data border
========================================================== */
.bl_dataBorder {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: solid 1px #D3D3D3;
  @media #{$pc} {
    border-bottom: none;
  }
  figure {
    width: 100%;
    max-width: 300px;
    margin: auto;
    padding: 48px 0;
    @media #{$pc} {
      padding: 70px 0;
    }
    img {
      width: auto;
      max-height: 130px;
      margin: auto;
    }
  }
  &_data {
    width: 100%;
    border-top: solid 1px #D3D3D3;
    padding: 30px 0;
    @media #{$pc} {
      text-align: center;
      padding: 70px 0;
    }
    &__1col {
      @media #{$pc} {
        border-bottom: solid 1px #D3D3D3;
      }
    }
    &__2col {
      //-text-align: left;
      @media #{$pc} {
        width: 46.61%;
        border-bottom: solid 1px #D3D3D3;
        margin: 0 auto;
      }
    }
    h3 {
      font-family: $garamond;
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: .1em;
      margin-bottom: 1em;
      line-height: 1.4;
      @media #{$pc} {
        font-size: 1.9rem;
      }
      span {
        display: inline-block;
        font-family: $gothic;
        font-size: 1.3rem;
        margin-top: .6em;
        letter-spacing: 0;
        @media #{$pc} {
          font-size: 1.4rem;
        }
      }
    }
    h4 {
      font-family: $gothic;
      font-weight: bold;
      margin-bottom: 1.2em;
      @media #{$pc} {
        margin-bottom: 1.5em;
      }
      i {
        margin-right: .6em;
      }
    }
  }
}

/* ==========================================================
 article list
========================================================== */
.bl_articleList {
  figure {
    position: relative;
  }
  .el_tagUline {
    margin-top: 1em;
    @media #{$pc} {
      margin-top: 1.4em;
    }
  }
  &_title {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.6;
    margin-top: .6em;
    @media #{$pc} {
      font-size: 1.5rem;
      margin-top: 1.2em;
    }
  }
  .el_textLink {
    margin-top: .2em;
    @media #{$pc} {
    }
  }
  &__btnMore {
    width: auto !important;
    display: flex;
    padding: 70px 0 0 30px !important;
    text-align: center;
    @media #{$pc} {
      padding-top: 130px !important;
    }
    .el_icon {
      margin-bottom: 10px;
    }
  }
}

.bl_articleListUnit {
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  @media #{$pc} {
    margin-left: -40px;
    margin-right: -40px;
  }
  &__3col {
    .bl_articleList {
      width: 75%;
      padding: 0 20px;
      border-right: solid 1px rgba(0,0,0,.1);
      @media #{$pc} {
        //-width: calc(100% / 3);
        width: 420px;
        padding: 0 40px;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}

/* ==========================================================
 post
========================================================== */
.bl_post {
  figure {
    position: relative;
  }
  &_title {
    margin-top: 18px;
    span {
      display: block;
      font-family: $gothic;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.1em;
    }
  }
}

.bl_postUnit {
  .bl_post {
    margin-top: 42px;
    &:first-child {
      margin-top: 0;
    }
  }
  @media #{$pc} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .bl_post {
      width: 31.52%;
      margin-top: 0;
    }
  }
  &__3col {
    .bl_post {
    }
  }
}

/* ==========================================================
 supplement
========================================================== */
.bl_sectionSupplement {
  display: flex;
  flex-direction: column;
  @media #{$pc} {
    flex-direction: row;
    align-items: center;
  }
  &_title {
    flex-shrink: 0;
    @media #{$pc} {
      width: 34.23%;
    }
    .el_lv5Hd {
      line-height: 1.6em;
    }
  }
  &_detail {
    margin-top: 20px;
    @media #{$pc} {
      margin-top: 0;
    }
    p {
      line-height: 1.7em;
      margin-top: .8em;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

/* ==========================================================
 news list
========================================================== */
.bl_newsList {
  &_inner {
    display: flex;
    justify-content: space-between;
  }
  &_item {
    width: 70.76%;
    @media #{$pc} {
      width: 23.05%;
    }
  }
  &_date {
    font-family: $garamond;
    font-size: 1.1rem;
    margin-top: 1em;
    @media #{$pc} {
      font-size: 1.2rem;
    }
  }
  &_title {
    color: gray;
    font-size: 1.1rem;
    line-height: 1.8em;
    @media #{$pc} {
      font-size: 1.2rem;
    }
  }
}

/* ==========================================================
 language select
========================================================== */
.bl_langSel {
  display: flex;
  justify-content: center;
  ul {
    background: #F8F8F8;
    display: flex;
    padding: 7px 15px;
  }
  li {
    font-size: 1.1rem;
    line-height: 1.4;
    border-right: solid 1px #989898;
    margin-right: .8em;
    padding-right: .8em;
    @media #{$pc} {
      font-size: 1.3rem;
    }
    a,
    button {
      color: #CCCCCC;
    }
    &.is_active {
      a,
      button {
        color: black;
        font-weight: bold;
        pointer-events: none;
      }
    }
    &:last-child {
      border-right: none;
      margin-right: 0;
      padding-right: 0;
    }
  }
}

/* ==========================================================
 officer list
========================================================== */
.bl_officer {
  ul.bl_officer_ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -20px;
    @media #{$pc} {
      margin-bottom: -38px;
    }
  }
  li.bl_officer_li {
    width: 46.66%;
    margin-bottom: 20px;
    @media #{$pc} {
      width: 29.87%;
      margin-bottom: 38px;
    }
    &__spacer {
      display: none;
      @media #{$pc} {
        display: block;
      }
    }
    figure {
      + .bl_officer_title {
        margin-top: 1em;
      }
    }
  }
  &_title {
    font-size: 1.1rem;
    @media #{$pc} {
      font-size: 1.3rem;
    }
  }
  &_jaName {
    font-family: $mincho;
    font-size: 1.6rem;
    margin-top: .2em;
    @media #{$pc} {
      font-size: 1.8rem;
    }
  }
  &_enName {
    font-family: $garamond;
    letter-spacing: .05em;
    font-size: 1.3rem;
    line-height: 1;
    margin-top: .4em;
    color: #555;
    @media #{$pc} {
      font-size: 1.4rem;
    }
  }
}

/* ==========================================================
 cancel panel
========================================================== */
.bl_panelCancel {
  border: solid 4px black;
  padding: 40px 20px 20px;
  @media #{$pc} {
    padding: 60px 30px 30px;
  }
  &_title {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2em;
    line-height: 1.6;
    @media #{$pc} {
      font-size: 2.4rem;
    }
  }
  &_message {
    font-weight: bold;
    em {
      font-style: normal;
      text-decoration: underline;
      color: #a40000;
      font-size: 125%;
    }
  }
  a.el_btn {
    display: block;
    margin: auto;
  }
}

/* ==========================================================
 key visual
========================================================== */
.bl_keyVisual {
  img {
    @media #{$pc} {
      max-height: 640px;
      object-fit: cover;
      object-position: center;
    }
  }
}


[data-scroll] {
  //opacity: 0;
  //will-change: transform, scale, opacity;
  //transform: translateY(6rem) scale(0.93);
  //transition: all 1.5s linear;
}

[data-scroll="in"] {
  //opacity: 1;
  //transform: translateY(0) scale(1);
}

/* ==========================================================
 icon and links
========================================================== */
.bl_listIconlinks {
  display: flex;
  flex-wrap: wrap;
  gap: 0 1em;
  font-size: 1.3rem;
  @media #{$pc} {
    font-size: 1.5rem;
  }
  li {
    white-space: nowrap;
    a {
      text-decoration: underline;
      &[href^="tel:"] {
        text-decoration: none;
      }
      i {
        margin-right: .3em;
      }
    }
  }
}
// help系コンテンツ

.un_help {
  font-family: $gothic;
  &Head1 {
    text-align: center;
    line-height: 1.3;
    font-size: 2.4rem;
    letter-spacing: .05em;
    span {
      font-size: 46%;
    }
    @media #{$pc} {
      font-size: 4.6rem;
    }
  }
  &Head2 {
    text-align: center;
    line-height: 1.3;
    font-size: 2rem;
    letter-spacing: .05em;
    @media #{$pc} {
      font-size: 3.2rem;
    }
  }
  &Head3 {
    text-align: center;
    line-height: 1.6;
    font-size: 1.6rem;
    letter-spacing: .05em;
    @media #{$pc} {
      font-size: 2.4rem;
    }
  }
  &Head4 {
    text-align: center;
    line-height: 1.6;
    font-size: 1.4rem;
    letter-spacing: .05em;
    @media #{$pc} {
      font-size: 1.7rem;
    }
  }
  &Para1 {
    text-align: center;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.6;
    letter-spacing: .05em;
    @media #{$pc} {
      font-size: 2.4rem;
    }
  }
  &Selection {
    display: flex;
    gap: 24px;
    margin-top: 15px !important;
    justify-content: center;
    @media #{$pc} {
      gap: 45px;
    }
    li {
      max-width: 80px;
      @media #{$pc} {
        max-width: 118px;
      }
      a {
        display: block;
        color: #A40000;
        font-weight: bold;
        text-align: center;
        p {
          display: inline-block;
          font-size: 1.2rem;
          background: url(/assets/images/help/onlineconcierge/oc_icon_angle_bottom.svg) no-repeat right center;
          background-size: 1em;
          padding-right: 1.2em;
          white-space: nowrap;
          @media #{$pc} {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
  &Btn {
    @media #{$pc} {
      text-align: center;
    }
    a {
      display: block;
      text-align: center;
      font-size: 2rem;
      line-height: 3;
      background: #A40000;
      color: white;
      font-weight: bold;
      border-radius: 4rem;
      @media #{$pc} {
        font-size: 2.3rem;
        padding: 0 2em;
        max-width: 350px;
        margin: auto;
      }
      i {
        margin-right: .3em;
      }
    }
  }
  &Chatbtn {
    margin-left: auto;
    margin-right: auto;
    max-width: 80px;
    @media #{$pc} {
      max-width: 100px;
    }
  }
}
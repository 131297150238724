/* ==========================================================
 unique
========================================================== */

.un_aboutBtnAfter {
  margin-top: 30px;
  @media #{$pc} {
    position: absolute;
    right: 25px;
    bottom: 15px;
  }
}

/* ==========================================================
 page / business
========================================================== */
.un_business_lv1_mtb {
  margin-top: 62px;
  margin-bottom: 80px;
  @media #{$pc} {
    margin-top: 220px;
    margin-bottom: 180px;
  }
}

/* ==========================================================
 page / company
========================================================== */
.un_company_lv1_mtb {
  margin-top: 62px;
  margin-bottom: 54px;
  @media #{$pc} {
    margin-top: 110px;
    margin-bottom: 100px;
  }
}
.un_hd_ms {
  text-align: center;
  font-family: $garamond;
  font-weight: normal;
  letter-spacing: .05em;
  font-size: 2.8rem;
  line-height: 1;
  margin: 46px auto 52px;
  span {
    display: inline-block;
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  @media #{$pc} {
    margin: 105px auto 62px;
    font-size: 3.2rem;
    span {
      font-size: 1.4rem;
      margin-bottom: 15px;
    }
  }
}
.un_company_philosophy {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 215px;
  padding-bottom: 96px;
  @media #{$pc} {
    padding-top: 265px;
    padding-bottom: 200px;
  }
  .el_icon_asterisk, .el_lv2Hd {
    margin-bottom: 32px;
    @media #{$pc} {
      margin-bottom: 80px;
    }
  }
}

/* ==========================================================
 page / company / top message
========================================================== */
.un_topMessage_langSel {
  margin: 40px auto -28px;
  @media #{$pc} {
    justify-content: flex-end;
    margin-bottom: -60px;
  }
}
.un_topMessage {
  @media #{$pc} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  > figure {
    margin-left: -25px;
    margin-right: -25px;
    @media #{$pc} {
      margin-left: 0;
      margin-right: 0;
      margin: auto;
      width: 72%; //51.35%;
    }
  }
  &_detail {
    @media #{$pc} {
      width: 55%; //39.32%;
      margin: 80px auto 0;
    }
    > .el_lv4Hd {
      margin-top: 60px;
      line-height: 1.7;
      @media #{$pc} {
        margin-top: 0;
        line-height: 1.8;
      }
    }
    > .el_pg {
      margin-top: 40px;
      @media #{$pc} {
        margin-top: 48px;
      }
      &.en {
        letter-spacing: .03em;
      }
    }
  }
  &_nameWrap {
    margin-top: 40px;
    text-align: right;
    > figure {
      display: inline-block;
      width: 130px;
      margin-top: 5px;
    }
  }
  &_name {
    font-family: $mincho;
    font-size: 1.8rem;
    line-height: 1.6;
  }
  &__rev {
    flex-direction: row-reverse;
  }
}

/* ==========================================================
 page / company / overview
========================================================== */
/* 削除
.un_company_tableDataBtnRight {
  @media #{$pc} {
    position: absolute;
    right: 0;
    top: -7px;
    width: 286px;
  }
}
*/

/* ==========================================================
 swiper custom
========================================================== */
div.swiper-pagination {

}
span.swiper-pagination-bullet {
  background: white;
  opacity: .2;
  margin: 0 8px !important;
  width: 10px;
  height: 10px;
}
span.swiper-pagination-bullet-active {
  opacity: 1;
}

/* ==========================================================
 Snow Peak Cycle Title
========================================================== */
.un_spcHd {
  font-family: $garamond;
  font-weight: 400;
  font-size: 2.4rem;
  color: white;
  letter-spacing: .08em;
  line-height: 1.7;
  @media #{$pc} {
    font-size: 4.5rem;
  }
}

/* ==========================================================
 bring
========================================================== */
.un_bring {
  background: #E0D6B8;
  padding-top: 60px;
  padding-bottom: 60px;
  @media #{$pc} {
    padding: 140px;
    border-radius: 12px;
  }
  &_head {
    @media #{$pc} {
      display: flex;
      justify-content: space-between;
    }
  }
  &_title {
  }
  &_logo {
    max-width: 207px;
    @media #{$pc} {
      max-width: 267px;
    }
  }
  &_lead {
    margin-top: 38px;
    font-size: 2.2rem;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: .05em;
    @media #{$pc} {
      margin-top: 50px;
      font-size: 2.6rem;
      line-height: 2;
      }
  }
  &_symbol {
    margin: 40px auto;
    max-width: 180px;
    @media #{$pc} {
      max-width: 223px;
      margin: 55px 75px;
    }
  }
  &_detail {
    @media #{$pc} {
      display: flex;
      justify-content: space-between;
      margin-top: 55px;
    }
  }
  &_detailWrap {
    padding: 20px 0;
    border-top: solid 2px black;
    border-bottom: solid 2px black;
    @media #{$pc} {
      width: 62.85%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &_guide {
    font-size: 1.7rem;
    font-weight: bold;
    @media #{$pc} {
      font-size: 1.9rem;
    }
  }
  &_text {
    margin-top: 15px;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.8;
    @media #{$pc} {
      font-size: 1.5rem;
      line-height: 2.1;
    }
  }
  &_fig {
    margin-top: 32px;
    @media #{$pc} {
      margin-top: 0;
      width: 32.85%;
    }
  }
}

/* ==========================================================
 page / products
========================================================== */
.flowHd {
  text-align: center;
  ine-height: 1.9;
  @media #{$pc} {
    text-align: left;
  }
}
.un_products_iconAsterisk {
  text-align: center;
  margin: 96px auto 32px;
  @media #{$pc} {
    margin: 170px auto 80px;
  }
}
.un_products_devflowImg {
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
  @media #{$pc} {
    max-width: initial;
  }
}
.un_products_devflowList {
  margin-top: 48px;
  overflow: hidden;
  @media #{$pc} {
    margin-top: 70px;
  }
  &_ol {
  }
  &_title {
    position: relative;
    font-size: 1.9rem;
    line-height: 1.5;
    margin: 0;
    color: white;
    padding-left: 26px;
    @media #{$pc} {
      font-size: 2.2rem;
      padding-left: 32px;
    }
    &:before {
      position: absolute;
      top: .2em;
      left: 0;
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background: #7A868E;
      border-radius: 50%;
      @media #{$pc} {
        width: 24px;
        height: 24px;
      }
    }
  }
  &_detail {
  }
  li {
    display: block;
    width: calc(100% - 72px);
    margin-left: 9px;
    margin-right: 9px;
    @media #{$pc} {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: auto;
      margin-left: 0;
      margin-right: 0;
      border: solid 1px white;
      border-radius: 6px;
      padding: 48px 40px;
      margin-bottom: 102px;
      &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -102px;
        content: '';
        display: block;
        height: 102px;
        background: url(/assets/images/about/img_products_devflow_arrow.svg) center no-repeat;
      }
    }
    &:nth-child(2) {
      .un_products_devflowList_title {
        &:before {
          background: #697379;
        }
      }
    }
    &:nth-child(3) {
      .un_products_devflowList_title {
        &:before {
          background: #575F65;
        }
      }
    }
    &:nth-child(4) {
      .un_products_devflowList_title {
        &:before {
          background: #464C50;
        }
      }
    }
    &:last-child {
      @media #{$pc} {
        margin-bottom: 0;
        &:after {
          content: none;
        }
      }
    }
    .el_pg {
      margin-top: 26px;
    }
    > div {
      @media #{$pc} {
        width: 54.56%;
      }
    }
    > figure {
      margin-top: 32px;
      @media #{$pc} {
        margin-top: 0;
        width: 40.97%;
      }
    }
  }
}

/* about / products / クライムギア上 */
.un_products_climbgear_top {
  background: url(/assets/images/about/bg_products_climbgear_top.jpg) no-repeat top center;
  background-size: contain;
  padding-top: 70px;
  @media #{$pc} {
    padding-top: 440px;
  }
}

/* about / products / クライムギア下 */
.un_products_climbgear_btm {
  background: url(/assets/images/about/bg_products_climbgear_btm.jpg) no-repeat top center;
  background-size: cover;
  margin-top: 30px;
  height: (850/1440*100)+vw;
}

/* about / products / ソリステマグなど */
.un_products_solidstake {
  height: (2900/750*100)+vw;
  background: url(/assets/images/about/bg_products_second_sp.jpg) no-repeat center;
  background-size: cover;
  @media #{$pc} {
    height: (1900/1440*100)+vw;
    background-image: url(/assets/images/about/bg_products_second_pc.jpg);
  }
}
.un_products_logo_s1958 {
  position: fixed;
  left: 50%;
  top: 50%;
  opacity: 0;
  pointer-events: none;
  width: 120px;
  transform: translateY(-50%) translateX(-50%);
  transition: opacity 0.25s linear;
  &.is_visible {
    opacity: 1;
    transition: opacity 2s linear;
  }
}

/* about / products / 燕三条の地図 */
.un_products_mapSanjo {
  overflow: hidden;
  margin-top: -25vw;
  margin-bottom: -50px;
  @media #{$pc} {
    margin-top: -18vw;
    margin-bottom: -160px;
  }
  figure {
    margin: 0 0 0 -6vw;
    mix-blend-mode: screen;
    @media #{$pc} {
      margin: auto (33/1440*100)+vw auto auto;
      max-width: (624/1440*100)+vw;
    }
  }
}

.un_products_threerules {
  position: relative;
  background: url(/assets/images/about/ph_products_threerules.jpg) no-repeat top center;
  background-size: contain;
  padding-top: 55.55vw;
  @media #{$pc} {
    padding-top: 720px;
  }
  > div {
    position: relative;
    z-index: 1;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 66.67vw;
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 30%,rgba(0,0,0,1) 99%);
  }
}

.un_products_rmem {
  position: relative;
  background: url(/assets/images/about/ph_products_relationmembers_sp.jpg) no-repeat top center;
  background-size: contain;
  //-padding-top: 640px;
  padding-top: 100vh;
  @media #{$pc} {
    background-image: url(/assets/images/about/ph_products_relationmembers_pc.jpg);
    //-padding-top: 850px;
  }
  > div {
    position: relative;
    z-index: 1;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 222.22vw;
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 30%,rgba(0,0,0,1) 99%);
    @media #{$pc} {
      height: 80vw;
      background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,1) 95%);
    }
  }
}

/* about / products / メインビジュアル + title */
.un_product_mv {
  height: calc(100vh - 80px - 50px + 30px); // ヘッダとサブヘッダ分を引いてちょい足し
  @media #{$pc} {
    height: calc(100vh - 80px - 50px + 60px); // ヘッダとサブヘッダ分を引いてちょい足し
  }
  &_inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    padding-top: calc(100vh - 140px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    &:before {
      position: absolute;
      content: '';
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 65%,rgba(0,0,0,1) 100%);
    }
    .ly_wideContent {
      position: relative;
      z-index: 11;
    }
  }
}

/* newproducts / メインビジュアル */
.un_newproduct_mv {
  height: calc(56vh - 80px - 50px + 30px); // ヘッダとサブヘッダ分を引いてちょい足し
  @media #{$pc} {
    height: calc(56vh - 80px - 50px + 60px); // ヘッダとサブヘッダ分を引いてちょい足し
  }
  &_inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60vh;
    padding-top: calc(68vh - 140px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    &:before {
      position: absolute;
      content: '';
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 77%,rgba(0,0,0,1) 100%);
    }
    .ly_wideContent {
      position: relative;
      z-index: 11;
    }
  }
}


/* products/gifts / メインビジュアル */
.un_gifts_mv {
  height: 40vh; // ヘッダとサブヘッダ分を引いてちょい足し
  @media #{$pc} {
    height: 60vh; // ヘッダとサブヘッダ分を引いてちょい足し
  }
  &_inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40vh;
    //-padding-top: calc(68vh - 140px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    &:before {
      position: absolute;
      content: '';
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      //-background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 77%,rgba(0,0,0,1) 100%);
    }
    @media #{$pc} {
      height: 60vh; // ヘッダとサブヘッダ分を引いてちょい足し
    }
    .ly_wideContent {
      position: relative;
      z-index: 11;
    }
  }
}

/* experience メインビジュアル */
.un_bl_experience_wrap {
  overflow-x: hidden;
}
.un_experience_problem { // problem 周辺のpadding設定
  padding-top: 65vh;
  padding-bottom: 80vh;
}
.un_experience_mv {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: black;
  &_inner {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity .25s linear;
    //transition: transform 0 linear;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 65%,rgba(0,0,0,1) 100%);
    }
  }
  &_upper {
    position: relative;
    z-index: 2;
  }
  &.is_hidden {
    visibility: hidden;
  }
}


/* campfield メインビジュアル */
.un_campfield_mv {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: black;
  &_item {
    height: 115vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    transition: opacity .25s linear;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to bottom,  rgba(0,0,0,.2) 0%,rgba(0,0,0,.1) 100%);
    }
  }
  &_upper {
    background: white;
    position: relative;
    z-index: 2;
    &__bgcTransparent {
      background: transparent;
    }
  }
  &.is_hidden {
    visibility: hidden;
  }
}

.un_campfield {
  &_logo {
    height: 76vh;
    padding: 0 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    &__tiny {
      min-height: 300px;
      height: calc(100vh - 168px);
      @media #{$pc} {
        height: calc(100vh - 201px);
      }
    }
  }
  &_detail {

  }
}
.un_campfield_sns {
  position: fixed;
  right: 0;
  bottom: 144px;
  z-index: 999;
  width: 50px;
  mix-blend-mode: difference;
  @media #{$pc} {
    bottom: 224px;
    width: 76px;
  }
  &.andSpa {
    bottom: 200px;
    @media #{$pc} {
      bottom: 300px;
    }
  }
}
.un_campfield_snsLinks_ul {
  text-align: center;
  //-display: flex;
  //-flex-wrap: wrap;
  //-justify-content: center;
  li {
    margin: 0 auto;
    font-size: 2.12rem;
    display: block;
    padding-bottom: 10px;
    @media #{$pc} {
      font-size: 2.4rem;
      padding-bottom: 14px;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}
.un_campfield_snsLinks_ul li a {
  color: rgba(255,255,255,.85);
  mix-blend-mode: difference;
  img {
    mix-blend-mode: difference;
    width: 22px;
    margin: 0 auto;
    padding-top: 2px;
    @media #{$pc} {
      width: 24px;
    }
  }
}
.un_campfield_reserve {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: 50px;
  @media #{$pc} {
    width: 73px;
  }
  &.andSpa {
    bottom: 0;
  }
}
.un_campfield_spaLink {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: 50px;
  @media #{$pc} {
    width: 73px;
  }
  &.andSpa {
    bottom: 92px;
    @media #{$pc} {
      bottom: 134px;
    }
  }
}
.un_campfield_news {
  .bl_funcBtnArrows {
    display: none;
    @media #{$pc} {
      display: block;
      margin-bottom: 20px;
    }
    .el_funcBtnArrow {
      margin-left: 10px;
    }
  }
  .bl_articleList {
    figure {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 66.6666%;
    }
  }
}
.un_campfield_tempdata {
  .bl_cardUnit__3col {
    flex-wrap: nowrap !important;
    .bl_card {
      width: calc(100% - 50px);
      margin-left: 6px;
      margin-right: 6px;
      @media #{$pc} {
        width: 372px;
        margin-left: 16px;
        margin-right: 16px;
        }
    }
  }
  &_ctrl {
    .bl_funcBtnArrows {
      display: none;
    }
    @media #{$pc} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bl_funcBtnArrows {
        display: block;
        .el_funcBtnArrow {
          margin-left: 10px;
        }
      }
    }
  }
}
.un_campfield_covidMes {
  margin-bottom: -32px;
  text-align: center;
  @media #{$pc} {
    margin-bottom: -37px;
    text-align: right;
  }
  a {
    display: inline-block;
    font-size: 1.1rem;
    line-height: 2;
    color: white;
    text-align: center;
    @media #{$pc} {
      font-size: 1.4rem;
      text-align: right;
    }
  }
}

/* 鹿沼 campfield & spa */
.ly_middle {
  figure {
    img.spalogo {
      max-height: 118px;
      text-align: center;
      margin: 100px auto 40px;
      @media #{$pc} {
        font-size: 1.4rem;
        margin: 100px auto 40px;
      }
    }
  }
}
.un_campfield_kanuma_spa {
  background: white;
  .el_lv1SubHd {
    margin-top: 0;
  }
  .bl_jumbotron {
    height: calc(100vw * .64);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media #{$pc} {
      height: 620px;
    }
  }
  .spa_contents_2col,
  .spa_contents_3col  {
    display: unset;
    @media #{$pc} {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .contentsTxt {
    width: 100%;
    @media #{$pc} {
      width: 244px;
    }
    h3 {
      text-align: left;
      font-family: $mincho;
      font-weight: normal;
      font-size: 2.1rem;
      line-height: 1.1;
      margin-bottom: 20px;
      @media #{$pc} {
        font-size: 2.8rem;
        margin-bottom: 30px;
      }
      span {
        font-family: $garamond;
        font-weight: normal;
        font-size: 1.6rem;
        @media #{$pc} {
          font-size: 1.8rem;
        }
      }
    }
    p {
      text-align: left;
      font-family: $gothic;
      font-weight: normal;
      font-size: 1.3rem;
      line-height: 1.9;
    }
  }
  .contentsRight {
    width: 100%;
    padding-left: 0;
    @media #{$pc} {
      padding-left: 56px;
    }
    figure {
      max-width: 100%;
      margin: 20px 0 48px;
      @media #{$pc} {
        margin: 0;
      }
      &.otherCon {
        margin: 0;
      }
    }
    img {
      width: 100%;
    }
  } 
  .spa_contents_other {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    .contents_other_box {
      width: 100%;
      padding-bottom: 30px;
      @media #{$pc} {
        width: calc(100% / 2 - 20px);
        padding-bottom: 50px;
      }
      figure {
        padding-bottom: 16px;
        @media #{$pc} {
          padding-bottom: 26px;
        }
      }
      h4 {
        text-align: left;
        font-family: $mincho;
        font-weight: normal;
        font-size: 2rem;
        line-height: 1.1;
        margin: 8px 0 16px;
        @media #{$pc} {
          font-size: 2.4rem;
          margin: 0 0 16px;
        }
        span {
          font-family: $garamond;
          font-weight: normal;
          font-size: 1.6rem;
          @media #{$pc} {
            font-size: 1.8rem;
          }
        }
      }
      p {
        text-align: left;
        font-family: $gothic;
        font-weight: normal;
        font-size: 1.3rem;
        line-height: 1.9;
      }
    }
  }
  td.el_pg {
    span {
      display: inline-block;
      margin-top: 10px;
      font-size: 1.3rem;
      line-height: 1.6;
    }
  }
  .contentsBnr {
    display: unset;
    @media #{$pc} {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
    }
    .bnrBox {
      margin-bottom: 25px;
      @media #{$pc} {
        margin-bottom: 40px;
        justify-content: space-between;
      }
    }
    .col2 {
      width: 100%;
      height: 80vw;
      @media #{$pc} {
        width: calc(100% / 2 - 20px);
        height: 400px;
      }
    }
    .col1 {
      width: 100%;
      height: 80vw;
      @media #{$pc} {
        height: 400px;
      }
    }
    a.bnrLink {
      display: flex;
      background-color: #000;
      background-size: cover;
      background-position: center;
      justify-content: center;
      align-items: flex-end;
      height: 100%;
    }
    p {
      text-align: center;
      color: #fff;
      font-family: $gothic;
      font-size: 1.4rem;
      letter-spacing: .03em;
      line-height: 1;
      background-image: url("/assets/images/common/icon_arrow-narrow_white.svg");
      background-repeat: no-repeat;
      background-position: right center;
      padding-right: 12px;
      margin-bottom: 24px;
      @media #{$pc} {
        font-size: 1.6rem;
        margin-bottom: 32px;
      }
    }
    &__logo {
      .col1 {
        img {
          max-height: 170px;
        }
      }
      a.bnrLink {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color:rgba(0,0,0,0.3);
        background-blend-mode: darken;
        padding: 0 20px;
      }
      p {
        margin-top: 32px;
        margin-bottom: 0;
        font-weight: bold;
      }
    }
  }
}
.un_campfield_tokachi_sauna {
  &_subTitle {
    display: block;
    span {
      display: inline-block;
      background: black;
      color: white;
      font-weight: bold;
      font-family: $gothic;
      font-size: 1.2rem;
      padding: .2em 1em;
      border-radius: 1em;
      @media #{$pc} {
        font-size: 1.3rem;
      }
    }
  }
  &_copyHeading {
    margin-bottom: 1.5em;
  }
  &_paraPrice {
    font-family: $garamond;
    font-size: 2.8rem;
    font-style: normal;
  }
  &_reserveTel {
    border: solid 10px #E2E2E2;
    padding: 20px;
    text-align: center;
    @media #{$pc} {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 25px;
      padding: 25px 35px;
    }
    &_title {
      font-weight: bold;
      @media #{$pc} {
        font-size: 2rem;
      }
    }
    &_tel {
      font-family: $garamond;
      font-size: 3.2rem;
      letter-spacing: .05em;
      margin-top: .3em;
      margin-bottom: .1em;
      @media #{$pc} {
        font-size: 3.6rem;
        margin-top: 0;
        line-height: 1;
      }
    }
    &_hour {
      font-size: 1.3rem;
      margin-top: 1em;
      @media #{$pc} {
        margin-top: 0;
      }
    }
  }
}

/* campfield レンタルプラン */
.un_campfield_rentalPlan {
  background: #ebebeb;
  padding: 100px 0;
  @media #{$pc} {
    padding: 200px 0;
  }
  &Lead {
    &Main {
      font-weight: bold;
      line-height: 1.6;
      span {
        font-family: $roboto;
        font-size: 150%;
      }
    }
    &Sub {
      margin-top: .5em;
      font-size: 1.1rem;
      @media #{$pc} {
        font-size: 1.3rem;
      }
      a {
        text-decoration: underline;
      }
    }
  }
  &List {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    @media #{$pc} {
      gap: 10px;
    }
    &Item {
      width: calc( calc( 100% - 10px ) / 3 );
      background: white;
      position: relative;
      @media #{$pc} {
        width: calc( calc( 100% - 40px ) / 5 );
      }
      &No {
        display: block;
        font-family: $roboto;
        font-weight: bold;
        font-size: 2rem;
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 2;
        @media #{$pc} {
          font-size: 2.2rem;
          left: 10px;
          top: 10px;
        }
      }
      figure {
        position: relative;
        padding-top: 10px;
        @media #{$pc} {
          padding-top: 0;
        }
        span {
          display: none;
          //display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          background: #666;
          color: white;
          font-size: 1rem;
          font-weight: bold;
          line-height: 1.6;
          padding: 0 .3em;
          @media #{$pc} {
            font-size: 1.2rem;
          }
        }
      }
      div {
        padding: 0 5px 5px;
        @media #{$pc} {
          padding: 0 10px 10px;
        }
      }
      &Label {
        font-size: 1rem;
        font-weight: bold;
        color: #999;
        line-height: 1.4;
        @media #{$pc} {
          font-size: 1.2rem;
        }
      }
      &Name {
        font-size: 1.3rem;
        font-weight: bold;
        line-height: 1.4;
        @media #{$pc} {
          font-size: 1.6rem;
        }
      }
      &Price {
        font-family: $roboto;
        font-size: 1.2rem;
        line-height: 1.4;
        color: #666;
        margin-top: .5em;
        @media #{$pc} {
          font-size: 1.4rem;
        }
      }
    }
  }
  &Sup {
    font-size: 1rem;
    @media #{$pc} {
      font-size: 1.2rem;
    }
    &__small {
      font-size: .9rem;
      @media #{$pc} {
        font-size: 1.1rem;
      }
    }
  }
}

/* 都城campfield */
.un_campfield_miyakonojo {
  &_entry {
    &_title {
      text-align: center;
      font-family: $roboto;
      font-size: 3.2rem;
      letter-spacing: .1em;
      line-height: 1;
      @media #{$pc} {
        font-size: 4.8rem;
      }
    &Lead {
        text-align: center;
        font-family: $mincho;
        font-size: 2.4rem;
        line-height: 1.4;
        margin-top: .5em;
        @media #{$pc} {
          font-size: 3.2rem;
        }
      }
      &Sub {
        text-align: center;
        font-weight: bold;
        font-size: 1.4rem;
        margin-top: 1em;
        @media #{$pc} {
          font-size: 1.6rem;
        }
      }
    }
    &_link {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      @media #{$pc} {
        flex-direction: row;
      }
      &Item {
        flex: 1;
        a {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          background-position: center;
          background-size: cover;
          color: white;
          aspect-ratio: 3 / 2;
          padding: 20px;
          @media #{$pc} {
            aspect-ratio: 1 / 1;
            padding: 30px;
          }
          &:after {
            position: absolute;
            right: 20px;
            content: url(/assets/images/campfield/miyakonojo/icon_arrow_long_right.svg);
            @media #{$pc} {
              right: 30px;
            }
          }
        }
        &Title {
          font-family: $roboto;
          font-weight: bold;
          font-size: 3.2rem;
          line-height: 1.2;
          letter-spacing: .075em;
          span {
            font-size: 75%;
          }
          @media #{$pc} {
            font-size: 4.8rem;
          }
          &Sub {
            font-size: 1.5rem;
            font-weight: bold;
            line-height: 1.2;
            @media #{$pc} {
              font-size: 1.8rem;
            }
          }
        }
      }
      &__2col {
        .un_campfield_miyakonojo_entry_linkItem {
          a {
            @media #{$pc} {
              aspect-ratio: 4 / 3;
            }
          }
        }
      }
    }
  }

  &_navLink {
    display: flex;
    justify-content: center;
    gap: 10px;
    @media #{$pc} {
      gap: 30px;
    }
    li {
      margin: 15px 0;
      @media #{$pc} {
        margin: 20px 0;
      }
      a {
        font-size: 12px;
        font-weight: bold;
        @media #{$pc} {
          font-size: 15px;
        }
      }
      &.current {
        border-bottom: solid 2px black;
      }
    }
  }
  &_lpTitle {
    figure {
      img {
        object-fit: cover;
        object-position: center;
        height: 220px;
        @media #{$pc} {
          height: 500px;
        }
      }
    }
    h1 {
      text-align: center;
      font-size: 1.8rem;
      line-height: 1.1;
      margin-top: -30px;
      @media #{$pc} {
        font-size: 2.4rem;
        margin-top: -60px;
      }
      span {
        display: block;
        font-family: $roboto;
        font-size: 6rem;
        letter-spacing: .075em;
        @media #{$pc} {
          font-size: 11rem;
        }
      }
    }
  }
  &_lpLeadcopy {
    padding: 0 25px;
    @media (min-width: 920px) and (max-width: 1230px) {
      margin-left: 25px !important;
    }
    @media #{$pc} {
      display: flex;
      padding: 0;
      margin-left: calc((100% - 1180px) / 2);
    }
    div {
      @media #{$pc} {
        width: 100%;
        max-width: 500px;
      }
      h2 {
        @media #{$pc} {
          margin-bottom: 60px;
        }
      }
    }
    figure {
      margin-top: 50px;
      @media #{$pc} {
        width: 100%;
        margin-top: 0;
        background-size: cover;
        background-position: center;
        img {
          display: none;
        }
      }
    }
  }
  &_lpCategory {
    div {
      h3 {
        font-size: 1.3rem;
        line-height: 2.2;
        @media #{$pc} {
          font-size: 1.5rem;
        }
        span {
          font-family: $garamond;
          font-weight: normal;
          font-size: 3.6rem;
          letter-spacing: .075em;
          margin-right: .2em;
          line-height: 1.1;
          @media #{$pc} {
            font-size: 4.8rem;
            line-height: 1.2;
          }
        }
      }
    }
    figure {
      img {
        @media #{$pc} {
          object-fit: cover;
          object-position: center;
          height: 670px;
        }
      }
    }
  }
  &_lpContent {
    &.bl_card {
      figure {
        margin-bottom: 25px;
      }
    }
    &_text {
      margin-top: 20px;
    }
    &_detail {
      margin-top: 20px;
      @media #{$pc} {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        margin-top: 30px;
      }
      &Data {
        @media #{$pc} {
          flex: 1;
        }
        table {
          width: 100%;
          border-top: solid 1px rgba(0,0,0,.5);
          text-align: left;
          border-collapse: collapse;
          font-size: 1.3rem;
          @media #{$pc} {
            font-size: 1.4rem;
          }
          tr {
            th {
              border-bottom: solid 1px rgba(0,0,0,.1);
              padding: 1em .5em 1em 0;
            }
            td {
              border-bottom: solid 1px rgba(0,0,0,.1);
              padding: 1em 0;
            }
          }
        }
        .garamond {
          font-family: $garamond;
          font-size: 200%;
        }
        .el_textLink {
          margin-top: .8em;
        }
      }
      &Equip {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li {
          margin-right: 1em;
        }
        font-size: 1.3rem;
        @media #{$pc} {
          font-size: 1.4rem;
        }
        i {
          font-size: 1.4rem;
          margin-right: .3em;
          @media #{$pc} {
            font-size: 1.5rem;
          }
        }
      }
      &Btn {
        margin-top: 20px;
        @media #{$pc} {
          margin-top: 0;
        }
        a {
          text-align: center;
          display: block;
          background: black;
          padding: .8em 1.2em;
          color: white;
          font-size: 1.3rem;
          font-weight: bold;
          @media #{$pc} {
            font-size: 1.5rem;
          }
          i {
            margin-left: .3em;
          }
          &:nth-child(2) {
            margin-top: 20px;
            @media #{$pc} {
              margin-top: 10px;
            }
            i {
              margin-left: 0;
              margin-right: .3em;
            }
            background-color: transparent;
            color: black;
            border: solid 1px #ddd;
            padding-top: calc(.8em - 1px);
            padding-bottom: calc(.8em - 1px);
          }
        }
      }
      &__row {
        @media #{$pc} {
          display: block;
          .un_campfield_miyakonojo_lpContent_detailBtn {
            margin-top: 30px;
            a {
              display: inline-block;
            }
          }
        }
      }
    }
    &_inquiry {
      background: #f5f5f5;
      text-align: center;
      margin-top: 20px;
      padding: 1em;
      @media #{$pc} {
        display: flex;
        justify-content: center;
        gap: 15px
      }
      &Title {
        font-size: 1.3rem;
        margin-bottom: .3em;
        @media #{$pc} {
          font-size: 1.5rem;
          margin-bottom: 0;
        }
      }
      &Phone {
        font-family: $roboto;
        font-size: 2rem;
        //line-height: 1.2;
        @media #{$pc} {
          font-size: 2.4rem;
        }
        i {
          font-size: 80%;
          margin-right: .2em;
        }
      }
    }
  }
  &_figureSwiper {
    figure {
      img {
        aspect-ratio: 1 / 1;
        object-fit: cover;
        object-position: center;
        @media #{$pc} {
          aspect-ratio: 1180 / 672;
        }
      }
    }
    .swiper-pagination {
      &-bullet {
        width: 30px;
        padding: 14px 0;
        border-radius: 0;
        margin: 0 2px !important;
        background: none;
        &:after {
          display: block;
          content: '';
          width: 100%;
          height: 2px;
          background: white;
        }
      }
    }
  }
}


/* ==========================================================
 page / award
========================================================== */
.un_pageSideLinks_bridge {
  display: block;
  position: sticky;
  top: 100px;
  //height: 0;
  //padding: 200px 25px;
  //margin-top: 200px;
  //transform: translateY(300px);
  > .bl_pageSideLinks {
    position: absolute;
    top: 0;
    //padding-top: 300px;
    height: 0;
  }
}


/* ==========================================================
 alert link
========================================================== */
.un_alertLink {
  padding: 12px 12px 12px 24px;
  @media #{$pc} {
    text-align: center;
    padding: 20px;
  }
  a {
    display: inline-block;
    font-size: 1.2rem;
    color: #a40000;
    font-weight: bold;
    text-indent: -8px;
    padding-left: 8px;
    line-height: 1.8;
    @media #{$pc} {
      font-size: 1.4rem;
      text-indent: 0;
      padding-left: 0;
    }
  }
}


/* ==========================================================
 media / event photo
========================================================== */
.un_eventPhotoArchive_logo {
  img {
    max-width: 120px;
    margin: 60px auto 20px;
  }
  &__lt {
    img {
      max-width: 400px;
      margin: 80px auto 60px;
    }
  }
}

.un_eventPhotoArchive_title {
  text-align: center;
  font-family: $mincho;
  font-weight: normal;
  font-size: 2.1rem;
  line-height: 1.4;
  @media #{$pc} {
    font-size: 2.8rem;
  }
  span {
    font-family: $gothic;
    font-weight: bold;
    font-size: 1.5rem;
    @media #{$pc} {
      font-size: 2rem;
    }
  }
}

/* custom for modula */
article.modula-album {
  animation-name: modulaAlbumFadein;
  animation-duration: 3s;
}
@keyframes modulaAlbumFadein {
  0% {
    opacity:0;
  }
  66% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
.modula-items {
  .figc {
    .jtg-title {
      min-height: 44px !important;
      @media #{$pc} {
        min-height: auto !important;
      }
    }
  } 
}
/*.modula-item-content {
  .figc {
    padding: 1em 0 3em 0 !important;
    background: white !important;
    color: black !important;
  }
}*/

/* ==========================================================
 wallpaper
========================================================== */
ul.itemsGallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 3em;
  //-padding: 0 25px;
  li {
    width: calc(100% / 2 - 12px);
    margin-bottom: 2em;
    a {
      display: block;
      div {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    &.border {
      width: 100%;
      margin: 0;
      border {
        visibility: hidden;
        height: 0;
        width: 0;
      }
    }
    h3 {
      padding: 0;
      margin: 0;
    }
    h4 {
      display: inline-block;
      font-size: 1.1rem;
      font-family: $roboto;
      font-weight: 500;
      line-height: 1.2em;
      color: gray;
      border: solid 1px lightgray;
      border-radius: 4px;
      padding: 2px 10px 1px 10px;
      vertical-align: middle;
    }
    h5 {
      font-family: $garamond;
      font-size: 1.4rem;
      line-height: 1.8em;
      margin: 0;
      padding: 0;
      color: gray;
      span.saleLabel {
        display: inline-block;
        font-size: 1.1rem;
        font-family: $roboto;
        font-weight: 500;
        line-height: 1.2em;
        color: white;
        background: #a40000;
        border-radius: 4px;
        padding: 2px 6px 1px 6px;
        vertical-align: middle;
        margin-left: .5em;
      }
      span.proper {
        text-decoration: line-through;
      }
    }
    p {
      padding-left: 0;
      padding-right: 0;
      margin-top: .6em;
      margin-bottom: 0;
      line-height: 1.5em;
    }
  }
  &.big {
    li {
      width: 100%;
    }
  }
}
@media #{$pc} {
  ul.itemsGallery {
    justify-content: space-between;
    li {
      width: calc(100% / 2 - 15px);
    }
  }
}

/* ==========================================================
 page / membership / survey
========================================================== */
.un_surveyRankLabel {
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
  @media #{$pc} {
    font-size: 2rem;
  }
  span {
    display: inline-block;
    margin-right: .2em;
    margin-bottom: .2em;
    padding: .1em .3em;
    line-height: 1.2;
  }
  &__c5 {
    background-color: #e85877;
  }
  &__c4 {
    background-color: #f2b011;
  }
  &__c3 {
    background-color: #309e5d;
  }
  &__c2 {
    background-color: #02b5b2;
  }
  &__c1 {
    background-color: #445771;
  }
}

/* ==========================================================
 page / recruit
========================================================== */
.un_recruit {
  &_executivePerson {
    display: flex;
    align-items: center;
    gap: 16px;
    @media #{$pc} {
      justify-content: center;
    }
    figure {
      width: 75px;
      border-radius: 50%;
      overflow: hidden;
    }
    &Div {
      font-size: 1.3rem;
    }
    &Name {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
}

/* ==========================================================
 page / recyarl
========================================================== */
.un_recyarl {
  &_location {
    border: solid 3px #ddd;
    padding: 15px;
    @media #{$pc} {
      padding: 40px;
    }
  }
  &_about {
    background: #ebebeb;
    padding: 70px 0;
    @media #{$pc} {
      padding: 140px 0;
      margin-bottom: 140px;
    }
    ul.itemsGallery li p {
      margin-top: 1em;
    }
  }
}

/* ==========================================================
 modal / standard
========================================================== */
.un_modalStd {
  @media #{$pc} {
    max-width: 800px !important;
    padding: 50px !important;
  }
  &Content {
    h4 {
      font-size: 1.8rem;
      @media #{$pc} {
        font-size: 2.1rem;
      }
    }
    h5 {
      font-size: 1.4rem;
      margin: 2em 0 1em;
      @media #{$pc} {
        font-size: 1.6rem;
      }
    }
    p {
      font-size: 1.3rem;
      line-height: 1.6;
      margin-top: 1em;
      @media #{$pc} {
        font-size: 1.5rem;
      }
    }
    ul {
      margin: 0;
      padding-left: 1.2em;
      li {
        line-height: 1.6;
        font-size: 1.3rem;
        @media #{$pc} {
          font-size: 1.5rem;
        }
      }
    }
  }
}
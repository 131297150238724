/* ==========================================================
 form
========================================================== */
.el_formSelect {
  appearance: none;
  border: none;
  outline: none;
  background: url(/assets/images/common/icon_navbtn_arrow_down.svg) no-repeat right center;
  padding-right: 15px;
  border-bottom: solid 1px #646464;
  font-size: 1.2rem;
  @media #{$pc} {
    font-size: 1.3rem;
  }
  &__search {
    width: 22px;
    background-image: url(/assets/images/common/icon_search.svg);
    background-position: 5px center;
    background-repeat: no-repeat;
    background-size: 12px;
    padding: 0 0 0 25px;
    transition: all .3s cubic-bezier(0,.87,.19,1);
    cursor: pointer;
    &:focus {
      width: 200px;
      background-color: whitesmoke;
      cursor: auto;
    }
  }
}

.el_formInput {
  display: block;
  background: #eee;
  border: none;
  width: 100%;
  padding: .6em .5em;
  border-radius: 4px;
  max-width: 690px;
  margin: auto;
  &__search {
    font-size: 14px;
    padding: .8em .6em .8em 3em;
    background-image: url(/assets/images/common/icon_search.svg);
    background-position: 14px center;
    background-repeat: no-repeat;
    background-size: 16px;
    @media #{$pc} {
      font-size: 16px;
      background-size: auto;
    }
  }
}
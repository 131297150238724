// common
.contentsBlock {
  padding-left: 25px;
  padding-right: 25px;
}


// recommend
#recommendVisual {
  max-width: 1440px; //$maxWidth;
  margin: 40px auto 0 auto;
  figure {
    width: 100%;
    margin-bottom: 16px;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.pageContent {
  &.recommend {
    @extend .contentsBlock;
    max-width: $maxWidth;
    margin: 50px auto 100px auto;
    h1 {
      font-family: $garamond;
      font-size: 3rem;
      padding: 0;
      margin-bottom: 1.5em;
      &.ja {
        font-size: 2.8rem;
        font-family: $mincho;
        line-height: 1.4em;
        margin-bottom: .5em;
        small {
          font-size: 50%;
        }
      }
    }
    h2 {
      font-family: $garamond;
      font-size: 2.4rem;
      margin: 1em 0;
      letter-spacing: 0.05em;
      padding: 0;
      margin-top: 2em;
      border-bottom: solid 1px gainsboro;
      &.langJp {
        font-family: $mincho;
      }
    }
    h3 {
      padding: 0;
      margin: 0;
    }
    h4 {
      display: inline-block;
      font-size: 1.1rem;
      font-family: $roboto;
      font-weight: 500;
      line-height: 1.2em;
      color: gray;
      border: solid 1px lightgray;
      border-radius: 4px;
      padding: 2px 10px 1px 10px;
      vertical-align: middle;
    }
    h5 {
      font-family: $garamond;
      font-size: 1.4rem;
      line-height: 1.8em;
      margin: 0;
      padding: 0;
      color: gray;
      span.saleLabel {
        display: inline-block;
        font-size: 1.1rem;
        font-family: $roboto;
        font-weight: 500;
        line-height: 1.2em;
        color: white;
        background: #a40000;
        border-radius: 4px;
        padding: 2px 6px 1px 6px;
        vertical-align: middle;
        margin-left: .5em;
      }
      span.proper {
        text-decoration: line-through;
      }
      &.price {
        display: inline-block;
      }
    }
    button {
      margin-top: .5em;
      width: 100%;
      font-size: 1rem;
      font-weight: bold;
      border-radius: 2em;
      border: solid 1px rgba(164, 0, 0, .3);
      color: #a40000;
      line-height: 2em;
      padding: 0 .4em;
      cursor: pointer;
      @media #{$pc} {
        font-size: 1.1rem;
      }
      i {
        color: #a40000;
      }
    }
    p {
      font-family: $mincho;
      font-size: 1.4rem;
      line-height: 1.8em;
      letter-spacing: 0.05em;
      margin: 0;
      padding: 0;
      &.subTitle {
        color: black;
        line-height: 1.2em;
        a {
          display: inline-block;
          background: whitesmoke;
          border-radius: 2em;
          padding: .6em 1em;
          margin-bottom: 3em;
          i {
            color: black;
          }
        }
      }
      &.leadCopy {
        font-size: 1.4rem;
        margin-bottom: 2em;
      }
      &.subCopy {
        font-size: 1.4rem;
        margin-bottom: 2em;
        small {

        }
      }
      &.anchorLink {
        text-align: center;
        font-size: 1.4rem;
        a {
          width: 100%;
          max-width: 400px;
          display: inline-block;
          border: solid 1px gainsboro;
          padding: 1em;
          font-family: $gothic;
        }
      }
      &.marginTop {
        margin-top: 7em;
      }
      &.desc {
        font-family: $gothic;
        font-size: 1.2rem;
        background: whitesmoke;
        margin-top: .5em;
        padding: .4em .6em;
        line-height: 1.6em;
      }
      span {
        &.attention {
          font-size: 1.2rem;
        }
      }
      &.releaseDate {
        margin-left: .2em;
        margin-top: .4em;
        display: inline-block;
        font-size: 1rem;
        font-family: $gothic;
        font-weight: bold;
        padding: .1em .5em;
        background: whitesmoke;
        color: gray;
        @media #{$pc} {
          font-size: 1.1rem;
        }
      }
    }
    ul.recommendItems {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      list-style: none;
      margin-bottom: 3em;
      li {
        width: calc(100% / 2 - 12px);
        margin-bottom: 2em;
        a {
          display: block;
          div {
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        &.border {
          width: 100%;
          margin: 0;
          border {
            visibility: hidden;
            height: 0;
            width: 0;
          }
        }
        &.preparation {
          a {
            h4 {
              position: relative;
              &:after {
                position: absolute;
                width: 5em;
                left: calc(100% + 5px);
                bottom: 0;
                display: inline-block;
                content: "準備中";
                font-size: 1.1rem;
                line-height: 1.2em;
                color: #fff;
                border-radius: 4px;
                padding: 3px 0 1px 0;
                text-align: center;
                vertical-align: middle;
                background: #999;
              }
            }
          }
        }
      }
      &.firstStandout {
        li:first-child {
          width: 100%;
          a {
            h4 {
              position: relative;
              &:after {
                position: absolute;
                width: 7em;
                left: calc(100% + 5px);
                bottom: 0;
                display: inline-block;
                content: "雪峰祭限定";
                font-size: 1.1rem;
                line-height: 1.2em;
                color: white;
                border-radius: 4px;
                padding: 3px 0 1px 0;
                text-align: center;
                vertical-align: middle;
                background: #9f4a4d;
              }
            }
          }
        }
      }
    }
    div.subVisual {
      max-width: 800px;
      margin: 2em auto 3em auto;
      &.vertical {
        max-width: 500px;
      }
      img {
        width: 100%;
        height: auto;
      }
      &.marginTop {
        margin-top: 7em;
      }
    }
    div.styleRecipe {
      max-width: 1000px;
      margin: 0 auto 6em auto;
      h3 {
        font-size: 1.8rem;
        margin-bottom: 1em;
      }
      h4 {
        margin-top: 3em;
        margin-bottom: 1em;
        font-size: 1.2rem;
      }
      div.ingredient {
        div.photo {
          img {
            width: 100%;
            height: auto;
          }
        }
        .ingredientTable {
          table {
            width: 100%;
            font-size: 1.2rem;
            tr {
              th {
                text-align: left;
                font-weight: normal;
                border-bottom: solid 1px gainsboro;
                padding: .2em;
                &.groupHead {
                  font-weight: bold;
                }
                &.group {
                  padding-left: 1.2em;
                }
              }
              td {
                border-bottom: solid 1px gainsboro;
                padding: .2em;
              }
            }
          }
          p {
            font-family: $gothic;
            font-size: 1.2rem;
            margin-top: .5em;
          }
        }
      }
      ul.making {
        list-style: none;
        li {
          margin-bottom: 1.5em;
          &:last-child {
            margin-bottom: 0;
          }
          div.photo {
            position: relative;
            span {
              position: absolute;
              bottom: 0;
              left: 0;
              font-family: $garamond;
              display: inline-block;
              background: rgba(0,0,0,0.5);
              color: white;
              padding: .1em .6em;
              &.numOnly {
                position: static;
              }
            }
            img {
              width: 100%;
              height: auto;
            }
          }
          p {
            font-family: $gothic;
            font-size: 1.2rem;
            margin-top: .5em;
          }
        }
      }
    }
    div.warning {
      padding: .4em .6em;
      border: solid 2px black;
      max-width: 800px;
      margin: .2em auto;
      dl {
        display: flex;
        line-height: 1.4em;
        dt {
          font-size: 1.4rem;
          i {
            color: black;
          }
        }
        dd {
          color: black;
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
    }
  }
  &.otherScene {
    background: whitesmoke;
    padding-top: 40px;
    padding-bottom: 60px;
    margin-bottom: 0;
    max-width: none;
    @extend .contentsBlock;
    h2 {
      text-align: center;
      font-family: $garamond;
      letter-spacing: .4em;
      font-size: 1.5rem;
    }
    ul {
      max-width: $maxWidth;
      margin: auto;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: calc(100% / 2 - 12px);
        margin-top: 2em;
        a {
          display: block;
          div {
            img {
              width: 100%;
              height: auto;
            }
          }
          h3 {
            padding: 0;
            margin: .5em 0;
            letter-spacing: .05em;
            font-family: $garamond;
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  &.landingList {
    padding-top: 40px;
    padding-bottom: 60px;
    margin-bottom: 0;
    max-width: none;
    @extend .contentsBlock;
    h2 {
      text-align: center;
      color: #252525;
      font-family: $gothic;
      //font-family: $mincho;
      letter-spacing: .1em;
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 1.2em;
      padding-top: 2em;
      padding-left: 0;
      padding-right: 0;
      small {
        font-size: .5em;
        letter-spacing: .05em;
      }
    }
    p {
      padding-left: 0;
      padding-right: 0;
      @media #{$pc} {
        text-align: center;
        font-size: 1.8rem;
        margin-bottom: 3em;
      }
      span.hashtag {
        border: solid 1px gainsboro;
        padding: .3em;
        background: whitesmoke;
        border-radius: 4px;
        white-space: nowrap;
        margin: 0 .3em;
      }
    }
    ul {
      max-width: $maxWidth;
      margin: auto;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: calc(100% / 2 - 12px);
        margin-top: 3em;
        a {
          display: block;
          div {
            img {
              width: 100%;
              height: auto;
            }
          }
          h3 {
            padding: 0;
            margin: .8em 0 0 0;
            letter-spacing: .05em;
            color: #8a8a8a;
            font-family: $garamond;
            font-size: 1.2rem;
            text-align: center;
          }
          p {
            padding: 0;
            margin: 0 0 .5em 0;
            letter-spacing: .05em;
            font-family: $mincho;
            //font-size: 1.3rem;
            font-size: 1.5rem;
            line-height: 2.2rem;
            text-align: center;
            margin-right: -1em;
          }
          button {
            margin-top: .5em;
            width: 100%;
            font-size: 1.1rem;
            font-weight: bold;
            border-radius: 2em;
            border: solid 1px rgba(164, 0, 0, .3);
            color: #a40000;
            line-height: 2em;
            padding: 0 .4em;
            cursor: pointer;
            @media #{$pc} {
              display: block;
              //font-size: 1.1rem;
              font-size: 1.2rem;
              width: 60%;
              margin-left: auto;
              margin-right: auto;
            }
            i {
              color: #a40000;
            }
          }
        }
      }
    }
  }
}
@media #{$pc} {
  #recommendVisual {
    //padding: 0 25px;
    margin-top: 90px;
    figure {
      margin-bottom: 40px;
      img {
        height: 600px;
        object-fit: cover;
        font-family: 'object-fit: cover;'
      }
    }
  }
  .pageContent {
    &.recommend {
      margin: 120px auto 150px auto;
      h1 {
        //max-width: 950px;
        font-size: 7rem;
        letter-spacing: .05em;
        &.ja {
          font-size: 6rem;
        }
      }
      h2 {
        font-size: 3.2rem;
        //max-width: 950px;
        margin-left: auto;
        margin-right: auto;
      }
      /*h3 {
        text-align: center;
      }
      h4 {
        font-size: 3.2rem;
        max-width: 950px;
      }*/
      p {
        font-size: 1.8rem;
        //max-width: 950px;
        margin-left: auto;
        margin-right: auto;
        font-weight: 600;
        letter-spacing: .1em;
        &.subTitle {
          font-size: 2.1rem;
        }
        &.leadCopy {
          font-size: 1.6rem;
          line-height: 2.4em;
        }
        &.subCopy {
          font-size: 1.6rem;
          line-height: 2.4em;
          text-align: center;
        }
        span {
          &.attention {
            font-size: 1.4rem;
          }
        }
      }
      ul.recommendItems {
        justify-content: center;
        li {
          width: calc(100% / 5 - 30px);
          margin-left: 15px;
          margin-right: 15px;
          //margin-bottom: 2em;
          a {
            div {
            }
          }
        }
        &.firstStandout {
          li:first-child {
            width: calc(100% / 5 * 2 - 30px);
          }
        }
        &.pcThree {
          li {
            width: calc(100% / 3 - 30px);
            &.border {
              width: 100%;
            }
          }
        }
        &.pcFour {
          li {
            width: calc(100% / 4 - 30px);
            &.border {
              width: 100%;
            }
          }
        }
      }
      div.styleRecipe {
        h3 {
          font-size: 2.1rem;
        }
        h4 {
          font-size: 1.4rem;
          margin-top: 0;
        }
        div.ingredient {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 2em;
          div.photo {
            width: 60%;
          }
          .ingredientTable {
            width: 37%;
            table {
              font-size: 1.4rem;
            }
          }
        }
        ul.making {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 23%;
            margin-bottom: 0;
            p {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
    &.otherScene {
      padding-top: 100px;
      padding-bottom: 100px;
      h2 {
        font-size: 1.8rem;
      }
      ul {
        li {
          width: calc(100% / 3 - 25px);
          a {
            h3 {
              font-size: 1.6rem;
            }
          }
        }
        &:after {
          content: " ";
          display: block;
          width: calc(100% / 3 - 25px);
        }
      }
    }
    &.landingList {
      padding-top: 0;
      padding-bottom: 100px;
      h2 {
        font-size: 3.6rem;
        small {
          font-size: .5em;
        }
      }
      ul {
        padding-left: 25px;
        padding-right: 25px;
        li {
          width: calc(100% / 3 - 25px);
          a {
            h3 {
              //font-size: 1.6rem;
              font-size: 1.4rem;
            }
            p {
              //font-size: 1.4rem;
              font-size: 2rem;
              line-height: 1.6em;
            }
          }
        }
        &:after {
          content: " ";
          display: block;
          width: calc(100% / 3 - 25px);
        }
      }
    }
  }
}


// takibidai
div.takibiCombiImg {
  width: 100%;
  max-width: 550px;
  margin: auto;
  img {
    width: 100%;
    height: auto;
  }
}
table.takibidaiOption {
  width: 100%;
  margin-bottom: 3em;
  tr {
    th {
      width: calc(100% / 3);
      border-left: solid 1px gainsboro;
      border-bottom: solid 1px gainsboro;
      padding: 2em .5em;
      vertical-align: top;
      &:first-child {
        border-left: none;
      }
      span {
        display: block;
        text-align: center;
        font-family: $mincho;
        font-weight: normal;
        line-height: 2em;
      }
    }
    td {
      padding: 2em .5em;
      border-left: solid 1px gainsboro;
      border-bottom: solid 1px gainsboro;
    }
    &:last-child {
      th, td {
        border-bottom: none;
      }
    }
  }
  div {
    img {
      width: 100%;
      height: auto;
    }
  }
}

@media #{$pc} {
  div.takibiCombiImg {
    padding-right: 12%;
    &.takibiJikaroCombiImg {
      padding-right: 0;
    }
  }
  table.takibidaiOption {
    tr {
      th {
        width: calc(100% / 7);
        padding: 1em 1em;
        span {
          line-height: 2em;
        }
      }
      td {
        padding: 1em 1em;
      }
    }
  }
}

// exhibit
div.listContent {
  //overflow-x: hidden;
  /*overflow-y: hidden;
  @media #{$pc} {
    overflow-y: inherit;
  }*/
  div.sticky_table_wrapper {
    //overflow: scroll;
    //width: calc(100vw);
    //height: 75vh;
    @media #{$pc} {
      height: calc(100% + 17px);
      //max-width: 1230px;
      //width: 100%;
      //overflow: hidden;
    }
    table {
      //width: 100%;
      width: 1200px;
      //table-layout: fixed;
      font-size: 1.2rem;
      @media #{$pc} {
        width: 100%;
        font-size: 1.4rem;
      }
      &.sticky_table {
        border-collapse: collapse;
        thead {
          th {
            background: #cecece;
            color: #000;
            font-weight: bold;
            text-align: left;
            padding: 1em 1em;
            /* 縦スクロール時に固定する */
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            /* tbody内のセルより手前に表示する */
            z-index: 1;
            border-right: solid 1px gainsboro;
            &:last-child {
              border-right: none;
            }
            span {
              vertical-align: -webkit-baseline-middle;
              margin: auto 0;
            }
            .itemName {
              display: flex;
              white-space: nowrap;
            }
            img {
              width: 50px;
              height: 37.5px;
              margin: 0 .8rem 0 0;
              mix-blend-mode: darken;
            }
          }
        }
        tbody {
          th, td {
            text-align: left;
            padding: 1em 1em;
            font-weight: normal;
            border-right: solid 1px gainsboro;
            &:last-child {
              border-right: none;
            }
            @media #{$pc} {
              padding: 1.5em 1em;
            }
          }
          tr {
            &:nth-child(even) {
              th { background: darken(#FAFAFA, 4%); }
              td { background: darken(#FFF, 4%); }
            }
            &:nth-child(odd) {
              th { background: #FAFAFA; }
              td { background: #FFF; }
            }
          }
        }
      }
      .state {
        width: 80px;
        min-width: 80px;
        @media #{$pc} {
          width: 100px;
          min-width: 100px;
        }
      }
    }
  }
}


// instafeed
#styleInstafeed {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.5px 20px;
  width: 100%;
  //max-width: $maxWidth;
  @media #{$pc} {
    margin: 0 auto 50px;
    //padding: 0 25px;
  }
  div {
    width: calc(100% / 3);
    padding: .5px;
    @media #{$pc} {
      width: calc(100% / 5);
      padding: 2px;
    }
    a {
      display: block;
      width: 100%;
      height: 0;
      padding-top: 100%;
      background-size: cover;
      background-position: center;
    }
  }
}


// newproducts bgcBlack
.hp_bgcBlack {
  
  #recommendVisual {
    max-width: 1440px; //$maxWidth;
    margin: 40px auto 0 auto;
    figure {
      width: 100%;
      margin-bottom: 16px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .pageContent {
    &.recommend {
      @extend .contentsBlock;
      max-width: $maxWidth;
      margin: 50px auto 0 auto;
      padding-bottom: 96px;
      h1 {
        color: white;
        font-family: $garamond;
        font-size: 3rem;
        padding: 0;
        margin-bottom: 1.5em;
        &.ja {
          font-size: 2.8rem;
          font-family: $mincho;
          line-height: 1.4em;
          margin-bottom: .5em;
          small {
            font-size: 50%;
          }
        }
      }
      h2 {
        color: white;
        font-family: $garamond;
        font-size: 2.4rem;
        margin: 1em 0;
        letter-spacing: 0.05em;
        padding: 0;
        margin-top: 2em;
        border-bottom: solid 1px rgba(255,255,255,.33);
        &.langJp {
          font-family: $mincho;
        }
      }
      h3 {
        color: white;
        padding: 0;
        margin: 0;
      }
      h4 {
        display: inline-block;
        font-size: 1.1rem;
        font-family: $roboto;
        font-weight: 500;
        line-height: 1.2em;
        color: white;
        border: solid 1px lightgray;
        border-radius: 4px;
        padding: 2px 10px 1px 10px;
        vertical-align: middle;
      }
      h5 {
        color: white;
        font-family: $garamond;
        font-size: 1.4rem;
        line-height: 1.8em;
        margin: 0;
        padding: 0;
        span.saleLabel {
          display: inline-block;
          font-size: 1.1rem;
          font-family: $roboto;
          font-weight: 500;
          line-height: 1.2em;
          color: white;
          background: #a40000;
          border-radius: 4px;
          padding: 2px 6px 1px 6px;
          vertical-align: middle;
          margin-left: .5em;
        }
        span.proper {
          text-decoration: line-through;
        }
        &.price {
          display: inline-block;
          small {
            font-size: 60%;
          }
        }
      }
      button {
        margin-top: .5em;
        width: 100%;
        font-size: 1rem;
        font-weight: bold;
        border-radius: 2em;
        border: solid 1px rgba(164, 0, 0, .3);
        color: #a40000;
        line-height: 2em;
        padding: 0 .4em;
        cursor: pointer;
        @media #{$pc} {
          font-size: 1.1rem;
        }
        i {
          color: #a40000;
        }
      }
      p {
        color: white;
        font-family: $mincho;
        font-size: 1.4rem;
        line-height: 1.8em;
        letter-spacing: 0.05em;
        margin: 0;
        padding: 0;
        &.subTitle {
          font-size: 1.4rem;
          color: white;
          line-height: 1.8em;
          a {
            display: inline-block;
            background: whitesmoke;
            border-radius: 2em;
            padding: .6em 1em;
            margin-bottom: 3em;
            i {
              color: white;
            }
          }
        }
        &.leadCopy {
          font-size: 1.4rem;
          margin-bottom: 2em;
        }
        &.subCopy {
          font-size: 1.4rem;
          margin-bottom: 2em;
          a {
            color: white;
            text-align: center;
            display: block;
          }
          small {

          }
        }
        &.anchorLink {
          text-align: center;
          font-size: 1.4rem;
          a {
            width: 100%;
            max-width: 400px;
            display: inline-block;
            border: solid 1px gainsboro;
            padding: 1em;
            font-family: $gothic;
          }
        }
        &.marginTop {
          margin-top: 7em;
        }
        &.desc {
          font-family: $gothic;
          font-size: 1.2rem;
          background: none;
          margin-top: .5em;
          padding: .4em .6em;
          line-height: 1.6em;
          text-align: left;
        }
        span {
          &.attention {
            font-size: 1.2rem;
          }
        }
        &.releaseDate {
          margin-left: .2em;
          margin-top: .4em;
          display: inline-block;
          font-size: 1rem;
          font-family: $gothic;
          font-weight: bold;
          padding: .1em .5em;
          background: #6f0000;
          color: white;
          @media #{$pc} {
            font-size: 1.1rem;
          }
        }
      }
      ul.recommendItems {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        list-style: none;
        margin-bottom: 3em;
        &.listtop {
          margin-bottom: 0;
        }
        li {
          width: calc(100% / 2 - 12px);
          margin-bottom: 2em;
          a {
            display: block;
            div {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
          &.border {
            width: 100%;
            margin: 0;
            border {
              visibility: hidden;
              height: 0;
              width: 0;
            }
          }
          &.preparation {
            a {
              h4 {
                position: relative;
                &:after {
                  position: absolute;
                  width: 5em;
                  left: calc(100% + 5px);
                  bottom: 0;
                  display: inline-block;
                  content: "準備中";
                  font-size: 1.1rem;
                  line-height: 1.2em;
                  color: #fff;
                  border-radius: 4px;
                  padding: 3px 0 1px 0;
                  text-align: center;
                  vertical-align: middle;
                  background: #686868;
                }
              }
            }
          }
        }
        &.firstStandout {
          li:first-child {
            width: 100%;
            a {
              h4 {
                position: relative;
                &:after {
                  position: absolute;
                  width: 7em;
                  left: calc(100% + 5px);
                  bottom: 0;
                  display: inline-block;
                  content: "雪峰祭限定";
                  font-size: 1.1rem;
                  line-height: 1.2em;
                  color: white;
                  border-radius: 4px;
                  padding: 3px 0 1px 0;
                  text-align: center;
                  vertical-align: middle;
                  background: #9f4a4d;
                }
              }
            }
          }
        }
      }
      div.subVisual {
        max-width: 800px;
        margin: 3em auto 3em auto;
        @media #{$pc} {
          margin: 4em auto 3em auto;
        }
        &.vertical {
          max-width: 500px;
        }
        img {
          width: 100%;
          height: auto;
        }
        video {
          width: 100%;
          height: auto;
        }
        &.marginTop {
          margin-top: 7em;
        }
      }
      div.styleRecipe {
        max-width: 1000px;
        margin: 0 auto 6em auto;
        h3 {
          font-size: 1.8rem;
          margin-bottom: 1em;
        }
        h4 {
          margin-top: 3em;
          margin-bottom: 1em;
          font-size: 1.2rem;
        }
        div.ingredient {
          div.photo {
            img {
              width: 100%;
              height: auto;
            }
          }
          .ingredientTable {
            table {
              width: 100%;
              font-size: 1.2rem;
              tr {
                th {
                  text-align: left;
                  font-weight: normal;
                  border-bottom: solid 1px gainsboro;
                  padding: .2em;
                  &.groupHead {
                    font-weight: bold;
                  }
                  &.group {
                    padding-left: 1.2em;
                  }
                }
                td {
                  border-bottom: solid 1px gainsboro;
                  padding: .2em;
                }
              }
            }
            p {
              font-family: $gothic;
              font-size: 1.2rem;
              margin-top: .5em;
            }
          }
        }
        ul.making {
          list-style: none;
          li {
            margin-bottom: 1.5em;
            &:last-child {
              margin-bottom: 0;
            }
            div.photo {
              position: relative;
              span {
                position: absolute;
                bottom: 0;
                left: 0;
                font-family: $garamond;
                display: inline-block;
                background: rgba(0,0,0,0.5);
                color: white;
                padding: .1em .6em;
                &.numOnly {
                  position: static;
                }
              }
              img {
                width: 100%;
                height: auto;
              }
            }
            p {
              font-family: $gothic;
              font-size: 1.2rem;
              margin-top: .5em;
            }
          }
        }
      }
      div.warning {
        padding: .4em .6em;
        border: solid 2px black;
        max-width: 800px;
        margin: .2em auto;
        dl {
          display: flex;
          line-height: 1.4em;
          dt {
            font-size: 1.4rem;
            i {
              color: black;
            }
          }
          dd {
            color: black;
            font-size: 1.2rem;
            font-weight: bold;
          }
        }
      }
    }
    &.otherScene {
      background: whitesmoke;
      padding-top: 40px;
      padding-bottom: 60px;
      margin-bottom: 0;
      max-width: none;
      @extend .contentsBlock;
      h2 {
        text-align: center;
        font-family: $garamond;
        letter-spacing: .4em;
        font-size: 1.5rem;
      }
      ul {
        max-width: $maxWidth;
        margin: auto;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: calc(100% / 2 - 12px);
          margin-top: 2em;
          a {
            display: block;
            div {
              img {
                width: 100%;
                height: auto;
              }
            }
            h3 {
              padding: 0;
              margin: .5em 0;
              letter-spacing: .05em;
              font-family: $garamond;
              font-size: 1.4rem;
            }
          }
        }
      }
    }
    &.landingList {
      padding-top: 40px;
      padding-bottom: 60px;
      margin-bottom: 0;
      max-width: none;
      @extend .contentsBlock;
      h2 {
        text-align: center;
        color: #252525;
        font-family: $gothic;
        //font-family: $mincho;
        letter-spacing: .1em;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 1.2em;
        padding-top: 2em;
        padding-left: 0;
        padding-right: 0;
        small {
          font-size: .5em;
          letter-spacing: .05em;
        }
      }
      p {
        padding-left: 0;
        padding-right: 0;
        @media #{$pc} {
          text-align: center;
          font-size: 1.8rem;
          margin-bottom: 3em;
        }
        span.hashtag {
          border: solid 1px gainsboro;
          padding: .3em;
          background: whitesmoke;
          border-radius: 4px;
          white-space: nowrap;
          margin: 0 .3em;
        }
      }
      ul {
        max-width: $maxWidth;
        margin: auto;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: calc(100% / 2 - 12px);
          margin-top: 3em;
          a {
            display: block;
            div {
              img {
                width: 100%;
                height: auto;
              }
            }
            h3 {
              padding: 0;
              margin: .8em 0 0 0;
              letter-spacing: .05em;
              color: #8a8a8a;
              font-family: $garamond;
              font-size: 1.2rem;
              text-align: center;
            }
            p {
              padding: 0;
              margin: 0 0 .5em 0;
              letter-spacing: .05em;
              font-family: $mincho;
              //font-size: 1.3rem;
              font-size: 1.5rem;
              line-height: 2.2rem;
              text-align: center;
              margin-right: -1em;
            }
            button {
              margin-top: .5em;
              width: 100%;
              font-size: 1.1rem;
              font-weight: bold;
              border-radius: 2em;
              border: solid 1px rgba(164, 0, 0, .3);
              color: #a40000;
              line-height: 2em;
              padding: 0 .4em;
              cursor: pointer;
              @media #{$pc} {
                display: block;
                //font-size: 1.1rem;
                font-size: 1.2rem;
                width: 60%;
                margin-left: auto;
                margin-right: auto;
              }
              i {
                color: #a40000;
              }
            }
          }
        }
      }
    }
  }
  @media #{$pc} {
    #recommendVisual {
      //padding: 0 25px;
      margin-top: 90px;
      figure {
        margin-bottom: 40px;
        img {
          height: 600px;
          object-fit: cover;
          font-family: 'object-fit: cover;'
        }
      }
    }
    .pageContent {
      &.recommend {
        margin: 120px auto 0 auto;
        padding-bottom: 140px;
        h1 {
          //max-width: 950px;
          font-size: 7rem;
          letter-spacing: .05em;
          &.ja {
            font-size: 6rem;
          }
        }
        h2 {
          font-size: 3.2rem;
          //max-width: 950px;
          margin-left: auto;
          margin-right: auto;
        }
        /*h3 {
          text-align: center;
        }
        h4 {
          font-size: 3.2rem;
          max-width: 950px;
        }*/
        p {
          font-size: 1.8rem;
          //max-width: 950px;
          margin-left: auto;
          margin-right: auto;
          font-weight: 600;
          letter-spacing: .1em;
          &.subTitle {
            font-size: 2.1rem;
          }
          &.leadCopy {
            font-size: 1.6rem;
            line-height: 2.4em;
          }
          &.subCopy {
            font-size: 1.9rem;
            line-height: 2.4em;
            text-align: center;
          }
          &.desc {
            text-align: center;
          }
          span {
            &.attention {
              font-size: 1.4rem;
            }
          }
        }
        a {
          font-size: 1.6rem;
        }
        ul.recommendItems {
          justify-content: center;
          li {
            width: calc(100% / 5 - 30px);
            margin-left: 15px;
            margin-right: 15px;
            //margin-bottom: 2em;
            a {
              div {
              }
            }
          }
          &.firstStandout {
            li:first-child {
              width: calc(100% / 5 * 2 - 30px);
            }
          }
          &.pcThree {
            li {
              width: calc(100% / 3 - 30px);
              &.border {
                width: 100%;
              }
            }
          }
          &.pcFour {
            li {
              width: calc(100% / 4 - 30px);
              &.border {
                width: 100%;
              }
            }
          }
        }
        div.styleRecipe {
          h3 {
            font-size: 2.1rem;
          }
          h4 {
            font-size: 1.4rem;
            margin-top: 0;
          }
          div.ingredient {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 2em;
            div.photo {
              width: 60%;
            }
            .ingredientTable {
              width: 37%;
              table {
                font-size: 1.4rem;
              }
            }
          }
          ul.making {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              width: 23%;
              margin-bottom: 0;
              p {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
      &.otherScene {
        padding-top: 100px;
        padding-bottom: 100px;
        h2 {
          font-size: 1.8rem;
        }
        ul {
          li {
            width: calc(100% / 3 - 25px);
            a {
              h3 {
                font-size: 1.6rem;
              }
            }
          }
          &:after {
            content: " ";
            display: block;
            width: calc(100% / 3 - 25px);
          }
        }
      }
      &.landingList {
        padding-top: 0;
        padding-bottom: 100px;
        h2 {
          font-size: 3.6rem;
          small {
            font-size: .5em;
          }
        }
        ul {
          padding-left: 25px;
          padding-right: 25px;
          li {
            width: calc(100% / 3 - 25px);
            a {
              h3 {
                //font-size: 1.6rem;
                font-size: 1.4rem;
              }
              p {
                //font-size: 1.4rem;
                font-size: 2rem;
                line-height: 1.6em;
              }
            }
          }
          &:after {
            content: " ";
            display: block;
            width: calc(100% / 3 - 25px);
          }
        }
      }
    }
  }


  // takibidai
  div.takibiCombiImg {
    width: 100%;
    max-width: 550px;
    margin: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
  table.takibidaiOption {
    width: 100%;
    margin-bottom: 3em;
    tr {
      th {
        width: calc(100% / 3);
        border-left: solid 1px gainsboro;
        border-bottom: solid 1px gainsboro;
        padding: 2em .5em;
        vertical-align: top;
        &:first-child {
          border-left: none;
        }
        span {
          display: block;
          text-align: center;
          font-family: $mincho;
          font-weight: normal;
          line-height: 2em;
        }
      }
      td {
        padding: 2em .5em;
        border-left: solid 1px gainsboro;
        border-bottom: solid 1px gainsboro;
      }
      &:last-child {
        th, td {
          border-bottom: none;
        }
      }
    }
    div {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  @media #{$pc} {
    div.takibiCombiImg {
      padding-right: 12%;
      &.takibiJikaroCombiImg {
        padding-right: 0;
      }
    }
    table.takibidaiOption {
      tr {
        th {
          width: calc(100% / 7);
          padding: 1em 1em;
          span {
            line-height: 2em;
          }
        }
        td {
          padding: 1em 1em;
        }
      }
    }
  }

  // exhibit
  div.listContent {
    //overflow-x: hidden;
    /*overflow-y: hidden;
    @media #{$pc} {
      overflow-y: inherit;
    }*/
    div.sticky_table_wrapper {
      //overflow: scroll;
      //width: calc(100vw);
      //height: 75vh;
      @media #{$pc} {
        height: calc(100% + 17px);
        //max-width: 1230px;
        //width: 100%;
        //overflow: hidden;
      }
      table {
        //width: 100%;
        width: 1200px;
        //table-layout: fixed;
        font-size: 1.2rem;
        @media #{$pc} {
          width: 100%;
          font-size: 1.4rem;
        }
        &.sticky_table {
          border-collapse: collapse;
          thead {
            th {
              background: #cecece;
              color: #000;
              font-weight: bold;
              text-align: left;
              padding: 1em 1em;
              /* 縦スクロール時に固定する */
              position: -webkit-sticky;
              position: sticky;
              top: 0;
              /* tbody内のセルより手前に表示する */
              z-index: 1;
              border-right: solid 1px gainsboro;
              &:last-child {
                border-right: none;
              }
              span {
                vertical-align: -webkit-baseline-middle;
                margin: auto 0;
              }
              .itemName {
                display: flex;
                white-space: nowrap;
              }
              img {
                width: 50px;
                height: 37.5px;
                margin: 0 .8rem 0 0;
                mix-blend-mode: darken;
              }
            }
          }
          tbody {
            th, td {
              text-align: left;
              padding: 1em 1em;
              font-weight: normal;
              border-right: solid 1px gainsboro;
              &:last-child {
                border-right: none;
              }
              @media #{$pc} {
                padding: 1.5em 1em;
              }
            }
            tr {
              &:nth-child(even) {
                th { background: darken(#FAFAFA, 4%); }
                td { background: darken(#FFF, 4%); }
              }
              &:nth-child(odd) {
                th { background: #FAFAFA; }
                td { background: #FFF; }
              }
            }
          }
        }
        .state {
          width: 80px;
          min-width: 80px;
          @media #{$pc} {
            width: 100px;
            min-width: 100px;
          }
        }
      }
    }
  }

  // instafeed
  #styleInstafeed {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.5px 20px;
    width: 100%;
    //max-width: $maxWidth;
    @media #{$pc} {
      margin: 0 auto 50px;
      //padding: 0 25px;
    }
    div {
      width: calc(100% / 3);
      padding: .5px;
      @media #{$pc} {
        width: calc(100% / 5);
        padding: 2px;
      }
      a {
        display: block;
        width: 100%;
        height: 0;
        padding-top: 100%;
        background-size: cover;
        background-position: center;
      }
    }
  }

}

// gifts for winter hp_bgcGreen
.hp_bgcGreen {
  .mvbg {
    background-image:url(/assets/images/products/gifts-for-winter/mvbg.jpg);background-size: cover;
    background-position: bottom;
  }
  .pageContent {
    &.recommend {
      @extend .contentsBlock;
      max-width: $maxWidth;
      margin: 50px auto 0 auto;
      padding-bottom: 96px;
      h1 {
        color: #d4d5ae;
        font-family: $garamond;
        font-size: 16vw;
        line-height: 1;
        padding: 0;
        letter-spacing: .08em;
        margin-bottom: 0.32em;
        &.ja {
          font-size: 2.8rem;
          font-family: $mincho;
          line-height: 1.4em;
          margin-bottom: .5em;
          small {
            font-size: 50%;
          }
        }
      }
      h2 {
        color: #d4d5ae;
        font-family: $garamond;
        font-size: 2.4rem;
        margin: 1em 0;
        letter-spacing: 0.05em;
        padding: 0;
        margin-top: 2em;
        border-bottom: solid 1px rgba(203,206,169,.7);
        &.langJp {
          font-family: $mincho;
        }
      }
      h3 {
        color: #d4d5ae;
        padding: 0;
        margin: 0;
      }
      h4 {
        display: inline-block;
        font-size: 1.1rem;
        font-family: $roboto;
        font-weight: 500;
        line-height: 1.2em;
        color: #d4d5ae;
        border: solid 1px #d4d5ae;
        border-radius: 4px;
        padding: 2px 10px 1px 10px;
        vertical-align: middle;
      }
      h5 {
        color: #d4d5ae;
        font-family: $garamond;
        font-size: 1.4rem;
        line-height: 1.8em;
        margin: 0;
        padding: 0;
        span.saleLabel {
          display: inline-block;
          font-size: 1.1rem;
          font-family: $roboto;
          font-weight: 500;
          line-height: 1.2em;
          color: #d4d5ae;
          background: #a40000;
          border-radius: 4px;
          padding: 2px 6px 1px 6px;
          vertical-align: middle;
          margin-left: .5em;
        }
        span.proper {
          text-decoration: line-through;
        }
        &.price {
          display: inline-block;
          small {
            font-size: 60%;
          }
        }
        span.taxin {
          font-size: .9rem;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          margin-left: .8em;
        }
      }
      button {
        margin-top: .5em;
        width: 100%;
        font-size: 1rem;
        font-weight: bold;
        border-radius: 2em;
        border: solid 1px rgba(164, 0, 0, .3);
        color: #a40000;
        line-height: 2em;
        padding: 0 .4em;
        cursor: pointer;
        @media #{$pc} {
          font-size: 1.1rem;
        }
        i {
          color: #a40000;
        }
      }
      p {
        color: #d4d5ae;
        font-family: $mincho;
        font-size: 1.4rem;
        line-height: 1.8em;
        letter-spacing: 0.05em;
        margin: 0;
        padding: 0;
        &.subTitle {
          font-size: 5.6vw;
          color: #d4d5ae;
          line-height: 1.8em;
          margin-bottom: 1em;
          a {
            display: inline-block;
            background: whitesmoke;
            border-radius: 2em;
            padding: .6em 1em;
            margin-bottom: 3em;
            i {
              color: #d4d5ae;
            }
          }
        }
        &.leadCopy {
          font-size: 1.4rem;
          margin-bottom: 4.8em;
        }
        &.subCopy {
          font-size: 1.9rem;
          margin-bottom: 2em;
          a {
            color: white;
            text-align: center;
            display: block;
          }
          small {
          }
          br.pcOnly {
            display: none;
          }
          @media #{$pc} {
            br.pcOnly {
              display: block;
            }
          }
        }
        &.anchorLink {
          text-align: center;
          font-size: 1.4rem;
          a {
            width: 100%;
            max-width: 400px;
            display: inline-block;
            border: solid 1px gainsboro;
            padding: 1em;
            font-family: $gothic;
          }
        }
        &.marginTop {
          margin-top: 7em;
        }
        &.desc {
          font-family: $gothic;
          font-size: 1.2rem;
          background: none;
          margin-top: .5em;
          padding: .4em .6em;
          line-height: 1.6em;
          text-align: left;
        }
        span {
          &.attention {
            font-size: 1.2rem;
          }
        }
        &.releaseDate {
          margin-left: .2em;
          margin-top: .4em;
          display: inline-block;
          font-size: 1rem;
          font-family: $gothic;
          font-weight: bold;
          padding: .1em .5em;
          background: #6f0000;
          color: white;
          @media #{$pc} {
            font-size: 1.1rem;
          }
        }
      }
      .contentsIndex {
        width: 100%;
        position: relative;
        ul.indexCtg {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          list-style: none;
          padding-bottom: 2.4em;
          li.ctgR {
            margin-top: 6vw;
            @media #{$pc} {
              margin-top: 0;
              padding-bottom: 5em;
            }
          }
          li.ctgL,
          li.ctgR {
            background-color: #d4d5ae;
            width: 100%;
            padding: 1em 1.5em 2em;
            @media #{$pc} {
              width: calc(100% / 2 - 2vw);
              padding: 1vw 2vw 3vw;
            }
            h3.ctgName {
              color: #00362f;
              font-size: 2.4rem;
              //-border-bottom: 1px solid #00362f;
              padding-bottom: .8em;
              padding-left: 49px;
              font-family: $garamond;
              letter-spacing: .1rem;
              line-height: 2em;
              background: url(/assets/images/products/gifts-for-winter/icon_ct_gear.svg) no-repeat left 16px;
              background-size: 39px;
              span {
                display: block;
                font-size: 1.1rem;
                line-height: 1.1rem;
                font-weight: bold;
              }
              @media #{$pc} {
                padding-left: 64px;
                font-size: 3.2rem;
                background: url(/assets/images/products/gifts-for-winter/icon_ct_gear.svg) no-repeat left center;
                background-size: 48px;
                span {
                  font-size: 1.4rem;
                }
              }
              &.aplr {
                padding-left: 60px;
                background: url(/assets/images/products/gifts-for-winter/icon_ct_apparel.svg) no-repeat left 16px;
                background-size: 49px;
                @media #{$pc} {
                  padding-left: 76px;
                  background: url(/assets/images/products/gifts-for-winter/icon_ct_apparel.svg) no-repeat left 24px;
                  background-size: 59px;
                }
              }
            }
            ul.ctg {
              list-style: none;
              li {
                color: #00362f;
                font-family: $garamond;
                line-height: 4.2rem;
                border-bottom: 1px solid #00362f;
                padding: 0;
                @media #{$pc} {
                  padding: 0 0.8em;
                  line-height: 4.8rem;
                }
                a {
                  width: 100%;
                  display: block;
                  color: #00362f;
                  background: url(/assets/images/common/icon_navbtn_arrow_down_green.svg) no-repeat right center;
                  background-size: 12px;
                  font-size: 1.6rem;
                  @media #{$pc} {
                    font-size: 1.8rem;
                  }
                }
              }
            }
          }
        }
      }
      ul.recommendItems {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        list-style: none;
        margin-bottom: 3em;
        &.listtop {
          margin-bottom: 0;
        }
        li {
          width: calc(100% / 2 - 12px);
          margin-bottom: 2em;
          a {
            display: block;
            div {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
          &.border {
            width: 100%;
            margin: 0;
            display: none;
            border {
              visibility: hidden;
              height: 0;
              width: 0;
            }
            @media #{$pc} {
              display: block;
            }
          }
          &.preparation {
            a {
              h4 {
                position: relative;
                &:after {
                  position: absolute;
                  width: 5em;
                  left: calc(100% + 5px);
                  bottom: 0;
                  display: inline-block;
                  content: "準備中";
                  font-size: 1.1rem;
                  line-height: 1.2em;
                  color: #fff;
                  border-radius: 4px;
                  padding: 3px 0 1px 0;
                  text-align: center;
                  vertical-align: middle;
                  background: #686868;
                }
              }
            }
          }
        }
        &.firstStandout {
          li:first-child {
            width: 100%;
            a {
              h4 {
                position: relative;
                &:after {
                  position: absolute;
                  width: 7em;
                  left: calc(100% + 5px);
                  bottom: 0;
                  display: inline-block;
                  content: "雪峰祭限定";
                  font-size: 1.1rem;
                  line-height: 1.2em;
                  color: white;
                  border-radius: 4px;
                  padding: 3px 0 1px 0;
                  text-align: center;
                  vertical-align: middle;
                  background: #9f4a4d;
                }
              }
            }
          }
        }
      }
      div.subVisual {
        max-width: 800px;
        margin: 3em auto 2em auto;
        @media #{$pc} {
          margin: 4em auto 3em auto;
        }
        &.vertical {
          max-width: 500px;
        }
        img {
          width: 100%;
          height: auto;
        }
        video {
          width: 100%;
          height: auto;
        }
        &.marginTop {
          margin-top: 7em;
        }
      }
      div.styleRecipe {
        max-width: 1000px;
        margin: 0 auto 6em auto;
        h3 {
          font-size: 1.8rem;
          margin-bottom: 1em;
        }
        h4 {
          margin-top: 3em;
          margin-bottom: 1em;
          font-size: 1.2rem;
        }
        div.ingredient {
          div.photo {
            img {
              width: 100%;
              height: auto;
            }
          }
          .ingredientTable {
            table {
              width: 100%;
              font-size: 1.2rem;
              tr {
                th {
                  text-align: left;
                  font-weight: normal;
                  border-bottom: solid 1px gainsboro;
                  padding: .2em;
                  &.groupHead {
                    font-weight: bold;
                  }
                  &.group {
                    padding-left: 1.2em;
                  }
                }
                td {
                  border-bottom: solid 1px gainsboro;
                  padding: .2em;
                }
              }
            }
            p {
              font-family: $gothic;
              font-size: 1.2rem;
              margin-top: .5em;
            }
          }
        }
        ul.making {
          list-style: none;
          li {
            margin-bottom: 1.5em;
            &:last-child {
              margin-bottom: 0;
            }
            div.photo {
              position: relative;
              span {
                position: absolute;
                bottom: 0;
                left: 0;
                font-family: $garamond;
                display: inline-block;
                background: rgba(0,0,0,0.5);
                color: white;
                padding: .1em .6em;
                &.numOnly {
                  position: static;
                }
              }
              img {
                width: 100%;
                height: auto;
              }
            }
            p {
              font-family: $gothic;
              font-size: 1.2rem;
              margin-top: .5em;
            }
          }
        }
      }
      div.warning {
        padding: .4em .6em;
        border: solid 2px black;
        max-width: 800px;
        margin: .2em auto;
        dl {
          display: flex;
          line-height: 1.4em;
          dt {
            font-size: 1.4rem;
            i {
              color: black;
            }
          }
          dd {
            color: black;
            font-size: 1.2rem;
            font-weight: bold;
          }
        }
      }
    }
    &.otherScene {
      background: whitesmoke;
      padding-top: 40px;
      padding-bottom: 60px;
      margin-bottom: 0;
      max-width: none;
      @extend .contentsBlock;
      h2 {
        text-align: center;
        font-family: $garamond;
        letter-spacing: .4em;
        font-size: 1.5rem;
      }
      ul {
        max-width: $maxWidth;
        margin: auto;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: calc(100% / 2 - 12px);
          margin-top: 2em;
          a {
            display: block;
            div {
              img {
                width: 100%;
                height: auto;
              }
            }
            h3 {
              padding: 0;
              margin: .5em 0;
              letter-spacing: .05em;
              font-family: $garamond;
              font-size: 1.4rem;
            }
          }
        }
      }
    }
    &.landingList {
      padding-top: 40px;
      padding-bottom: 60px;
      margin-bottom: 0;
      max-width: none;
      @extend .contentsBlock;
      h2 {
        text-align: center;
        color: #252525;
        font-family: $gothic;
        //font-family: $mincho;
        letter-spacing: .1em;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 1.2em;
        padding-top: 2em;
        padding-left: 0;
        padding-right: 0;
        small {
          font-size: .5em;
          letter-spacing: .05em;
        }
      }
      p {
        padding-left: 0;
        padding-right: 0;
        @media #{$pc} {
          text-align: center;
          font-size: 1.8rem;
          margin-bottom: 3em;
        }
        span.hashtag {
          border: solid 1px gainsboro;
          padding: .3em;
          background: whitesmoke;
          border-radius: 4px;
          white-space: nowrap;
          margin: 0 .3em;
        }
      }
      ul {
        max-width: $maxWidth;
        margin: auto;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: calc(100% / 2 - 12px);
          margin-top: 3em;
          a {
            display: block;
            div {
              img {
                width: 100%;
                height: auto;
              }
            }
            h3 {
              padding: 0;
              margin: .8em 0 0 0;
              letter-spacing: .05em;
              color: #8a8a8a;
              font-family: $garamond;
              font-size: 1.2rem;
              text-align: center;
            }
            p {
              padding: 0;
              margin: 0 0 .5em 0;
              letter-spacing: .05em;
              font-family: $mincho;
              //font-size: 1.3rem;
              font-size: 1.5rem;
              line-height: 2.2rem;
              text-align: center;
              margin-right: -1em;
            }
            button {
              margin-top: .5em;
              width: 100%;
              font-size: 1.1rem;
              font-weight: bold;
              border-radius: 2em;
              border: solid 1px rgba(164, 0, 0, .3);
              color: #a40000;
              line-height: 2em;
              padding: 0 .4em;
              cursor: pointer;
              @media #{$pc} {
                display: block;
                //font-size: 1.1rem;
                font-size: 1.2rem;
                width: 60%;
                margin-left: auto;
                margin-right: auto;
              }
              i {
                color: #a40000;
              }
            }
          }
        }
      }
    }
  }
  @media #{$pc} {
    #recommendVisual {
      //padding: 0 25px;
      margin-top: 90px;
      figure {
        margin-bottom: 40px;
        img {
          height: 600px;
          object-fit: cover;
          font-family: 'object-fit: cover;'
        }
      }
    }
    .pageContent {
      &.recommend {
        margin: 120px auto 0 auto;
        padding-bottom: 140px;
        h1 {
          //max-width: 950px;
          font-size: 10vw;
          margin-bottom: 0.2em;
          &.ja {
            font-size: 6rem;
          }
        }
        h2 {
          font-size: 3.2rem;
          //max-width: 950px;
          margin-left: auto;
          margin-right: auto;
        }
        /*h3 {
          text-align: center;
        }
        h4 {
          font-size: 3.2rem;
          max-width: 950px;
        }*/
        p {
          font-size: 1.8rem;
          //max-width: 950px;
          margin-left: auto;
          margin-right: auto;
          font-weight: 400;
          letter-spacing: .1em;
          &.subTitle {
            font-size: 2.4vw;
            margin-bottom: 1.8em;
          }
          &.leadCopy {
            font-size: 1.6rem;
            line-height: 2.4em;
            margin-bottom: 8em;
          }
          &.subCopy {
            //-font-size: 1.9rem;
            line-height: 2.4em;
            text-align: center;
          }
          &.desc {
            text-align: center;
          }
          span {
            &.attention {
              font-size: 1.4rem;
            }
          }
        }
        a {
          font-size: 1.6rem;
        }
        ul.recommendItems {
          justify-content: center;
          li {
            width: calc(100% / 5 - 30px);
            margin-left: 15px;
            margin-right: 15px;
            //margin-bottom: 2em;
            a {
              div {
              }
            }
          }
          &.firstStandout {
            li:first-child {
              width: calc(100% / 5 * 2 - 30px);
            }
          }
          &.pcThree {
            li {
              width: calc(100% / 3 - 30px);
              &.border {
                width: 100%;
              }
            }
          }
          &.pcFour {
            li {
              width: calc(100% / 4 - 30px);
              &.border {
                width: 100%;
              }
            }
          }
        }
        div.styleRecipe {
          h3 {
            font-size: 2.1rem;
          }
          h4 {
            font-size: 1.4rem;
            margin-top: 0;
          }
          div.ingredient {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 2em;
            div.photo {
              width: 60%;
            }
            .ingredientTable {
              width: 37%;
              table {
                font-size: 1.4rem;
              }
            }
          }
          ul.making {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              width: 23%;
              margin-bottom: 0;
              p {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
      &.otherScene {
        padding-top: 100px;
        padding-bottom: 100px;
        h2 {
          font-size: 1.8rem;
        }
        ul {
          li {
            width: calc(100% / 3 - 25px);
            a {
              h3 {
                font-size: 1.6rem;
              }
            }
          }
          &:after {
            content: " ";
            display: block;
            width: calc(100% / 3 - 25px);
          }
        }
      }
      &.landingList {
        padding-top: 0;
        padding-bottom: 100px;
        h2 {
          font-size: 3.6rem;
          small {
            font-size: .5em;
          }
        }
        ul {
          padding-left: 25px;
          padding-right: 25px;
          li {
            width: calc(100% / 3 - 25px);
            a {
              h3 {
                //font-size: 1.6rem;
                font-size: 1.4rem;
              }
              p {
                //font-size: 1.4rem;
                font-size: 2rem;
                line-height: 1.6em;
              }
            }
          }
          &:after {
            content: " ";
            display: block;
            width: calc(100% / 3 - 25px);
          }
        }
      }
    }
  }
}

// Other Link
#otherLink {
  padding-bottom: 80px;
  margin: 0;
  @media #{$pc} {
    padding-bottom: 120px;
    margin: 0;
  }
  .linkCon {
    margin: 0 auto;
    //-max-width: 950px;
    ul {
      list-style: none;
      li {
        margin-bottom: 2em;
        @media #{$pc} {
          margin-bottom: 4em;
        }
      }
      li:last-child {
        margin-bottom: 0;
      }
    }
    img {
      width: 100%;
      height: 100%;
      padding: 0 7.5px;
      @media #{$pc} {
        max-width: 680px;
        padding: 0 24px;
      }
    }
  }
  h2.titleBlock {
    text-align: center;
    font-family: $garamond;
    font-weight: normal;
    font-size: 3.2rem;
    letter-spacing: .07em;
    line-height: 1.2em;
    padding: 0 0 1.2em 0;
    span {
      font-family: $gothic;
      font-size: 1.2rem;
      //-i {
      //-  font-size: 2rem;
      //-  vertical-align: -1px;
      //-}
    }
  }
  &.clGr {
    color: #d4d5ae;
  }
}
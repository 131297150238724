// pageContent
#pageVisual {
  height: 200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  &.about {
    background-image: url(/img/about/head_top.jpg);
  }
  &.corporate {
    background-image: url(/img/about/head_corporate.jpg);
  }
  &.recruit {
    background-image: url(/img/about/head_recruit.jpg);
  }
  &.products {
    background-image: url(/img/products/head_products.jpg);
  }
  &.event {
    background-image: url(/img/event/head_event.jpg);
  }
  &.faq {
    background-image: url(/img/faq/head_faq.jpg);
  }
}
.pageContent {
  max-width: $maxWidth;
  margin: 0 auto 60px auto;
  color: $colorText;
  h1 {
    //-//-@extend .contentsBlock;
    color: $colorHead;
    font-family: $mincho;
    font-size: 2.4rem;
    font-weight: normal;
    padding-top: 2em;
    margin-bottom: 2em;
    line-height: 1.8em;
    &.newsTitle {
      font-size: 2rem;
      padding-top: 0;
    }
    span.hint {
      display: block;
      font-size: 1.4rem;
      font-weight: bold;
      font-family: $gothic;
      line-height: 1em;
    }
    &.aboutMessage {
      padding: 1em 0 0 0;
      margin: 0;
    }
  }
  h2 {
    //-@extend .contentsBlock;
    color: $colorHead;
    font-family: $mincho;
    font-size: 2rem;
    font-weight: normal;
    line-height: 1.8em;
    margin: 2em 0;
    span.num {
      display: inline-block;
      text-indent: -1.6em;
      padding-left: 1.6em;
    }
  }
  h3 {
    //-@extend .contentsBlock;
    color: $colorHead;
    font-family: $mincho;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.8em;
    margin: 2em 0 1em 0;
    span.num {
      display: inline-block;
      text-indent: -2em;
      padding-left: 2em;
    }
  }
  h4 {
    //-@extend .contentsBlock;
    color: $colorHead;
    font-family: $mincho;
    font-weight: normal;
    margin: 1.5em 0 0.5em 0;
  }
  h5 {
    //-@extend .contentsBlock;
    color: $colorHead;
    font-family: $mincho;
    font-weight: normal;
    margin: 1.5em 0 0.5em 0;
  }
  h6 {
    //-@extend .contentsBlock;
    color: $colorHead;
    font-family: $mincho;
    font-weight: normal;
    margin: 1.5em 0 0.5em 0;
  }
  p {
    //-@extend .contentsBlock;
    line-height: 2em;
    margin-bottom: 1.5em;
    img {
      max-width: 100%;
      height: auto;
      width: auto;
    }
    &.leadCopy {
      margin-bottom: 50px;
    }
    &.pMessage {
      font-family: $mincho;
    }
    &.signature {
      font-family: $mincho;
      text-align: right;
      margin-bottom: 0.5em;
      span {
        font-size: 1.8rem;
      }
    }
    &.signatureImg {
      text-align: right;
      img {
        width: 150px;
        height: auto;
      }
    }
    &.center {
      text-align: center;
    }
    &.minchoL {
      font-family: $mincho;
    }
  }
  a {
    color: $colorHead;
    text-decoration: none;
  }
  ul.standard {
    //-@extend .contentsBlock;
    padding-left: 45px;
    line-height: 2em;
    margin-bottom: 1.5em;
  }
  ul.dots {
    list-style-type: disc;
    padding-left: 1.5em;
    line-height: 2em;
    margin-bottom: 2em;
  }
  ul.imageBtns {
    //-@extend .contentsBlock;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    li {
      width: 100%;
      margin-bottom: 50px;
      a {
        display: block;
        text-align: center;
        text-decoration: none;
        color: black;
        div {
          img {
            width: 100%;
            height: auto;
          }
        }
        h2 {
          padding: 1.5em 0;
          font-size: 1.8rem;
          font-family: $mincho;
          font-weight: normal;
          margin: 0;
          span.modelNumber {
            display: inline-block;
            font-size: 1.1rem;
            font-family: $roboto;
            font-weight: 500;
            line-height: 1.2em;
            color: gray;
            border: solid 1px lightgray;
            border-radius: 4px;
            padding: 2px 10px 1px 10px;
            vertical-align: middle;
            margin: 0 10px 5px 0;
          }
          span.prep {
            display: inline-block;
            font-size: 1.2rem;
            font-family: $gothic;
            line-height: 1.2em;
            color: gray;
            border: solid 1px lightgray;
            border-radius: 4px;
            padding: 2px 8px 1px 8px;
            vertical-align: middle;
            margin: 0 0 5px 10px;
            &.rankBlack {
              border-color: black;
              background: black;
              color: white;
            }
          }
          span.ended {
            display: inline-block;
            font-size: 1.2rem;
            font-family: $gothic;
            line-height: 1.2em;
            color: white;
            border: solid 1px darkgray;
            background: darkgray;
            border-radius: 4px;
            padding: 2px 8px 1px 8px;
            vertical-align: middle;
            margin: 0 0 5px 10px;
            &.rankBlack {
              border-color: black;
              background: black;
              color: white;
            }
          }
        }
        &.disable {
          pointer-events: none;
          div {
            filter: grayscale(100%);
            opacity: 0.7;
          }
        }
      }
    }
    &.three {
      li {
        margin-bottom: 30px;
      }
    }
  }
  ul.blockBtns {
    //-@extend .contentsBlock;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    li {
      width: 100%;
      margin-bottom: 25px;
      a {
        display: block;
        text-align: center;
        text-decoration: none;
        border: solid 1px silver;
        color: black;
        padding: 1.5em 1em;
        font-size: 1.6rem;
        font-family: $mincho;
        font-weight: normal;
      }
    }
  }
  ul.history {
    //-@extend .contentsBlock;
    list-style: none;
    h2 {
      font-size: 1.8rem;
      font-weight: normal;
      color: $colorHead;
    }
    p {
      padding: 0;
    }
  }
  ul.pageLinks {
    //-@extend .contentsBlock;
    padding-top: 25px;
    padding-bottom: 25px;
    list-style: none;
    margin-bottom: 4em;
    background: whitesmoke;
    li {
      border-bottom: solid 1px white;
      a {
        position: relative;
        display: block;
        padding: 0.8em 0;
        &:after {
          position: absolute;
          right: 0;
          content: "\f107";
          font-family: $fontAwesome;
          font-weight: bold;
          color: $colorText;
        }
      }
    }
  }
  ul.listLinks {
    //-@extend .contentsBlock;
    list-style: none;
    margin-bottom: 4em;
    li {
      border-bottom: solid 1px silver;
      a {
        position: relative;
        display: block;
        padding: 0.8em 0;
        &:after {
          position: absolute;
          right: 0;
          content: "\f105";
          font-family: FontAwesome;
          color: $colorText;
        }
      }
    }
  }
  ul.globalLinks {
    //-@extend .contentsBlock;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    li {
      width: 48%;
      margin-bottom: 20px;
      a {
        display: block;
        text-align: center;
        color: black;
        div {
          border: solid 1px whitesmoke;
          img {
            width: 100%;
            height: auto;
          }
        }
        h2 {
          padding: 0.8em 0 0 0;
          font-size: 1.4rem;
          font-family: $mincho;
          font-weight: normal;
          margin: 0;
        }
      }
    }
  }
  ul.tileLinks {
    //-@extend .contentsBlock;
    list-style: none;
    margin-bottom: 4em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: calc(100% / 2 - 2px);
      margin-bottom: 2px;
      a {
        background: whitesmoke;
        position: relative;
        display: block;
        padding: 1.5em 0;
        text-align: center;
      }
    }
  }
  ul.textlistLinks {
    //-@extend .contentsBlock;
    list-style: none;
    margin-bottom: 4em;
    li {
      a {
        position: relative;
        display: inline-block;
        padding: 0 0 0 2em;
        margin: 1.4em 0;
        &:before {
          position: absolute;
          left: 0;
          content: "Q.";
          font-family: $garamond;
          font-size: 1.8rem;
          color: $colorText;
        }
      }
    }
  }
  h1.faqAnswer {
    //-@extend .contentsBlock;
    font-size: 1.8rem;
    span {
      display: block;
      position: relative;
      padding: 0 0 0 25px;
      &:before {
        position: absolute;
        left: 0;
        content: "Q.";
        font-family: $garamond;
        font-size: 1.8rem;
        color: $colorText;
      }
    }
  }
  div.faqAnswer {
    //-@extend .contentsBlock;
    margin-bottom: 8em;
    span {
      display: block;
      position: relative;
      padding: 0 0 0 25px;
      &:before {
        position: absolute;
        left: 0;
        content: "A.";
        font-family: $garamond;
        font-size: 1.8rem;
        color: $colorText;
        line-height: 1.5em;
      }
      p {
        padding: 0;
      }
      a {
        text-decoration: underline;
        word-break: break-all;
      }
      table {
        width: 100% !important;
        font-size: 1.2rem;
        tr {
          width: auto !important;
          th, td {
            width: auto !important;
            border: solid 1px dimgray;
            padding: .2em .5em;
          }
        }
      }
    }
  }
  ol.privacy {
    //-@extend .contentsBlock;
    list-style: none;
    margin-bottom: 1.5em;
    li {
      position: relative;
      padding-left: 2.2em;
      line-height: 2em;
      margin-bottom: 0.6em;
      em {
        position: absolute;
        left: 0;
        font-style: normal;
      }
    }
  }
  ol.number {
    list-style: decimal;
    padding-left: 1.6em;
    li {
      margin-bottom: .6em;
    }
  }
  div.picPresident {
    text-align: center;
    margin-bottom: 40px;
    img {
      width: 100%;
      height: auto;
    }
  }
  div.picSendai {
    text-align: center;
    margin-bottom: 40px;
    img {
      max-width: 200px;
      height: auto;
    }
  }
  div.idea {
    background: WhiteSmoke;
    padding: 50px 0;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 50px;
    font-family: $mincho;
    h2 {
      text-align: center;
      font-weight: normal;
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 1.5em;
    }
    p {
      br {
        display: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  div.table {
    //-@extend .contentsBlock;
    &.history {
      margin-top: 100px;
    }
    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;
      caption {
        display: none;
      }
      thead {
        th {
          display: block;
          color: $colorHead;
          text-align: left;
          padding: 2.5em 0 1em 0;
          font-size: 1.7rem;
          font-weight: normal;
        }
      }
      tbody {
        th {
          display: block;
          color: $colorHead;
          text-align: left;
          padding: 2.5em 0 1em 0;
          font-size: 1.7rem;
          font-weight: normal;
        }
        td {
          display: block;
          border-bottom: solid 1px silver;
          padding: 1em 0 2.5em 0;
          line-height: 1.8em;
          a {
            text-decoration: underline;
            i {
              margin-left: 0.3em;
            }
          }
          p {
            padding: 0;
            margin: 0;
          }
          em {
            font-style: normal;
          }
          div.address {
            margin-top: 2em;
            iframe {
              display: block;
              width: 100%;
              height: 250px;
              margin-bottom: 1.2em;
            }
            &:first-child {
              margin-top: 0;
            }
            strong {
              color: $colorHead;
              font-weight: normal;
            }
          }
          ul {
            list-style: none;
            &.officer {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              li {
                width: 47%;
                margin-bottom: 20px;
                div {
                  margin-bottom: 5px;
                  img {
                    width: 100%;
                    height: auto;
                  }
                }
                p {
                  &.officer {
                    font-size: 1.1rem;
                    line-height: 2.5em;
                  }
                  &.officerName {
                    line-height: 1.4em;
                    font-size: 1.6rem;
                    font-family: $mincho;
                  }
                  &.officerNameE {
                    line-height: 1.4em;
                    font-size: 1.3rem;
                    font-family: $garamond;
                  }
                }
                &:last-child {
                  //margin-bottom: 0;
                }
              }
              &.president {
                justify-content: center;
                li {
                  width: 60%;
                }
              }
            }
            &.office {
              li {
                margin-top: 2em;
                &:first-child {
                  margin-top: 0;
                }
                strong {
                  color: $colorHead;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }
    &.spec {
      table {
        tbody {
          th {
            color: $colorHead;
            padding: 1em 0 0.25em 0;
            font-size: 1.2rem;
          }
          td {
            padding: 0.25em 0 1em 0;
            font-size: 1.2rem;
          }
        }
      }
    }
    &.multiple {
      table {
        margin-bottom: 1.5em;
        tbody {
          th {
            display: table-cell;
            font-size: 1.2rem;
            width: 15%;
            border-bottom: solid 1px silver;
            white-space: nowrap;
            padding: 1em 1em 1em 0;
          }
          td {
            display: table-cell;
            font-size: 1.2rem;
            padding: 1em;
            &:nth-child(even) {
              background: whitesmoke;
            }
            &[colspan] {
              background: none;
              text-align: center;
            }
          }
        }
      }
    }
    &.notice {
      table {
        margin-bottom: 1.5em;
        thead {
          th {
            display: table-cell;
            background: lightgray;
            color: $colorHead;
            padding: 0.5em 0.5em;
            font-size: 1.2rem;
            border: solid 1px silver;
          }
          &.nowrap {
            th {
              white-space: nowrap;
            }
          }
        }
        tbody {
          th {
            display: table-cell;
            background: lightgray;
            color: $colorHead;
            padding: 0.5em 0.5em;
            font-size: 1.2rem;
            border: solid 1px silver;
          }
          td {
            display: table-cell;
            padding: 0.5em 0.5em;
            font-size: 1.2rem;
            border: solid 1px silver;
            &.pink {
              color: DeepPink;
              font-weight: bold;
            }
            &.blue {
              color: DodgerBlue;
              font-weight: bold;
            }
          }
        }
      }
      &.stripe {
        tr:nth-child(even) {
          td {
            background: whitesmoke;
          }
        }
      }
    }
    &.scrollX {
      overflow: auto;
      -webkit-overflow-scrolling : touch;
    }
  }
  div.supp {
    background: WhiteSmoke;
    padding: 2em 0 1em 0;
  }
  a.btn {
    display: block;
    background: silver;
    color: black;
    text-decoration: none;
    text-align: center;
    padding: 1.2em 0;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1em;
    i {
      color: white;
      width: auto;
    }
    &.disable {
      pointer-events: none;
      background: transparent;
      color: gray;
      border: solid 1px silver;
    }
  }
  hr {
    margin: 50px 25px 10px 25px;
    border-top: solid 1px silver;
  }
  i {
    width: 1.5em;
    color: darkgray;
  }
  .vMargin {
    margin: 50px 0;
  }
  div.eyeCatch {
    margin-bottom: 40px;
    img {
      width: 100%;
      height: auto;
    }
  }
  div.storeLinkBtn {
    //-@extend .contentsBlock;
    margin: 50px 0;
    /*a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: solid 2px darkgray;
      padding: 10px 20px;
      div {
        flex-grow: 1;
        text-align: center;
        img {
          width: auto;
          height: 60px;
        }
      }
      span {
        flex-grow: 1;
        text-align: center;
        font-size: 1.6rem;
        i {
          width: auto;
          font-size: 2.4rem;
          margin-left: 0.5em;
          vertical-align: bottom;
        }
      }
    }*/
  }
  span.newsDate {
    //-@extend .contentsBlock;
    display: block;
    margin-top: 2em;
    margin-bottom: 0.4em;
    text-align: right;
    font-size: 1.4rem;
    font-family: "EB Garamond",serif;
    color: black;
  }
}
@media #{$pc} {
  #pageVisual {
    height: 300px;
    background-size: 100% auto;
    background-position: 50% calc(50% - 150px);
    background-attachment: fixed;
    &.corporate {
      height: 200px;
    }
    &.recruit {
      height: 200px;
    }
  }
  .pageContent {
    h1 {
      font-size: 2.8rem;
      &.newsTitle {
        font-size: 2.8rem;
      }
      &.aboutMessage {
        padding: 2em 0 0 0;
      }
    }
    p {
      &.leadCopy {
      }
      &.pMessage {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.6rem;
      }
      &.signature,
      &.signatureImg {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
      }
      &.minchoL {
        font-size: 1.8rem;
      }
      &.attention {
        color: #f60;
      }
    }
    ul.imageBtns {
      justify-content: space-between;
      li {
        width: 47%;
        a {
          text-align: left;
        }
      }
      &.three {
        li {
          width: 30%;
          margin-bottom: 25px;
        }
        &::after {
          content:"";
          display: block;
          width:30%;
        }
      }
    }
    ul.blockBtns {
      justify-content: space-between;
      li {
        width: 47%;
        a {
          text-align: left;
        }
      }
    }
    ul.pageLinks {
      padding: 20px 25px;
      margin-left: 25px;
      margin-right: 25px;
      display: flex;
      justify-content: flex-start;
      li {
        margin-right: 2em;
        border-bottom: 0;
        a {
          padding: 0;
          &:after {
            position: static;
            margin-left: 0.5em;
          }
        }
      }
    }
    ul.listLinks {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        width: 32%;
        border: solid 1px silver;
        margin: 10px 0;
        a {
          padding: 20px 25px;
          &:after {
            right: 25px;
          }
        }
      }
      &:after {
        content: "";
        display: block;
        width: 32%;
      }
    }
    ul.globalLinks {
      margin-bottom: 150px;
      li {
        width: 18%;
        margin-bottom: 0;
        a {
          h2 {
            font-size: 1.5rem;
          }
        }
      }
    }
    ul.tileLinks {
      li {
        width: calc(100% / 6 - 2px);
        a {
          padding: 3em 0;
          font-size: 1.4rem;
        }
      }
    }
    ul.textlistLinks {
      li {
        a {
          padding: 0 0 0 3.5em;
          margin: 1.8em 0;
          &:before {
            font-size: 3rem;
          }
        }
      }
    }
    h1.faqAnswer {
      font-size: 2.4rem;
      span {
        padding: 0 0 0 40px;
        &:before {
          font-size: 2.8rem;
          line-height: 1.4em;
        }
      }
    }
    div.faqAnswer {
      span {
        padding: 0 0 0 40px;
        &:before {
          font-size: 2.8rem;
          line-height: 1em;
        }
        table {
          font-size: 1.4rem;
        }
      }
    }
    div.picPresident {
      padding: 0 25px;
      img {
        width: 100%;
      }
    }
    div.picSendai {
      img {
        max-width: 450px;
      }
    }
    div.idea {
      h2 {
        font-size: 2.4rem;
      }
      p {
        font-size: 1.6rem;
        text-align: center;
        br {
          display: inline;
        }
      }
    }
    div.table {
      table {
        thead {
          th {
            display: table-cell;
            border-bottom: solid 1px silver;
            padding: 2.5em 0;
            min-width: 22%;
          }
        }
        tbody {
          th {
            display: table-cell;
            border-bottom: solid 1px silver;
            padding: 2.5em 0;
            width: 22%;
          }
          td {
            display: table-cell;
            padding: 2.5em 0;
            div.address {
              iframe {
                height: 350px;
              }
            }
            ul {
              &.officer {
                li {
                  width: 30%;
                  //margin-bottom: 30px;
                  p {
                    &.officer {
                      font-size: 1.3rem;
                    }
                    &.officerName {
                      font-size: 1.8rem;
                    }
                    &.officerNameE {
                      font-size: 1.4rem;
                    }
                  }
                }
                &.president {
                  justify-content: space-between;
                  li {
                    width: 30%;
                  }
                }
                &:after {
                  content: " ";
                  display: block;
                  width: 30%;
                }
              }
            }
            a.btn {
              display: block;
              background: silver;
              color: black;
              text-decoration: none;
              text-align: center;
              padding: 1.2em 0;
              font-size: 1.2rem;
              font-weight: bold;
              margin-top: 1em;
            }
            div.withLink {
              display: flex;
              justify-content: space-between;
              align-items: center;
              a {
                width: 250px;
                margin: 0;
              }
            }
          }
        }
      }
      &.spec {
        table {
          tbody {
            th {
              padding: 1em 0;
              font-size: 1.4rem;
            }
            td {
              padding: 1em 0;
              font-size: 1.4rem;
            }
          }
        }
      }
    &.multiple {
      table {
        tbody {
          th {
            font-size: 1.4rem;
            padding: 1em 1em 1em 0;
          }
          td {
            font-size: 1.4rem;
            padding: 1em;
          }
        }
      }
    }
      &.notice {
        table {
          thead {
            th {
              padding: 1.5em 1em;
              font-size: 1.4rem;
              border-bottom: none;
            }
          }
          tbody {
            th {
              padding: 1.5em 1em;
              font-size: 1.4rem;
              border-bottom: none;
              width: auto;
            }
            td {
              padding: 1.5em 1em;
              font-size: 1.4rem;
            }
          }
        }
      }
    }
    div.supp {
      text-align: center;
      margin-left: 25px;
      margin-right: 25px;
      p {
        a.btn {
          margin-top: 1em;
        }
      }
    }
    a.btn {
      display: block;
      max-width: 400px;
      margin: auto;
    }
    hr {
      margin: 80px 25px 40px 25px;
    }
    .center {
      text-align: center;
    }
    div.storeLinkBtn {
      margin: 90px 0 100px 0;
      a {
        max-width: 400px;
        margin: auto;
        div {
          img {
            height: 80px;
          }
        }
      }
    }
  }
}


// newsContent
.newsContent {
  p {
    a {
      text-decoration: underline;
      word-break: break-all;
      i {
        margin-left: 0.3em;
        color: darkgray;
      }
    }
  }
}


//newsYearLink
#newsYearLink {
  max-width: $maxWidth;
  margin: 0 auto;
  font-size: 1.3rem;
  ul {
    //-@extend .contentsBlock;
    list-style: none;
    display: flex;
    li {
      margin-right: 1.5em;
      &.current {
        border-bottom: solid 2px black;
      }
      a {

      }
    }
  }
}

// newsList
#newsList,
#noticeList {
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 50px 0;
  ul.newsList {
    //-@extend .contentsBlock;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      margin-bottom: 48px;
      a {
        width: 100%;
        display: inline-block;
        text-decoration: none;
        color: black;
        div.img {
          width: 100%;
          img {
            width: 100%;
            height: auto;
          }
        }
        div.detail {
          /*div.category {
            display: inline-block;
            color: white;
            background: #434343;
            padding: 3px 0;
            min-width: 80px;
            text-align: center;
            font-size: 1.4rem;
            line-height: 1.4rem;
            margin-right: 5px;
          }*/
          div.date {
            display: inline-block;
            font-family: $garamond;
            padding-top: 4px;
            font-size: 1.3rem;
            line-height: 1.3rem;
            margin: 0.6em 0;
          }
          p.title {
            color: $colorText;
            padding: 0;
            margin: 0;
            font-size: 1.3rem;
            line-height: 1.8em;
          }
        }
      }
    }
    &.top {
      li {
        width: 47%;
        margin-bottom: 40px;
        a {
          div.detail {
            letter-spacing: 0.05em;
            p.title {
              font-size: 1.2rem;
              line-height: 1.6em;
            }
          }
        }
      }
    }
  }
}
#noticeList {
  &.top {
    background: whitesmoke;
  }
}
#topContent {
  #newsList,
  #noticeList {
    padding: 60px 0;
  }
}
@media #{$pc} {
  #newsList,
  #noticeList {
    padding: 80px 0;
    ul.newsList {
      li {
        width: 31%;
        a {
          width: 100%;
          text-decoration: none;
          color: black;
          div.img {
          }
          div.detail {
            div.date {
            }
            p.title {
            }
          }
        }
      }
      &:after{
        content:"";
        display: block;
        width:31%;
      }
      &.top {
        li {
          width: 22%;
          margin-bottom: 48px;
          a {
            div.detail {
              p.title {
                font-size: 1.3rem;
                line-height: 1.8em;
              }
            }
          }
        }
      }
    }
  }
  #noticeList {
    ul.newsList {
      li {
        width: 100%;
      }
    }
  }
  #topContent {
    #newsList,
    #noticeList {
      padding: 100px 0;
    }
  }
}
#noticeList {
  max-width: none;
  ul.newsList {
    max-width: $maxWidth;
    margin: auto;
  }
}


// news release footer
#releaseFooter {
  //-@extend .contentsBlock;
  //display: flex;
  //justify-content: space-between;
  text-align: center;
  margin: 3em 0;
  a.pdf-download {
    display: inline-block;
    border: solid 1px lightgray;
    padding: 2px 6px;
    font-size: 1.3rem;
    font-weight: 500;
    border-radius: 4px;
    margin-bottom: 20px;
    i {
      color: #b40000;
    }
  }
  div.snsBlock {
    div.twitter,
    div.facebook {
      display: inline-block;
      vertical-align: top;
      margin: 0 5px;
    }
  }
}
@media #{$pc} {
  #releaseFooter {
    display: flex;
    justify-content: space-between;
    a.pdf-download {
      //-margin-bottom: 0;
    }
  }
}
#releaseInfo {
  //-@extend .contentsBlock;
  margin: 4em 0;
  div {
    padding-top: 25px;
    padding-bottom: 25px;
    background: whitesmoke;
    text-align: center;
    h4 {
      color: black;
      font-family: $gothic;
    }
    p {
      padding: 0;
      margin: 0;
    }
    a {
      display: inline-block;
      border: solid 1px lightgray;
      padding: 2px 6px;
      font-size: 1.3rem;
      font-weight: 500;
      border-radius: 4px;
      margin: 10px 0;
    }
  }
}
@media #{$pc} {
  #releaseInfo {
    div {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}

// newsContent
.newsContent {
  p {
    a {
      text-decoration: underline;
      word-break: break-all;
      i {
        margin-left: 0.3em;
        color: darkgray;
      }
    }
  }
  .pastEventLink {
    max-width: 500px;
    margin: 10px auto;
    a {
      display: block;
      background: #ebebeb;
      border-radius: 4px;
      overflow: hidden;
      figure {
        img {
          width: 100%;
          height: auto;
        }
      }
      p {
        font-size: 1.2rem;
        font-weight: bold;
        padding: .8em 1em;
        line-height: 1.6em;
        margin-bottom: 0;
        i {
          margin-left: .3em;
        }
      }
    }
  }
}

/* ==========================================================
 リンク要素のhover初期値
========================================================== */

//プレーンなテキストリンクとアンカーリンク
a:not([class]),
a[class^="hp_"],
a[href^="#"]:not([style^="background"]) {
  transition: opacity 0.3s linear;
  &:hover {
    opacity: .5;
  }
}

//直下の内容が画像のリンク
a > img {
  transition: filter 0.3s linear;
  &:hover {
    filter:brightness(60%);
  }
}

//背景画像が敷かれているリンク
a[style^="background"] {
  transition: filter 0.3s linear;
  &:hover {
    filter:brightness(60%);
  }
}

//直下の内容が動画のリンク
a > video {
  transition: filter 0.3s linear;
  &:hover {
    filter:brightness(60%);
  }
}


/* ==========================================================
 el_btnの初期値
========================================================== */

a[class^="el_btn"] {
  transition: all 0.3s linear;
  &:hover {
    opacity: .5;
  }
}


/* ==========================================================
 swiper左右ボタンの初期値
========================================================== */
.swiper-button-prev,
.swiper-button-next {
  transition: opacity 0.3s linear;
  &:hover {
    opacity: .5;
  }
}


/* ==========================================================
 個別の指定
========================================================== */

// ヘッダメニュー・サブメニューボタン
.bl_header_menu button,
.bl_subHeader_btn {
  transition: opacity 0.3s linear;
  &:hover {
    opacity: .5;
  }
}

// 黒いボタン
.el_btn__clBlack {
  &:hover {
    background-color: inherit;
    border-color: inherit;
    color: inherit;
    background-image: url(../images/common/icon_arrow-narrow.svg);
    opacity: 1 !important;
  }
}

// Gmapリンク
.bl_cfPlan_mapLink {
  transition: opacity 0.3s linear;
  i {
    transition: transform 0.2s ease-out;
  }
  &:hover {
    opacity: .5;
    i {
      transform: translateY(-3px);
    }
  }
}
/* ==========================================================
 page / top
========================================================== */

// main visual
.un_topMv {
  display: flex;
  width: 100%;
  height: 100vh;
  @media #{$pc} {
    height: calc(100vh - 65px);
  }
  &_wrap {
    width: 100%;
    height: 100vh;
    @media #{$pc} {
      height: calc(100vh - 65px);
    }
  }
  &_item {
    position: relative;
    display: flex !important; // slickによってblockに変更されるのを防ぐ
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    @media #{$pc} {
      height: calc(100vh - 65px);
    }
    div, a {
      display: block;
      width: 100%;
      padding: 0 25px 54px;
      max-width: 1330px; //$maxWidth;
      z-index: 2;
      h2 {
        color: white;
        text-shadow: 0 0 6px rgba(0,0,0,.3);
        span + span {
          margin-top: 10px;
          @media #{$pc} {
            margin-top: 20px;
          }
        }
      }
      .el_btn {
        border-color: #DBDBDB;
        background-color: rgba(255,255,255,.85);
        margin-top: 25px;
        @media #{$pc} {
          margin-top: 40px;
        }
      }
    }
    &_ja {
      display: block;
      font-family: $mincho;
      font-weight: normal;
      font-size: 1.6rem;
      letter-spacing: .04em;
      line-height: (24/16);
      @media #{$pc} {
        font-size: 2.4rem;
      }
      &__en {
        font-family: $garamond;
        font-size: 2rem;
        letter-spacing: .06em;
        @media #{$pc} {
          font-size: 2.8rem;
        }
      }
    }
    &_en {
      display: block;
      font-family: $garamond;
      font-weight: normal;
      font-size: 4rem;
      letter-spacing: .06em;
      line-height: 1;
      @media #{$pc} {
        font-size: 6.4rem;
      }
      &__ja {
        font-family: $mincho;
        font-size: 3.6rem;
        line-height: 1.2;
        @media #{$pc} {
          font-size: 5.2rem;
          letter-spacing: .1em;
        }
      }
    }
    &_img {
      max-width: 180px;
      @media #{$pc} {
        max-width: 400px;
      }
    }
    p.caption {
      position: absolute;
      right: 0;
      bottom: 0;
      color: #fff;
      font-weight: bold;
      background-color: rgba(0,0,0,.6);
      line-height: 1;
      padding: 4px 8px;
      font-size: 8px;
      width: max-content;
      max-width: 220px;
    }
  }
  /*.swiper-pagination { // swiperのpagenationカスタマイズ
    width: auto;
    left: auto;
    right: 20px;
    bottom: 50px;
    @media #{$pc} {
      left: 25px;
      right: 25px;
      //width: 100%;
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      text-align: right;
    }
  }*/
  .slick-dots { // swiperのpagenationカスタマイズ
    position: absolute;
    width: auto;
    left: auto;
    right: 20px;
    bottom: 50px;
    pointer-events: none;
    li {
      pointer-events: auto;
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: white;
      border-radius: 50%;
      margin: 0 8px;
      opacity: .2;
      transition: opacity linear .3s;
      &.slick-active {
        opacity: 1;
      }
      button {
        text-indent:110%;
        white-space:nowrap;
        overflow:hidden;
      }
    }
    @media #{$pc} {
      left: 25px;
      right: 25px;
      //width: 100%;
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      text-align: right;
    }
  }
  &_video {
    position: absolute;
    padding: 0 !important;
    max-width: none !important;
    z-index: 1;
    div {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100vh;
      padding: 0 !important;
      max-width: none !important;
      video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }
  }
}

// top navigation
.un_topNav {
  &_wrap {
    @media #{$pc} {
      border-bottom: solid 1px rgba(0,0,0,.15);
    }
  }
  &_lv1ul {
    display: flex;
    flex-wrap: wrap;
    @media #{$pc} {
      align-items: center;
      //max-width: ($maxWidth+100px);
      //padding: 0 25px !important;
      //margin: auto !important;
    }
    li {
      width: 50%;
      text-align: center;
      border-bottom: solid 1px rgba(0,0,0,.15);
      @media #{$pc} {
        width: 20%;
        border-bottom: none;
        padding: 0;
        //margin: 24px 0;
        line-height: 1;
        border-right: solid 1px rgba(0,0,0,.15);
      }
      a {
        display: block;
        font-family: $mincho;
        font-size: 1.2rem;
        padding: 12px;
        @media #{$pc} {
          display: inline-block;
          font-size: 1.7rem;
          padding: 0;
          line-height: 1;
        }
      }
      &:first-child {
        width: 100%;
        border-bottom: none;
        a {
          background: #820000;
          font-family: $gothic;
          font-weight: bold;
          letter-spacing: .1em;
          color: white;
          padding-bottom: 11px;
          @media #{$pc} {
            padding: 21px 0;
            width: 100%;
          }
          img {
            display: inline;
            width: auto;
            height: 20px;
            vertical-align: middle;
            margin-right: 8px;
            margin-bottom: 3px;
          }
        }
        @media #{$pc} {
          width: 20%;
          //border-left: solid 1px rgba(0,0,0,.15);
          border-right: none;
        }
      }
      &:nth-child(2) {
        width: 100%;
        border-bottom: none;
        border-right: none !important;
        a {
          background: #3E3E3E;
          font-family: $gothic;
          font-weight: bold;
          letter-spacing: .1em;
          color: white;
          padding-bottom: 11px;
          @media #{$pc} {
            padding: 21px 0;
            width: 100%;
          }
          img {
            display: inline;
            width: auto;
            height: 20px;
            vertical-align: middle;
            margin-right: 8px;
            margin-bottom: 3px;
          }
        }
        @media #{$pc} {
          width: 20%;
          border-right: none;
        }
      }
      &:nth-child(3) {
        width: 100%;
        @media #{$pc} {
          width: 20%;
        }
      }
      &:nth-child(even) {
        border-right: solid 1px rgba(0,0,0,.15);
      }
      &:last-child {
        @media #{$pc} {
          border-right: none;
        }
      }
    }
    &__bgBlack {
      li {
        &:first-child {
          a {
            background: black;
          }
        }
      }
    }
    &__bgRed {
      li {
        &:first-child {
          a {
            background: #a40000;
          }
        }
      }
    }
  }
  &_lv2ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    border-bottom: solid 1px rgba(0,0,0,.15);
    @media #{$pc} {
      border-bottom: none;
      height: 60px;
    }
    li {
      margin: 0 12px;
      @media #{$pc} {
        line-height: 1;
        margin: 0;
        padding: 0 32px;
        border-right: solid 1px rgba(0,0,0,.15);
        &:first-child {
          border-left: solid 1px rgba(0,0,0,.15);
        }
      }
      a {
        font-size: 1rem;
        font-family: $gothic;
        @media #{$pc} {
          font-size: 1.5rem;
        }
      }
    }
  }
}

//- news
.un_topNews {
  @media #{$pc} {
    display: flex;
    flex-wrap: wrap;
  }
  .el_lv4Idx {
    @media #{$pc} {
      text-align: left;
      width: 25%;
      flex-shrink: 0;
    }
  }
  &_ul {
    border-bottom: solid 1px rgba(0,0,0,.15);
    @media #{$pc} {
      width: 75%;
      margin-top: -5px !important;
    }
    li {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      font-size: 1.2rem;
      line-height: (18/12);
      padding: 20px 0;
      border-top: solid 1px rgba(0,0,0,.15);
      @media #{$pc} {
        flex-wrap: nowrap;
        font-size: 1.44rem;
        padding: 24px 0;
        &:first-child {
          padding-top: 0;
          border-top: none;
        }
      }
      time {
        width: 25%;
        flex-shrink: 0;
        color: #808080;
        font-family: $lato;
        letter-spacing: .04em;
        @media #{$pc} {
          width: 12%;
        }
      }
      span {
        width: 75%;
        margin-bottom: 3px;
        @media #{$pc} {
          width: 13%;
        }
        a {
          background: #888;
          color: white;
          font-size: 1.1rem;
          font-family: $mincho;
          letter-spacing: .05em;
          padding: .2em .5em;
          border-radius: 3px;
          @media #{$pc} {
            display: block;
            text-align: center;
            min-width: 100px;
            white-space: nowrap;
            vertical-align: top;
            font-size: 1.2rem;
            padding: .1em .8em;
            margin-top: 1px;
          }
        }
      }
      a {
        width: 75%;
        color: #1C1C1C;
      }
    }
  }
  &_btn {
    margin-top: 20px;
    @media #{$pc} {
      margin-top: -38px;
    }
    .el_btn {
      @media #{$pc} {
        display: inline;
        border: none;
        padding: 0 20px 0 0;
      }  
    }
  }
  &_notice {
    font-size: 1.2rem;
    border: none;
    font-weight: normal;
  }
}

//- top banners
.un_topBnrs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  a {
    padding-top: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    line-height: 0;
    &.un_topBnrs_grad {
      &:before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        content: '';
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 40%,rgba(0,0,0,.67) 100%);
      }
    }
    h3 {
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 16px;
      color: white;
      font-weight: normal;
      @media #{$pc} {
        left: 28px;
        right: 28px;
        bottom: 28px;
      }
    }
    video {
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      position: relative;
    }
    &.un_topBnrs_video {
      padding-top: 0;
    }
  }
  &_title {
    display: block;
    font-family: $garaAndMin;
    font-size: 1.44rem;
    line-height: (30/24);
    letter-spacing: .04em;
    @media #{$pc} {
      font-size: 2.4rem;
    }
    &_en {
      display: block;
      font-family: $garaAndMin;
      font-size: 1rem;
      //-line-height: (30/24);
      line-height: 1.4;
      margin-bottom: .3em;
      letter-spacing: .08em;
      @media #{$pc} {
        font-size: 1.44rem;
      }
    }
  }
  &_subTitle {
    display: block;
    font-family: $mincho;
    font-size: 1rem;
    //-line-height: (30/24);
    line-height: 1.4;
    margin-top: .5em;
    letter-spacing: .04em;
    @media #{$pc} {
      font-size: 1.44rem;
    }
  }
  .un_topBnrs_item1,
  .un_topBnrs_item6 {
    .un_topBnrs_title {
      font-size: 2rem;
      @media #{$pc} {
        font-size: 3.2rem;
      }
    }
    .un_topBnrs_subTitle {
      font-size: 1.2rem;
      @media #{$pc} {
        font-size: 1.6rem;
      }
    }
  }

  &_item1 {
    grid-row: 1 / 3;
    grid-column: 1 / 3;
  }
  &_item2 {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }
  &_item3 {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
  }
  &_item4 {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
  }
  &_item5 {
    grid-row: 4 / 5;
    grid-column: 2 / 3;
  }
  &_item6 {
    grid-row: 5 / 7;
    grid-column: 1 / 3;
  }
  @media #{$pc} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: 16px;
    row-gap: 16px;
    &_item1 {
      grid-row: 1 / 3;
      grid-column: 1 / 3;
    }
    &_item2 {
      grid-row: 1 / 2;
      grid-column: 3 / 4;
    }
    &_item3 {
      grid-row: 1 / 2;
      grid-column: 4 / 5;
    }
    &_item4 {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
    }
    &_item5 {
      grid-row: 3 / 4;
      grid-column: 2 / 3;
    }
    &_item6 {
      grid-row: 2 / 4;
      grid-column: 3 / 5;
    }
  }
}

// business banners
.un_topBusiness {
  padding-top: 40px;
  padding-bottom: 40px;
  @media #{$pc} {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: row;
    height: auto;
    li {
      width: calc(100% / 2 - 4px);
      padding-bottom: 8px;
      @media #{$pc} {
        width: calc(100% / 4 - 16px);
        padding-bottom: 0;
    height: auto;
      }
      &.spBottom {
        padding-bottom: 0;
      }
      a {
        padding-top: 100%;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
        display: flex;
        background-color: #000;
        background-size: cover;
        background-position: center;
        justify-content: center;
        align-items: flex-end;
        height: 100%;
      }
    }
  }
}

// business banners
.un_topBusinessSwiper {
  position: relative;
  overflow: hidden;
  @media #{$pc} {
    padding: 0 25px;
  }
  .swiperSubBnr {
    padding: 40px 25px;
    overflow: hidden;
    @media #{$pc} {
      max-width: 1280px;
      margin: auto;
      padding: 120px 0;
    }
  }
  &Nav {
    display: none;
    @media #{$pc} {
      display: block;
      position: absolute;
      z-index: 9;
      top: 50%;
      left: 0;
      right: 0;
      max-width: 1280px;
      width: 85%;
      margin: auto;
      .el_icon_next {
        right: -84px;
        &__rev {
          right: auto;
          left: -84px;
        }
      }
    }
  }
}

// experience portal
.un_topExportal {
  background: url(/assets/images/top/bg_experienceportal.jpg) no-repeat center;
  background-size: cover;
  color: white;
  padding: 80px 25px;
  position: relative;
  @media #{$pc} {
    padding: 140px 25px;
  }
  &:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    background: rgba(0,0,0,.4);
  }
  &_wrap {
    max-width: $maxWidth;
    margin: auto;
    text-align: center;
    position: relative;
  }
  &_sub {
    font-family: $mincho;
    font-size: 1.2rem;
    @media #{$pc} {
      font-size: 1.6rem;
    }
  }
  &_title {
    font-family: $mincho;
    font-weight: normal;
    font-size: 2.4rem;
    margin-top: .6em;
    @media #{$pc} {
      font-size: 4rem;
      margin-top: .8em;
    }
  }
  &_ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px 0 -15px !important;
    @media #{$pc} {
      margin-top: 90px !important;
    }
    li {
      width: 30.58%;
      margin-bottom: 15px;
      @media #{$pc} {
        width: 11.48%;
      }
      figure {
        margin: auto;
        width: 64px;
        @media #{$pc} {
          width: 72px;
        }
      }
    }
  }
  &_en {
    font-family: $garamond;
    font-size: 1.6rem;
    letter-spacing: .1em;
    line-height: 1;
    margin-top: .6em;
    @media #{$pc} {
      font-size: 2rem;
      margin-top: .8em;
    }
  }
  &_ja {
    font-family: $mincho;
    font-size: 1rem;
    line-height: 1;
    margin-top: .3em;
    @media #{$pc} {
      font-size: 1.2rem;
      margin-top: .5em;
    }
  }
  .el_btn {
    margin-top: 28px;
    @media #{$pc} {
      margin-top: 50px;
    }
  }
}


/* Safari用のハックは、Chromeに適用されないようにする */
@supports (-webkit-touch-callout: none) {
  body {
    /* Safari用のハック */
    height: -webkit-fill-available;
  }
}
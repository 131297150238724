/* ==========================================================
 paragraph / 基本的にpタグのみに設定
========================================================== */
.el_pg {
  font-size: 1.3rem;
  line-height: 1.8;
  letter-spacing: .08em;
  @include lineHeightCrop(1.8);
  @media #{$pc} {
    font-size: 1.5rem;
  }
  &__l {
    font-size: 1.5rem;
    @media #{$pc} {
      font-size: 1.7rem;
    }
  }
  &__m {
    font-size: 1.4rem;
    @media #{$pc} {
      font-size: 1.5rem;
    }
  }
  &__s {
    font-size: 1.2rem;
    @media #{$pc} {
      font-size: 1.4rem;
    }
  }
  &__xs {
    font-size: 1.1rem;
    line-height: 2;
    @media #{$pc} {
      font-size: 1.2rem;
    }
  }
  &__wid {
    line-height: 1.8em;
    @include lineHeightCrop(1.8);
  }
  &__lBld {
    font-size: 1.8rem;
    font-weight: bold;
  }
  &__xlBld {
    font-size: 2.4rem;
    font-weight: bold;
  }
  &__xsBld {
    font-size: 1rem;
    font-weight: bold;
    @media #{$pc} {
      font-size: 1.2rem;
    }
  }
  &__wht {
    color: white;
  }
  &__gray {
    color: #818181;
  }
  &__dGray {
    color: #4e4e4e;
  }
  &__2col {
    @media #{$pc} {
      columns: 2;
      column-gap: 8.1%;
      //column-rule: 1px solid #cdcdcd;
      span {
        display: block;
        break-inside: avoid-column;
      }
      &::before,
      &::after {
        content: none !important; // lineHeightCropを無効にする。2カラム目以降が揃わなくなるため。
      }
      transform: translateY(-.6em); // 高さクロップの解消1
      margin-bottom: -.6em; // 高さクロップの解消2
    }
  }
  &__lMin {
    font-family: $mincho;
    font-size: 1.7rem;
    line-height: 2.1em;
    @include lineHeightCrop(2.1);
    @media #{$pc} {
      font-size: 1.9rem;
      //-line-height: 2.6em;
      //-@include lineHeightCrop(2.6);
    }
  }
  &__mMin {
    font-family: $mincho;
    font-size: 1.6rem;
    line-height: 2.3;
    @include lineHeightCrop(2.3);
    @media #{$pc} {
      font-size: 1.8rem;
    }
  }
  &__mlMin {
    font-family: $mincho;
    font-size: 1.6rem;
    line-height: 2.1;
    @include lineHeightCrop(2.1);
    @media #{$pc} {
      font-size: 1.7rem;
    }
  }
  &__sMin {
    font-family: $mincho;
    font-size: 1.5rem;
    line-height: 2.1;
    @include lineHeightCrop(2.1);
    @media #{$pc} {
      font-size: 1.5rem;
    }
  }
  &__sMinW {
    font-family: $mincho;
    font-size: 1.5rem;
    line-height: 2.1;
    @include lineHeightCrop(2.1);
    @media #{$pc} {
      font-size: 1.5rem;
    }
  }
  &__xsMin {
    font-family: $mincho;
    font-size: 1.4rem;
    line-height: 2.1;
    @include lineHeightCrop(2.1);
    @media #{$pc} {
      font-size: 1.4rem;
    }
  }
  &__companyInfo {
    letter-spacing: 0.04em;
  }
}

/* ==========================================================
 text
========================================================== */

.el_textQa {
  &_q {
    position: relative;
    padding-left: 25px;
    font-size: 1.6rem;
    font-weight: bold;
    @media #{$pc} {
      font-size: 1.9rem;
    }
    &::before {
      position: absolute;
      left: 0;
      content: 'Q.';
      display: inline-block;
      font-size: 1.9rem;
      margin-right: 10px;
    }
  }
  &_a {
    position: relative;
    padding-left: 25px;
    font-size: 1.3rem;
    line-height: 2.1;
    color: #585858;
    @media #{$pc} {
      font-size: 1.5rem;
    }
    &::before {
      position: absolute;
      left: 0;
      content: 'A.';
      display: inline-block;
      font-size: 1.9rem;
      color: black;
      margin-right: 10px;
      top: -.2em;
    }
    &_section {
      display: block;
      margin-top: .9em;
      strong {
        color: black;
        font-weight: bold;
      }
    }
  }
}

.el_figCaption {
  font-size: 1.3rem;
  line-height: 1.8em;
  margin-top: 1em;
  @media #{$pc} {
    font-size: 1.5rem;
  }
  &__wht {
    color: white;
  }
}

/* ==========================================================
 ol, ul / 基本的にpタグのみに設定
========================================================== */
.el_ol {
  li {
    position: relative;
    padding-left: 2.5em;
    line-height: 1.8em;
    margin-bottom: 0.6em;
    letter-spacing: .08em;
    font-size: 1.3rem;
    @media #{$pc} {
      font-size: 1.5rem;
    }
    em {
      position: absolute;
      left: 0;
      font-style: normal;
    }
  }
}
.el_ul {
  list-style: disc !important;
  padding-left: 1.5em !important;
  li {
    position: relative;
    line-height: 1.8em;
    margin-bottom: 0.6em;
    letter-spacing: .08em;
    font-size: 1.3rem;
    @media #{$pc} {
      font-size: 1.5rem;
    }
  }
}
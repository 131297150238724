/* ==========================================================
 block nav
========================================================== */

//- メインナビ

.bl_nav {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  background: white;
  visibility: hidden;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 10002;
  @media #{$pc} {
    display: flex;
    flex-direction: column;
    top: 90px;
  }

  &_main {
    @media #{$pc} {
      width: 100%;
      max-width: $maxWidth;
      padding: 24px 25px 54px;
      margin: auto;
      border-bottom: solid 1px #e6e6e6;
    }
    &_ul {
      @media #{$pc} {
        display: flex;
        justify-content: center;
      }
      > li {
        @media #{$pc} {
          width: 32%;
        }
        > a, > button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-top: solid 1px #E6E6E6;
          width: 100%;
          height: 60px;
          font-family: $mincho;
          font-size: 1.5rem;
          line-height: 1.4;
          background: url(/assets/images/common/icon_navbtn_plus.svg) no-repeat right 12px center;
          color: inherit;
          @media #{$pc} {
            border-top: none;
            height: auto;
            font-size: 2rem;
            background-position: right 12px top 7px;
          }
          &.is_active {
            background-image: url(/assets/images/common/icon_navbtn_minus.svg);
          }
          &[href] {
            background-image: url(/assets/images/common/icon_navbtn_arrow.svg);
          }
          span {
            font-family: $gothic;
            font-size: .9rem;
            color: #8E8D9B;
            @media #{$pc} {
              font-size: 1.2rem;
            }
          }
        }
      }
    }

  }

  &_mainLarge { // PCのときだけ表示する要素（ダブりあり）
    margin: 50px auto 0;
    width: 100%;
    max-width: 1030px;
    padding: 0 25px 50px;
    //-padding-bottom: 50px;
    &_title {
      font-family: $mincho;
      font-weight: normal;
      font-size: 2rem;
      letter-spacing: .04em;
    }
    &_wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
    }
    &_item {
      margin-right: 42px;
      &:first-child {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .bl_nav_sub_li_detail {
        margin-top: 18px; 
      }
      p {
        margin-bottom: 8px;
        a {
          font-size: 1.4rem;
          //-line-height: 2;
          line-height: 1.2;
          display: inline-block;
          background: url(/assets/images/common/icon_navbtn_arrow.svg) no-repeat right top 3px;
          background-size: 10px;
          padding-right: 16px;
        }
      }
      &__gap {
        margin-right: 105px
      }
    }
    &_link {
      margin-top: 50px;
      padding-top: 50px;
      border-top: solid 1px #e6e6e6;
      display: flex;
      justify-content: space-around;
      div {
        a {
          display: inline-block;
          font-family: $garamond;
          //-font-size: 1.8rem;
          font-size: 2.1rem;
          line-height: 1.3;
          letter-spacing: .1em;
          padding-right: 18px;
          background: url(/assets/images/common/icon_navbtn_arrow.svg) no-repeat right top 7px;
          span {
            display: block;
            font-family: $mincho;
            font-size: 1.2rem;
            letter-spacing: .02em;
            line-height: 1.6;
          }
        }
      }
    }
  }

}
button + .bl_nav_sub {
  display: none; // 直前にボタンがある＝トグルされた状態にする
}



//- メインナビサブ
.bl_nav_sub {

  &_visual {
    &_slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 56.26vw;
      background: url(/assets/images/common/bg_nav_visual_01.jpg) no-repeat;
      background-size: cover;
    }
    &_tagline {
      width: 56vw;
    }
  }

  &_ul {
    > li {
      border-top: solid 5px white;
      background: #E6E6E6;
      &:first-child {
        border-top: none;
      }
      > button {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 32px 16px;
        background: url(/assets/images/common/icon_navbtn_plus.svg) no-repeat right 12px center;
        &.is_active {
          background-image: url(/assets/images/common/icon_navbtn_minus.svg);
        }
        color: inherit;
      }
    }
  }
  &_li {
    &_title {
      width: 31.42%;
      font-family: $garamond;
      font-size: 1.5rem;
      line-height: (18/15);
      span {
        margin-top: 6px;
        display: block;
        font-family: $mincho;
        font-size: 1rem;
        line-height: (14/10);
      }
    }
    &_detail {
      font-family: $mincho;
      font-size: 1.4rem;
      line-height: (26/14);
      color: black;
      @media #{$pc} {
        color: #717171;
      }
    }
  }

}

//- メインナビサブの第二階層
.bl_nav_sub2nd {
  padding: 0 16px 32px;
  margin-top: -4px;
  &_ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 100%;
      &.f2col {
        width: 47.81%;
      }
      a, span {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        font-family: $mincho;
        font-size: 1.4rem;
        border-bottom: solid 1px rgba(0,0,0,.1);
        background: url(/assets/images/common/icon_navbtn_arrow.svg) no-repeat right center;
        &[href^="#"] {
          background-image: url(/assets/images/common/icon_navbtn_arrow_down.svg);
        }
        &[target] {
          position: relative;
          background: none;
          font-family: $gothic;
          font-weight: bold;
          font-size: 1.3rem;
          &:after {
            position: absolute;
            right: 0;
            top: 27%;
            content: "\f360";
            font-size: 1.2rem;
            font-family: $fontAwesome;
            font-weight: bold;
            color: #a40000
          }
        }
        @media #{$pc} {
          font-size: 1.5rem;
          height: 56px;
        }
      }
      span {
        background-image: none;
      }
      > .bl_nav_sub2nd_ul {
        padding-left: 12px;
        li {
          a {
            font-size: 1.2rem;
            font-family: $gothic;
            line-height: 1.4;
            padding-right: 12px;
            @media #{$pc} {
              font-size: 1.4rem;
              padding-right: 24px;
            }    
          }
        }
      }
    }
  }
}
button + .bl_nav_sub2nd {
  display: none; // 直前にボタンがある＝トグルされた状態にする
}

//- タブナビ
.bl_nav_tab {
  display: flex;
  border-top: solid 5px #E6E6E6;
  @media #{$pc} {
    border-top: none;
  }
  &_btn {
    width: 70px;
    background: #E6E6E6;
    flex-shrink: 0;
    > li {
      > button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70px;
        border-top: solid 1px white;
        font-family: $gothic;
        font-size: .9rem;
        line-height: 1;
        color: inherit;
        span {
          display: block;
          width: 32px;
          height: 32px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          margin-bottom: 6px;
          opacity: .25;
        }
        &.is_active {
          background-color: white;
          span {
            opacity: 1;
          }
        }  
      }
      &:first-child {
        > button {
          border-top-color: transparent;
        }
      }
    }
  }

  &_itemWrap {
    width: 100%;
    padding-bottom: 32px;
    @media #{$pc} {
      padding-bottom: 0;
    }
  }

  &_item {
    padding-left: 24px !important;
    @media #{$pc} {
      padding-left: 0 !important;
    }
    &_title {
      font-family: $garamond;
      font-size: 1.5rem;
      letter-spacing: .1em;
      line-height: 1;
      margin: 20px 0;
      @media #{$pc} {
        flex-shrink: 0;
        //-font-size: 1.8rem;
        font-size: 2.1rem;
        margin: 0 60px 0 0;
        line-height: 1.3;
      }
      span {
        display: block;
        font-family: $mincho;
        font-size: 1rem;
        margin-top: 5px;
        @media #{$pc} {
          font-size: 1.2rem;
          letter-spacing: .02em;
          line-height: 1.6;
        }
      }
    }
    @media #{$pc} {
      > li {
        display: flex;
        padding: 50px 0;
        max-width: 980px;
        margin: auto;
        border-top: solid 1px #e6e6e6;
      }
      &:last-child {
        > li {
          border-bottom: solid 1px #e6e6e6;
        }
      }
    }
  }

}

.bl_nav_tab_item2nd {
  @media #{$pc} {
    display: flex;
    > a {
      width: 240px !important;
      flex-shrink: 0;
    }
  }
  > a, > button {
    display: block;
    background: url(/assets/images/common/icon_navbtn_plus.svg) no-repeat right 12px center;
    width: 100%;
    color: inherit;
    > span {
      display: flex;
      align-items: center;
      width: 100%;
      height: 44px;
      font-family: $mincho;
      font-size: 1.4rem;
      border-bottom: solid 1px rgba(0,0,0,.1);
      background: no-repeat;
      background-position: 0 center;
      background-size: auto 28px;
      &[style] {
        padding-left: 46px;
      }
      @media #{$pc} {
        display: inline;
        border-bottom: none;
        width: auto;
        height: auto;
        font-family: $gothic;
        line-height: 2;
        background: url(/assets/images/common/icon_navbtn_arrow.svg) no-repeat right top 4px;
        background-size: 10px;
        padding-right: 13px;
      }
    }
    &.is_active {
      background-image: url(/assets/images/common/icon_navbtn_minus.svg);
    }
  }
  > a {
    background-image: url(/assets/images/common/icon_navbtn_arrow.svg);
    @media #{$pc} {
      background: none;
      //-font-weight: bold;
    }
  }
  &_ul {
    padding-left: 20px !important;
    @media #{$pc} {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0 !important;
    }
    > li {
      @media #{$pc} {
        width: 240px;
      }
      > a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
        font-family: $gothic;
        font-size: 1.2rem;
        border-bottom: solid 1px rgba(0,0,0,.1);
        background: no-repeat;
        background-position: 0 center;
        background-size: auto 28px;
        position: relative;
        padding-right: 26px; // 苦肉の策
        line-height: 1.4;
        &[style] {
          padding-left: 46px;
        }
        @media #{$pc} {
          display: inline;
          padding-right: 0;
          width: auto;
          height: auto;
          border-bottom: 0;
          font-size: 1.4rem;
          line-height: 2;
          background: url(/assets/images/common/icon_navbtn_arrow.svg) no-repeat right top 3px;
          background-size: 10px;
          padding-right: 13px;
        }
        &:after { // 苦肉の策
          content: '';
          position: absolute;
          right: 0;
          width: 26px;
          height: 100%;
          background: url(/assets/images/common/icon_navbtn_arrow.svg) no-repeat right 12px center;
          @media #{$pc} {
            content: none;
          }
        }
      }
      > .bl_nav_tab_item2nd {
        > a, > button {
          > span {
            font-family: $gothic;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

button + .bl_nav_tab_item2nd_ul {
  display: none; // 直前にボタンがある＝トグルされた状態にする
}

// 読み物
.bl_nav_tab_itemMedia {
  padding-right: 12px;
  &_ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 47.68%;
      .el_tagUline {
        margin-top: 12px;
      }
    }
  }
  &_subTitle {
    font-size: 1.1rem;
    margin-top: 12px;
    line-height: (16/11);
  }
  &_title {
    font-size: 1.5rem;
    margin-top: 6px;
    //-line-height: (16/14);
    line-height: 1,5;
    font-family: $mincho;
  }
}

// 新着情報
.bl_nav_tab_itemNews {
  border-top: solid 1px rgba(0,0,0,.1);
  padding-right: 12px;
  li {
    margin-right: -12px;
    a {
      display: flex;
      width: 100%;
      border-bottom: solid 1px rgba(0,0,0,.1);
      padding: 20px 12px 20px 0;
    }
  }
  &_date {
    font-family: $lato;
    font-size: 12px;
    color: rgba(0,0,0,.5);
    width: 76px;
    flex-shrink: 0;
  }
  &_title {
    font-size: 1.2rem;
    line-height: (18/12);
  }
}

// 下部バナー
.bl_nav_banner {
  @media #{$pc} {
    max-width: 400px;
    margin: 96px auto 0;
    order: 1;
  }
}

// グリッドリンク
.bl_nav_grid {
  @media #{$pc} {
    margin-top: 56px;
  }
  &_ul {
    display: flex;
    flex-wrap: wrap;
    border-bottom: solid 4px #E6E6E6;
    @media #{$pc} {
      justify-content: center;
      border-bottom: none;
    }
    li {
      width: 50%;
      border-bottom: solid 1px #E6E6E6;
      @media #{$pc} {
        width: auto;
        border-bottom: none;
      }
      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding: 0 14px;
        font-size: 1.1rem;
        background: url(/assets/images/common/icon_navbtn_arrow.svg) no-repeat right 12px center;
        @media #{$pc} {
          display: inline;
          padding: 0;
          height: auto;
          margin: 0 33px;
          font-size: 1.4rem;
          background: none;
        }
      }
      &:nth-child(even) {
        border-left: solid 1px #E6E6E6;
        @media #{$pc} {
          border-left: none;
        }
      }
    }
  }
}

// SNS
.bl_nav_sns {
  padding: 32px 0 160px;
  @media #{$pc} {
    order: 1;
    padding: 56px 0;
  }
  p {
    text-align: center;
    font-family: $garamond;
    font-size: 1.5rem;
    letter-spacing: .1em;
    @media #{$pc} {
      font-size: 1.8rem;
    }
  }
  &_ul {
    display: flex;
    justify-content: center;
    margin-top: 10px !important;
    li {
      margin: 0 8px;
      a {
        font-size: 2.4rem;
      }
    }
  }
}

// Scroll arrow for PC
.bl_nav_scrollarrow {
  display: none;
  @media #{$smallPc} {
    position: absolute;
    display: block;
    left: -25px;
    top: 240px;
    font-family: $lato;
    font-size: 1rem;
    padding-right: 100px;
    line-height: 1;
    color: #666;
    transform: rotate(90deg);
    background: url(/assets/images/common/icon_scrollarrow.svg) no-repeat right top;
  }
}
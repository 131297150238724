//!!!後日：ハーフリーディングを考慮してmargin-topをすべて取り直す!!!//

/* ==========================================================
 heading
========================================================== */
.el_lv1Hd {
  font-family: $garamond;
  font-weight: normal;
  font-size: 2.6rem;
  line-height: .9;
  letter-spacing: 0.08em;
  @include lineHeightCrop(.9);
  @media #{$pc} {
    font-size: 3.4rem;
    line-height: 1.4;
    @include lineHeightCrop(1.4);
  }
  span {
    font-family: $mincho;
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    @media #{$pc} {
      font-size: 1.3rem;
    }  
  }
  &__rev {
    text-align: right;
  }
  &__ctr {
    text-align: center;
  }
  &__wht {
    color: white;
    span {
      color: white;
    }
  }
}
.el_lv1HdGtc {
  font-family: $gothic;
  font-weight: bold;
  font-size: 2.6rem;
  line-height: 1.5em;
  letter-spacing: 0.04em;
  height: 80vh; //仮
  @media #{$pc} {
    font-size: 3.6rem;
    line-height: 1.8em;
    letter-spacing: 0.015em;
  }
  &__wht {
    color: white;
    span {
      color: white;
    }
  }
}

.el_lv1SubHd {
  font-family: $mincho;
  font-weight: normal;
  font-size: 2.8rem;
  text-align: center;
  letter-spacing: 0.08em;
  line-height: 1.4;
  margin-top: 40px;
  margin-bottom: 50px;
  @media #{$pc} {
    font-size: 3.8rem;
    margin-top: 150px;
    margin-bottom: 70px;
  }
  span {
    color: #404040;
    font-size: 1.3rem;
    letter-spacing: 0.02em;
    @media #{$pc} {
      font-size: 1.6rem;
    }
  }
}

.el_lv2Hd {
  @include fS_lv2;
  font-family: $mincho;
  font-weight: normal;
  letter-spacing: 0.05em;
  margin-bottom: 56px;
  @media #{$pc} {
    margin-bottom: 140px;
  }
  &__wht {
    color: white;
  }
  &__nrw {
    @media #{$pc} {
      line-height: 1.8em;
      @include lineHeightCrop(1.8);
    }
  }
  &__gtc {
    font-family: $gothic;
    font-weight: bold;
    letter-spacing: 0.015em;
  }
  &__cnt {
    text-align: center;
  }
}
.el_lv3Hd {
  @include fS_lv3;
  font-family: $mincho;
  font-weight: normal;
  letter-spacing: 0.05em;
  margin-bottom: 56px;
  @media #{$pc} {
    margin-bottom: 140px;
  }
  &__wht {
    color: white;
  }
  &__mbSmall {
    margin-bottom: 48px;
    @media #{$pc} {
      margin-bottom: 80px;
    }
  }
  &__gtc {
    font-family: $gothic;
    font-weight: bold;
    letter-spacing: 0.015em;
  }
}
.el_lv3SubHd {
  font-family: $garamond;
  font-size: 1.3rem;
  letter-spacing: 0.12em;
  margin-bottom: 1em;
  @media #{$pc} {
    font-size: 1.5rem;
    margin-bottom: 36px;
  }
  &__wht {
    color: white;
  }
  &__wide {
    line-height: 2.1;
    @include lineHeightCrop(2.1);
    margin-bottom: 70px;
    @media #{$pc} {
      margin-bottom: 75px;
    }
  }
}
.el_lv4Hd {
  @include fS_lv4;
  font-family: $mincho;
  font-weight: normal;
  letter-spacing: 0.05em;
  &_sub {
    font-size: 1.4rem;
    @media #{$pc} {
      font-size: 1.8rem;
    }
  }
  &__wht {
    color: white;
  }
  &__gtc {
    font-family: $gothic;
    font-weight: bold;
    letter-spacing: 0.015em;
  }
}
.el_lv4SubHd {
  font-family: $garamond;
  font-size: 1.2rem;
  letter-spacing: 0.08em;
  margin-bottom: 0;
  color: #6E6E6E;
  line-height: 1;
  @include lineHeightCrop(1);
  @media #{$pc} {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
}
.el_lv5Hd {
  @include fS_lv5;
  font-family: $mincho;
  font-weight: normal;
  letter-spacing: .06em;
  &__ul {
    //-border-bottom: solid 1px black;
    //-padding-bottom: .5em;
  }
  &__img {
    padding: .3em 0;
    img {
      max-width: 50%;
      margin: auto;
    }
  }
  &__wht {
    color: white;
  }
  &__gtc {
    font-family: $gothic;
    font-weight: bold;
    letter-spacing: 0.015em;
  }
}
.el_lv6Hd {
  @include fS_lv6;
  font-family: $mincho;
  font-weight: normal;
  letter-spacing: .06em;
  &__wht {
    color: white;
  }
  &__gtc {
    font-family: $gothic;
    font-weight: bold;
    letter-spacing: 0.015em;
  }
}
.el_lv2Idx {
  font-family: $mincho;
  font-weight: normal;
  font-size: 2.6rem;
  line-height: 1;
  letter-spacing: .05em;
  @media #{$pc} {
    text-align: center;
    font-size: 3.2rem;
  }
  span {
    display: inline-block;
    font-family: $garamond;
    font-size: 1.3rem;
    margin-bottom: 18px;
    @media #{$pc} {
      font-size: 1.5rem;
      margin-bottom: 38px;
    }
  }
}
.el_lv4Idx {
  text-align: center;
  font-family: $garamond;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 1.3em;
  letter-spacing: 0.05em;
  margin-bottom: 26px;
  margin-top: -.3em;
  @media #{$pc} {
    font-size: 3.2rem;
    margin-bottom: 55px;
  }
  span {
    color: $colorGray;
    font-family: $mincho;
    font-size: 1.2rem;
    letter-spacing: 0.12em;
  }
  &__wht {
    color: white;
    span {
      color: white;
    }
  }
}
.el_hdMonth {
  font-family: $mincho;
  font-weight: normal;
  font-size: 2.2rem;
  margin: 36px 0 25px;
  span {
    font-family: $garamond;
    font-size: 1.5rem;
    letter-spacing: .08em;
    margin-left: .7em;
  }
}
.el_hdYear {
  //margin-bottom: 70px;
  @media #{$pc} {
    //margin-bottom: 140px;
  }
  &_en {
    display: block;
    font-family: $garamond;
    font-weight: 500;
    font-size: 4.8rem;
    letter-spacing: .08em;
    line-height: 1em;
    @media #{$pc} {
      font-size: 10rem;
    }
  }
  &_ja {
    display: block;
    font-family: $gothic;
    font-weight: normal;
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1em;
    margin-top: .8em;
    @media #{$pc} {
      font-size: 1.6rem;
    }
  }
  &__indent {
    .el_hdYear_en {
      margin-left: -.1em;
    }
  }
  &__wht {
    color: white;
  }
}
.el_hdNum {
  font-family: $garamond;
  font-size: 3.2rem;
  font-weight: normal;
  line-height: 1;
  letter-spacing: .05em;
  @media #{$pc} {
    font-size: 4rem;
  }
  &__wht {
    color: white;
  }
}
.el_hdSssrf {
  display: inline-block;
  font-family: $roboto;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.4em;
  margin-bottom: 40px;
  @media #{$pc} {
    font-size: 1.3rem;
    margin-bottom: 64px;
  }
  &__ul {
    border-bottom: solid 1px black;
  }
  &__wht {
    color: white;
    border-bottom-color: white;
  }
}

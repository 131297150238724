// 店舗情報

.un_sbs {

  // ヘッダ
  &Header {
    &Logo {
      width: 100px;
    }
    &Wrap {
      @media #{$pc} {
        display: flex;
        justify-content: space-between;
      }
    }
    &Storename {
      margin-top: 18px;
      font-family: fot-tsukuaoldmin-pr6n, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 2.6rem;
      -webkit-text-stroke: .007em black;
      line-height: 1.2;
      letter-spacing: .05em;
      max-width: 700px;
      @media #{$pc} {
        font-size: 3.4rem;
      }
      span {
        display: block;
        font-family: sans-serif;
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 2;
        letter-spacing: 0;
        @media #{$pc} {
          font-size: 1.4rem;
        }
      }
    }
    &Sns {
      display: flex;
      gap: 12px;
      margin-top: 18px !important;
      @media #{$pc} {
        margin-top: 28px !important;
      }
      li {
        font-size: 1.8rem;
        &:first-child {
          position: absolute;
          right: 25px;
          top: 17px;
          font-size: 1.4rem;
          @media #{$pc} {
            position: static;
            font-size: 1.6rem;
            margin-right: 12px;
          }
          a {
            border: solid 1px #ccc;
            border-radius: 1em;
            display: inline-block;
            padding: .1em .6em;
            @media #{$pc} {
              border: none;
              border-radius: 0;
              display: inline;
              padding: 0;
            }
          }
          i {
            font-size: 1.2rem;
            margin-right: .2em;
            @media #{$pc} {
              font-size: 1.4rem;
            }
          }
        }
      }
      &Rsv {
        margin-left: 12px;
        white-space: nowrap;
        a {
          font-size: 1.4rem;
          font-weight: bold;
          color: white;
          background: #1e1e1e;
          padding: .6em 1em;
          border-radius: 2em;
        }
      }
    }
  }

  // 店舗イメージ
  &Figure {
    margin: 20px -25px 0;
    @media #{$pc} {
      margin: 40px 0 0;
    }

    .slider {
      figure {
        img {
          @media #{$pc} {
            aspect-ratio: 1.96 / 1;
            object-fit: cover;
          }
        }
      }
    }

    // slick
    .slick-dots {
      display: flex;
      justify-content: center;
      gap: 4px;
      button {
        text-indent: -9999px;
        font-size: 0;
        line-height: 0;
        padding:  12px 0;
        &::after {
          display: block;
          content: '';
          width: 30px;
          height: 2px;
          background: #D9D9D9;
          transition: background-color .3s;
        }
      }
      .slick-active button {
        &::after {
          background: #666;
        }
      }
    }
  }

  // 店舗コピー
  &Leadcopy {
    text-align: center;
    h2 {
      font-size: 2.1rem;
      font-family: $mincho;
      font-weight: normal;
      letter-spacing: .1em;
      line-height: 2;
      margin-top: 1.5em;
      @media #{$pc} {
        font-size: 2.8rem;
      }
    }
    p {
      font-size: 1.4rem;
      font-family: $mincho;
      letter-spacing: .05em;
      line-height: 2;
      margin-top: 1.1em;
      @media #{$pc} {
        font-size: 1.6rem;
        margin-top: 2em;
      }
    }
  }

  // 共通 - 見出し
  &Headline {
    margin: 75px 0 50px;
    text-align: center;
    font-family: $garamond;
    font-weight: normal;
    font-size: 2.4rem;
    line-height: 1.1;
    letter-spacing: .1em;
    @media #{$pc} {
      font-size: 2.8rem;
      margin: 100px 0 75px;
    }
    span {
      display: block;
      font-family: sans-serif;
      font-weight: bold;
      font-size: 1.2rem;
      line-height: 2;
      letter-spacing: 0;
      @media #{$pc} {
        font-size: 1.4rem;
      }
    }
    &__compact {
      margin: 40px 0 25px;
      @media #{$pc} {
        margin: 50px 0 25px;
      }
      span {
        display: none;
      }
    }
  }

  // 共通 - 表組
  &Table {
    table {
      width: 100%;
      font-size: 1.4rem;
      border-collapse: collapse;
      @media #{$pc} {
        font-size: 1.5rem;
      }
      tr {
        text-align: left;
        th {
          display: block;
          margin-top: .5em;
          @media #{$pc} {
            display: table-cell;
            vertical-align: top;
            white-space: nowrap;
            padding: .3em 1em .3em 0;
            padding-right: 1em;
          }
        }
        td {
          display: block;
          @media #{$pc} {
            display: table-cell;
            vertical-align: top;
            padding: .3em 0;
          }
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }

  // 共通 - google map
  &Googlemap {
    margin-left: -25px;
    margin-right: -25px;
    @media #{$pc} {
      margin-left: 0;
      margin-right: 0;
    }
    iframe {
      width: 100vw;
      height: 100vw;
      @media #{$pc} {
        width: 100%;
        height: auto;
        aspect-ratio: 1.96 / 1;
      }
    }
  }

  // 共通 - elfsight
  &Elfsight {
    margin-left: -24px;
    margin-right: -24px;
    @media #{$pc} {
      margin-left: 0;
      margin-right: 0;
    }
  }

  // 共通 - ボタン
  &Btn {
    text-align: center;
    a {
      display: inline-block;
      background: #1e1e1e;
      font-weight: bold;
      font-size: 1.2rem;
      padding: 1em;
      border-radius: 2em;
      color: white;
      margin-top: .8em;
      min-width: 250px;
      @media #{$pc} {
        font-size: 1.5rem;
      }
      i {
        margin-right: .3em;
      }
    }
  }

  // 限定アイテム等
  &Items {
    & + .un_sbsItems {
      margin-top: 50px;
      @media #{$pc} {
        margin-top: 75px;
      }
    }
    &Title {
      font-size: 1.8rem;
      @media #{$pc} {
        font-size: 2.1rem;
      }
    }
    &Desc {
      font-size: 1.3rem;
      line-height: 1.6;
      margin-top: .8em;
      @media #{$pc} {
        font-size: 1.5rem;
      }
    }
    &List {
      margin-top: 25px !important;
      display: flex;
      flex-wrap: wrap;
      gap: 30px 12px;
      @media #{$pc} {
        gap: 50px 20px;
        margin-top: 50px !important;
      }
      &Item {
        width: calc(50% - 6px);
        @media #{$pc} {
          width: calc(50% - 10px);
        }
        h4 {
          font-weight: bold;
          font-size: 1.3rem;
          line-height: 1.6;
          margin-top: .8em;
          @media #{$pc} {
            font-size: 1.5rem;
          }
        }
        p {
          font-size: 1.2rem;
          line-height: 1.6;
          margin-top: .5em;
          @media #{$pc} {
            font-size: 1.4rem;
          }
        }
        a {
          display: inline-block;
          background: #1e1e1e;
          font-weight: bold;
          font-size: 1.2rem;
          padding: .4em 1em;
          border-radius: 2em;
          color: white;
          margin-top: .8em;
          @media #{$pc} {
            font-size: 1.5rem;
          }
          &.disabled {
            background: #ebebeb;
            color: gray;
            font-size: 1.1rem;
            pointer-events: none;
            @media #{$pc} {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }

  // ストーリー
  &Story {
    background: #1e1e1e;
    padding-bottom: 50px;
    margin: 50px 0;
    @media #{$pc} {
      margin: 100px 0;
      padding: 100px 0;
    }
    &Leadcopy {
      color: white;
      h2 {
        text-align: center;
        font-size: 2.1rem;
        font-family: $mincho;
        font-weight: normal;
        letter-spacing: .1em;
        line-height: 2;
        margin-top: 30px;
        @media #{$pc} {
          font-size: 2.4rem;
        }
      }
      p {
        font-size: 1.4rem;
        line-height: 1.6;
        margin-top: 30px;
        @media #{$pc} {
          font-size: 1.5rem;
          margin-top: 50px;
        }
      }
    }
    &Figures {
      color: white;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 30px !important;
      @media #{$pc} {
        flex-direction: row;
        gap: 14px;
        margin-top: 50px !important;
      }
      li {
        figure {

        }
        p {
          text-align: center;
          font-size: 1.2rem;
          margin-top: .5em;
        }
      }
    }
    // slick
    .slick-dots {
      button {
        &::after {
          background-color: #666;
        }
      }
      .slick-active button {
        &::after {
          background-color: #D9D9D9;
        }
      }
    }
  }

  footer {
    margin-top: 50px;
  }
}
// event
// legacy css
.un_legacy_spw {

  // コロナ対策
  .emergency {
    background: #eee;
    padding: 25px;
    max-width: 860px;
    margin: 50px auto 0;
    h2 {
      font-family: $gothic;
      font-weight: bold;
      text-align: center;
      margin: 0 0 15px 0;
    }
    p {
      margin-bottom: 0;
    }
  }
  .afterEmergency {
    background: #f5f5f5;
    padding: 60px 25px;
    @media #{$pc} {
      padding: 120px 25px;
    }
    &Wrap {
      max-width: 600px;
      margin: 0 auto;
    }
    &Title {
      color: #227f38;
      text-align: center;
      line-height: 1.4;
      font-size: 1.4rem;
      @media #{$pc} {
        font-size: 1.8rem;
      }
    }
    &Para {
      margin-top: 2em;
      font-size: 1.3rem;
      @media #{$pc} {
        font-size: 1.4rem;
      }
    }
  }

  #eventVisual {
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    width: 100%;
    height: 400px;
    //-margin-top: 70px;
    &.spwTop {
      z-index: 1;
      position: relative;
      overflow: hidden;
      &.spw2020 {
        height: 500px;
        span {
          height: 500px;
        }
      }
      span {
        display: flex;
        align-items: center;
        float: left;
        width: 100%;
        height: 400px;
        background: url(/assets/images/event/spw/_mv_spw2018.jpg) no-repeat center center;
        background-size: cover;
        &:nth-child(2) {
          background: url(/assets/images/event/spw/mv_spw2018_2.jpg) no-repeat center center;
          background-size: cover;
        }
        &:nth-child(3) {
          background: url(/assets/images/event/spw/mv_spw2018_3.jpg) no-repeat center center;
          background-size: cover;
        }
        &:nth-child(4) {
          background: url(/assets/images/event/spw/mv_spw2018_4.jpg) no-repeat center center;
          background-size: cover;
        }
        &:nth-child(5) {
          background: url(/assets/images/event/spw/mv_spw2018_5.jpg) no-repeat center center;
          background-size: cover;
        }
      }
    }
    &.spwDetail {
      display: flex;
      align-items: center;
      background: url(/assets/images/event/spw/mv_spw2018_detail.jpg) no-repeat center center;
      background-size: cover;
      height: 150px;
      &.spw2019 {
        background-image: url(/assets/images/event/spw/2019/mv_spw_1.jpg);
        background-position: center 30%;
      }
      &.spw2020 {
        background-image: url(/assets/images/event/spw/2020/mv_spw2020_detail.jpg);
        background-position: center bottom;
      }
      &.spw2021 {
        background-image: url(/assets/images/event/spw/2021/mv_01.jpg);
        background-position: center 80%;
      }
      &.spw2022 {
        background-image: url(/assets/images/event/spw/2022/mv_01.jpg);
        background-position: center;
      }
      &.spw2023 {
        background-image: url(/assets/images/event/spw/2023/mv_01.jpg);
        background-position: center 90%;
      }
      div {
        max-width: 100px;
        margin: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    h1, figure {
      width: 60%;
      max-width: 350px;
      margin: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  #breadCrumb.eventVisual {
    margin-top: 0;
    padding-top: 30px;
  }
  .eventIntro {
    max-width: 900px;
    margin: 0 auto 50px auto;
  }
  .eventImages {
    @extend .contentsBlock;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 900px;
    margin: auto;
    li {
      width: calc(100% / 2 - 10px);
      margin-bottom: 20px;
      img {
        width: 100%;
        height: auto;
      }
      &:last-child {
        display: none;
      }
    }
  }
  .eventMap {
    @extend .contentsBlock;
    margin-bottom: 50px;
    div {
      max-width: 1000px;
      margin: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .eventPlaceList {
    @extend .contentsBlock;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      margin-bottom: 40px;
      a {
        display: block;
        h3 {
          background: url(/assets/images/event/spw/icon_rightangle.svg) no-repeat right 75%;
        }
      }
      div {
        position: relative;
        .status {
          position: absolute;
          padding: .3em .4em;
          line-height: 1.2;
          left: 0;
          top: 0;
          font-size: 1.1rem;
          font-weight: bold;
          background: white;
          color: black;
          &__start {
            background: #90c139;
            color: white;
          }
          &__end {
            background: #858891;
            color: white;
          }
        }
      }
      img {
        width: 100%;
        height: auto;
      }
      h3 {
        padding: 0;
        margin: 1em 0 0 0;
        span {
          display: inline-block;
          line-height: 1.2;
          font-family: $gothic;
          font-size: 1.1rem;
          font-weight: bold;
          background: black;
          color: white;
          padding: .3em .4em .2em;
          border-radius: 3px;
          margin-right: .5em;
          margin-top: -4px;
          vertical-align: middle;
          &.new {
            background: #90c139;
            margin-left: .5em;
            margin-right: 0;
          }
        }
      }
      p {
        padding: 0;
        color: #555;
        margin-bottom: .2em;
        a {
          display: inline-block;
          text-decoration: underline;
          i {
            margin-left: .2em;
          }
          &.textBtn {
            margin-top: .4em;
            border: solid 1px gainsboro;
            background: whitesmoke;
            padding: 0 0 0 .6em;
            text-decoration: none;
          }
        }
        &.subscription {
          font-size: 1.2rem;
          margin-bottom: 0;
          span {
            border: solid 1px gainsboro;
            color: gray;
            padding: .3em .5em;
            margin-right: .5em;
            border-radius: 4px;
          }
        }
        &.ended {
          font-size: 1.2rem;
          margin-bottom: 0;
          span {
            border: solid 1px darkgray;
            background: darkgray;
            color: white;
            padding: .3em .5em;
            margin-right: .5em;
            border-radius: 4px;
            &.photoadded {
              border-color: #90c139;
              background: #90c139;
            }
          }
          a {
            &.photoadded {
              text-decoration: none;
              line-height: 1;
              color: white;
              padding: .3em .5em;
              border-radius: 4px;
              background: #90c139;
              border: solid 1px #90c139;
              &:after {
                font-family: $fontAwesome;
                font-weight: bold;
                content:'\f14c';
                color: white;
                margin-left: .3em;
              }
            }
          }
        }
      }
      &.new, &.renew {
        div {
          position: relative;
          &:before {
            position: absolute;
            left: -8px;
            top: -8px;
            width: 12.5%;
            height: auto;
            content: url(/assets/images/event/spw/icon_content_new.svg);
          }
        }
      }
      &.renew {
        div {
          position: relative;
          &:before {
            content: url(/assets/images/event/spw/icon_content_renew.svg);
          }
        }
      }
      &.mycup {
        div {
          position: relative;
          span {
            position: absolute;
            right: 0;
            bottom: 0;
            color: white;
            font-size: 1.2rem;
            font-weight: bold;
            background: rgba(0,0,0,.5);
            white-space: nowrap;
            padding: .3em .5em;
            img {
              display: inline;
              width: auto;
              height: 16px;
              vertical-align: middle;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  #blackWrap .eventPlaceList {
    li {
      h3 {
        span {
          background: white;
          color: black;
        }
      }
    }
  }

  h2.eventSection {
    text-align: center;
    font-family: "EB Garamond",serif;
    font-size: 1.8rem;
    letter-spacing: .4em;
    text-indent: .4em;
    line-height: 1.4em;
    margin: 120px 0 70px 0;
    padding: 0 25px;
    span {
      font-family: sans-serif;
      font-size: 1.2rem;
      letter-spacing: .1em;
      text-indent: .1em;
      color: #555;
    }
  }
  .eventSectionAttention {
    color: red;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.6;
    @extend .contentsBlock;
    margin: -3em auto 4em;
    @media #{$pc} {
      text-align: center;
      margin: -3em auto 5em;
    }
  }
  h3.eventDayTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    margin: 0 auto 50px auto;
    padding: 0;
    border-radius: 50px;
    border: solid 1px gray;
  }
  .eventTimeSchedule {
    @extend .contentsBlock;
    list-style: none;
    li {
      margin-bottom: 50px;
      display: flex;
      justify-content: space-between;
      div {
        width: 48%;
        img {
          width: 100%;
          height: auto;
        }
      }
      h4 {
        margin-top: 0;
        padding: 0;
        font-size: 1.6rem;
        span {
          font-size: 3rem;
          color: gray;
          display: inline-block;
          margin-bottom: 0.5em;
          letter-spacing: 0.1em;
          line-height: 1.2em;
        }
      }
      p {
        padding: 0;
      }
    }
  }
  .eventTimeSchedule2nd {
    @extend .contentsBlock;
    list-style: none;
    li {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      div {
        width: 40%;
        @media #{$pc} {
          width: 30%;
        }
        img {
          width: 100%;
          height: auto;
        }
        &:last-child {
          border-radius: 8px;
          background: white;
          width: 55%;
          padding: 1.2em 1em;
          position: relative;
          .eventHowToSpend{
            background-color: #fff0eb;
            width: auto;
            padding: 0.5em 0.8em;
            margin: 4px 0 0 0;
            p {
              font-weight: bold;
              font-size: 1.1rem;
              color: #000;
              @media #{$pc}{
                font-size: 1.2rem;
              }
            }
            .orange{
              font-size: 1.0rem;
              color: #ff4e00;
              @media #{$pc}{
                font-size: 1.1rem;
              }
            }
          }
          &:after {
            right: 100%;
            top: 30px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-right-color: white;
            border-width: 8px;
            margin-top: -8px;
          }
          @media #{$pc} {
            width: 66%;
          }
        }
      }
      h4 {
        margin-top: 0;
        padding: 0;
        font-size: 1.6rem;
        span {
          font-size: 2rem;
          color: gray;
          display: inline-block;
          margin-bottom: 0.5em;
          letter-spacing: 0.1em;
          line-height: 1.2em;
        }
      }
      p {
        font-size: 1.2rem;
        line-height: 1.6em;
        padding: 0;
        margin-bottom: 0;
        @media #{$pc} {
          font-size: 1.3rem;
        }
      }
    }
  }
  .eventTimeTable {
    @extend .contentsBlock;
    h3 {
      padding: 0;
    }
    div {
      margin-bottom: 100px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  h3.eventFaqRead {
    text-align: center;
    margin-bottom: 100px;
  }
  .eventFaq {
    @extend .contentsBlock;
    margin-bottom: 4em;
    list-style: none;
    li {
      //border-bottom: solid 1px gainsboro;
      margin-bottom: 1em;
      //padding-bottom: 1.2em;
      p {
        position: relative;
        padding: 0 0 0 30px;
        span {
          position: absolute;
          left: 0;
          font-family: $mincho;
          font-size: 1.8rem;
          color: black;
        }
        em {
          &.orange {
            color: #f60;
            font-style: normal;
          }
        }
        a {
          text-decoration: underline;
          word-break: break-all;
          i {
            margin-left: .3em;
          }
        }
        &:first-child {
          margin-bottom: .5em;
          cursor: pointer;
          &:after {
            font-family: $fontAwesome;
            font-weight: bold;
            content:'\f0d7';
            color: darkgray;
            margin-left: .3em;
          }
        }
        &:last-child {
          display: none;
        }
      }
    }
    &__most {
      li {
        p {
          &:first-child {
            font-weight: bold;
            span {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
  .eventAttention {
    @extend .contentsBlock;
    margin: 80px 25px 50px 25px;
    padding: 20px;
    border: solid 2px gainsboro;
    h2 {
      text-align: center;
    }
    h3 {
      padding: 0;
      margin-bottom: 50px;
      font-family: $gothic;
      font-weight: bold;
      color: #f60;
    }
    ul {
      list-style: disc;
      margin-left: 20px;
      &.asterisk {
        list-style: none;
        li {
          position: relative;
          &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "※";
            line-height: 2em;
            margin-left: -1.4em;
          }
        }
      }
      li {
        p {
          position: relative;
          padding: 0;
          margin-bottom: .4em;
        }
      }
      &.bold {
        li {
          p {
            font-weight: bold;
            .orange {
              color: #f60;
            }
          }
        }
      }
    }
    p {
      &.btn {
        text-decoration: underline;
        margin: 2em 0 1em 0;
        i {
          text-decoration: none;
          margin-left: .3em;
        }
      }
    }
    div.btn {
      text-align: center;
      button {
        text-align: center;
        cursor: pointer;
        text-decoration: underline;
        margin-top: 25px;
        &:focus {
          outline:0;
        }
      }
    }
    div.cancelPolicy {
      margin-top: 25px;
      h4, p {
        padding: 0;
      }
      h4 {
        margin-top: 3em;
        font-family: $gothic;
        font-weight: bold;
      }
      table {
        margin: .8em 0;
        th, td {
          text-align: left;
          border: solid 1px gainsboro;
          padding: .3em .5em;
        }
        tr {
          &:first-child {
            th {
              background: gainsboro;
            }
          }
        }
      }
    }
  }
  .eventFaqInquiry {
    background: whitesmoke;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    max-width: 800px;
    p {
      padding: 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  .eventSponsor {
    @extend .contentsBlock;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    li {
      width: 100%;
      margin: 20px 0;
      text-align: center;
      a {
        img {
          display: inline;
          width: auto;
          height: auto;
          max-width: 150px;
          max-height: 100px;
          &:hover {
            filter:brightness(100%);
          }
        }
      }
      &.asahishuzo {
        a {
          img {
            max-width: 160px;
          }
        }
      }
      &.tennensui {
        a {
          img {
            max-width: 120px;
          }
        }
      }
      &.hakushu {
        a {
          img {
            max-height: 80px;
          }
        }
      }
      &.rokugin {
        a {
          img {
            max-width: 120px;
          }
        }
      }
      &.allfree {
        a {
          img {
            max-width: 110px;
          }
        }
      }
      &.starbucks {
        a {
          img {
            max-width: 70px;
          }
        }
      }
      &.panasonic {
        a {
          img {
            max-width: 120px;
          }
        }
      }
      &.kuzefuku {
        a {
          img {
            max-width: 150px;
          }
        }
      }
      &.bring {
        a {
          img {
            max-width: 80px;
          }
        }
      }
      &.br {
        width: 100%;
        margin: 0;
      }
    }
  }
  .eventVenuePh {
    max-width: 850px;
    margin: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
  .eventDetail {
    margin-top: -2.5em;
    margin-bottom: 50px;
  }
  .eventStaffLead {
    max-width: 900px;
    margin: 0 auto 50px auto;
  }
  .eventStaff {
    //-@extend .contentsBlock;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: calc(100% / 2 - 10px);
      margin-bottom: 12px;
      div {
        max-width: 150px;
        margin: 0 auto 10px auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      p {
        padding: 0;
        margin-bottom: 0;
        font-weight: bold;
        span {
          display: block;
          font-size: 75%;
          line-height: 1.4;
          font-weight: normal;
        }
      }
    }
  }
  .eventStaffHidewrap {
    position: relative;
    height: 130px;
    overflow: hidden;
    &__open {
      height: initial;
      overflow: initial;
      &::after {
        display: none;
      }
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 80%);
    }
    &_openBtn {
      width: 100%;
      background: #F5F5F5;
      padding: 1em;
      font-weight: bold;
      text-align: center;
      @media #{$pc} {
        display: block;
        max-width: 400px;
        margin: auto;
      }
    }
  }
  .eventBgLetter {
    background: #f6f5e4;
    padding-bottom: 80px;
    .pageContent {
      margin-bottom: 0;
    }
  }
  .eventBgSchedule {
    background: url(/assets/images/event/spw/bg_schedule.jpg) no-repeat center center;
    background-size: cover;
    padding-top: 1px;
    padding-bottom: 80px;
    .pageContent {
      margin-bottom: 0;
    }
  }
  .eventBgTimetable {
    background: #f9f9f9;
    padding-top: 1px;
    padding-bottom: 1px;
    .pageContent {
      margin-bottom: 0;
    }
  }
  .eventEntryBtn {
    @extend .contentsBlock;
    margin: 100px 0 150px 0;
    a.btn {
      font-size: 1.6rem;
    }
  }
  .eventPhotoLinks {
    margin: 4em auto 0 auto;
    li {
      a {
        div {
          box-shadow: 0 0 20px rgba(0,0,0,.1);
        }
        h4 {
          font-size: 1.6rem;
          margin-top: 1em;
          padding-left: 0;
          padding-right: 0;
          background: url(/assets/images/event/spw/icon_rightangle.svg) no-repeat right 75%;
          span {
            font-family: $gothic;
            font-size: 1.2rem;
            border: solid 1px darkgray;
            background: darkgray;
            color: white;
            padding: .3em .5em;
            margin-right: 1em;
            border-radius: 4px;
            &.photoadded {
              border-color: #90c139;
              background: #90c139;
            }
          }
        }
      }
    }
  }
  div.noveltyPresent {
    width: 100%;
    max-width: 400px;
    margin-top: 1em;
    img {
      width: 100%;
      height: auto;
    }
    span {
      display: block;
      text-align: center;
      font-size: 1.3rem;
      margin-top: 1em;
    }
  }
  div.stickerPresent {
    width: 100%;
    margin-top: 1em;
    max-width: 200px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    @media #{$pc} {
      margin-left: 0;
      margin-right: 0;
    }
    img {
      display: inline;
      max-width: 100px;
      width: 100%;
      height: auto;
    }
    span {
      display: block;
      text-align: center;
      font-size: 1.3rem;
      margin-top: 1em;
    }
  }
  #eventPhotos {
    @extend .contentsBlock;
    div {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      figure {
        width: calc(100% / 3 - 2px);
        margin-bottom: 3px;
        a {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      &:after {
        content: " ";
        width: calc(100% / 3 - 2px);
      }
    }
  }
  .eventBgEcocup {
    background: #2B9B2B;
    padding: 100px 0;
    .pageContent {
      margin-bottom: 0;
    }
  }
  .eventEcocup {
    @extend .contentsBlock;
    figure.mv {
      background: url(/assets/images/event/spw/2021/eco_mv.jpg) no-repeat center center;
      background-size: cover;
      width: 100%;
      height: 200px;
      border-radius: 8px;
      position: relative;
      margin-bottom: 50px;
      @media #{$pc} {
        background-position: center 40%;
        height: 450px;
        margin-bottom: 80px;
      }
      h2 {
        position: absolute;
        bottom: 15px;
        left: 15px;
        padding: 0;
        margin: 0;
        font-family: $gothic;
        font-weight: bold;
        color: #2B9B2B;
        line-height: 1.4em;
        @media #{$pc} {
          font-size: 3.8rem;
          bottom: 30px;
          left: 30px;
        }
        span {
          display: inline-block;
          background: white;
          border-radius: 4px;
          padding: .3em .6em;
          &:first-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            padding-bottom: 0;
          }
          &:last-child {
            border-top-left-radius: 0;
          }
          img {
            margin-left: .2em;
            height: 1em;
            width: auto;
            display: inline;
            vertical-align: middle;
            margin-bottom: 6px;
          }
        }
      }
    }
    .sustainable {
      text-align: center;
      &_subTitle {
        font-size: 2.2rem;
        font-family: $mincho;
        font-weight: normal;
        color: white;
        margin: .3em 0 0;
        line-height: 1.8;
      }
      &_bottomTitle {
        font-size: 1.3rem;
        font-family: $gothic;
        font-weight: bold;
        color: white;
        margin: 0;
      }
      &_title {
        font-family: $garamond;
        margin: 0;
        font-size: 4.8rem;
        color: white;
        letter-spacing: .1em;
        line-height: 1.2;
        @media #{$pc} {
          font-size: 8rem;
        }
      }
      &_leadText {
        text-align: left;
        font-size: 1.8rem;
        font-weight: bold;
        color: white;
        margin-top: 3em;
        @media #{$pc} {
          text-align: center;
          font-size: 2rem;
        }
      }
    }
    .myCup {
      &_figure {
        border-radius: 8px;
        overflow: hidden;
        margin-top: 100px;
      }
      &_title {
        font-weight: bold;
        text-align: center;
        font-family: $gothic;
        color: white;
        margin: 1em 0;
        @media #{$pc} {
          font-size: 3.2rem;
        }
      }
    }
    div.wasteReduction {
      color: white;
      max-width: 800px;
      margin: 0 auto 50px auto;
      @media #{$pc} {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;
      }
      div.message {
        margin-bottom: 50px;
        @media #{$pc} {
          width: 100%;
          margin-bottom: 0;
        }
        p {
          padding: 0;
          font-weight: bold;
          @media #{$pc} {
            font-size: 1.7rem;
          }
          &.sup {
            font-weight: normal;
            font-size: 1.2rem;
            span {
              display: inline-block;
              padding-left: 1em;
              text-indent: -1em;
            }
          }
        }
      }
      div.goal {
        @media #{$pc} {
          width: 35%;
        }
        img {
          width: 100%;
          max-width: 250px;
          height: auto;
          margin: auto;
          @media #{$pc} {
            max-width: none;
          }
        }
        .sup {
          margin-top: 1em;
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 1.6;
        }
      }
    }
    div.items {
      @media #{$pc} {
        display: flex;
        justify-content: space-between;
      }
      div.ecoCup {
        border: solid 4px white;
        border-radius: 8px;
        padding: 25px;
        color: white;
        margin-bottom: 50px;
        @media #{$pc} {
          width: calc(50% - 10px);
          padding: 40px;
          height: 100%;
          margin-bottom: 0;
        }
        div.ecoCupDetail {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1em;
          @media #{$pc} {
            margin-bottom: 2em;
          }
          figure {
            width: 35%;
            @media #{$pc} {
              padding-top: 15px;
              padding-left: 10px;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
          div {
            width: 55%;
            h3 {
              color: white;
              padding: 0;
              margin: 0;
              font-family: $gothic;
              font-weight: bold;
              font-size: 2.1rem;
              line-height: 1.2em;
              margin-bottom: 1em;
              @media #{$pc} {
                font-size: 4rem;
                margin-bottom: .5em;
              }
              span {
                font-size: 46%;
              }
            }
            p {
              padding: 0;
              margin: 0;
              font-size: 1.2rem;
              line-height: 1.8em;
              margin-bottom: 1em;
              @media #{$pc} {
                font-size: 1.4rem;
                margin-bottom: 1.5em;
              }
            }
            a {
              display: block;
              padding: .5em 1em;
              background: white;
              color: #2B9B2B;
              font-size: 1.2rem;
              line-height: 1.6em;
              @media #{$pc} {
                font-size: 1.4rem;
                text-align: center;
              }
            }
          }
        }
        p.sup {
          padding: 0;
          margin: 0;
          font-size: 1.2rem;
          line-height: 1.6em;
        }
      }
      div.mag {
        background: white;
        border: solid 4px white;
        border-radius: 8px;
        padding: 25px;
        color: black;
        @media #{$pc} {
          width: calc(50% - 10px);
          padding-top: 50px;
        }
        h3 {
          padding: 0 0 1em 0;
          margin: 0;
          color: #2B9B2B;
          font-family: $gothic;
          font-weight: bold;
          text-align: center;
          @media #{$pc} {
            font-size: 2.1rem;
            padding-top: .5em;
          }
        }
        h4 {
          padding: 0;
          margin: 0;
          font-family: $gothic;
          font-weight: bold;
          font-size: 1.2rem;
          @media #{$pc} {
            font-size: 1.4rem;
            text-align: center;
          }
        }
        ul {
          display: flex;
          justify-content: space-between;
          list-style: none;
          margin: 1em 0 2em 0;
          padding: 0;
          @media #{$pc} {
            justify-content: space-around;
            margin-left: 10px;
            margin-right: 10px;
          }
          li {
            a {
              display: block;
            }
            width: 32%;
            @media #{$pc} {
              //width: 25%;
            }
            figure {
              img {
                width: 100%;
              }
            }
            p {
              padding: 0;
              margin: 0;
              line-height: 1.4em;
              font-size: 1.0rem;
              text-align: center
            }
          }
        }
        p.sup {
          line-height: 1.6em;
          font-size: 1.2rem;
          padding: 0;
          margin: 1em 0 0 0;
          span {
            display: inline-block;
            padding-left: 1em;
            text-indent: -1em;
          }
        }
      }
    }
  }
  .eventBring {
    margin-bottom: 100px;
    padding: 0 25px;
    @media #{$pc} {
      margin-top: 100px;
    }
    div.mv {
      @media #{$pc} {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
      }
      figure {
        margin: 0 -25px;
        @media #{$pc} {
          margin: 0;
          width: 50%;
          order: 2;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      div {
        text-align: center;
        @media #{$pc} {
          width: 50%;
        }
        h2 {
          @media #{$pc} {
            font-size: 2.8rem;
          }
        }
        p {
          img {
            width: 100%;
            max-width: 180px;
            height: auto;
            display: inline;
            @media #{$pc} {
              max-width: 220px;
            }
          }
        }
      }
    }
    h3 {
      text-align: center;
      font-size: 1.2rem;
      font-family: $gothic;
      font-weight: bold;
      &.pcOnly {
        display: none;
      }
      @media #{$pc} {
        font-size: 2rem;
        &.spOnly {
          display: none;
        }
        &.pcOnly {
          display: block;
          margin-bottom: 20px;
        }
      }
      span {
        border-bottom: solid 2px black;
        padding-bottom: .2em;
        @media #{$pc} {
          border-bottom-width: 3px;
        }
      }
    }
    h4 {
      text-align: center;
      font-size: 1.8rem;
      font-family: $gothic;
      font-weight: bold;
      &.pcOnly {
        display: none;
      }
      @media #{$pc} {
        font-size: 3.2rem;
        &.spOnly {
          display: none;
        }
        &.pcOnly {
          display: block;
          margin-bottom: 50px;
        }
      }
    }
    div.table {
      table {
        margin-bottom: 25px;
        border-top: solid 1px silver;
        tr {
          th {
            font-weight: bold
          }
        }
      }
    }
  }
  .eventBringWrap {
    background: #2b9b2b;
    .pageContent {
      margin-bottom: 0;
    }
    .eventBring {
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 100px;
      .mv {
        figure {
          padding-left: 25px;
          padding-right: 25px;
          @media #{$pc} {
            padding-left: 0;
            padding-right: 0;
          }
          img {
            border-radius: 8px;
            overflow: hidden;
          }
        }
      }
      h3 {
        @media #{$pc} {
          margin-top: 0;
        }
        span {
          color: white;
          border-bottom-color: white;
        }
      }
      h4 {
        color: white;
      }
      h2 {
        color: white;
      }
      table {
        border-top-color: white !important;
        th,td {
          color: white !important;
        }
        th {
          @media #{$pc} {
            border-bottom-color: white;
          }
        }
        td {
          border-bottom-color: white;
          &.bring_goal_detail {
            border-bottom: none;
            @media #{$pc} {
              border-bottom: solid 1px white;
            }
          }
          .bring_goal {
            max-width: 250px;
            margin: auto;
            @media #{$pc} {
              max-width: 300px;
              margin: 20px 0;
            }
          }
        }
      }
    }
  }
  #eventTopNavi {
    @extend .contentsBlock;
    margin-top: 100px;
    h2 {
      text-align: center;
      letter-spacing: .1em;
      font-size: 1.6rem;
      margin-bottom: 2em;
      font-weight: normal;
    }
    ul {
      border-top: solid 1px silver;
      list-style: none;
      max-width: $maxWidth;
      margin: auto;
      padding: 0;
      @media #{$pc} {
        display: flex;
        justify-content: center;
        border-top: none;
      }
      li {
        padding: 0 .5em;
        border-bottom: solid 1px silver;
        font-size: 1.2rem;
        &.new {
          background: url(/assets/images/event/spw/icon_content_new.png) no-repeat left top;
          background-size: 25px;
          @media #{$pc} {
            background-size: 35px;
          }
        }
        &.renew {
          background: url(/assets/images/event/spw/icon_content_renew.png) no-repeat left top;
          background-size: 25px;
          @media #{$pc} {
            background-size: 35px;
          }
        }
        &.spwp {
          margin-bottom: 14px;
          @media #{$pc} {
            margin-bottom: 0;
          }
        }
        @media #{$pc} {
          border-bottom: none;
          font-size: 1.5rem;
          font-weight: bold;
          padding: .8em 1.2em;
          box-shadow: 0 0 5px rgba(0,0,0,0.15);
          margin: 0 .5em;
          border-radius: 4px;
        }
        a {
          display: block;
          text-decoration: none;
          color: black;
          position: relative;
          padding: .5em 0 .5em 2em;
          @media #{$pc} {
            padding-left: 1em;
          }
          &:before {
            position: absolute;
            left: 1em;
            font-family: $fontAwesome;
            font-weight: bold;
            content: '\f107';
            @media #{$pc} {
              left: 0;
            }
          }
        }
      }
    }
  }
  .eventBgLastyear {
    background: url(/assets/images/event/spw/2021/lastyear_bg.jpg) no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    @media #{$pc} {
      height: 400px;
    }
    a {
      margin: 0 25px;
      display: inline-block;
      color: black;
      background: white;
      text-decoration: none;
      padding: 1.2em 1.5em;
      font-weight: bold;
      box-shadow: 0 0 8px rgba(0,0,0,0.5);
      @media #{$pc} {
        font-size: 1.6rem;
      }
    }
  }
  .eventInfection {
    padding: 50px 25px;
    background: #f4f4f4;
    @media #{$pc} {
      background: none;
      border: solid 3px #dcdcdc;
      border-radius: 6px;
      max-width: 850px;
      margin: 0 auto;
      padding: 50px 40px;
    }
    &.stCamp {
      max-width: 980px;
    }
    h2 {
      font-family: $gothic;
      font-weight: bold;
      color: #227f38;
      text-align: center;
      border-bottom: solid 2px #227f38;
      margin: 0 0 .5em 0;
      @media #{$pc} {
        font-size: 2.8rem;
      }
    }
    h3 {
      font-family: $gothic;
      font-weight: bold;
    }
    p {
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
      line-height: 1.6em;
      @media #{$pc} {
        font-size: 1.8rem;
      }
    }
    .eventCapBox {
      background-color: #fff;
      padding: 0;
      margin: 1.4em 0 0.8em;
      padding: 15px;
      @media #{$pc} {
        background-color: #f0f0f0;
      }
      p {
        text-align: left;
        font-size: 1.4rem;
        margin-bottom: 0;
        @media #{$pc} {
          font-size: 1.5rem;
        }
      }
    }
    .eventInfectionIcon {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      list-style: none;
      @media #{$pc} {
        justify-content: space-between;
        padding: 0 25px;
      }
      li {
        width: 45%;
        @media #{$pc} {
          width: 30%;
        }
        figure {
          margin-bottom: .5em;
          img {
            width: 100%;
            height: auto;
          }
        }
        p {
          font-size: 1.4rem;
          color: black;
          @media #{$pc} {
            font-size: 1.6rem;
          }
        }
      }
    }
    .eventInfectionDetail {
      padding: 0 25px;
      list-style: disc;
      li {
        margin-top: .5em;
        @media #{$pc} {
          font-size: 1.5rem;
        }
      }
    }
  }
  @media #{$pc} {
    #eventVisual {
      //-margin-top: 90px;
      height: 550px;
      &.spwTop {
        span {
          height: 550px;
        }
        &.spw2020 {
          height: calc(100vh - 90px);
          span {
            height: calc(100vh - 90px);
          }
        }
      }
      &.spwDetail {
        height: 200px;
        h1, div {
          max-width: 130px;
        }
      }
    }
    #breadCrumb.eventVisual {
      padding-top: 60px;
    }
    .eventIntro {
      margin: 50px auto 100px auto;
    }
    .eventImages {
      li {
        width: calc(100% / 3 - 25px);
        margin-bottom: 40px;
        &:last-child {
          display: list-item;
        }
      }
    }
    .eventMap {
      margin-bottom: 100px;
    }
    .eventPlaceList {
      li {
        width: calc(100% / 3 - 35px);
        &.twoCol {
          width: calc(100% / 2 - 35px);
        }
      }
      &:after {
        content: "";
        display: block;
        width: calc(100% / 3 - 35px);
      }
    }
    .eventTimeScheduleWrap {
      display: flex;
      justify-content: space-between;
      .eventTimeScheduleColumn {
        width: 50%;
        padding-left: 40px;
        padding-right: 40px;
        &:first-child {
          border-right: solid 1px gainsboro;
        }
      }
    }
    .eventAttention {
      margin: 100px 25px;
      padding: 20px 80px 50px 80px;
    }
    .eventSponsor {
      li {
        width: auto;
        margin: 0 15px 20px 15px;
      }
    }
    .eventStaff {
      li {
        width: calc(100% / 5 - 20px);
      }
    }
    .eventBgSchedule {
      background-attachment: fixed;
    }
    .pageContent {
      ul.imageBtns.eventPhotoLinks {
        li {
          margin-bottom: 0;
        }
      }
    }
    #eventPhotos {
      div {
        figure {
          width: calc(100% / 4 - 2px);
          margin-bottom: 2px;
        }
        &:after {
          content: " ";
          width: calc(100% / 4 - 2px);
        }
        &:before {
          content: " ";
          width: calc(100% / 4 - 2px);
          order: 1;
        }
      }
    }
  }
  .pageContent a.btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  div#pageNavi {
    //-display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(245,245,245,0.8);
    z-index: 999;
    ul {
      display: flex;
      width: 100%;
      max-width: 900px;
      margin: auto;
      list-style: none;
      justify-content: space-between;
      li {
        a {
          display: block;
          color: black;
          text-decoration: none;
          font-family: $mincho;
          font-size: 1.1rem;
          letter-spacing: 0.2em;
          padding: 15px 0;
          &.top {
            padding-left: 20px;
            padding-right: 10px;
            font-size: 1.8rem;
          }
          &.store {
            background: rgba(80,80,80,0.8);
            padding-left: 16px;
            padding-right: 17px;
            color: white;
          }
        }
        &:last-child {
          a {
            padding-right: 17px;
          }
        }
      }
    }
    &.narrow {
      ul {
        li {
          a {
            font-size: 0.9rem;
            letter-spacing: 0em;
            &.top {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
    &.spw2020 {
      background: rgba(245,245,245,0.6);
      backdrop-filter: blur(8px);
      ul {
        max-width: 1100px;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 10px 25px 25px 25px;
        @media #{$pc} {
          padding: 25px;
        }
        li {
          margin: 0 3px;
          a {
            font-family: $gothic;
            padding: 5px 0;
          }
        }
      }
    }
  }
  @media #{$pc} {
    div#pageNavi {
      ul {
        li {
          a {
            font-size: 1.5rem;
            padding: 20px 0;
            &.top {
              padding-left: 20px;
              padding-right: 10px;
              font-size: 2.1rem;
            }
            &.store {
              img {
                vertical-align: text-bottom;
                margin-right: 8px;
              }
            }
          }
        }
      }
      &.narrow {
        ul {
          li {
            a {
              font-size: 1.5rem;
              &.top {
                font-size: 2.1rem;
              }
            }
          }
        }
      }
    }
  }
}
ul.pageLinks {
  padding: 25px;
  list-style: none;
  margin-bottom: 4em;
  background: whitesmoke;
  li {
    border-bottom: solid 1px white;
    a {
      position: relative;
      display: block;
      padding: 0.8em 0;
      &:after {
        position: absolute;
        right: 0;
        content: "\f107";
        font-family: $fontAwesome;
        font-weight: bold;
        color: $colorText;
      }
    }
  }
}
@media #{$pc} {
  ul.pageLinks {
    padding: 20px 25px;
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    justify-content: flex-start;
    li {
      margin-right: 2em;
      border-bottom: 0;
      font-size: 1.3rem;
      &:last-child {
        margin-right: 0;
      }
      a {
        padding: 0;
        &:after {
          position: static;
          margin-left: 0.5em;
        }
      }
    }
  }
}

h3[id^="eventFaq"] {
  padding: 0 25px;
}

p.attention {
  padding: 0 25px;
  color: #f60;
}

// Safari 改行抑制のため 左右幅変更 4em -> 2em
.el_btn {
  padding: 1em 2em;
}
.un_prlfLogo {
  max-width: 460px;
  margin: auto;
}
.un_prlfRicetitle {
  @media #{$pc} {
    font-size: 4rem;
    line-height: 1.4;
    letter-spacing: .08em;
  }
}
.un_prlfItems {
  &_ul {
    @media #{$pc} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__3col {
      @media screen and (min-width: 1580px) {
        .un_prlfItems_li {
          width: calc(100% / 3 - 26.66px);
        }
      }
    }
  }
  &_li {
    margin-top: 75px;
    @media #{$pc} {
      width: calc(100% / 2 - 20px);
    }
  }
  &_img {
    max-width: 550px;
    margin: auto;
  }
  &_detail {
    margin: auto;
    @media #{$pc} {
      max-width: 220px;
    }
    &__large {
      max-width: 490px;
    }
  }
  &_name {
    line-height: 1.4;
    margin-top: 1.5em;
    span {
      font-size: 80%;
    }
    &__mt {
      @media #{$pc} {
        margin-top: 3em;
      }
    }
  }
  &_subText {
    font-size: 1.4rem;
    margin-top: .6em;
  }
  &_handling {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.3;
    margin-top: 1.2em;
    + .un_prlfItems_price {
      margin-top: .3em;
    }
  }
  &_price {
    font-size: 2rem;
    span {
      font-size: 60%;
    }
    margin-top: 1em;
  }
  &_link {
    display: block;
    text-align: center;
    border: solid 1px black;
    background: black;
    color: white;
    padding: .8em;
    margin-top: 1.5em;
    transition: all .2s linear !important;
    &:hover {
      opacity: 1 !important;
      background: transparent;
      color: black;
    }
    @media #{$pc} {
      display: inline-block;
      padding: .6em .9em;
    }
    i {
      display: none;
      margin-left: .3em;
      opacity: .2;
    }
    &.disabled {
      pointer-events: none;
    }
  }

  &_spec {
    margin-top: 50px;
    table {
      border-spacing: 0;
      tr {
        th {
          padding: .2em 1em .3em 0;
        }
        td {
          padding: .2em 0 .3em 0;
        }
      }
    }
  }

  &_supplement {
    background: #f5f5f5;
    border: solid 1px #ebebeb;
    border-radius: 6px;
    padding: 1.5em;
    h4 {
      margin-bottom: 1em;
    }
  }

  &_store {
    padding-bottom: 100px;
    @media #{$pc} {
      padding-bottom: 200px;
    }
    &Title {
      text-align: center;
      font-size: 1.4rem;
    }
    &TitleSub {
      text-align: center;
      font-size: 1.1rem;
      margin-top: .3em;
      line-height: 1.4;
    }
    &Link {
      display: block;
      background: #f0f0f0;
      padding: 1.6em;
      font-weight: bold;
      text-align: center;
      font-size: 1.8rem;
      margin-top: 1em;
      i {
        margin-left: 1em;
        opacity: .5;
      }
    }
  }
}

.un_prlfGaramonTitle {
  text-align: center;
  font-family: "EB Garamond",serif;
  font-size: 2.1rem;
  letter-spacing: .4em;
  text-indent: 0.4em;
  line-height: 1.4em;
  margin: 0 0 2em 0;
  padding: 0 25px;
}

.un_prlfWxlarge {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

// キャンパーの食卓

.un_prcd {
  &Logo {
    width: 80%;
    max-width: 340px;
    margin: auto;
  }
  &Divider {
    background-image: url('/assets/images/products/localfoods/img_set_camperdinner.jpg');
    min-height: 500px;
    height: 75vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    //-transition: background-position 2s ease-out;
    @media #{$pc} {
      background-attachment: fixed;
    }
    img {
      object-fit: cover;
      object-position: center;
      min-height: 750px;
      height: 75vh;
    }
  }
  &Catehead {
    font-family: adobe-garamond-pro, serif;
    font-weight: 600;
    font-style: normal;
    font-size: 4rem;
    letter-spacing: .08em;
    line-height: .7;
    @media #{$pc} {
      font-size: 6.7rem;
    }
  }
  &LayoutWide {
    @media screen and (min-width: 1580px) {
      max-width: 1540px;
    }
  }
}

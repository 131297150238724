// event - starter camp
// legacy css
.un_legacy_starter {
  .starter_kv_bg {
    background-color: #00A95F;
  }
  .bl_breadCrumb {
    ul {
      font-size: 1.0rem;
      list-style: none;
      padding: 0;
      margin: 0 0 -5px 0;
      li {
        display: inline-block;
        color: rgba(255,255,255,1);
        line-height: 1.2em;
        a {
          color: inherit;
        }
        &::after {
          content: "/";
          margin: 0 .4em 0 .6em;
        }
        &:last-child {
          pointer-events: none;
          a {
            color: rgba(255,255,255,1);
            font-weight: bold;
          }
          &::after {
            content: normal;
            margin: 0 .5em;
          }
        }
      }
    }
  }
  .bl_subHeader + .bl_breadCrumb {
    margin-top: 14px;
  }
  .pageContent {
    max-width: 1230px;
    margin: 0 auto 60px auto;
    color: #555;
    &.howto {
      margin-bottom: 20px;
    }
  }
  .clearfix:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
  }
  i.support {
    color: #000;
  }
  div#storeCampAbout {
    .aboutCnt {
      max-width: 1000px;
      width: 100%;
      margin: 2em auto;
      padding: 0 25px;
      color: #000;
      h2 {
        text-align: center;
        font-size: 3.2rem;
        font-family: $gothic;
        font-weight: bold;
        letter-spacing: .2rem;
        line-height: 1.5;
        margin-bottom: 1.1em;
      }
      p {
        text-align: center;
        font-size: 1.6rem;
        font-family: $gothic;
        font-weight: bold;
        line-height: 2.2;
      }
      figure {
        margin: 3em auto 0;
        padding: 0;
        img {
          text-align: center;
          margin: 0 auto;
          max-width: 570px;
          width: 100%;
        }
      }
    }
  }
  div.starterCampOverview,
  div.storeCampOverview {
    h2 {
      position: relative;
      text-align: center;
      max-width: 500px;
      margin: 3em auto 2em auto;
      margin-bottom: 3em;
      img {
        width: 60%;
        max-width: 360px;
        height: auto;
        margin: 0 auto;
      }
      span {
        position: absolute;
        bottom: -45px;
        right: 10px;
        width: 25%;
        animation: logo 2s ease-in-out infinite;
        animation-direction: alternate;
        img {
          width: 100%;
          max-width: 200px;
        }
      }
    }
    div.pictures {
      margin-bottom: 100px;
      figure {
        float: left;
        width: 100%;
        &:nth-child(1) {
          width: calc(100% / 3 * 2);
          position: relative;
          span {
            position: absolute;
            left: -5px;
            bottom: 5px;
            //transform: rotate(-10deg);
            z-index: 1;
            width: 25vw;
            height: auto;
            max-width: 250px;
            animation: logo 2s ease-in-out infinite;
            animation-direction: alternate;
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: calc(100% / 3);
        }
        &:nth-child(4) {
          width: calc(100% / 3);
          clear: left;
        }
        &:nth-child(5) {
          width: calc(100% / 3 * 2);
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    h3 {
      margin: 3em 0 2em 0;
      font-family: $gothic;
      font-weight: bold;
      text-align: center;
      span {
        display: inline-block;
        border: solid 4px #f0d400;
        border-radius: 20px;
        position: relative;
        padding: .5em;
        &:after, &:before {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &:after {
          border-top-color: white;
          border-width: 16px;
          margin-left: -16px;
        }
        &:before {
          border-top-color: #f0d400;
          border-width: 22px;
          margin-left: -22px;
        }
      }
    }
    div.worry {
      max-width: 850px;
      margin: 0 auto 3em auto;
      padding: 0 25px;
      figure {
        margin: 10px 0;
        img {
          width: 100%;
          height: 10vw;
        }
        &:nth-child(odd) {
          text-align: right;
        }
        &:nth-child(5) {
          img {
            height: 28.8vw;
          }
        }
        &:nth-child(6) {
          img {
            height: 24.26vw;
          }
        }
      }
    }
    p.worryAnswer {
      color: black;
    }
  }
  
  @media #{$pc} {
    div#storeCampAbout {
      .aboutCnt {
        margin: 150px auto;
        h2 {
          font-size: 4.2rem;
          margin-bottom: 1.1em;
        }
        p {
          font-size: 1.8rem;
        }
        figure {
          margin: 120px auto 0;
        }
      }
    }
    div.starterCampOverview,
    div.storeCampOverview {
      h2 {
        margin: 4em auto 2em auto;
      }
      h2 {
        max-width: 800px;
        span {
          position: absolute;
          bottom: -45px;
          right: 0;
        }
      }
      div.pictures {
        figure {
          &:nth-child(1) {
            span {
              left: -30px;
            }
          }
        }
      }
      h3 {
        margin: 4em 0 1.5em 0;
        font-size: 3.2rem;
        span {
          padding: .8em 1.2em;
        }
      }
      div.worry {
        margin-bottom: 6em;
        figure {
          margin: 20px 0;
          img {
            width: auto;
            height: 75px;
            margin: 0 auto;
          }
          &:nth-child(5) {
            img {
              height: 216px;
            }
          }
          &:nth-child(6) {
            img {
              height: 182px;
            }
          }
        }
      }
      p.worryAnswer {
        text-align: center;
        font-size: 1.8rem;
        margin-bottom: 100px;
      }
    }
  }
  
  div#starterCampBgGray {
    position: relative;
    background: #b98641;
    //-background-image: radial-gradient(#aaa 20%, transparent 0), radial-gradient(#aaa 20%, transparent 0);
    background-position: 0 0, 2px 2px;
    background-size: 4px 4px;
    padding-top: 1px;
    padding-bottom: 1px;
    .pageContent {
      margin: 70px auto;
      ul {
        max-width: 1000px;
        margin: auto;
        list-style: none;
        padding: 0 25px;
        li {
          background: white;
          padding: 25px;
          border-radius: 10em;
          &:last-child {
            margin-top: 2em;
          }
          h4 {
            font-size: 1.6rem;
            line-height: 1.8em;
            font-family: $gothic;
            font-weight: bold;
            text-align: center;
            padding: 0;
            margin: 0;
          }
          p {
            padding: 0;
            margin: 0;
          }
          a {
            display: block;
            text-align: center;
            padding: 1em 0;
            background: #77994b;
            color: white;
          }
        }
      }
      h4.bottom {
        text-align: center;
        color: white;
        font-family: $gothic;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 1.6em;
        margin-top: 2em;
      }
    }
    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: #b98641;
      border-width: 30px;
      margin-left: -30px;
    }
  }
  @media #{$pc} {
    div#starterCampBgGray {
      .pageContent {
        margin: 100px auto;
        ul {
          display: flex;
          justify-content: space-between;
          li {
            width: calc(100% / 2 - 10px);
            &:last-child {
              margin-top: 0;
            }
            h4 {
              font-size: 1.8rem;
            }
            a {
              font-size: 1.6rem;
            }
          }
        }
        h4.bottom {
          font-size: 2.4rem;
        }
      }
    }
  }
  
  
  div#starterCampDetail,
  div#storeCampDetail {
    padding-top: 1px;
    padding-bottom: 1px;
    background: url(/assets/images/event/startercamp/bg_detail.jpg) center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    .pageContent {
      h4 {
        font-family: $gothic;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        margin: 50px auto;
        padding: 0;
        border-radius: 50px;
        border: solid 1px gray;
      }
      ul.timeSchedule {
        list-style: none;
        padding: 0 25px;
        li {
          h5 {
            font-family: $roboto;
            font-weight: 100;
            font-size: 2.8rem;
            line-height: 1.6em;
            color: gray;
            span {
              display: inline-block;
              font-family: $gothic;
              font-size: 1.8rem;
              line-height: 1.4em;
              color: black;
            }
          }
          figure {
            //-padding: 0 25px;
            padding: 0;
            img {
              width: 100%;
              height: auto;
              display: block;
              margin-bottom: 12px;
            }
            img:last-child {
              margin-bottom: 0;
            }
          }
          &.courseImg {
            display: flex;
            flex-wrap: wrap;
            //-padding: 0 25px;
            padding: 0;
            figure {
              padding: 0;
              width: calc(100% / 3);
            }
          }
        }
      }
    }
  }
  @media #{$pc} {
    div#starterCampDetail,
    div#storeCampDetail {
      .pageContent {
        ul.timeSchedule {
          padding: 0 25px;
          li {
            display: flex;
            justify-content: space-between;
            h5 {
              font-size: 3.2rem;
              width: 25%;
              padding: 0 0 50px 0;
              margin: 0;
              border-right: solid 1px gainsboro;
              span {
                font-size: 2.1rem;
              }
            }
            p {
              width: 35%;
              padding: 0 0 50px 0;
              margin: 0;
              font-size: 1.8rem;
              &.noFigure {
                width: 72.5%;
              }
              span {
                font-size: 90%;
                line-height: 1.6em;
              }
            }
            figure {
              width: 35%;
              padding: 0 0 50px 0;
              margin: 0;
              img {
                margin-bottom: 12px;
              }
            }
            &.courseImg {
              padding: 0;
              margin: 50px 0;
              justify-content: space-around;
              figure {
                width: calc(100% / 6 - .5px);
              }
            }
          }
        }
      }
    }
  }
  
  
  div#starterCampSchedule,
  div#storeCampSchedule {
    .pageContent {
      padding-bottom: 1px;
      h4 {
        font-family: $gothic;
        margin-bottom: 4em;
        &.scheduleTtl {
          padding: 0 25px;
          margin: 80px 0 0;
          font-weight: 600;
        }
      }
      h5 {
        font-family: $gothic;
        text-align: right;
      }
      .ContPlr {
        padding: 0 25px;
        margin-top: .5em;
      }
      .table {
        overflow: scroll;
        padding: 0 25px;
        table {
          width: 300%;
          th.app {
            width: 92px;
          }
          th:nth-child(1) {
            width: 17%
          }
          th:nth-child(2) {
            width: 11%
          }
          th:nth-child(3) {
            width: 19%
          }
          th:nth-child(4) {
            width: 22%
          }
          th:nth-child(5) {
            width: 31%
          }
        }
      }
    }
  }
  @media #{$pc} {
    div#starterCampSchedule,
    div#storeCampSchedule {
      .pageContent {
        padding-bottom: 30px;
        h4 {
          text-align: center;
          font-size: 1.8rem;
          line-height: 1.8em;
          &.scheduleTtl {
            padding: 0 25px;
            margin: 80px 0 0;
            font-weight: 600;
          }
        }
        .table {
          overflow: unset;
          padding: 0 25px;
          table {
            width: 100%;
            th.app {
              width: 116px;
            }
          }
        }
      }
    }
  }
  
  div#starterCampUsers {
    background-color: #F2EDDB;
    padding: 1px 0 24px;
    .pageContent {
      padding: 0 25px;
      h4 {
        font-family: $gothic;
        text-align: center;
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: .2rem;
        line-height: 1.5;
        margin: 1.5em 0 1.4em;
      }
      .qaContents {
        margin: 0 auto;
        text-align: left;
        max-width: 800px;
        h5 {
          font-family: $gothic;
          font-size: 2rem;
          font-weight: 700;
          letter-spacing: .2rem;
          position: relative;
          padding: 0 0 10px;
          margin: 0 auto;
          max-width: 700px;
          z-index: 2;
          line-height: 3rem;
        }
        h5:before {
          content: '';
          background: url(/assets/images/event/startercamp/2022/usersvoice_q.svg) no-repeat center center;
          background-size: 70%;
          display: block;
          position: unset;
          height: 60px;
          width: 40px;
          z-index: -1;
        }
        p {
          font-family: $gothic;
          font-size: 1.5rem;
          font-weight: 400;
          max-width: 700px;
          margin: 0 auto;
          margin-bottom: 40px;
          &.userPrf {
            text-align: center;
            font-size: 1.4rem;
            span {
              font-weight: bold;
            }
          }
        }
        img {
          margin: 1.8em 0 1.2em;
        }
      }
    }
  }
  @media #{$pc} {
    div#starterCampUsers {
      padding: 1px 0 60px;
      .pageContent {
        padding: 0;
        h4 {
          font-size: 2.7rem;
          letter-spacing: .2rem;
          line-height: 1.8;
          margin: 1.5em 0 1.4em;
        }
        .qaContents {
          max-width: 800px;
          h5 {
            font-family: $gothic;
            font-size: 2.4rem;
            font-weight: 700;
            letter-spacing: .2rem;
            position: relative;
            padding: 40px 0;
            margin: 0 auto;
            max-width: 700px;
            z-index: 2;
          }
          h5:before {
            content: '';
            background: url(/assets/images/event/startercamp/2022/usersvoice_q.svg) no-repeat center center;
            display: block;
            position: absolute;
            left: -22px;
            top: 4px;
            height: 80px;
            width: 50px;
            z-index: -1;
          }
          p {
            font-family: $gothic;
            font-size: 1.6rem;
            margin-bottom: 40px;
            &.userPrf {
              font-size: 1.5rem;
            }
          }
          img {
            margin: 1.8em 0 2.4em;
          }
        }
      }
    }
  }
  
  div#starterCampApplication,
  div#storeCampApplication {
    background: #e7eede;
    padding: 1px 0 40px 0;
    .pageContent {
      max-width: 750px;
      h4 {
        font-family: $gothic;
        font-size: 1.8rem;
      }
      p {
        a {
          text-decoration: underline;
          i {
            margin-left: .3em;
          }
        }
      }
      &.appCont {
        padding: 0 25px;
      }
    }
  }
  @media #{$pc} {
    div#starterCampApplication {
      padding: 1px 0 60px 0;
      .pageContent {
        p {
          font-size: 1.6rem;
        }
      }
    }
  }
  
  div#starterCampRecom {
    .pageContent {
      h4 {
        font-family: $gothic;
        margin-bottom: 4em;
        &.ContPlr {
          padding: 0 25px;
        }
      }
      ul.recommendItems {
        list-style: none;
        padding: 0 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: calc(100% / 2 - 10px);
          margin-bottom: 20px;
          a {
            display: block;
            div {
              img {
                width: 100%;
                height: auto;
              }
            }
            h3 {
              padding: 0;
              margin: 0;
              text-align: left;
              font-size: 1.4rem;
              line-height: 1.6em;
              font-family: $mincho;
            }
            h4 {
              display: inline-block;
              font-size: 1.1rem;
              font-family: $roboto;
              font-weight: 500;
              line-height: 1.2em;
              color: gray;
              border: solid 1px lightgray;
              border-radius: 4px;
              margin-bottom: .4em;
              padding: 2px 10px 1px 10px;
              vertical-align: middle;
            }
            h5 {
              font-family: $garamond;
              font-size: 1.4rem;
              line-height: 1.8em;
              margin: 0;
              padding: 0;
              color: gray;
            }
          }
        }
      }
      div.youtube {
        width: 100%;
        max-width: 1000px;
        margin: 30px auto 50px auto;
        padding: 0 25px;
        div.wrap {
          position: relative;
          width: 100%;
          padding-top: 56.25%;
          iframe {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      div.bnrs {
        width: 100%;
        max-width: $maxWidth;
        margin: 30px auto;
        padding: 0 25px;
        ul {
          list-style: none;
          &.stc {
            padding-left: 0;
          }
          li {
            margin-bottom: 10px;
            a {
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  @media #{$pc} {
    div#starterCampRecom {
      .pageContent {
        h4 {
          text-align: center;
          font-size: 1.8rem;
          line-height: 1.8em;
        }
        ul.recommendItems {
          li {
            width: calc(100% / 6 - 10px);
            margin-bottom: 20px;
          }
        }
        div.bnrs {
          ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              width: calc(100% / 3 - 20px);
            }
          }
        }
      }
    }
  }

  // After Emergency
  .afterEmergency {
    background: #f5f5f5;
    padding: 60px 25px;
    @media #{$pc} {
      padding: 120px 25px;
    }
    &Wrap {
      max-width: 600px;
      margin: 0 auto;
    }
    &Title {
      color: #227f38;
      text-align: center;
      line-height: 1.4;
      font-size: 1.4rem;
      @media #{$pc} {
        font-size: 1.8rem;
      }
    }
    &Para {
      margin-top: 2em;
      font-size: 1.3rem;
      @media #{$pc} {
        font-size: 1.4rem;
      }
    }
  }
  
  // video
  #startercampVisual {
    margin: 0 auto 0 auto;
    position: relative;
    #mvVideo {
      overflow: hidden;
      .videoWrap {
        width: 150vw;
        height: 80vw;
        left: -25vw;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        button {
          position: absolute;
          cursor: pointer;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
          transition: opacity linear 0.3s, background-color linear 0.3s;
          img {
            position: absolute;
            bottom: 25px;
            right: calc(25vw + 25px);
            width: 40px;
            height: auto;
            opacity: 0.4;
            transition: opacity linear 0.3s
          }
          &:hover {
            opacity: 0.7;
            background-color: rgba(255,255,255,0.2);
            img {
              opacity: 1;
            }
          }
          &:focus {
            outline: none;
          }
        }
        .mask {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: url(../images/event/startercamp/2019/mov_posterframe.jpg) no-repeat center;
          background-size: cover;
          z-index: 1;
        }
        #ytVideo {
          width: 100%;
          height: 80vw;
        }
      }
    }
  }
  #mvVideoYoutube {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1001;
    background: black;
    width: 100%;
    height: 100%;
    align-items: center;
    #mvVideoYoutubeIframe {
      width: 100%;
      height: 56.25vw;
    }
    button {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 28px;
      height: 28px;
      background: url(../img/btn_mvclose.svg) no-repeat;
      background-size: cover;
      opacity: 0.8;
      cursor: pointer;
      transition: opacity linear 0.3s;
      &:hover {
        opacity: 0.6;
      }
      &:focus {
        outline: none;
      }
    }
  }
  @media #{$pc} {
    #startercampVisual {
      #mvVideo {
        margin-top: 100px;
        .videoWrap {
          width: auto;
          height: 45vw;
          left: auto;
          button {
            img {
              right: 30px;
              bottom: 30px;
              width: 50px;
            }
          }
          #ytVideo {
            height: calc(56.25vw - 8px);
            margin-top: -5.8vw;
          }
        }
      }
    }
    #mvVideoYoutube {
      #mvVideoYoutubeIframe {
        height: 100%;
        margin-top: 0;
      }
    }
  }
  
  .eventFaq {
    @extend .contentsBlock;
    margin-bottom: 4em;
    list-style: none;
    li {
      //border-bottom: solid 1px gainsboro;
      margin-bottom: 1em;
      //padding-bottom: 1.2em;
      p {
        position: relative;
        padding: 0 0 0 30px;
        span {
          position: absolute;
          left: 0;
          font-family: $mincho;
          font-size: 1.8rem;
          color: black;
        }
        a {
          text-decoration: underline;
          word-break: break-all;
          i {
            margin-left: .3em;
          }
        }
        &:first-child {
          margin-bottom: .5em;
          cursor: pointer;
          &:after {
            font-family: $fontAwesome;
            font-weight: bold;
            content:'\f0d7';
            color: darkgray;
            margin-left: .3em;
          }
        }
        &:last-child {
          display: none;
        }
      }
    }
  }
  .eventAttention {
    @extend .contentsBlock;
    margin: 80px 25px 50px 25px;
    padding: 20px;
    border: solid 2px gainsboro;
    h2 {
      text-align: center;
    }
    h3 {
      padding: 0;
      margin-bottom: 50px;
      font-family: $gothic;
      font-weight: bold;
      color: #f60;
    }
    ul {
      list-style: disc;
      margin-left: 20px;
      &.asterisk {
        list-style: none;
        li {
          position: relative;
          &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "※";
            line-height: 2em;
            margin-left: -1.4em;
          }
        }
      }
      li {
        p {
          position: relative;
          padding: 0;
          margin-bottom: .4em;
        }
      }
      &.bold {
        li {
          p {
            font-weight: bold;
          }
        }
      }
    }
    p {
      &.btn {
        text-decoration: underline;
        margin: 2em 0 1em 0;
        i {
          text-decoration: none;
          margin-left: .3em;
        }
      }
    }
    div.btn {
      text-align: center;
      button {
        text-align: center;
        cursor: pointer;
        text-decoration: underline;
        margin-top: 25px;
        &:focus {
          outline:0;
        }
      }
    }
    div.cancelPolicy {
      margin-top: 25px;
      h4, p {
        padding: 0;
      }
      h4 {
        margin-top: 3em;
        font-family: $gothic;
        font-weight: bold;
      }
      table {
        margin: .8em 0;
        th, td {
          text-align: left;
          border: solid 1px gainsboro;
          padding: .3em .5em;
        }
        tr {
          &:first-child {
            th {
              background: gainsboro;
            }
          }
        }
      }
    }
  }
  .eventFaqInquiry {
    background: whitesmoke;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    max-width: 800px;
    p {
      padding: 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  
  // common
  .pageContent {
    h3.starterCampComtitle,
    h3.storeCampComtitle {
      font-size: 2.8rem;
      font-family: $roboto;
      letter-spacing: .1em;
      text-align: center;
      line-height: 1em;
      margin: 2.5em 0 1.5em 0;
      span {
        display: inline-block;
        font-size: 1.4rem;
      }
      &.scheduleTop {
        margin-top: 3.5em;
      }
      
    }
  }
  span.ended {
    font-size: 1.2rem;
    border: solid 1px #a9a9a9;
    background: #a9a9a9;
    color: #fff;
    padding: .3em .5em;
    border-radius: 4px;
    white-space: nowrap;
  }
  @media #{$pc} {
    .pageContent {
      h3.starterCampComtitle,
      h3.storeCampComtitle {
        font-size: 3.6rem;
        margin: 3em 0 1em 0;
      }
    }
  }
  .inviewfadeIn {
    &.scaleIn {
      filter: blur(0);
      transform: scale(0.9);
      transition: opacity 3s, transform 1s;
    }
    &.up {
      filter: blur(0);
      transform: translateY(30px);
      transition: opacity 3s, transform 2s;
    }
  }
  .fadeIn {
    &.scaleIn {
      transform: scale(1);
    }
    &.up {
      transform: translateY(0);
    }
  }
  @keyframes logo {
    0% {
      transform: rotate(-4deg);
    }
    100% {
      transform: rotate(14deg);
    }
  }
}

// store camp mv
// slide
#mvSlideSc {
  position: relative;
  width: 100%;
  height: auto;
  .mvImage {
    //-display: block;
    display: flex;
    width: 100%;
    height: 100%;
    @media #{$pc} {
      display: flex;
    }
    .mvSlide {
      width: 100%;
      height: 45vh;
      background-size: cover;
    }
    @media #{$pc} {
      .mvSlide {
        width: 100vw;
        height: 60vh;
        background-position: center 70%;
      }
    }
  }
  .mvText {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width: 60vw;
    @media #{$pc} {
      width: 40vh;
      max-width: 400px;
    }
  }
}

// event map
.le_eventMapWrap {
  padding: 0 25px;
}
.le_eventMapPop {
  p {
    line-height: 1.6;
    margin-bottom: 0;
  }
}
#le_eventMap {
  width: 100%;
  height: 550px;
}
.le_eventSchedule {
  padding: 0 25px;
  table {
    width: 100%;
    border-bottom: solid 1px #ddd;
    border-collapse: collapse;
    tr {
      th {
        display: none;
        @media #{$pc} {
          display: table-cell;
          text-align: left;
          padding: 15px 0;
        }
      }
      td {
        display: block;
        @media #{$pc} {
          display: table-cell;
          padding: 15px 0 !important;
          border-top: solid 1px #ddd !important;
        }
        i {
          margin-left: .3em;
        }
        &:nth-child(1) {
          border-top: solid 1px #ddd;
          padding-top: 15px;
          font-weight: bold;
          font-size: 1.3rem;
          @media #{$pc} {
          }  
          span {
            display: inline-block;
            font-weight: normal;
            font-size: 1.1rem;
          }
        }
        &:nth-child(2) {
          font-family: $mincho;
          font-size: 1.8rem;
        }
        &:nth-child(3) {
          font-size: 1.3rem;
        }
        &:nth-child(4) {
          font-size: 1.3rem;
        }
        &:nth-child(5) {
          padding-bottom: 15px;
          text-align: center;
          margin-top: 5px;
          a {
            display: inline-block;
            text-align: center;
            min-width: 200px;
            background: #000;
            color: white;
            padding: .3em 1em;
            border-radius: 4px;
            font-size: 1.3rem;
            font-weight: bold;
            @media #{$pc} {
              min-width: auto;
            }
            i {
              width: auto;
            }
          }
        }
      }
    }
  }
}

// 参加店舗一覧ポップアップ
.js_storeList {
  cursor: pointer;
}
.swal2-html-container {
  text-align: left;
  ul {
    list-style: none;
    text-align: left;
    margin: 0;
    @media #{$pc} {
      margin-top: 20px;
    }
    padding: 0;
    font-size: 1.3rem;
    li {
      margin: .3em 0;
    }
    a {
      text-decoration: underline;
    }
  }
}
.swal2-confirm {
  background: none !important;
  color: black !important;
  font-size: 1.3rem !important;
}
.swal2-popup {
  @media #{$pc} {
    padding: 20px !important;
  }
}
.un_pointdoublecp {
  &_mv {
    background: #e50012;
    overflow: hidden;
    img {
      animation: zoomOut 3s ease-out forwards;
      display: block;
      height: 100%;
      width: auto;
      margin: auto;
    }
    @media #{$pc} {
      height: 50vw;
    }
  }
  &_hd {
    font-size: 2.4rem;
    line-height: 1.6;
    @media #{$pc} {
      font-size: 7rem;
    }
  }
  &_lead {
    @media #{$pc} {
      text-align: center;
    }
  }
}

.un_autumnnoasobiset {
  &_mv {
    overflow: hidden;
    img {
      animation: zoomOut 3s ease-out forwards;
      height: 100vw;
      width: auto;
      margin: auto;
      @media #{$pc} {
        height: auto;
      }
    }
  }
}




.un_campaign {
  div.mv {
    display: flex;
    div.left {
      width: 25px;
      @media #{$pc} {
        width: 23%;
        background: url(/img/contents/pointdoublecp2020/img_bg_left.jpg) center no-repeat;
        background-size: cover;
      }
    }
    div.campaign {
      width: calc(100% - 50px);
      background: #D00010;
      padding: 50px 25px 40px;
      text-align: center;
      @media #{$pc} {
        width: 54%;
      }
      img {
        width: 100%;
        height: auto;
        @media #{$pc} {
          width: auto;
          max-width: 100%;
          min-height: 500px;
          height: 80vh;
        }
      }
    }
    div.right {
      width: 25px;
      @media #{$pc} {
        width: 23%;
        background: url(/img/contents/pointdoublecp2020/img_bg_right.jpg) center no-repeat;
        background-size: cover;
      }
    }
  }
  div.lead {
    padding: 25px;
    h2 {
      text-align: center;
      font-size: 2.1rem;
      margin: 30px 0;
      @media #{$pc} {
        margin: 100px 0 70px 0;
        font-size: 4rem;
      }
    }
    p {
      margin-top: 1.2em;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2em;
      @media #{$pc} {
        text-align: center;
        font-size: 2.4rem;
        margin-top: 1.6em;
      }
    }
  }
  div.pointcard {
    margin: 30px 0 0;
    padding: 25px;
    background: #F7F7F7;
    border: solid 1px #DBDBDB;
    border-radius: 8px;
    @media #{$pc} {
      max-width: 800px;
      margin: 80px auto 0;
      padding: 35px;
    }
    h3 {
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 1.4em;
      @media #{$pc} {
        font-size: 2.1rem;
        margin: .5em auto 1.6em;
      }
    }
    p {
      font-size: 1.3rem;
      line-height: 1.8em;
      margin-bottom: 2em;
      @media #{$pc} {
        font-size: 1.6rem;
      }
    }
    div.btn {
      margin-top: 10px;
      @media #{$pc} {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }
      a {
        display: block;
        color: black;
        background: #ccc;
        text-decoration: none;
        text-align: center;
        border-radius: 6px;
        font-weight: bold;
        padding: .8em;
        @media #{$pc} {
          font-size: 1.8rem;
        }
        i {
          color: white;
          opacity: .75;
          margin-left: .3em;
        }
      }
      &.red {
        a {
          background: #D00010;
          color: white;
        }
      }
    }
    h4 {
      text-align: center;
      font-size: 1.3rem;
      margin: 2.5em 0 1.5em;
      line-height: 1.8em;
      @media #{$pc} {
        font-size: 1.8rem;
      }
    }
    div.appBtn {
      display: flex;
      justify-content: space-between;
      @media #{$pc} {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }
      a {
        width: calc(100% / 2 - 7px);
        display: block;
        img {
          width: 100%;
        }
        @media #{$pc} {
          width: calc(100% / 2 - 10px);
        }
      }
    }
  }
  div.doingStore {
    padding: 0;
    margin: 50px auto 0;
    @media #{$pc} {
      margin-top: 100px;
      max-width: 800px;
      padding: 0;
    }
    h3 {
      text-align: center;
      color: #D00010;
      font-size: 1.8rem;
      margin-bottom: 1em;
      @media #{$pc} {
        font-size: 2.4rem;
        margin-bottom: 1.2em;
      }
    }
    ul {
      padding: 1em 0;
      list-style: none;
      border-top: solid 2px #D00010;
      border-bottom: solid 2px #D00010;
      @media #{$pc} {
        padding: 1.5em 1em;
      }
      li {
        text-align: center;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 2em;
        @media #{$pc} {
          font-size: 2.1rem;
        }
        a {
          //color: #D00010;
          text-decoration: underline;
          i {
            color: gray;
            margin-left: .3em;
          }
        }
        p {
          font-size: 1.3rem;
          font-weight: normal;
          text-align: left;
          line-height: 1.6em;
          text-indent: -1em;
          padding-left: 1em;
          @media #{$pc} {
            font-size: 1.5rem;
            text-align: center;
            text-indent: 0;
            padding-left: 0;
          }
        }
      }
    }
    p.attention {
      font-size: 1.4rem;
      line-height: 1.8em;
      margin-top: 20px;
      font-weight: bold;
      padding: 25px;
      background: #F7F7F7;
      border: solid 1px #DBDBDB;
      border-radius: 8px;
      color: black;
      @media #{$pc} {
        margin-top: 30px;
        max-width: 800px;
        font-size: 1.6rem;
        padding: 35px;
      }
    }
  }
  ul.bigBtn {
    list-style: none;
    padding: 0;
    margin: 50px auto 0;
    @media #{$pc} {
      margin-top: 100px;
      max-width: 800px;
      padding: 0;
    }
    li {
      margin-top: 20px;
      @media #{$pc} {
        margin-top: 50px;
      }
      a {
        display: block;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        color: black;
        text-decoration: none;
        background: #CECECE;
        border: solid 1px #959595;
        border-radius: 8px;
        box-shadow: 0 3px 0 #959595;
        padding: 1em .5em;
        @media #{$pc} {
          font-size: 2.4rem;
        }
        i {
          margin-left: .3em;
        }
      }
      &.red {
        a {
          color: white;
          background: #D00010;
          border-color: #81000A;
          box-shadow: 0 3px 0 #81000A;
          padding-top: 2em;
          padding-bottom: 2em;
          @media #{$pc} {
            font-size: 2.8rem;
          }
        }
      }
      &.text {
        margin-top: 10px;
        @media #{$pc} {
          margin-top: 25px;
        }
        a {
          background: transparent;
          border: none;
          box-shadow: none;
          padding: 0;
          font-size: 1.3rem;
          font-weight: normal;
          text-decoration: underline;
          @media #{$pc} {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
  ul.bnrs {
    margin: 50px auto 100px;
    padding: 0 25px;
    list-style: none;
    padding: 0;
    @media #{$pc} {
      //display: flex;
      //justify-content: space-between;
      margin-top: 50px;
      max-width: 800px;
    }
    li {
      border-radius: 8px;
      margin-top: 50px;
      overflow: hidden;
      @media #{$pc} {
        //width: calc(100% / 2 - 20px);
      }
      a {
        display: block;
        div {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

// 新規会員獲得CP
.un_membershipcp {
  &_title {
    font-size: min(7.2vw, 5.8rem);
    line-height: 1;
    span {
      font-size: min(4.4vw, 3.2rem);
    }
  }
  &_title_s {
    font-size: min(7.2vw, 5.8rem);
    line-height: .8;
    margin-bottom: 24px;
    span {
      font-size: min(4.4vw, 3.2rem);
    }
  }
  &_supTitle {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.6;
    @media #{$pc} {
      font-size: 1.8rem;
    }
    a.txtLink {
      border-bottom: 1px solid #000;
      padding-bottom: 6px;
    }
    a:hover {
      opacity:0.5;
      -moz-opacity:0.5;
      filter:alpha(opacity = 50);
    }
  }
  &_btnRegist {
    display: block;
    //- background: #f00;
    background: #DC000C;
    max-width: 560px;
    margin: auto;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-size: min(6vw, 4rem);
    padding: .6em;
    i {
      margin-left: .3em;
    }
  }
  &_btnStore {
    display: block;
    //- background: #f00;
    background: #000;
    max-width: 560px;
    margin: auto;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-size: min(6vw, 4rem);
    padding: .6em;
    i {
      margin-left: .3em;
    }
  }
  &_btnDetail {
    display: block;
    max-width: 560px;
    margin: auto;
    border-radius: 10px;
    border: solid 1px #CCCBCC;
    font-weight: bold;
    font-size: min(3.4vw, 2.4rem);
    padding: .6em;
    i {
      margin-left: .3em;
      opacity: .5;
    }
  }
  &_btnGift {
    display: inline-block;
    background: white;
    border-radius: 10px;
    color: black;
    font-weight: bold;
    font-size: min(4vw, 1.6rem);
    line-height: 1.2;
    padding: .6em 1em;
    margin-top: .6em;
    @media #{$pc} {
      margin-top: 0;
      vertical-align: middle;
      margin-left: 1em;
    }
    i {
      margin-left: .3em;
      opacity: .5;
    }
  }
  &_bfTitle {
    font-size: min(7.2vw, 5.8rem);
    line-height: 1.2;
    span {
      font-size: min(4.4vw, 3.2rem);
    }
  }
  &_bfList {
    margin-top: 30px !important;
    @media #{$pc} {
      margin-top: 50px !important;
    }
    &Item {
      background: #EAD7BA;
      margin-top: 10px;
      padding: 25px 25px 0;
      @media #{$pc} {
        padding: 0;
      }
      &Wrap {
        @media #{$pc} {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      &Copy {
        text-align: center;
        @media #{$pc} {
          text-align: left;
        }
        &Hd {
          font-size: 2.8rem;
          line-height: 1.4;
          @media #{$pc} {
            font-size: 3.2rem;
            display: inline-block;
          }
          span {
            display: inline-block;
            font-size: 1.6rem;
            margin-top: .5em;
            @media #{$pc} {
              font-size: 2rem;
            }
          }
        }
      }
      &Img {
        img {
          max-width: 200px;
          margin: auto;
          @media #{$pc} {
            max-width: none;
            width: auto;
            height: 180px;
          }
        }
      }
    }
  }
  &_subCap {
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.6;
    letter-spacing: .1rem;
    word-break: break-all;
    a {
      text-decoration: underline;
    }
  }
  &_rules {
    max-width: 560px;
    margin: 0 auto;
    font-size: 1.2rem;
    line-height: 1.6;
    letter-spacing: .1rem;
    word-break: break-all;
    a {
      text-decoration: underline;
    }
  }
  &_appDownload {
    background-color: #F5F5F5;
    padding: 75px 0;
    @media #{$pc} {
      padding: 150px 0;
    }
    &Wrap {
      @media #{$pc} {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
      &Img {
        @media #{$pc} {
          width: 60%;
        }
      }
      &Copy {
        text-align: center;
        @media #{$pc} {
          text-align: left;
        }
        &Hd {
          margin-top: 1em;
          font-size: min(7.2vw, 5.8rem);
          line-height: 1.2;
          span {
            font-size: min(4.4vw, 3.2rem);
          }
        }
        div.appBtn {
          display: flex;
          margin-top: 25px;
          justify-content: space-between;
          @media #{$pc} {
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
          }
          a {
            width: calc(100% / 2 - 7px);
            display: block;
            img {
              width: 100%;
            }
            @media #{$pc} {
              width: calc(100% / 2 - 10px);
            }
          }
        }    
      }
    }
  }
}

// 春の野遊びCP2023
.un_springnoasobicp2023 {
  &_bg {
    background: white;
    overflow: hidden;
    animation: un_springnoasobicp2023_bg_anim 1s ease-in-out forwards;
    animation-delay: 2s;
    @keyframes un_springnoasobicp2023_bg_anim {
      0%{
        background: white;
       }
      100%{
        background: $colorMosgreen;
      }
    }
    &__lp {
      animation: none;
      background: $colorMosgreen;
      .un_springnoasobicp2023_mv {
        height: 50vh; //70vh;
        height: 50dvh; //70dvh;
        @media #{$pc} {
          width: 600px;
          height: 450px;
          padding-bottom: 0;
          justify-content: end;
        }
      }
      .un_springnoasobicp2023_mv_copy {
        animation: none;
        opacity: 1;
        transform: scale(1);
      }
      .un_springnoasobicp2023_mv_head {
        animation: none;
        opacity: 1;
        width: 100%;
      }
      .un_springnoasobicp2023_mv_scroll {
        display: none;
      }
    }
  }

  &_mv {
    display: flex;
    height: 100vh; //70vh;
    height: 100dvh; //70dvh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 25px 25px;
    position: relative;
    @media #{$pc} {
      height: 100vh;
      max-width: 1000px;
      margin: auto;
      padding-bottom: 5%;
    }
    &_copy {
      width: 65%;
      opacity: 0;
      transform: scale(3);
      animation: un_springnoasobicp2023_mv_copy_anim 1s ease-in-out forwards;
      @keyframes un_springnoasobicp2023_mv_copy_anim {
        0%{
          opacity: 0;
          transform: scale(3);
        }
        100%{
          opacity: 1;
          transform: scale(1);
        }
      }

    }
    &_head {
      //width: 100%;
      //margin: 4vw 0;
      margin: 0;
      width: 0%;
      opacity: 0;
      animation: un_springnoasobicp2023_mv_head_anim 2.2s ease-in-out forwards;
      animation-delay: 2s;
      @keyframes un_springnoasobicp2023_mv_head_anim {
        0%{
          opacity: 0;
          width: 0%;
        }
        50% {
          opacity: 0;
          width: 100%;
        }
        100%{
          opacity: 1;
          width: 100%;
        }
      }
    }
    &_period {
      width: 56%;
    }
    &_scroll {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      animation-name: un_springnoasobicp2023_mv_scroll_icon;
      animation-duration: 1s;
      animation-timing-function: ease-out;
      animation-iteration-count: infinite;
      img {
        width: 30px;
        @media #{$pc} {
          width: 45px;
        }
      }
      &Text {
        font-family: $lato;
        font-size: .8rem;
      }
      &Line {
        width: 1px;
        height: 40px;
        display: block;
        margin: auto;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 50%);
        background-position: 0 40px;
        background-size: 100% 200%;
        animation: scrolldown 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
      }
      @keyframes scrolldown {
        0%{
          background-position: 0 -40px;
        }
        75% {
          background-position: 0 0;
        }
        100%{
          background-position: 0 40px;
        }
      }
    }
  }

  &_scene {
    height: 100vh;
    background: url(/assets/images/contents/springnoasobicp2023/mv_bg_springcamp.jpg) center no-repeat;
    background-size: cover;
    @media #{$pc} {
      background-attachment: fixed;
    }
  }

  &_cpWrap {
    background: white;
    padding: 0 25px 50px;
    margin: 10px;
    @media #{$pc} {
      margin: 60px;
      padding-bottom: 100px;
    }
    &__noBorder {
      padding: 0 25px 50px;
      margin: 100px 0 0;
      @media #{$pc} {
        margin: 200px 0 0;
        padding-bottom: 150px;
      }
      }
  }

  &_cpNum {
    text-align: center;
    color: $colorMosgreen;
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    font-weight: bold;
    font-size: 10rem;
    line-height: 1.2;
    padding-top: .3em;
    @media #{$pc} {
      font-size: 14rem;
    }
  }

  &_cpSubTitle {
    text-align: center;
    color: $colorMosgreen;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.2;
    margin-top: .3em;
    letter-spacing: .08em;
    @media #{$pc} {
      font-size: 3.6rem;
    }
    &__noNum {
      padding-top: 30px;
      @media #{$pc} {
        padding-top: 100px;
      }
    }
  }

  &_cpTitle {
    text-align: center;
    font-family: $mincho;
    font-weight: normal;
    font-size: 3.8rem;
    line-height: 1.2;
    letter-spacing: .15em;
    margin-top: .1em;
    @media #{$pc} {
      font-size: 7.2rem;
    }
  }

  &_cpDetail {
    font-family: $gothic;
    line-height: 1.7;
    max-width: 600px;
    margin: 1em auto auto;
    strong,
    em {
      color: $colorOrange;
    }
    em {
      font-weight: bold;
      font-style: normal;
      text-decoration: underline;
    }
    @media #{$pc} {
      font-size: 1.6rem;
    }
  }

  &_cpStarter {
    margin-top: 50px;
    @media #{$pc} {
      max-width: 1300px;
      margin: 80px auto auto;
    }
    &Ul {
      @media #{$pc} {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    &Li {
      margin-top: 10px;
      @media #{$pc} {
        width: calc(100% / 3 - 20px);
        margin: 10px;
        &.col2 {
          width: calc(50% - 20px);
        }
      }
    }
  }

  &_cpStarterCamp {
    max-width: 900px;
    margin: 50px auto 0;
    @media #{$pc} {
      margin-top: 100px;
    }
    &Logo {
      width: 150px;
      margin: auto;
      @media #{$pc} {
        width: 200px;
      }
    }
    &Sublogo {
      width: 100px;
      margin-left: auto;
      margin-right: 10px;
      margin-top: -30px;
      position: relative;
      z-index: 2;
      @media #{$pc} {
        width: 150px;
        margin-top: -60px;
        margin-right: 20%;
      }
    }
    &Ph {
      margin-top: -30px;
      @media #{$pc} {
        margin-top: -50px;
      }
    }
    &Title {
      text-align: center;
      font-size: 1.8rem;
      margin-top: 2em;
      line-height: 1.6;
      @media #{$pc} {
        font-size: 2.1rem;
      }
    }
    &Btn {
      text-align: center;
      a {
        margin-top: 30px;
      }
    }
  }

  &_cpMugImg {
    max-width: 600px;
    margin: 50px auto 0;
    @media #{$pc} {
      margin-top: 80px;
    }
    &Sub {
      margin-top: 1em;
      font-size: 1.2rem;
      text-align: center;
    }
  }

  &_cpMugTable {
    width: 100%;
    margin-top: 50px;
    border-bottom: solid 1px #D8D8D8;
    @media #{$pc} {
      margin: 100px auto 0;
      max-width: 1080px;
    }
    tr {
      th {
        display: block;
        border-top: solid 1px #D8D8D8;
        padding-top: 40px;
        @media #{$pc} {
          display: table-cell;
          text-align: left;
          padding: 40px;
        }
        h3 {
          display: inline-block;
          position: relative;
          font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
          font-weight: bold;
          font-size: 2rem;
          z-index: 1;
          span {
            font-size: 3.6rem;
          }
          @media #{$pc} {
            font-size: 2.4rem;
            span {
              font-size: 4.2rem;
            }
          }
          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -2px;
            right: -2px;
            height: 6px;
            background: #FFED00;
            z-index: -1;
          }
        }
        h4 {
          color: #888888;
          font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
          font-size: 1.2rem;
          margin-top: .3em;
          span {
            font-size: 1.4rem;
          }
          @media #{$pc} {
            font-size: 1.4rem;
            span {
              font-size: 1.8rem;
            }
          }
        }
        p {
          margin-top: 1em;
          font-size: 1.4rem;
        }
      }
      td {
        display: block;
        text-align: center;
        padding-bottom: 20px;
        @media #{$pc} {
          display: table-cell;
          text-align: left;
          border-top: solid 1px #D8D8D8;
          padding: 40px;
        }
        img {
          display: inline-block;
          width: auto;
          height: 90px;
          margin: 10px;
          @media #{$pc} {
            height: 120px;
            margin: 0 10px;
          }
        }
      }
    }
  }
  &_cpAttention {
    max-width: 750px;
    margin: 50px auto 0;
    font-size: 1.3rem;
    @media #{$pc} {
      font-size: 1.2rem;
      margin-top: 100px;
    }
    ul {
      margin-top: 0;
      padding-left: 20px;
      li {
        margin-left: 0;
      }
    }
    a {
      text-decoration: underline;
    }
  }

  &_cpAppImg {
    max-width: 1100px;
    margin: 50px auto 0;
    @media #{$pc} {
      margin-top: 80px;
    }
    &Sub {
      margin-top: 1em;
      font-size: 1.2rem;
      text-align: center;
    }
  }

  &_cpAppStep {
    margin-top: 50px;
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      counter-reset: item;
      @media #{$pc} {
        display: flex;
        justify-content: center;
      }
      > li {
        text-align: center;
        margin-top: 40px;
        @media #{$pc} {
          margin-left: 20px;
          margin-right: 20px;
        }
        &::before {
          counter-increment: item;
          content: counter(item);
          display: inline-block;
          font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
          color: $colorMosgreen;
          font-size: 3rem;
          font-weight: bold;
          padding: .4em .5em;
          border: solid 3px $colorMosgreen;
          border-radius: 40px;
        }
        &.numLarge {
          &::before {
            //background: $colorMosgreen;
            font-size: 6rem;
            padding: 0;
            //vertical-align: middle;
            border: none;
            color: $colorMosgreen;
            vertical-align: text-bottom;
            margin-right: .1em;
          }
        }
      }
    }
    &Title {
      font-size: 2.4rem;
      margin-top: .8em;
      &__inline {
        display: inline-block;
        margin-bottom: 1.5em;
      }
    }
    &Subtitle {
      margin-top: .5em;
      font-weight: bold;
      img {
        display: inline-block;
        max-width: 26px;
        max-height: 26px;
        vertical-align: middle;
        margin-right: 5px;
      }
      &__mt {
        margin-top: 3em;
      }
      &__large {
        font-size: 1.6rem;
        color: $colorMosgreen;
      }
    }
    &Dlbtn {
      max-width: 200px;
      margin: auto;
      a {
        display: inline-block;
        width: 100%;
        margin-top: 10px;
      }
    }
    &Login {
      max-width: 170px;
      margin: 20px auto 0;
    }
    &Btn {
      display: block;
      background: $colorMosgreen;
      color: white;
      font-weight: bold;
      padding: .8em;
      border-radius: 7px;
      margin-top: 10px;
      font-size: 1.6rem;
      i {
        margin-left: .3em;
      }
    }
    &Form {
      margin: 20px auto 0;
      display: none;
      @media #{$pc} {
        display: block;
        img {
          max-width: 160px;
          margin: auto;
        }
      }
      &Btn {
        margin-top: 20px;
        background: linear-gradient($colorOrange,#d14b00);
        //-background-image: none;
        border: none;
        color: white;
        font-size: 2rem;
        width: 100%;
        border-radius: 40px;
        box-shadow: 0 3px 0 #b03f00;
        @media #{$pc} {
          font-size: 2.4rem;
        }
        i {
          //opacity: .5;
          margin-left: .3em;
        }
        &Sub {
          text-align: center;
          font-weight: bold;
          margin-top: 1em;
          @media #{$pc} {
            font-size: 1.8rem;
          }
        }
        &__gray {
          background: linear-gradient(#666,#555);
          box-shadow: 0 3px 0 #333;
          font-size: 1.6rem;
          @media #{$pc} {
            font-size: 2rem;
          }
        }
      }
    }
    > a.el_btn {
      display: none;
      @media #{$pc} {
        display: block;
      }
      margin: 40px auto 0;
      max-width: 500px;
    }
    &Flow {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        @media #{$pc} {
          justify-content: center;
        }
        li {
          position: relative;
          @media #{$pc} {
            width: calc(100% / 5 - 20px);
          }
          &::after {
            content: '';
            background: url(/assets/images/contents/springnoasobicp2023/icon_right.svg) right center no-repeat;
            background-size: contain;
            position: absolute;
            top: 0;
            bottom: 0;
            right: -8px;
            width: 16px;
            z-index: 2;
          }
          &:last-child {
            &::after {
              content: none;
            }
          }
        }
      }
    }
    &__appdllp {
      > ul {
        display: block;
      }
    }
  }

  &_cp2col {
    margin: 10px;
    @media #{$pc} {
      margin: 60px;
      //-padding-bottom: 100px;
      display: flex;
    }
    &Wrap {
      background: white;
      padding: 25px;
      flex: 1;
      margin-top: 10px;
      @media #{$pc} {
        margin-top: 0;
        padding: 50px;
        &:nth-child(odd) {
          margin-right: 30px;
        }
        &:nth-child(even) {
          margin-left: 30px;
        }
      }
    }
    
    &SubTitle {
      text-align: center;
      color: $colorMosgreen;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.2;
      margin-top: .3em;
      letter-spacing: .08em;
      @media #{$pc} {
        font-size: 2.8rem;
      }
    }
  
    &Title {
      text-align: center;
      font-family: $mincho;
      font-weight: normal;
      font-size: 3rem;
      line-height: 1.2;
      letter-spacing: .15em;
      margin-top: .1em;
      @media #{$pc} {
        font-size: 5rem;
      }
    }

    &Img {
      margin: 10px 0;
      @media #{$pc} {
        margin: 25px 0;
      }
      img {
        @media #{$pc} {
          width: 90%;
          margin: auto;
        }
      }
      &__mug {
        img {
          width: 70%;
          margin: auto;
          @media #{$pc} {
            width: 50%;
          }
        }
      }
    }

    &Btn {
      display: block;
      background: $colorMosgreen;
      color: white;
      font-weight: bold;
      text-align: center;
      width: 80%;
      max-width: 350px;
      margin: auto;
      padding: .5em;
      @media #{$pc} {
        font-size: 1.8rem;
      }
      i {
        margin-left: .3em;
      }
    }
  
  }

  &_end {
    border-top: solid 1px $colorMosgreen;
  }
}

.cpEndTxt {
  padding: 8px 0;
  line-height: 2;
  font-size: 1.8rem;
}

.un_shoulderBnr {
  position: fixed;
  right: 0;
  bottom: 100px;
  //transform: translateY(0%);
  transition: transform .5s;
  transform: translateX(35px);
  &.displayIn {
    transform: translateX(0);
  }
  @media #{$pc} {
    transform: translateY(-50%);
    bottom: auto;
    top: 50%;
  }
  z-index: 999;
  a {
    img {
      transform: translateX(12px);
      transition: transform .5s;
      width: 45px;
      border-radius: 6px;
      box-shadow: 0 0 5px rgba(0,0,0,0.2);
      @media #{$pc} {
        width: 70px;
        transform: translateX(17px);
        border-radius: 0;
        //box-shadow: none;
      }
    }
    &:hover {
      opacity: 1;
      img {
        filter:brightness(100%);
        @media #{$pc} {
          transform: translateX(5px);
        }
      }
    }
  }
}

// camphack
.un_socialPromotion {
  ul.un_newsPg {
    list-style: disc;
    padding-left: 20px;
    margin-top: 10px !important;
    @media #{$pc} {
      margin-top: 15px !important;
    }
  }
  &Agree {
    text-align: center;
    &__inner {
      background: #E9E9E9;
      font-weight: bold;
      padding: 20px;
      font-size: 1.4rem;
      @media #{$pc} {
        display: inline-block;
        font-size: 1.6rem;
      }
      input,
      label {
        cursor: pointer;
      }
    }
    &__btn {
      a {
        display: block;
        padding: 1em 1em;
        font-size: 1.6rem;
        border-radius: 3em;
        background: #333;
        color: #fff;
        text-align: center;
        font-weight: bold;
        @media #{$pc} {
          font-size: 2.4rem;
        }
      }
      &__red {
        a {
          background: #a40000;
        }
      }
      &.disabled {
        opacity: .25;
        a {
          pointer-events: none;
          background: #333;
        }
      }
    }
  }
  &Attn {
    line-height: 1.5;
    font-weight: bold;
    i {
      font-size: 200%;
    }
    &__red {
      color: #a40000;
    }
  }
  &Eximg {
    width: 60%;
    @media #{$pc} {
      width: 80%;
    }
    p {
      font-weight: bold;
    }
    img {
      border: solid 1px black;
    }
  }
  &Modal {
    background: white;
    padding: 20px;
    border-radius: 20px;
    max-width: 800px;
    margin: 0 auto;
    overflow-y: scroll;
    padding-top: 40px;
    padding-bottom: 40px;
    @media #{$pc} {
      overflow-y: auto;
      padding: 50px;
    }
    figure {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &End {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #a40000;
    line-height: 1.5;
    border: solid 3px #a40000;
    padding: 1em;
    @media #{$pc} {
      font-size: 1.8rem;
    }
  }
}

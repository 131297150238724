/* ==========================================================
 page / news
========================================================== */
.un_newsHeader {
  @media #{$pc} {
    display: flex;
    justify-content: space-between;
  }
}

.un_newsFuncSort {
  margin-top: 25px;
  .el_formSelect {
    margin-right: 20px;
    @media #{$pc} {
      margin-right: 0;
      margin-left: 20px;
    }
  }
  @media #{$pc} {
    margin-top: 0;
    white-space: nowrap;
  }
}

.un_newsList {
  @media #{$pc} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &_item {
    margin-bottom: 40px;
    figure {
      img {
        aspect-ratio: 3 / 2;
        object-fit: cover;
      }
    }
    @media #{$pc} {
      margin-bottom: 48px;
      &__superLarge,
      &__large {
        margin-bottom: 88px;
      }
    }
    a {
      display: inline-block;
      .el_labelCategory {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }
  &_itemDetail {
    margin-top: 20px;
  }
  &_title {
    margin-top: 6px;
    font-size: 1.4rem;
    line-height: (22/14);
  }
  &_date {
    margin-top: 10px;
    font-family: $garamond;
    font-size: 1.3rem;
    color: #6F6F6F;
    letter-spacing: .08em;
  }
  &_footLink a {
    font-size: 1.3rem;
  }
  li {
    @media #{$pc} {
      width: 31.01%;
    }
    &.un_newsList_item__superLarge {
      figure {
        margin-left: -25px;
        margin-right: -25px;
      }
      .un_newsList_title {
        font-size: 1.6rem;
        @media #{$pc} {
          font-size: 2rem;
        }
      }
      @media #{$pc} {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        figure {
          margin-left: 0;
          margin-right: 0;
          width: 61.01%;
        }
        .un_newsList_itemDetail {
          width: 34.74%;
          margin-top: 0;
        }
      }
    }
    &.un_newsList_item__large {
      @media #{$pc} {
        width: 46.61%;
        .un_newsList_title {
          font-size: 1.8rem;
        }
      }
    }
  }
}

.un_newsPage {
  &_title {
    h1 {
      font-family: $mincho;
      font-size: 2.4rem;
      font-weight: normal;
      letter-spacing: .05em;
      line-height: (36/24); @include lineHeightCrop(36/24);
      margin-top: 20px;
      @media #{$pc} {
        font-size: 2.8rem;
        line-height: (50/28); @include lineHeightCrop(50/28);
      }
    }
    .un_newsList_date {
      margin-top: 20px;
    }
    .el_labelCategory {
      margin-bottom: 3px;
    }
  }
}

// heading
.un_newsHd {
  &Lv2 { // (h2)
    font-family: $mincho;
    font-weight: normal;
    font-size: 2.1rem;
    line-height: (34/21); @include lineHeightCrop(34/21);
    @media #{$pc} {
      font-size: 2.2rem;
      line-height: (36/22); @include lineHeightCrop(36/22);
    }
    &__gothic {
      font-family: $gothic;
      font-weight: bold;
    }
  }
  &Lv3 { // (h3)
    font-family: $mincho;
    font-weight: normal;
    font-size: 1.7rem;
    line-height: (30/17); @include lineHeightCrop(30/17);
    @media #{$pc} {
      font-size: 1.8rem;
      line-height: (36/18); @include lineHeightCrop(36/18);
    }
  }
  &Lv4 { // (h3)
    font-weight: bold;
    font-size: 1.6rem;
    line-height: (26/16); @include lineHeightCrop(26/16);
  }
}

// paragraph
.un_newsPg {
  font-size: 1.4rem;
  line-height: 1.8;
  letter-spacing: .08em;
  color: #4e4e4e;
  @include lineHeightCrop(1.8);
  @media #{$pc} {
    font-size: 1.5rem;
  }
  a {
    color: black;
    text-decoration: underline;
  }
  strong {
    font-weight: bold;
  }
  &__s {
    font-size: 1.2rem;
    line-height: (21/12);
    @media #{$pc} {
      font-size: 1.3rem;
      line-height: (21/13);
    }
  }
}

// button / link
.un_newsBtn {
  a {
    display: block;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 1.5em .6em;
    line-height: (20/13);
    background: #C0C0C0;
  }
}
.un_newsTextlink {
  text-align: center;
  a {
    display: inline-block;
    font-size: 1.4rem;
    text-decoration: underline;
    line-height: (22/14);
    @media #{$pc} {
      font-size: 1.5rem;
      line-height: (28/15);
    }
  }
}
.un_newsBtn + .un_newsBtn,
.un_newsBtn + .un_newsTextlink,
.un_newsTextlink + .un_newsBtn { // 連続するときはマージン量を強制指定
  margin-top: 24px;
}

// bgbox
.un_newsBgBox {
  background: #f5f5f5;
  margin-left: -25px;
  margin-right: -25px;
  padding: 40px 25px;
  @media #{$pc} {
    margin-left: 0;
    margin-right: 0;
    padding: 48px 32px;
  }
}

// figure colums
.un_newsFigure {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: -40px;
  figure {
    margin-bottom: 40px;
  }
  &__2col {
    figure {
      @media #{$pc} {
        width: 48%;
      }
    }
  }
  &__3col {
    figure {
      @media #{$pc} {
        width: 31%;
      }
    }
  }
}

// caption
.un_figureCaption {
  text-align: center;
  font-size: 1rem;
  margin-top: 1em;
  line-height: 1.6;
  @media #{$pc} {
    font-size: 1.2rem;
  }
}

// ul, ol / list
.un_newsUol {
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: .08em;
  color: #4e4e4e;
  list-style: disc !important;
  padding-left: 1.5em !important;
  margin-bottom: -.4em;
  li {
    margin-bottom: .4em;
  }
  @media #{$pc} {
    font-size: 1.5rem;
  }
  a {
    color: black;
    text-decoration: underline;
  }
  strong {
    font-weight: bold;
  }
  &__ordered {
    list-style: decimal !important;
  }
}

// table
.un_newsTable {
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: .08em;
  @media #{$pc} {
    font-size: 1.5rem;
  }
  border-collapse: collapse;
  border-top: solid 1px gainsboro;
  thead {
    tr {
      th {
        background: whitesmoke;
      }
    }
  }
  tbody {

  }
  tr {
    th {
      display: block;
      font-weight: bold;
      text-align: left;
      vertical-align: top;
      padding: 1.5em 0 1em;
      @media #{$pc} {
        display: table-cell;
        width: 25%;
        padding: 1.5em 1em 1.5em 0;
        border-bottom: solid 1px gainsboro;
      }
    }
    td {
      color: #4e4e4e;
      display: block;
      text-align: left;
      vertical-align: top;
      padding-bottom: 1.5em;
      border-bottom: solid 1px gainsboro;
      @media #{$pc} {
        display: table-cell;
        padding: 1.5em 0;
      }
    }
  }
  &__2axis {
    border-top: none;
    width: auto !important;
    @media #{$pc} {
      width: 100% !important;
    }
    min-width: 600px;
    table-layout: fixed;
    tr {
      th, td {
        width: auto;
        display: table-cell;
        border: solid 1px gainsboro;
        padding: 1em;
      }
    }
  }
}

// SNS共有
.un_newsSnsShare {
  text-align: center;
}
.un_newsSnsShareTw {
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
}
.un_newsSnsShareFb {
  display: inline-block;
  vertical-align: top;
}
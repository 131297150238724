/* event */
.un_eventMv {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background: black;
  &_inner {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity .25s linear;
    //transition: transform 0 linear;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to bottom,  rgba(0,0,0,.2) 0%,rgba(0,0,0,0) 100%);
    }
    &__video {
      position: relative;
      overflow: hidden;
      video {
        //-width: auto;
        //-height: 100vh;
        //-margin-left: 50%;
        //-transform: translateX(-50%);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        min-width: 100%;
        min-height: 100%;
      }
      &:after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(to bottom,rgba(0,0,0,0) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,1) 100%)
      }
    }
  }
  &_upper {
    position: relative;
    z-index: 2;
  }
}

.un_eventLogo {
  height: calc(100vh - 130px - 32px);
  display: flex;
  justify-content: center;
  align-items: center;
  @media #{$pc} {
    height: calc(100vh - 148px - 32px);
  }
  &Spwp {
    height: calc(100vh - 130px - 55px);
    @media #{$pc} {
      height: calc(100vh - 148px - 34px);
    }
  }
  img {
    max-width: 200px;
    margin-bottom: 18%;
    @media #{$pc} {
      max-width: 324px;
      margin-bottom: 12%;
    }
  }
}

.un_eventBgGreen {
  background: #EAF2D8;
}

.un_eventEnjoying {
  position: relative;
  overflow: hidden;
  &Hd {
    text-align: center;
    line-height: 1.4;
    margin-top: 10px;
    @media #{$pc} {
      margin-top: 15px;
      margin-bottom: 66px;
    }
    &Sub {
      text-align: center;
      font-size: 1.4rem;
      font-weight: bold;
      margin-bottom: 5px;
      @media #{$pc} {
        font-size: 1.8rem;
      }
    }

  }
  &Point {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -20px !important;
    gap: 4%;
    @media #{$pc} {
      gap: 0;
      justify-content: space-between;
    }
    li {
      margin-bottom: 20px;
      width: 48%;
      @media #{$pc} {
        width: 32.25%;
      }
    }
  }
  &Price {
    figure {
      filter: drop-shadow(0 3px 3px rgba(0,0,0,.16));
    }
    @media #{$pc} {
      display: flex;
      align-items: center;
    }
    &__plus {
      width: 40px;
      margin: 10px auto;
      @media #{$pc} {
        width: 60px;
        margin: 22px -20px 0;
        position: relative;
        z-index: 2;
      }
    }
  }
  &Sub {
    li {
      margin-top: .5em;
      padding-left: 1em;
      text-indent: -1em;
    }
  }
  &Step {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -20px !important;
    li {
      position: relative;
      margin-bottom: 20px;
      width: 47%;
      img {
        filter: drop-shadow(0 3px 3px rgba(0,0,0,.16));
      }
      span {
        display: block;
        position: absolute;
        color: #638C0B;
        right: -14px;
        top: 48%;
        font-size: 1.6rem;
        @media #{$pc} {
          right: -20px;
        }
      }
      &:nth-child(2) {
        span {
          right: auto;
          left: -14px;
          top: auto;
          bottom: -20px;
          transform: rotate(135deg);
          @media #{$pc} {
            right: -20px;
            top: 48%;
            left: auto;
            bottom: auto;
            transform: rotate(0);
          }
        }
      }
      @media #{$pc} {
        width: 22.5%;
      }
    }
  }
  &Hd__bgBlack {
    span {
      border-bottom-color: white;
    }
  }
  &Step__bgBlack {
    filter: saturate(0%);
  }
  &BtnSchedule {
    background-color: #6C8B2C;
    color: white;
    font-size: 2.1rem;
    padding-left: 0;
    padding-right: 0;
    i {
      margin-right: .5em;
    }
  }
}


// anchor link
.un_eventAnchorLink {
  border-bottom: #F3F3F3 solid 1px;
  padding: 15px 0;
  p {
    font-family: $garaAndMin;
    font-size: 1.5rem;
    letter-spacing: .1em;
    margin-top: 60px;
    @media #{$pc} {
      margin-top: 0;
      width: 100px;
    }
  }
  > div {
    @media #{$pc} {
      display: flex;
      align-items: center;
    }  
  }
}
.un_eventAnchorLinkUL {
  margin-top: 20px !important;
  @media #{$pc} {
    margin-top: 0 !important;
    width: calc(100% - 200px);
    display: flex;
    justify-content: center;
  }
  li {
    border-bottom: solid 1px silver;
    @media #{$pc} {
      border-bottom: none;
      margin: 0 .5em;
    }
    a {
      position: relative;
      display: block;
      padding: .8em 0;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.4;
      background: url(/assets/images/common/icon_navbtn_arrow_down.svg) no-repeat right center;
      @media #{$pc} {
        background: url(/assets/images/common/icon_navbtn_arrow_down.svg) no-repeat left center;
        padding: 0 0 0 1.2em;
        font-size: 1.6rem;
      }
    }
  }
}



// corona check

.un_cfr-checklist-title {
  border: solid 3px #009944;
  border-radius: 9px;
  text-align: center;
  color: #009944;
  padding: 15px;
  @media #{$pc} {
    border-radius: 12px;
    padding: 35px;
  }
  .un_cfr-checklist-head {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.6em;
    @media #{$pc} {
      font-size: 2.6rem;
    }
  }
  .un_cfr-checklist-supp {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.6em;
    margin-top: .5em;
    @media #{$pc} {
      font-size: 1.6rem;
    }
  }
}

.un_cfr-checkitems {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .un_cfr-checkitems-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: calc(100% / 2 - 4px);
    margin-top: 8px;
    border: solid 3px #009944;
    border-radius: 9px;
    color: #009944;
    padding: 15px;
    &:nth-child(5),
    &:nth-child(6) {
      width: 100%;
      @media #{$pc} {
        //width: calc(100% / 2 - 4px);
      }
      figure {
        width: 50%;
        max-width: 344px;
        @media #{$pc} {
          width: 80%;
        }
      }
    }
    &:hover {
      opacity: .75;
    }
    @media #{$pc} {
      position: relative;
      padding: 25px;
      border-radius: 12px;
    }
    p {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 10px;
      span {
        display: inline-block;
        font-size: 1.2rem;
        line-height: 1.4;
        font-weight: normal;
      }
      @media #{$pc} {
        font-size: 2.1rem;
        line-height: 1.6em;
        span {
          font-size: 1.4rem;
        }
      }
    }
    figure {
      width: 100%;
      margin: auto auto 0 auto;
      @media #{$pc} {
        width: 80%;
      }
      img {
        width: 100%;
        height: auto;
        mix-blend-mode: multiply;
      }
    }
    div {
      margin-top: 15px;
      width: 100%;
      text-align: center;
      color: black;
      font-size: 1.5rem;
      font-weight: bold;
      @media #{$pc} {
        width: auto;
        position: absolute;
        right: 25px;
        bottom: 25px;
      }
      i {
        margin-left: 5px;
        font-size: 4rem;
        color: gainsboro;
        vertical-align: middle;
      }
    }
    &.checked {
      background: #E4F4EB;
      div {
        i {
          color: #009944;
        }
      }
    }
  }
  &__bgBlack {
    .un_cfr-checkitems-item {
      &.checked {
        background: rgba(255,255,255,.2);
      }
      figure {
        img {
          filter: invert(100%) saturate(0%) brightness(200%);
          mix-blend-mode: exclusion;
        }
      }
      div {
        color: white;
      }
    }
  }
}

.un_cfr-button-reservation {
  font-size: 1.8rem;
  line-height: 1.4em;
  font-weight: bold;
  margin-top: 8px;
  display: block;
  background: #6C8B2C;
  border-radius: 9px;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 1em .6em;
  i {
    margin-right: .3em;
  }
  &:hover {
    opacity: .75;
  }
  &.disable {
    background: gainsboro;
    pointer-events: none;
  }
  @media #{$pc} {
    font-size: 2.4rem;
    border-radius: 12px;
    transition: opacity .25s linear;
  }
  small {
    font-size: 50%;
  }
}


.un_eventLinkMyPage {
  text-align: right;
  line-height: 2.2rem;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: .5em;
  @media #{$pc} {
    margin-top: 0;
  }
  a {
    text-decoration: underline;
  }
  i {
    margin-left: .3em;
    color: #a40000;
  }
}
.bl_breadCrumb__wht > .un_eventLinkMyPage {
  a {
    color: white;
  }
  i {
    color: white;
  }
}

// 手ぶらCAMPプラン
.un_eventTeburaplan {
  @media #{$pc} {
    //display: flex;
    //justify-content: space-between;
  }
  &Img {
    padding-top: 100%;
    background: url(/assets/images/event/spw/tebura_camp.jpg) no-repeat center 75%;
    background-size: cover;
    @media #{$pc} {
      //width: 45.76%;
      padding-top: 66%;
    }
  }
  &Content {
    @media #{$pc} {
      //width: 45.76%;
    }
    &Title {
      margin-top: 1em;
      text-align: center;
      font-family: $mincho;
      font-weight: normal;
      font-size: 3rem;
      letter-spacing: .05em;
      line-height: 1.5;
      @media #{$pc} {
        //margin-top: 0;
        font-size: 4rem;
      }
    }
    &Copy {
      font-family: $mincho;
      font-size: 1.4rem;
      line-height: 1.8;
      margin-top: 1.5em;
      @media #{$pc} {
        text-align: center;
        font-size: 1.6rem;
        line-height: 2;
      }
    }
    &Price {
      margin-top: 30px;
      figure {
        filter: drop-shadow(0 3px 3px rgba(0, 0, 0, .16));
        &:nth-child(2) {
          width: 30px;
          margin: -8px auto;
          position: relative;
          z-index: 2;
        }
      }
    }
    &Process {
      margin-top: 30px;
      font-weight: bold;
      text-align: center;
      line-height: 1.6;
      @media #{$pc} {
        font-size: 1.6rem;
      }
    }
    &Attention {
      margin-top: 15px;
      font-size: 1.2rem;
      text-align: center;
    }
    &Link {
      text-align: center;
      margin-top: 1em;
      text-decoration: underline;
      i {
        margin-left: .3em;
        opacity: .5;
      }
    }
  }
}

// イベントフォトアーカイブリンクボタン
.un_eventPhotoArchive {
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 120px;
  &Btn {
    background: #EBEBEB;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 4px;
    max-width: 400px;
    margin: auto;
    font-weight: bold;
    font-size: 1.2rem;
    @media #{$pc} {
      font-size: 1.4rem;
    }
    img {
      width: 34px;
      height: auto;
      margin-right: 14px;
    }
  }
  &__bgBlack {
    .un_eventPhotoArchiveBtn {
      background: #2B2B2B;
      color: white;
    }
  }
}

// 開催スケジュールショートカット
.un_eventScheduleFixBtn {
  position: fixed;
  z-index: 2;
  right: 0;
  bottom: 0;
  width: 40px;
  @media #{$pc} {
    width: 50px;
  }
}

// イベントメッセージ
.un_eventMessage {
  .el_lv3Hd {
    margin: 30px 0;
    @media #{$pc} {
      margin: 60px 0;
    }
  }
  &_h2 {
    font-family: 'adobe-garamond-pro', 'EB Garamond', serif;
    font-weight: normal;
    font-size: 12vw;
    letter-spacing: .06em;
    line-height: 1;
    @media #{$pc} {
      font-size: 6rem;
    }
  }
  &_popup {
    font-size: 1.4rem;
    display: inline-block;
    margin-top: 40px;
    text-decoration: underline;
    i {
      opacity: .5;
      font-size: 90%;
      margin-left: .3em;
    }
  }
}

// イメージ区切り
.un_eventVisualDivide {

}

// イベント会場（新）
.un_eventVenue {
  @media #{$pc} {
    width: $maxWidth;
    margin: auto;
    padding: 0 25px;
  }
  &Map {
    margin-left: 15px;
    margin-right: 15px;
    max-width: 1000px;
    @media #{$pc} {
      margin: auto;
    }
  }
  &TitleSub {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 15px;
    @media #{$pc} {
      font-size: 1.8rem;
    }
  }
  &List {
    width: 100%;
    margin-top: 40px;
    border-bottom: solid 1px #ddd;
    border-collapse: collapse;
    @media #{$pc} {
      margin-top: 70px;
    }
    &Head {
      display: none;
      @media #{$pc} {
        display: table-row;
        border-top: solid 1px #ddd;
        th {
          text-align: left;
          padding: 15px 0;
          color: #808080;
          &:first-child {
            padding-left: 15px;
          }
        }
      }
    }
    &Item {
      background: url(/assets/images/common/icon_arrow-narrow.svg) right 25px center no-repeat;
      cursor: pointer;
      @media #{$pc} {
        background: none;
      }
      td {
        display: block;
        @media #{$pc} {
          display: table-cell;
        }
      }
      &Date {
        font-size: 1.3rem;
        font-weight: bold;
        border-top: solid 1px #ddd;
        padding: 15px 25px 0;
        @media #{$pc} {
          font-size: 1.4rem;
          padding: 15px 0 15px 15px;
        }
      }
      &Name {
        font-family: $mincho;
        font-size: 1.6rem;
        padding: 0 25px;
        @media #{$pc} {
          border-top: solid 1px #ddd;
          padding: 15px 0;
          font-size: 1.8rem;
        }
        span {
          display: inline-block;
          line-height: 1.2;
          font-family: $gothic;
          font-size: 1.1rem;
          font-weight: bold;
          background: black;
          color: white;
          padding: .3em .4em .2em;
          border-radius: 3px;
          margin-right: .5em;
          margin-top: -4px;
          vertical-align: middle;
          @media #{$pc} {
            margin-right: 1.5em;
          }
        }
      }
      &Place {
        font-size: 1.3rem;
        padding: 0 25px 15px;
        @media #{$pc} {
          font-size: 1.4rem;
          border-top: solid 1px #ddd;
          padding: 15px 0;
          background: url(/assets/images/common/icon_arrow-narrow.svg) right 15px center no-repeat;
        }
      }
      &__end {
        background-color: #F5F5F5;
        td {
          opacity: .4;
          &.un_eventVenueListItemDate {
            &::after {
              content: '受付終了';
              margin-left: 1em;
            }
          }
        }
      }
      &__app {
        background-color: #F6FCF4;
        td {
          &.un_eventVenueListItemDate {
            &::after {
              content: '●受付中';
              margin-left: 1em;
              color: #48D000;
            }
          }
        }
      }
      &__appAdditional {
        td {
          &.un_eventVenueListItemDate {
            &::after {
              content: '●追加受付中';
              color: red;
            }
          }
        }
      }

      &:hover {
        background: #EAFCE4;
      }
    }
  }
}

// コンテンツ
.un_eventContents {
  position: relative;
  overflow: hidden;
  .el_lv3Hd {
    margin-bottom: 70px;
    @media #{$pc} {
      margin-bottom: 100px;
    }
  }
  .un_eventAnimationLargeHd {
    top: 70px;
    @media #{$pc} {
      top: 90px;
    }
  }
  &_takibitalk {
    figure {
      img {
        @media #{$pc} {
          aspect-ratio: 150 / 61;
          object-fit: cover;
        }
      }
    }
    .un_eventContents_item {
      padding: 0 25px;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }
    .un_eventContents_itemName {
      font-size: 1.8rem;
      @media #{$pc} {
        font-size: 2.4rem;
      }
    }
    .un_eventContents_itemDetail {
      font-size: 1.4rem;
    }
  }
  &_item {
    margin-top: 1em;
    @media #{$pc} {
      margin-top: 35px;
    }
    &Name {
      font-size: 1.6rem;
      font-weight: bold;
      @media #{$pc} {
        display: inline-block;
      }
    }
    &Label {
      margin-top: .3em;
      @media #{$pc} {
        display: inline-block;
        margin-left: .5em;
        margin-top: 0;
        vertical-align: top;
      }
      span {
        display: inline-block;
        font-size: 1.2rem;
        font-weight: bold;
        border: solid 1px #ADADAD;
        background: #ADADAD;
        border-radius: 4px;
        line-height: 1.6;
        color: white;
        padding: 0 .5em;
        margin-right: .3em;
        
      }
      &_green {
        background: white !important;
        border-color: #84B209 !important;
        color: #84B209 !important;
      }
    }
    &DeteTime {
      font-size: 1.2rem;
      margin-top: .2em;
    }
    &Detail {
      font-size: 1.3rem;
      margin-top: 1em;
    }
  }
  &_items {
    margin-top: 50px;
    padding: 0 25px;
    overflow: visible;
    @media #{$pc} {
      margin-top: 70px;
    }
    &Ul {
      position: relative;
    }
    &Li {
      @media #{$pc} {
      }
    }
    figure {
      margin-bottom: 1em;
    }
    .un_eventContents_itemName {
    }
    &Prev,
    &Next {
      display: block;
      position: absolute;
      left: 10px;
      top: 120px;
      z-index: 2;
      background: url(/assets/images/common/icon_arrow.svg) no-repeat;
      background-size: 100%;
      width: 40px;
      height: 40px;
      &:disabled {
        opacity: .3;
      }
      @media #{$pc} {
        top: 150px;
        left: -5px;
        width: 60px;
        height: 60px;
      }
    }
    &Prev {
      transform: scale(-1, 1);
    }
    &Next {
      left: auto;
      right: 10px;
      @media #{$pc} {
        right: -5px;
      }
    }
  }
}

// 共通フッターバナー
.un_eventFooterLinks {
  @media #{$pc} {
    display: flex;
  }
  a {
    display: block;
    flex: 1;
  }
  img {
    display: block;
    max-width: 280px;
    margin: auto;
    @media #{$pc} {
      max-width: 460px;
    }
  }
  &_comApp {
    background: #2D2D2D;
  }
  &_cup {
    background: #F5F5F5;
  }
  &_bring {
    background: #EAE1D0;
  }
  &_spw {
    background: url(/assets/images/event/spw/2024/bg_spw_lastyear.jpg) black no-repeat center center;
    background-size: cover;
  }
}

// 共通アニメーションヘッダでか文字
.un_eventAnimationLargeHd {
  display: block;
  font-family: "EB Garamond", serif;
  white-space: nowrap;
  line-height: 1;
  color: #f2f2f2;
  position: absolute;
  left: 0;
  top: 100px;
  letter-spacing: 0.05em;
  font-size: 16rem;
  font-weight: normal;
  animation: loop 1000s linear infinite;
  z-index: -1;
  @media #{$pc} {
    font-size: 32rem;
    top: 125px;
  }  
}
@keyframes loop {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

// modal override
.jquery-modal.blocker {
  z-index: 9999;
  padding: 30px 0;
  @media #{$pc} {
    padding: 80px 0;
  }
  .modal {
    padding: 30px;
    @media #{$pc} {
      padding: 150px;
      max-width: 950px;
    }
    a.close-modal {
      background-image: url(/assets/images/event/spw/btn_modal_close.svg);
      @media #{$pc} {
        width: 58px;
        height: 58px;
        top: -25px;
        right: -25px;
      }
    }
  }
}

// モーダル
.un_eventModal {
  // SPWについて
  &_about {
    &Hd {
      font-family: $mincho;
      font-weight: normal;
      letter-spacing: .05em;
      line-height: 2;
      @media #{$pc} {
        font-size: 3.6rem;
      }
    }
    &Detail {
      font-family: $mincho;
      line-height: 2;
      margin-top: 1em;
      @media #{$pc} {
        font-size: 1.8rem;
      }
    }
    &Logo {
      max-width: 100px;
      margin: 30px auto 0;
      @media #{$pc} {
        margin: 50px auto 0;
        max-width: 130px;
      }
    }

  }
  &_btn {
    &Close {
      text-align: center;
      margin-top: 30px;
      @media #{$pc} {
        margin-top: 50px;
      }
      a {
        display: inline-block;
        background: url(/assets/images/event/spw/btn_modal_close_mini.svg) no-repeat left center;
        background-size: 20px;
        padding: 10px 0 10px 25px;
        text-decoration: underline;
        cursor: pointer;
        @media #{$pc} {
          font-size: 1.6rem;
        }
      }
    }
  }

  // マイカップ
  &_mycup {
    &Title {
      font-size: 1.8rem;
      text-align: center;
      line-height: 1.6;
      @media #{$pc} {
        font-size: 2.4rem;
      }
    }
    &Detail {
      font-size: 1.3rem;
      line-height: 1.6;
      margin-top: 1em;
      @media #{$pc} {
        font-size: 1.5rem;
      }
      &Sup {
        font-size: 1.1rem;
        line-height: 1.4;
        margin-top: .5em;
        @media #{$pc} {
          font-size: 1.3rem;
        }
        span {
          display: inline-block;
          text-indent: -1em;
          padding-left: 1em;
        }
      }
    }
    &Ecocup {
      margin-top: 2em;
      &Img {
        max-width: 150px;
        margin: 20px auto;
      }
      &Spec {
        font-size: 1.1rem;
        line-height: 1.4;
        margin-top: 1em;
        @media #{$pc} {
          font-size: 1.3rem;
        }
      }
    }
  }

  // bring
  &_bring {
    &Title {
      font-family: $mincho;
      font-size: 1.8rem;
      font-weight: normal;
      text-align: center;
      line-height: 2;
      @media #{$pc} {
        font-size: 2.4rem;
      }
      &Copy {
        font-size: 1.4rem;
        text-align: center;
        @media #{$pc} {
          font-size: 1.8rem;
        }
      }
      &En {
        font-size: 1.1rem;
        text-align: center;
        @media #{$pc} {
          font-size: 1.4rem;
          line-height: 2;
        }
      }
    }
    &Logo {
      max-width: 150px;
      margin: 30px auto;
      @media #{$pc} {
        max-width: 250px;
        margin: 30px auto 90px;
      }
    }
    &Detail {
      margin-top: 20px;
      table {
        border-bottom: solid 1px #ddd;
        border-collapse: collapse;
        width: 100%;
        tr {
          th, td {
            display: block;
            font-size: 1.3rem;
            line-height: 1.5;
            @media #{$pc} {
              font-size: 1.4rem;
              display: table-cell;
              vertical-align: top;
            }
          }
          th {
            border-top: solid 1px #ddd;
            padding-top: 1em;
            @media #{$pc} {
              padding: 1em 0;
            }
          }
          td {
            padding-bottom: 1em;
            @media #{$pc} {
              border-top: solid 1px #ddd;
              padding: 1em 0;
            }
            figure {
              max-width: 120px;
              margin: auto;
            }
          }
        }
      }
    }
    &Links {
      margin-top: 20px;
      div {
        font-size: 1.3rem;
        line-height: 1.5;
        @media #{$pc} {
          font-size: 1.4rem;
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }
}

// 各会場ページ
.un_eventVenuePage {
  &Logo {
    max-width: 110px;
    margin: 30px auto;
    @media #{$pc} {
      max-width: 130px;
      margin: 40px auto;
    }
  }
  // 写真カルーセル
  &Photos {
    figure {
      width: 100%;
      max-width: 860px;
    }
    &Link {
      width: 82.88%;
      max-width: 860px;
      margin: .7em auto 0;
      font-size: 1.3rem;
      text-align: right;
      @media #{$pc} {
        font-size: 1.4rem;
      }
      a.BlackBg {
        color: white;
      }
      i {
        margin-left: .3em;
        opacity: .8;
      }
    }
  }
  // 2日間の流れ
  &Twoday {
    padding: 80px 0 0;
    overflow: hidden;
    @media #{$pc} {
      padding-top: 100px;
    }
    &Title {
      text-align: center;
      font-family: $garamond;
      font-weight: normal;
      font-size: 2.6rem;
      letter-spacing: .06em;
      @media #{$pc} {
        font-size: 3.2rem;
      }
      span {
        font-family: $gothic;
        font-size: 1.2rem;
        letter-spacing: 0;
        display: inline-block;
        @media #{$pc} {
          font-size: 1.4rem;
          margin-top: 1em;
        }
      }
    }
    &Detail {
      position: relative;
      margin: 40px auto 0;
      padding: 0 25px;
      max-width: 1230px;
      overflow: visible;
      @media #{$pc} {
        margin-top: 70px;
      }
      &Day {
        width: 140px;
        @media #{$pc} {
          width: 180px;
        }
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $mincho;
          font-size: 1.8rem;
          width: 100px;
          height: 100px;
          margin: 130px auto 0;
          border-radius: 70px;
          border: solid 1px #707070;
          text-align: center;
          @media #{$pc} {
            margin-top: 110px;
            width: 140px;
            height: 140px;
            font-size: 2.4rem;
          }
        }
      }
      &Hour {
        width: 280px;
        figure {
          margin: 20px 0;
        }
        div {
          font-family: $roboto;
          font-size: 2.8rem;
          @media #{$pc} {
            font-size: 3.2rem;
          }
        }
        h4 {
          font-size: 1.4rem;
          @media #{$pc} {
            font-size: 1.6rem;
          }
        }
        p {
          margin-top: .7em;
          font-size: 1.2rem;
          line-height: 1.6;
          @media #{$pc} {
            font-size: 1.4rem;
          }
        }
      }
      &_itemsPrev,
      &_itemsNext {
        display: block;
        position: absolute;
        left: 10px;
        top: 160px;
        z-index: 2;
        background: url(/assets/images/common/icon_arrow.svg) no-repeat;
        background-size: 100%;
        width: 40px;
        height: 40px;
        &:disabled {
          opacity: .3;
        }
        @media #{$pc} {
          top: 150px;
          left: -25px;
          width: 60px;
          height: 60px;
        }
      }
      &_itemsPrev {
        transform: scale(-1, 1);
      }
      &_itemsNext {
        left: auto;
        right: 10px;
        @media #{$pc} {
          right: -25px;
        }
      }
    }
  }
}
.eventBgSchedule {
  margin-top: 100px;
}


/* ==========================================================
 キャンプフィールドイベント
========================================================== */
.un_cfEvent {
  &Detail {
    overflow: hidden;
    &_title {
      margin-bottom: 1.5em;
      @media #{$pc} {
        margin-bottom: 2em;
      }
      &Text {
        display: inline-block;
        width: auto;
      }
      &Sub {
        display: inline-block;
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 10px;
        @media #{$pc} {
          margin-left: 30px;
          font-size: 1.4rem;
        }
        &__link {
          background: url(/assets/images/campfield/cfevent/cfev_icon_timetable.svg) no-repeat left center;
          padding-left: 20px;
        }
      }
    }
    &_recom {
      overflow: visible;
      position: relative;
      &_ul {
      }
      &_li {
        .el_lv6Hd {
          margin: 1.1em 0 .9em;
          font-size: 1.5rem;
          @media #{$pc} {
            font-size: 1.8rem;
          }
        }
      }
      &_btn {
        display: none;
        @media #{$pc} {
          display: block;
        }
        position: absolute;
        left: 0;
        right: 0;
        top: 200px;
        z-index: 2;
        &_prev, &_next {
          position: absolute;
          right: -30px;
          top: 0;
          width: 60px;
          height: 60px;
          background: url(/assets/images/campfield/cfevent/cfev_icon_arrow.svg) no-repeat center white;
          border-radius: 50%;
          background-size: 100%;
          box-shadow: 0 0 12px rgba(0,0,0,.16);
          &.swiper-button-disabled {
            opacity: .5;
          }
        }
        &_prev {
          position: absolute;
          left: -30px;
          right: auto;
          transform: scale(-1, 1);
        }
      }
    }
    &_fee {
      &_ul {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
      &_li {
        text-align: center;
        font-weight: bold;
        border: solid 1px #ddd;
        border-radius: 6px;
        padding: 20px;
        width: calc( calc(100% - 20px) / 3);
        @media #{$pc} {
          width: auto;
          display: flex;
          align-items: center;
          padding: 20px 30px;
        }
        &__2col {
          width: calc( calc(100% - 10px) / 2);
          @media #{$pc} {
            width: auto;
          }
        }
        figure {
          img {
            max-width: 48px;
            margin: auto;
            @media #{$pc} {
              margin-right: 1em;
            }
          }
        }
        p {
          margin-top: .5em;
          font-size: 1.5rem;
          @media #{$pc} {
            margin-top: 0;
          }
          span {
            font-family: $roboto;
          }
        }
        &__plus {
          width: 100%;
          padding: 0;
          border: none;
          @media #{$pc} {
            width: auto;
          }
          figure {
            img {
              width: 20px;
              @media #{$pc} {
                margin-right: 0;
              }
            }
          }
        }
        &__cf {
          width: 100%;
          @media #{$pc} {
            width: auto;
          }
        }
      }
    }
    &_schedule {
      overflow: visible;
      &_year {
        font-family: $roboto;
        font-weight: bold;
        font-size: 1.8rem;
        margin-bottom: .8em;
      }
      &_ul {
      }
      &_li {
        width: auto;
        a {
          display: block;
          border-left: solid 3px #a40000;
          padding: 0 12px;
        }
        &_date {
          font-size: 3.2rem;
          font-family: $roboto;
          font-weight: bold;
          color: black;
          line-height: 1;
          span {
            font-size: 2.1rem;
            margin: 0 .1em;
          }
        }
        &_status {
          display: inline-block;
          color: #a40000;
          font-weight: bold;
          margin-top: .5em;
          line-height: 1.2;
          background: url(/assets/images/campfield/cfevent/cfev_icon_rightarrow_red.svg) no-repeat right center;
          padding-right: 22px;
        }
        &.is_end {
          a {
            border-left-color: #848484;
            pointer-events: none;
          }
          .un_cfEventDetail_schedule_li_date {
            color: #848484;
          }
          .un_cfEventDetail_schedule_li_status {
            color: #848484;
            background: none;
          }
        }
      }
      &_btn {
        margin-top: 20px;
        &_prev, &_next {
          width: 14px;
          height: 24px;
          background: url(/assets/images/campfield/cfevent/cfev_icon_arrow.svg) no-repeat center white;
          &.swiper-button-disabled {
            opacity: .25;
          }
        }
        &_prev {
          transform: scale(-1, 1);
          margin-right: 10px;
        }
      }


    }
    &_other {

    }
    &_timetable {
      &_title {
        font-size: 1.6rem;
        text-align: center;
        img {
          display: inline-block;
          width: 22px;
          height: auto;
          vertical-align: top;
          margin-right: .3em;
        }
        @media #{$pc} {
          font-size: 1.8rem;
        }
      }
      &_table {
        width: 100%;
        border-top: solid 1px #D3D3D3;
        border-bottom: solid 1px #D3D3D3;
        border-collapse: collapse;
        tr {
          th {
            vertical-align: top;
            padding: .5em 2em;
            white-space: nowrap;
            + td {
              font-weight: bold;
            }
          }
          td {
            vertical-align: top;
            padding: .5em;
            &:first-child {
              font-weight: bold;
            }
          }
        }
        &_divide {
          th {
            border-top: solid 1px #D3D3D3;
          }
          td {
            border-top: solid 1px #D3D3D3;
          }
        }
      }
    }
  }
}
.un_cfEventModal {
  @media #{$pc} {
    padding: 50px !important;
  }
}
// event
// legacy css ( spwpremium )
.un_legacy_spw__premium {

  // コロナ対策
  .emergency {
    background: #333;
    padding: 25px;
    max-width: 850px;
    margin: 0 auto 50px auto;
    h2 {
      font-family: $gothic;
      font-weight: bold;
      text-align: center;
      margin: 0 0 15px 0;
    }
    p {
      margin-bottom: 0;
    }
  }
  .contentsAttention {
    font-size: 1.3rem !important;
    font-family: $gothic !important;
    @media #{$pc} {
      text-align: center;
    }
  }

  #blackWrap {
    background: black;
    padding-bottom: 1px;
    h1, h2, h2 span.modelNumber, p {
      color: white;
    }
    .eventFaqInquiry {
      background: dimgray;
    }
    &.detail {
      padding-top: 130px;
      @media #{$pc} {
        padding-top: 148px;
      }
    }
  }

  // イベントメッセージ
  .un_eventMessage {
    color: white;
    .el_lv3Hd {
      margin: 30px 0;
      @media #{$pc} {
        margin: 60px 0;
      }
    }
    &_h2 {
      font-family: 'adobe-garamond-pro', 'EB Garamond', serif;
      font-weight: normal;
      font-size: 12vw;
      letter-spacing: .06em;
      line-height: 1;
      @media #{$pc} {
        font-size: 6rem;
      }
    }
    &_popup {
      color: white;
      font-size: 1.4rem;
      display: inline-block;
      margin-top: 40px;
      text-decoration: underline;
      i {
        opacity: .5;
        font-size: 90%;
        margin-left: .3em;
      }
    }
  }

  // コンテンツ・ワークショップ
  .un_eventContents {
    color: white;
  }

  // 3日間の流れ
  .eventBgSchedulePrm {
    background: url(/assets/images/event/spwpremium/bg_schedule.jpg) no-repeat center center;
    background-size: cover;
    padding-top: 1px;
    padding-bottom: 80px;
    color: white;
    .pageContent {
      margin-bottom: 0;
    }
  }
  .eventBgSchedulePrm {
    background-attachment: fixed;
  }

  // main visual video
  div#spwpMvVideo {
    position: relative;
    margin-bottom: 20vw;
    overflow: hidden;
    a {
      display: none;
      position: absolute;
      bottom: 50px;
      left: 50%;
      z-index: 2;
      margin-left: -13px;
    }
    video {
      position: relative;
      z-index: 1;
      width: auto;
      height: 100vh;
      margin-left: 50%;
      transform: translate(-50%, 0);
    }
    h1.logo {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      //height: calc(160vw / 3 * 2);
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,1) 100%);
      img {
        max-width: 200px;
        height: auto;
      }
    }
  }
  
  div#firstLead {
    padding: 50px 25px 100px;
    h2 {
      font-size: 3rem;
      margin: 0 auto 1.5em;
      max-width: 720px;
      letter-spacing: .1em;
      span {
        display: inline-block;
        background: #222;
        padding: .4em .8em;
      }
    }
    p {
      font-family: $mincho;
      font-size: 1.7rem;
      max-width: 720px;
      margin: 0 auto 1em auto;
    }
  }
  @media #{$pc} {
    div#firstLead {
      h2 {
        font-size: 4.4rem;
      }
      p {
        font-size: 2.2rem;
      }
    }
  }
  
  div#takibiImages {
    margin-top: 100px;
    figure {
      width: calc( 100% / 3 );
      &:nth-child(2) {
        width: calc( 100% / 3 * 2 );
      }
      &:nth-child(11) {
        width: calc( 100% / 3 * 2 );
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  @media #{$pc} {
    div#takibiImages {
      margin-top: 150px;
      figure {
        width: calc( 100% / 5 );
        &:nth-child(2) {
          width: calc( 100% / 5 * 2 );
        }
        &:nth-child(11) {
          width: calc( 100% / 5 * 2 );
        }
        &:last-child {
          display: none;
        }
      }
    }
  }
  
  #pointOne {
    position: relative;
    overflow: hidden;
    div.bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url(/assets/images/event/spwpremium/bg_point1.jpg) no-repeat bottom center;
      background-size: 100% auto;
    }
  }
  #pointTwo {
    position: relative;
    overflow: hidden;
    div.bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url(/assets/images/event/spwpremium/bg_point2.jpg) no-repeat bottom center;
      background-size: 100% auto;
    }
  }
  #pointThree {
    position: relative;
    overflow: hidden;
    div.bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url(/assets/images/event/spwpremium/bg_point3.jpg) no-repeat bottom center;
      background-size: 100% auto;
    }
  }
  
  .pointWrap {
    max-width: 800px;
    margin: auto;
    padding: 100px 0 50vw 0;
    .takibiTalk {
      text-align: center;
      img {
        width: 30%;
        height: 100%;
        max-width: 168px;
      }
      span {
        display: inline-block;
        font-size: 1.6rem;
        font-family: $mincho;
        color: white;
        margin-top: .6em;
      }
    }
    .point {
      text-align: center;
      color: white;
      font-family: $garamond;
      font-size: 3rem;
      letter-spacing: .1em;
      margin-top: 1.3em;
      span {
        margin-left: .1em;
        font-size: 4rem;
      }
    }
    h2 {
      font-size: 2.4rem;
      text-align: center;
      margin-top: 1.5em;
    }
    p {
      font-size: 1.6rem;
      font-family: $mincho;
    }
  }
  @media #{$pc} {
    .pointWrap {
      padding-top: 150px;
      .takibiTalk {
        span {
          font-size: 2rem;
        }
      }
      .point {
        font-size: 4rem;
        span {
          font-size: 5rem;
        }
      }
      h2 {
        font-size: 2.8rem;
      }
      p {
        font-size: 2rem;
      }
    }
  }
  
  
  div.novelty {
    width: 100%;
    max-width: 300px;
    margin-top: 1em;
    img {
      width: 100%;
      height: auto;
    }
    span {
      display: block;
      text-align: center;
      font-size: 1.3rem;
      margin-top: 1em;
    }
  }
  
  
  // override
  #breadCrumb.eventVisual {
    color: white;
    a {
      color: white;
    }
  }
  div#blackWrap {
    .pageContent {
      p {
        small {
          display: inline-block;
          font-size: 75%;
          line-height: 2em;
          &.attention {
            margin-top: .5em;
            font-family: $gothic;
            text-indent: -1em;
            padding-left: 1em;
          }
        }
        &.changed {
          display: inline-block;
          font-weight: bold;
          line-height: 1.6em;
          color: black;
          background: orange;
          padding: .3em .6em;
        }
      }
      h3 {
        color: white;
        font-size: 1.8rem;
        @media #{$pc} {
          font-size: 2rem;
        }
      }
      h4 {
        color: white;
      }
      div.table {
        table {
          thead {
            th {
              color: white;
            }
          }
          tbody {
            th {
              color: white;
            }
          }
          td {
            color: white;
            a {
              color: white;
            }
            figure {
              width: 100%;
              height: auto;
              margin: 1.5em 0;
              img {
                width: 100%;
                height: auto;
              }
              span {
                margin-top: .5em;
                display: block;
                text-align: center;
                font-size: 1.2rem;
                font-weight: bold;
              }
            }
            p.sup {
              text-indent: -1em;
              padding-left: 1em;
            }
          }
        }
      }
    }
    .eventPhotoLinks {
      li {
        a {
          h4 {
            background: url(/assets/images/event/spwpremium/icon_rightangle.svg) no-repeat right 75%;
          }
        }
      }
    }
  }
  @media #{$pc} {
    div#blackWrap {
      .pageContent {
        div.table {
          table {
            td {
              figure {
                max-width: 500px;
              }
            }
          }
        }
      }
    }
  }
  h2.eventSection {
    span {
      color: #999;
    }
  }
  h3.eventDayTitle {
    border: solid 1px gainsboro;
  }
  .eventPlaceList {
    li {
      a {
        h3 {
          background: url(/assets/images/event/spwpremium/icon_rightangle.svg) no-repeat right 75%;
        }
        p {
          &.subscription {
            letter-spacing: -.05em;
            span {
              color: white;
            }
          }
        }
      }
    }
  }
  .eventBgSchedule {
      background: url(/assets/images/event/spwpremium/2020/bg_schedule.jpg) no-repeat center center;
      background-size: cover;
  }
  .eventTimeSchedule {
    li {
      h4 {
        span {
          color: gainsboro;
        }
      }
    }
  }
  .eventFaq {
    li {
      //border-bottom: solid 1px dimgray;
      p {
        span {
          color: gainsboro !important;
        }
        a {
          color: white !important;
        }
      }
    }
  }
  #blackWrap {
    .pageContent {
      .eventAttention h3, p.attention {
        color: #f60;
      }
    }
  }
  #blackWrap .pageContent ul.pageLinks {
    @media #{$pc} {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    li {
      @media #{$pc} {
        margin: 0 1em;
      }
      a {
        @media #{$pc} {
          padding: 10px 0;
        }
      }
    }
  }
  #blackWrap .pageContent .eventAttention a {
    color: white;
  }
  
  #eventVisual {
    margin-top: 0;
    &.spwDetail {
      background: url(/assets/images/event/spwpremium/mv_spwp2018_detail.jpg) no-repeat center center;
      background-size: cover;
    }
  }

  @media #{$pc} {
    .eventTimeScheduleWrap {
      .eventTimeScheduleColumn {
        width: calc(100% / 3);
        padding-left: 15px;
        padding-right: 15px;
        &:nth-child(2) {
          border-right: solid 1px gainsboro;
        }
      }
    }
    .eventBgSchedule {
        background-attachment: fixed;
    }
    .eventPlaceList {
      li {
        a {
          h3 {
            font-size: 2rem;
          }
          p {
            font-size: 1.6rem;
            &.subscription {
              letter-spacing: 0;
              font-size: 1.4rem;
            }
            &.ended {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
    .eventTimeSchedule {
      li {
        flex-direction: column;
        width: 100%;
        h4 {
          span {
            color: gainsboro;
          }
        }
        div {
          width: 100%;
        }
        div:first-child {
          order: 2;
        }
        div:last-child {
          order: 1;
          margin-bottom: 1.5em;
        }
      }
    }
    .eventPlaceList {
      //-justify-content: space-around;
      li {
        width: calc(100% / 2 - 35px);
      }
      &:after {
        display: none;
      }
      &__two {
        justify-content: space-around !important;
        li {
          width: calc(100% / 2 - 35px) !important;
        }
        &:after {
          display: none !important;
        }
      }
    }
    #eventVisual {
      margin-top: 0;
    }
  }
  
  
  // order
  #rankCheck {
    text-align: center;
    p {
      text-align: left;
      font-size: 1.6rem;
      margin-bottom: 1em;
      padding-left: 0;
      padding-right: 0;
    }
    label {
      font-size: 1.6rem;
      display: inline-block;
      margin-bottom: 1.5em;
      color: white;
      padding: 1em 1.5em;
      border: solid 1px white;
      input {
        margin-right: .3em;
        border: solid 1px gray;
        border-radius: 3px;
      }
    }
    a {
      background: #222222;
      pointer-events: none;
      transition: background linear .1s;
      &.checked {
        pointer-events: auto;
        background: silver;
      }
    }
  }
  @media #{$pc} {
    #rankCheck {
      p {
        text-align: center;
        font-size: 2rem;
      }
    }
  }
  
  
  // kyoto price & diamond
  #forDiamond, #forSapphireBlack, #kyotoSupplement {
    margin: 50px auto 0 auto;
    padding: 0 25px;
    div.wrap {
      border: solid 4px white;
      margin: auto;
      max-width: 980px;
      h3 {
        text-align: center;
        padding: 1em;
      }
      h4 {
        margin: 0 25px 1em 25px;
        padding: 0;
        text-align: center;
        font-size: 1.6rem;
        line-height: 1.6em;
        padding-top: 2em;
        border-top: gray solid 1px;
      }
      h5 {
        margin: 0 25px 1.5em 25px;
        padding: 0;
        text-align: center;
        color: white;
        font-family: $gothic;
        padding-bottom: 2em;
        border-bottom: gray solid 1px;
        line-height: 2em;
        span {
          &.price {
            font-size: 2.1rem;
            margin: 0 .2em;
          }
        }
      }
      p {
        a {
          i {
            margin-left: .3em;
            color: black;
          }
        }
        &.kyotoPriceShow {
          text-align: center;
          a {
            color: white;
            text-decoration: underline;
            cursor: pointer;
            i {
              color: white;
              width: auto;
              margin-left: 0;
              font-size: 1.8rem;
            }
          }
        }
        &.noReserve {
          span {
            display: block;
            text-indent: -1em;
            padding-left: 1em;
          }
        }
      }
      div.kyotoPriceTable {
        padding: 0 25px 25px 25px;
        table {
          width: 100%;
          color: white;
          caption {
            margin-bottom: 1em;
          }
          tr {
            font-size: 1rem;
            th {
              border-bottom: solid 1px gray;
              font-weight: normal;
              background: #222;
              padding: 1em .2em;
            }
            td {
              border-bottom: solid 1px gray;
              text-align: center;
              padding: 1em .2em;
            }
          }
        }
        p {
          padding: 0;
          margin: 2em 0 0 0;
        }
      }
    }
    &.disable {
      opacity: .25;
      pointer-events: none;
    }
  }
  @media #{$pc} {
    #forDiamond, #forSapphireBlack, #kyotoSupplement {
      margin: 100px auto 0 auto;
      div.wrap {
        padding: 0 25px 25px 25px;
        h3 {
          font-size: 2.1rem;
        }
        h4 {
          font-size: 1.8rem;
        }
        h5 {
          span {
            &.price {
              font-size: 2.4rem;
            }
          }
        }
        div.kyotoPriceTable {
          table {
            tr {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
  #forDiamond, #forSapphireBlack {
    div.wrap {
      background: dimgray;
      h5 {
        margin: 2em 25px 1.5em 25px;
        padding: .3em .3em .5em .3em;
        border: solid 1px white;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.5;
        @media #{$pc} {
          font-size: 1.8rem;
        }
      }
      > p {
        padding-left: 25px;
        padding-right: 25px;
      }
      p {
        a.btn {
          font-size: 1.4rem;
          &.inv {
            background: #bfb772;
          }
          &.pri {
            background: #909dc3;
          }
          @media #{$pc} {
            font-size: 1.5rem;
          }
        }
      }
      hr {
        margin: 2em 25px;
        border-top: none;
        border-bottom: solid 1px gray;
      }
    }
  }
  #forDiamondLabel {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 1002;
    h2 {
      margin: 0;
      padding: 1em;
      text-align: center;
      background: #696969;
      font-size: 1.2rem;
      box-shadow: 0 0 10px rgba(0,0,0,.2);
      @media #{$pc} {
        padding: 1.3em;
        font-size: 1.5rem;
      }
      a {
        margin-left: .5em;
        color: white;
        text-decoration: underline;
        font-family: $gothic;
        font-size: 90%;
      }
    }
  }
  
  
  // special meeting
  div#blackWrap {
    &.spmtg {
      padding-top: 200px;
      span.spLogo {
        display: block;
        text-align: center;
        img {
          width: 100px;
          height: auto;
        }
      }
      h1.logo {
        text-align: center;
        padding-top: 25px;
        margin-bottom: 100px;
        img {
          max-width: 500px;
          width: 100%;
          height: auto;
        }
      }
      h2.first {
        margin-bottom: 4em;
      }
      p.first {
        font-family: $mincho;
      }
      h3.spmtgNotes {
        font-size: 1.8rem;
        margin: 8em 0 3em 0;
        text-align: center;
      }
      div.spmtgVenue {
        margin-bottom: 3em;
        text-align: center;
        img {
          max-width: 750px;
          width: 100%;
          height: auto;
        }
      }
      ul.spmtgNotes {
        list-style: none;
        a {
          color: white;
          text-decoration: underline;
        }
        li {
          p {
            span.strong {
              font-size: 1.8rem;
              font-weight: bold;
              text-decoration: underline;
            }
          }
          &.inquiry {
            p {
              span {
                display: block;
                text-align: center;
                padding: 1em;
                background: #111;
              }
            }
          }
        }
      }
    }
  }
  @media #{$pc} {
    div#blackWrap {
      &.spmtg {
        span.spLogo {
        }
        h1.logo {
          margin-bottom: 150px;
        }
        h2.first {
          text-align: center;
        }
        p.first {
          text-align: center;
          font-size: 1.5rem;
        }
      }
    }
  }
  
  
  // stop announcement
  #stopAnnouncement {
    padding: 70px 25px 0 25px;
    max-width: 1000px;
    margin: 0 auto 25px auto;
    div {
      background: white;
      color: black;
      padding: 1.5em;
      h3 {
        font-size: 1.8rem;
        margin: 2em 0;
        line-height: 1.6em;
      }
      h4 {
        font-size: 1.6rem;
        font-weight: normal;
        margin-bottom: 2em;
        line-height: 1.6em;
      }
      p {
        color: black;
        margin-bottom: 1em;
        a {
          color: black;
          i {
            margin: 0 .3em;
            color: gray;
          }
        }
        &.indent {
          text-indent: -1em;
          padding-left: 1em;
        }
        &.right {
          text-align: right;
        }
      }
    }
  }
  @media #{$pc} {
    #stopAnnouncement {
      div {
        padding: 3em;
        h3, h4 {
          text-align: center;
        }
      }
    }
  }
  #modal .attention .content {
    &.preModalTxt p {
      color: black;
    }
  }
  
  
  // future session takibi talk
  h3.fstH {
    text-align: center;
    color: white;
    font-family: $garamond;
    font-size: 2.8rem;
    letter-spacing: .05em;
    line-height: 0.8em;
    margin: 3em 0 2em 0;
    span {
      display: inline-block;
      width: 40px;
      height: 1px;
      background: white;
    }
  }
  @media #{$pc} {
    h3.fstH {
      font-size: 3.6rem;
      span {
        width: 60px;
      }
    }
  }
  #spwpFstTitle {
    div.fstMv {
      position: relative;
      width: 100%;
      height: 90vh;
      background: url(/assets/images/event/spwpremium/fst_mv.jpg) no-repeat center;
      background-size: cover;
      span {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,1) 100%);
      }
    }
    div.fstLogo {
      position: relative;
      z-index: 2;
      margin-top: -100px;
      text-align: center;
      img {
        width: 120px;
        height: auto;
      }
    }
    h1 {
      text-align: center;
      padding-left: 25px;
      padding-right: 25px;
      font-family: $garamond;
      font-weight: normal;
      font-size: 3rem;
      letter-spacing: .05em;
      line-height: 1.2em;
      margin: 1.5em 0;
    }
  }
  @media #{$pc} {
    #spwpFstTitle {
      div.fstMv {
        height: 80vh;
      }
      div.fstLogo {
        margin-top: -150px;
        img {
          width: 200px;
        }
      }
      h1 {
        font-size: 4rem;
        letter-spacing: .1em;
      }
    }
  }
  div#fstFirstLead {
    p {
      font-family: $mincho;
      font-size: 1.7rem;
      max-width: 820px;
      margin: 0 auto 1em auto;
    }
  }
  @media #{$pc} {
    div#fstFirstLead {
      margin-bottom: 100px;
      p {
        font-size: 2.2rem;
      }
    }
  }
  #fstCrowdfunding {
    padding: 50px 0;
    h2 {
      text-align: center;
      font-size: 1.4rem;
      font-family: $mincho;
      font-weight: normal;
      line-height: 3em;
      margin: 0 0 3em 0;
      span {
        display: block;
        font-family: $garamond;
        font-size: 4rem;
        letter-spacing: .05em;
        line-height: 1.4em;
      }
    }
    h3 {
      text-align: center;
      color: white;
      font-family: $garamond;
      font-size: 2.8rem;
      letter-spacing: .05em;
      line-height: 0.8em;
      margin: 3em 0 2em 0;
      span {
        display: inline-block;
        width: 40px;
        height: 1px;
        background: white;
      }
    }
    p {
      font-family: $mincho;
      font-size: 1.7rem;
      max-width: 820px;
      margin: 0 auto 1em auto;
    }
    ul.itemLists {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 25px;
      li {
        width: 100%;
        margin-bottom: 50px;
        a {
          display: block;
        }
        div {
          img {
            width: 100%;
            height: auto;
          }
          span {
            display: block;
            text-align: center;
            font-family: $garamond;
            color: white;
            font-size: 6rem;
            line-height: 1em;
            margin-top: -.55em;
            margin-bottom: .3em;
          }
        }
        p {
          text-align: center;
          font-family: $gothic;
          font-size: 1.4rem;
          padding: 0;
          line-height: 1.2em;
          margin: .5em 0;
        }
        h4 {
          text-align: center;
          font-family: $gothic;
          font-size: 1.6rem;
          font-weight: bold;
          padding: 0;
          color: white;
          line-height: 1.2em;
          margin: .5em 0;
        }
      }
    }
    ul.items {
      margin-top: 50px;
      > li {
        margin-top: 100px;
        div.mv {
          img {
            width: 100%;
            height: auto;
          }
        }
        div.detail {
          padding: 0 25px;
          div.itemName {
            display: flex;
            margin: 40px 0;
            span {
              width: 1em;
              font-family: $garamond;
              color: white;
              font-size: 6rem;
              line-height: 1em;
            }
            div {
              p {
                font-family: $gothic;
                font-size: 1.4rem;
                padding: 0;
                line-height: 1.2em;
                margin: .7em 0 .5em 0;
              }
              h4 {
                font-family: $gothic;
                font-size: 1.6rem;
                font-weight: bold;
                padding: 0;
                color: white;
                line-height: 1.2em;
                margin: 0;
              }
            }
          }
          div.story {
            p {
              padding: 0;
              margin-bottom: 40px;
            }
          }
          ul.scene {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 40px;
            li {
              width: 100%;
              margin-bottom: 16px;
              img {
                width: 100%;
                height: auto;
              }
              &.half {
                width: calc(100% / 2 - 8px)
              }
            }
          }
          ul.function {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              margin-bottom: 40px;
              img {
                width: 100%;
                height: auto;
              }
              h5 {
                font-family: $gothic;
                font-weight: normal;
                padding: 0;
                color: white;
                margin: 1em 0 0 0;
              }
            }
          }
        }
      }
    }
    div.crowdlink {
      padding: 0 25px;
      a {
        font-size: 1.5rem;
        background: white;
      }
    }
  }
  @media #{$pc} {
    #fstCrowdfunding {
      padding: 100px 0;
      h2 {
        font-size: 1.6rem;
        margin: 0 0 4em 0;
        span {
          font-size: 6rem;
          letter-spacing: .1em;
        }
      }
      h3 {
        font-size: 3.6rem;
        span {
          width: 60px;
        }
      }
      p {
        font-size: 2.2rem;
      }
      ul.itemLists {
        li {
          width: calc(100% / 2 - 25px);
        }
      }
      ul.items {
        margin-top: 100px;
        > li {
          margin-top: 150px;
          div.mv {
            padding: 0 25px;
          }
          div.detail {
            div.itemName {
              margin: 40px 0;
              span {
                font-size: 8rem;
              }
              div {
                p {
                  font-size: 1.8rem;
                }
                h4 {
                  font-size: 2rem;
                }
              }
            }
            div.story {
              p {
                margin-bottom: 80px;
                max-width: none;
              }
            }
            ul.scene {
              li {
                margin-bottom: 50px;
                &.half {
                  width: calc(100% / 2 - 25px)
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
            ul.function {
              li {
                width: calc(100% / 2 - 30px);
                margin-bottom: 40px;
              }
            }
          }
        }
      }
      div.crowdlink {
        margin-top: 60px;
        a {
          font-size: 1.8rem;
        }
      }
    }
  }
  div#fstOthervoices {
    border-top: solid 1px black;
    p {
      font-family: $mincho;
      font-size: 1.7rem;
      max-width: 820px;
      margin: 0 auto 1em auto;
    }
    ul.voicesAndAnswer {
      max-width: 820px;
      margin: auto;
      padding: 0 25px;
      list-style: none;
      li {
        position: relative;
        border: solid 2px #404040;
        border-radius: 15px;
        padding: 1em 0;
        p {
          color: white;
          font-family: $mincho;
          font-size: 1.6rem;
          line-height: 1.8em;
          margin: 0;
        }
        &.voice {
          margin-top: 6em;
          margin-right: 6vw;
          span {
            position: absolute;
            top: -.6em;
            right: 25px;
            color: white;
            font-size: 2.4rem;
            font-family: $garamond;
            letter-spacing: .05em;
            content: "Voice";
          }
        }
        &.answer {
          margin-top: 3em;
          margin-left: 6vw;
          span {
            position: absolute;
            top: -.6em;
            left: 25px;
            color: white;
            font-size: 2.4rem;
            font-family: $garamond;
            letter-spacing: .05em;
            content: "Answer";
          }
        }
        &.voice:after,
        &.voice:before,
        &.answer:after,
        &.answer:before {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &.answer:after,
        &.answer:before {
          left: 100%;
        }
        &.voice:after {
          border-color: rgba(136, 183, 213, 0);
          border-right-color: black;
          border-width: 8px;
          margin-top: -8px;
        }
        &.voice:before {
          border-color: rgba(194, 225, 245, 0);
          border-right-color: #404040;
          border-width: 11px;
          margin-top: -11px;
        }
        &.answer:after {
          border-color: rgba(136, 183, 213, 0);
          border-left-color: black;
          border-width: 8px;
          margin-top: -8px;
        }
        &.answer:before {
          border-color: rgba(194, 225, 245, 0);
          border-left-color: #404040;
          border-width: 11px;
          margin-top: -11px;
        }
      }
    }
  }
  @media #{$pc} {
    div#fstOthervoices {
      p {
        font-size: 2.2rem;
      }
      ul.voicesAndAnswer {
        li {
          p {
            font-size: 2rem;
          }
          &.voice,
          &.answer {
            span {
              font-size: 2.8rem;
            }
          }
        }
      }
    }
  }
  #fstTothefuture {
    border-top: solid 1px black;
    p {
      font-family: $mincho;
      font-size: 1.7rem;
      max-width: 820px;
      margin: 0 auto 1em auto;
    }
    div.fstLogo {
      text-align: center;
      margin: 100px 0 200px 0;
    }
  }
  @media #{$pc} {
    div#fstTothefuture {
      p {
        font-size: 2.2rem;
      }
    }
  }
  
  .eventSponsor {
    max-width: 880px;
    margin: 0 auto 100px;
  }
  
  // inview showAnimationDuration
  .inviewfadeIn {
    opacity: 0;
    filter: blur(10px);
    transition: opacity 3s, filter 1.5s;
  }
  .inviewfadeUp {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 3s, transform 2s, filter 1.5s;
  }
  .fadeIn {
    opacity: 1.0;
    filter: blur(0);
    transform: translateY(0);
  }

  .inviewfadeIn {
    &.logo {
      transform: scale(1.5);
      transition: opacity 8s, filter 4s, transform 10s ease-out;
    }
    &.slow {
      transform: scale(1.25);
      transition: opacity 8s, filter 4s, transform 10s ease-out;
    }
    &.up {
      filter: blur(0);
      transform: translateY(30px);
      transition: opacity 3s, transform 2s;
    }
  }
  .fadeIn {
    &.logo, &.slow {
      transform: scale(1);
    }
    &.up {
      transform: translateY(0);
    }
  }
  
  // 2020 point
  .point123Wrap {
    max-width: 1230px;
    margin: auto;
    padding: 100px 0 0;
    .takibiTalk {
      text-align: center;
      img {
        display: inline;
        width: 30%;
        height: 100%;
        max-width: 168px;
      }
      span {
        display: inline-block;
        font-size: 1.6rem;
        font-family: $mincho;
        color: white;
        margin-top: .6em;
      }
    }
    .point123Title {
      padding: 0 20px;
      font-size: 1.8rem;
    }
    ul {
      padding: 0;
      width: 100%;
      list-style: none;
      li {
        img {
          width: 100%;
        }
      }
    }
    .point {
      text-align: center;
      color: white;
      font-family: $garamond;
      font-size: 3rem;
      letter-spacing: .1em;
      margin-top: 1.3em;
      span {
        margin-left: .1em;
        font-size: 4rem;
      }
    }
    h2 {
      font-size: 2.4rem;
      text-align: center;
      margin: 1.5em 0 1em;
    }
    p {
      font-size: 1.6rem;
      font-family: $mincho;
      padding-left: 25px;
      padding-right: 25px;
      margin: 2em 0 6em;
    }
  }
  @media #{$pc} {
    .point123Wrap {
      .takibiTalk {
        margin-bottom: 64px;
        span {
          font-size: 2rem;
        }
      }
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        li {
          width: calc(100% / 3 - 25px);
          img {
            margin-bottom: 3em;
          }
        }
      }
      .point {
        font-size: 4rem;
        span {
          font-size: 5rem;
        }
      }
      h2 {
        font-size: 2.5rem;
        padding-left: 0;
        padding-right: 0;
      }
      p {
        font-size: 1.9rem;
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
      }
    }
  }
  // 2020 top navi
  #eventTopNavi {
    ul.spwp {
      li{
        box-shadow: 0 0 5px rgb(255, 255, 255);
        a{
          color: #fff;
          font-family: $mincho;
        }
      }
    }
  }
  // 2020 time schedule
  #blackWrap {
    .pageContent {
      ul.eventTimeSchedule2nd {
        h4 {
          color: #000;
        }
        p {
          color: gray;
        }
      }
    }
  }
  // 2020 eco cup
  #blackWrap {
    /*.eventBgEcocup {
      background: #000;
    }*/
    .eventEcocup {
      /*h2 {
        color: #000;
      }*/
      .mag {
        h3 {
          //-color: #2b9b2b;
          color: #000;
        }
        h4,p {
          color: #000;
        }
      }
    }
  }
  // 2020 bring
  #blackWrap {
    .eventBringWrap {
      //background-color: #fff;
      .pageContent {
        //background-color: #fff;
        color: #000;
        &.bringWrap {
          padding: 0 0 100px;
          @media #{$pc} {
            padding: 50px 0 80px;
          }
        }
        .eventBring {
          margin: 0;
        }
        /*h2,h3,h4,p {
          color: #000;
        }*/
        div.table {
          table {
            thead {
              th {
                color: black;
              }
            }
            tbody {
              th {
                color: black;
              }
            }
            td {
              color: black;
              a {
                color: black;
              }
            }
          }
        }
      }
    }
  }
  // 2020 tshirt
  #blackWrap {
    .itemTshirt {
      background: #191919;
      padding: 100px 25px 30px;
      .pageContent {
        .eventTshirt {
          padding: 0;
          h2 {
            text-align: center;
            font-size: 2.2rem;
            letter-spacing: .1em;
            text-indent: .1em;
            line-height: 1.6em;
            margin: 0 0 2em;
          }
          figure {
            max-width: 960px;
            margin: 0 auto 3em;
            padding: 0 25px;
            img {
              width: 100%;
            }
          }
          p {
            text-align: left;
            line-height: 2em;
            margin-bottom: 1.5em;
            font-weight: 700;
            span {
              font-weight: 400;
              font-size: 1.2rem;
              display: inline-block;
              padding-left: 1em;
              text-indent: -1em;
              line-height: 2.4rem;
              padding-top: .8rem;
            }
            a {
              display: inline-block;
              text-decoration: underline;
              i {
                margin-left: .2em;
              }
              &.textBtn {
                margin: 1em 0 0;
                border: solid 1px #dcdcdc;
                background: #f5f5f5;
                padding: .6em .8em;
                text-decoration: none;
                width: 100%;
                text-align: center;
                max-width: 100%;
                line-height: 2rem;
                @media #{$pc} {
                  max-width: 580px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media #{$pc} {
    #blackWrap {
      .itemTshirt {
        .pageContent {
          .eventTshirt {
            padding: 0 25px;
            h2 {
              text-align: center;
              font-size: 3.2rem;
              letter-spacing: .1em;
              text-indent: .1em;
              line-height: 1.6em;
              margin: 1em 0 2em;
            }
            figure {
              margin: 0 auto 5em;
              padding: 0;
            }
            p {
              text-align: center;
              font-size: 1.8rem;
              font-weight: 700;
              span {
                font-weight: 400;
                font-size: 1.2rem;
                display: inline-block;
                padding-left: 1em;
                text-indent: -1em;
                line-height: 2.4rem;
                padding-top: .8rem;
              }
            }
          }
        }
      }
    }
  }
  // 2020 attention
  #blackWrap {
    .pageContent {
      color: #fff;
      .eventAttention {
        button {
        color: white;
        }
        table tr:first-child th {
          color: black;
        }
      }
    }
  }
  // 2020 Entry Button
  #blackWrap {
    .eventEntryBtn {
      margin: 30px 0 20px 0;
    }
    // rankCheck
    .rankCheck,
    .rankCheckSapphire {
      text-align: center;
      p {
        text-align: left;
        font-size: 1.6rem;
        margin-bottom: 1em;
        padding-left: 0;
        padding-right: 0;
      }
      label {
        font-size: 1.6rem;
        display: inline-block;
        margin-bottom: 0;
        color: white;
        padding: 1em 1.5em;
        border: solid 1px white;
        input {
          margin-right: .3em;
          border: solid 1px gray;
          border-radius: 3px;
        }
      }
      a {
        pointer-events: none;
        transition: background linear .1s;
        &.checked {
          pointer-events: auto;
        }
      }
    }
    .rankCheck {
      a {
        background: #4e4e4e;
        &.checked {
          background: silver;
        }
      }
    }
    .rankCheckSapphire {
      a {
        background: #4e4e4e;
        &.checked {
          background: #909dc3;
        }
      }
    }
    @media #{$pc} {
      .rankCheck,
      .rankCheckSapphire {
        p {
          text-align: center;
          font-size: 2rem;
        }
        label {
          margin-bottom: 1.5em;
        }
      }
    }
  }
  // 2020 modal
  #modal .content p {
    color: black;
  }
  
  // modal dialog
  #modalOverlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10001;
    background-color: rgba(0, 0, 0, .65);
  }
  #modal {
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 10002;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    overflow: auto;
    @media #{$pc} {
      max-height: 95vh;
    }
    button {
      position: fixed;
      right: .6em;
      top: .4em;
      color: gainsboro;
      font-size: 1.8rem;
      cursor: pointer;
    }
    div.sizechart {
      h3 {
        text-align: center;
        font-size: 1.4rem;
        padding: 10px;
        line-height: 1.6em;
        background: #292929;
        color: white;
        @media #{$pc} {
          font-size: 1.8rem;
        }
      }
      figure {
        background: #ebebeb;
        img {
          display: block;
          margin: auto;
          width: 100%;
          height: auto;
        }
      }
      div.content {
        padding: 15px;
        p {
          font-size: 1.2rem;
          line-height: 1.6em;
          margin-top: 1em;
          &.comming {
            @media #{$pc} {
              text-align: center;
            }
          }
          &:first-child {
            margin-top: 0;
          }
          @media #{$pc} {
            font-size: 1.4rem;
          }
        }
        h4 {
          font-size: 1.2rem;
          line-height: 1.6em;
          margin-top: 1em;
          text-align: center;
          &.comming {
            font-size: 1.6rem;
            color: gray;
            padding: 1em;
          }
          @media #{$pc} {
            font-size: 1.4rem;
          }
        }
        table {
          font-size: 1.2rem;
          line-height: 1.6em;
          margin-top: 1em;
          width: 100%;
          border-top: solid 1px gainsboro;
          @media #{$pc} {
            font-size: 1.4rem;
          }
          tr {
            th {
              text-align: left;
              border-bottom: solid 1px gainsboro;
              padding: .3em;
              background: whitesmoke;
            }
            td {
              border-bottom: solid 1px gainsboro;
              padding: .3em;
            }
          }
        }
      }
    }
    div.attention {
      padding: 15px;
      h3 {
        text-align: center;
        font-size: 1.6rem;
        font-family: $mincho;
        font-weight: normal;
        padding: 10px;
        line-height: 1.6em;
        span {
          border-bottom: solid 2px black;
          padding-bottom: .2em;
        }
        @media #{$pc} {
          font-size: 2.1rem;
        }
      }
      div.content {
        padding: 15px;
        p {
          font-size: 1.2rem;
          line-height: 1.6em;
          margin-top: 1em;
          &:first-child {
            margin-top: 0;
          }
          @media #{$pc} {
            font-size: 1.4rem;
          }
        }
        div.attentionCheck {
          text-align: center;
          margin-top: 1em;
          font-size: 1.2rem;
          @media #{$pc} {
            font-size: 1.4rem;
          }
          label {
            display: inline-block;
            border: solid 1px gainsboro;
            padding: .4em 1em;
            input {
              margin-right: .5em;
            }
          }
          a {
            display: inline-block;
            color: black;
            margin-top: 1em;
            text-decoration: none;
            i {
              opacity: .5;
            }
          }
        }
      }
    }
  }
  .active {
    display: block !important;
  }

}

// rank alert popup
#rankModalTrig,
#rankModal {
  display: none;
}
.rankModal {
  text-align: center;
  &Desc {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.8;
    @media #{$pc} {
      font-size: 1.6rem;
    }
  }
  &Link {
    display: inline-block;
    border: solid 1px gray;
    border-radius: 2em;
    padding: .4em 1.5em;
    font-size: 1.3rem;
    line-height: 1.4;
    margin-top: 1.2em;
    @media #{$pc} {
      font-size: 1.4rem;
    }
  }
  &Close {
    display: inline-block;
    background: #333;
    border-radius: 2em;
    padding: .4em 1.5em;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.4;
    margin-top: 1.2em;
    color: white;
    cursor: pointer;
    transition: opacity ease .3s;
    &:hover {
      opacity: .75;
    }
    @media #{$pc} {
      font-size: 1.4rem;
    }
  }
}
.modaal-overlay {
  z-index: 9998 !important;
}
.modaal-container {
  max-width: 600px !important;
}
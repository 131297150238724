/* ==========================================================
 overflow control
========================================================== */
.hp_overfHide {
  overflow: hidden;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.86);
    z-index: 1000;
    backdrop-filter: blur(4px);
  }
}

/* ==========================================================
 font color
========================================================== */
.hp_fW {
  color: white;
}
.hp_fDg {
  color: dimgray;
}

/* ==========================================================
 font weight
========================================================== */
.hp_fBld {
  font-weight: bold;
}


/* ==========================================================
 background color
========================================================== */
.hp_bgcBlack {
  background-color: black;
}
.hp_bgcWhite {
  background-color: white;
}
.ly_header.white + .hp_bgcWhite {
  padding-top: 80px;
  @media #{$pc} {
    padding-top: 90px;
  }
}
.ly_header.white + .hp_bgcBlack {
  padding-top: 130px;
  @media #{$pc} {
    padding-top: 145px;
  }
}
.hp_bgcWhitesmoke {
  background-color: #f7f7f7;
}
.hp_bgcLightgray {
  background-color: #f5f5f5;
}
.hp_bgcIvory {
  background-color: #fafaf2;
}
.hp_bgcGreen {
  background-color: #00362f;
}

/* ==========================================================
 background image
========================================================== */
.hp_bgImage {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 480px;
  @media #{$pc} {
    height: 580px;
  }
}
.hp_bgFixImage {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  @media #{$pc} {
    background-attachment: fixed;
  }
  &__darken {
    background-color:rgba(0,0,0,0.7);
    background-blend-mode: darken;
    &[data-scroll] {
      background-color:rgba(0,0,0,1);
      transition: background-color 5s;
    }
    &[data-scroll="in"] {
      background-color:rgba(0,0,0,0.7);
    }
  }
}

/* ==========================================================
 note
========================================================== */
.hp_note {
  display: inline-block;
  color: #818181;
  margin-top: .5em;
}

/* ==========================================================
 margin
========================================================== */
.hp_mt0 {
  margin-top: 0;
  @media #{$pc} {
    margin-top: 0;
  }
}
.hp_mt0-200 {
  margin-top: 0;
  @media #{$pc} {
    margin-top: 200px;
  }
}
.hp_mb0 {
  margin-bottom: 0;
}
.hp_mt5 {
  margin-top: 5px;
}
.hp_mt10-15 {
  margin-top: 10px;
  @media #{$pc} {
    margin-top: 15px;
  }
}
.hp_mt10-20 {
  margin-top: 10px;
  @media #{$pc} {
    margin-top: 20px;
  }
}
.hp_mt20 {
  margin-top: 20px;
  @media #{$pc} {
    margin-top: 30px;
  }
}
.hp_mt24-38 {
  margin-top: 24px;
  @media #{$pc} {
    margin-top: 38px;
  }
}
.hp_mt24-48 {
  margin-top: 24px;
  @media #{$pc} {
    margin-top: 48px;
  }
}
.hp_mt24-56 {
  margin-top: 24px;
  @media #{$pc} {
    margin-top: 56px;
  }
}
.hp_mt24-80 {
  margin-top: 24px;
  @media #{$pc} {
    margin-top: 80px;
  }
}
.hp_mt30 {
  margin-top: 30px;
  @media #{$pc} {
    margin-top: 50px;
  }
}
.hp_mt32-48 {
  margin-top: 32px;
  @media #{$pc} {
    margin-top: 48px;
  }
}
.hp_mt32-74 {
  margin-top: 32px;
  @media #{$pc} {
    margin-top: 74px;
  }
}
.hp_mt32-80 {
  margin-top: 32px;
  @media #{$pc} {
    margin-top: 80px;
  }
}
.hp_mt32-104 {
  margin-top: 32px;
  @media #{$pc} {
    margin-top: 104px;
  }
}
.hp_mt40 {
  margin-top: 40px;
  @media #{$pc} {
    margin-top: 70px;
  }
}
.hp_mt40-48 {
  margin-top: 40px;
  @media #{$pc} {
    margin-top: 48px;
  }
}
.hp_mt40-100 {
  margin-top: 40px;
  @media #{$pc} {
    margin-top: 100px;
  }
}
.hp_mt48-64 {
  margin-top: 48px;
  @media #{$pc} {
    margin-top: 64px;
  }
}
.hp_mt50 {
  margin-top: 50px;
  @media #{$pc} {
    margin-top: 90px;
  }
}
.hp_mt60 {
  margin-top: 60px;
  @media #{$pc} {
    margin-top: 110px;
  }
}
.hp_mt56-64 {
  margin-top: 56px;
  @media #{$pc} {
    margin-top: 64px;
  }
}
.hp_mt56-72 {
  margin-top: 56px;
  @media #{$pc} {
    margin-top: 72px;
  }
}
.hp_mt56-88 {
  margin-top: 56px;
  @media #{$pc} {
    margin-top: 88px;
  }
}
.hp_mt56-100 {
  margin-top: 56px;
  @media #{$pc} {
    margin-top: 100px;
  }
}
.hp_mt48-136 {
  margin-top: 48px;
  @media #{$pc} {
    margin-top: 136px;
  }
}
.hp_mt60-140 {
  margin-top: 60px;
  @media #{$pc} {
    margin-top: 140px;
  }
}
.hp_mt70-140 {
  margin-top: 70px;
  @media #{$pc} {
    margin-top: 140px;
  }
}
.hp_mt48-72 {
  margin-top: 48px;
  @media #{$pc} {
    margin-top: 72px;
  }
}
.hp_mt80-100 {
  margin-top: 80px;
  @media #{$pc} {
    margin-top: 100px;
  }
}
.hp_mt80-140 {
  margin-top: 80px;
  @media #{$pc} {
    margin-top: 140px;
  }
}
.hp_mt65-160 {
  margin-top: 65px;
  @media #{$pc} {
    margin-top: 160px;
  }
}
.hp_mt90-160 {
  margin-top: 90px;
  @media #{$pc} {
    margin-top: 160px;
  }
}
.hp_mt100-120 {
  margin-top: 100px;
  @media #{$pc} {
    margin-top: 120px;
  }
}
.hp_mt96-140 {
  margin-top: 96px;
  @media #{$pc} {
    margin-top: 140px;
  }
}
section + section {
  margin-top: 96px;
  @media #{$pc} {
    margin-top: 200px;
  }
}
.hp_mt96-200 { // 将来的に上記のsection間でのマージンでなくこのヘルパーに置き換えるべき
  margin-top: 96px;
  @media #{$pc} {
    margin-top: 200px;
  }
}
section + section.hp_mtShortSc {
  @media #{$pc} {
    margin-top: 140px;
  }
}
.hp_mt56-140 {
  margin-top: 56px;
  @media #{$pc} {
    margin-top: 140px;
  }
}
.hp_mb20-30 {
  margin-bottom: 20px;
  @media #{$pc} {
    margin-bottom: 30px;
  }
}
.hp_mb60-40 {
  margin-bottom: 40px;
  @media #{$pc} {
    margin-bottom: 60px;
  }
}
.hp_mb56-140 {
  margin-bottom: 56px;
  @media #{$pc} {
    margin-bottom: 140px;
  }
}

/* ==========================================================
 padding
========================================================== */
.hp_pt10-15 {
  padding-top: 10px;
  @media #{$pc} {
    padding-top: 15px;
  }
}
.hp_pt70-140 {
  padding-top: 70px;
  @media #{$pc} {
    padding-top: 140px;
  }
}
.hp_ptbSection {
  padding: 56px 0;
  @media #{$pc} {
    padding: 140px 0;
  }
}
.hp_ptb95 {
  padding: 95px 0;
  @media #{$pc} {
    padding: 200px 0;
  }
}
.hp_ptb95cf {
  padding-top: 95px;
  padding-bottom: 95px;
  @media #{$pc} {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}
.hp_ptb550 {
  padding: 550px 0 750px;
  @media #{$pc} {
    padding: 700px 0 900px;
  }
}
.hp_pb95-200 {
  padding-bottom: 95px;
  @media #{$pc} {
    padding-bottom: 200px;
  }
}

/* ==========================================================
 image size
========================================================== */
.hp_mw640 {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.hp_mw1380 {
  max-width: 1380px;
  margin-left: auto;
  margin-right: auto;
}

.hp_mw240-500 {
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
  @media #{$pc} {
    max-width: 500px;
  }
}

.hp_wFull {
  margin-left: -25px;
  margin-right: -25px;
  @media #{$pc} {
    margin-left: 0;
    margin-right: 0;
  }
}
.hp_wdFull {
  margin-left: -36px;
  margin-right: -36px;
  @media #{$pc} {
    margin-left: 0;
    margin-right: 0;
  }
}


/* ==========================================================
 text align
========================================================== */
.hp_txtCenter {
  text-align: center;
  &__pc {
    text-align: left;
    @media #{$pc} {
      text-align: center;
    }
  }
}
.hp_txtRight {
  text-align: right;
}

/* ==========================================================
 text decoration
========================================================== */
.hp_txtUnderline {
  text-decoration: underline;
  i.far { // fontawesomeアイコンが含まれていたら整える
    margin-left: .4em;
    opacity: .5;
  }
}

/* ==========================================================
 el_hdYear minus margin
========================================================== */
.hp_hYearMT {
  margin-top: -30px;
  @media #{$pc} {
    margin-top: -60px;
  }
}

/* ==========================================================
 smartphone pc display ctrl
========================================================== */
.hp_brSp {
  @media #{$pc} {
    display: none;
  }
}
.hp_brPc {
  display: none;
  @media #{$pc} {
    display: inline;
  }
}
.hp_dispSp {
  @media #{$pc} {
    display: none;
  }
}
.hp_dispPc {
  display: none;
  @media #{$pc} {
    display: initial;
  }
}


/* ==========================================================
 smartphone x-scroll
========================================================== */
.hp_xScroll {
  width: calc(100% + 25px);
  overflow-x: auto;
  overflow-scrolling: touch;
  @media #{$pc} {
    width: 100%;
    overflow-x: visible;
  }
  &_desc {
    font-size: 1.1rem;
    margin-bottom: 5px;
    i {
      margin-right: .3em;
      opacity: .5;
    }
    @media #{$pc} {
      display: none;
    }
  }
}

/* ==========================================================
 position control
========================================================== */
.hp_pRlv {
  position: relative;
}

/* ==========================================================
 image color control
========================================================== */
.hp_imgColor_gray {
  filter: grayscale(1);
}

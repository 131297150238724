/* ==========================================================
 button
========================================================== */
.el_btn {
  display: inline-block;
  font-family: $gothic;
  font-size: 1.3rem;
  font-weight: bold;
  border: solid 1px #707070;
  padding: 1em 4em;
  background: url(../images/common/icon_arrow-narrow.svg) no-repeat center right 10px;
  width: 100%;
  text-align: center;
  @media #{$pc} {
    //-width: auto;
    //-text-align: left;
    font-size: 1.4rem;
    max-width: 320px;
  }
  &.el_btn__bgRed {
    color: white;
    background-color: $colorRed;
    border-color: $colorRed;
    background-image: url(../images/common/icon_arrow-narrow_white.svg);
  }
  &.el_btn__bgOrange {
    color: white;
    background-color: $colorCinnamon;
    border-color: $colorCinnamon;
    background-image: url(../images/common/icon_arrow-narrow_white.svg);
  }
  &.el_btn__bgBlack,
  &.el_btn__clRed {
    color: white;
    border-color: white;
    background-image: url(../images/common/icon_arrow-narrow_white.svg);
  }
  &.is_active {
    color: white;
    background: black;
    border-color: black;
    .el_labelCategory {
      color: white;
    }
  }
  &.not_btn {
    background: none;
  }
  // size
  &__100p {
    width: 100% !important;
    text-align: center;
    padding: 1em;
  }
  &__mW {
    @media #{$pc} {
      min-width: 270px;
      text-align: center;
    }
  }
  &__mini {
    font-size: 1.2rem;
    padding: .5em;
  }
  &__s {
    width: auto;
  }
  &__forMv {
    width: auto;
    padding: .5em 2em;
  }
  &__playMovie {
    width: auto;
    padding: .5em 0 .5em 3em;
    border: none;
    background-color: transparent !important;
    background: transparent url(../images/common/icon_playmovie.svg) no-repeat center left;
    background-size: auto 90%;
    color: white;
  }
  &__middle {
    width: 270px;
    text-align: center;
    padding-left: .7em;
    padding-right: .7em;
    background: url(../images/common/icon_rightarrowhead.svg) no-repeat center right 12px;
    &.el_btn__bgBlack,
    &.el_btn__clRed {
      background-image: url(../images/common/icon_rightarrowhead_white.svg);
    }
  }
  &__l {
    width: 100%;
    text-align: center;
    padding-left: .7em;
    padding-right: .7em;
    background: url(../images/common/icon_rightarrowhead.svg) no-repeat center right 12px;
    @media #{$pc} {
      width: 334px;
    }
    &.el_btn__bgBlack,
    &.el_btn__clRed {
      background-image: url(../images/common/icon_rightarrowhead_white.svg);
    }
  }
  &__xl {
    width: 100%;
    text-align: center;
    padding-left: .7em;
    padding-right: .7em;
    background: url(../images/common/icon_rightarrowhead.svg) no-repeat center right 12px;
    @media #{$pc} {
      width: 450px;
    }
    &.el_btn__bgBlack,
    &.el_btn__clRed {
      background-image: url(../images/common/icon_rightarrowhead_white.svg);
    }
  }
  &__full {
    width: 100%;
    max-width: none;
    background: url(../images/common/icon_rightarrowhead.svg) no-repeat center right 12px;
    padding-left: .9em;
    padding-right: .9em;
    text-align: left;
    font-size: 1.3rem;
    @media #{$pc} {
      font-size: 1.4rem;
    }
    .el_labelCategory {
      margin-right: 1em;
    }
    &.el_btn__bgBlack {
      background-image: url(../images/common/icon_rightarrowhead_white.svg);
      .el_labelCategory {
        color: white;
      }
    }
    &.is_active {
      background: url(../images/common/icon_rightarrowhead.svg) no-repeat center right 12px black;
    }
  }
  // height
  &__thick {
    padding: .9em;
    @media #{$pc} {
      padding: 1.3em;
    }
    //background-position: center right 12px;
  }
  // color
  &__clWhite {
    background-color: white;
    border-color: white;
  }
  &__clGray {
    background-color: #E6E6E6;
    border-color: #E6E6E6;
  }
  &__clRed {
    background-color: #9d0000;
    border-color: #9d0000 !important;
  }
  &__clBlack {
    background-color: black;
    border-color: black;
    color: white;
    background-image: url(../images/common/icon_arrow-narrow_white.svg);
  }
  // shape
  &__shRd {
    //-border-radius: 4px;
  }
  // position
  &__center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  // font
  &__fMin {
    font-family: $mincho;
    font-weight: normal;
    font-size: 1.4rem;
    @media #{$pc} {
      font-size: 1.6rem;
    }
  }
}
.el_btn + .el_btn {
  margin-top: 12px;
}
.bl_btnUnit {
  &__2col {
    ul {
      list-style: none;
      padding: 0;
      margin-bottom: -16px;
      display: flex;
      flex-direction: column;
      @media #{$pc} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: -45px;
      }
      li {
        margin-bottom: 16px;
        @media #{$pc} {
          margin: 0 5px 45px;
        }
      }
    }
  }
  &__3col {
    ul {
      list-style: none;
      padding: 0;
      margin-bottom: -14px;
      @media #{$pc} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -45px;
      }
      &::after {
        @media #{$pc} {
          content: '';
          width: 31.52%;
        }
      }
      li {
        margin-bottom: 14px;
        @media #{$pc} {
          margin-bottom: 45px;
          width: 31.52%;
        }
      }
    }
  }
  &__4col {
    ul {
      list-style: none;
      padding: 0;
      margin-bottom: -14px;
      @media #{$pc} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -45px;
      }
      &::after {
        @media #{$pc} {
          content: '';
          width: 23%;
        }
      }
      li {
        margin-bottom: 14px;
        @media #{$pc} {
          margin-bottom: 45px;
          width: 23%;
        }
      }
    }
  }
  &__btmShort {
    ul {
      li {
        margin-bottom: 14px;
      }
    }
  }
}
/* ----------------------------------------------------------
 single-arrow
---------------------------------------------------------- */
.el_funcBtnArrow {
  display: inline-block;
  width: 40px;
  padding: 10px 0;
  img {
    opacity: .6;
  }
  &__rev {
    transform: scale(-1, 1);
  }
  &__disabled,
  &.swiper-button-disabled {
    img {
      opacity: .2;
    }
  }
}

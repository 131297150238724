/* ==========================================================
 google map
========================================================== */
.un_gm_basic {
  iframe {
    width: 100%;
    height: 66.66vw;
    filter: grayscale(1);
    @media #{$pc} {
      height: 410px;
    }
  }
}

/* ==========================================================
 grayscale map for campfield
========================================================== */
.un_cfGrayMap {
  width: 100vw;
  height: 100vw;
  margin-left: -25px;
  margin-right: -25px;
  filter: grayscale(1);
  @media #{$pc} {
    width: 100%;
    height: 500px;
    margin-left: 0;
    margin-right: 0;
  }
}
@charset "UTF-8";

/* ==========================================================
 fonts
========================================================== */

//@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:400,500|Roboto:400,500');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500&family=Lato:wght@700&family=Roboto:wght@400;500&display=swap');

/* ==========================================================
 base
========================================================== */

@import './1_base/reset.css';
@import './1_base/base.scss';

/* ==========================================================
 layout
========================================================== */

@import './2_layout/layout.scss';

/* ==========================================================
 module
========================================================== */

@import './3_module/button.scss';
@import './3_module/footer.scss';
@import './3_module/form.scss';
@import './3_module/header_nav.scss';
@import './3_module/header.scss';
@import './3_module/heading.scss';
@import './3_module/icon.scss';
@import './3_module/module.scss';
@import './3_module/paragraph.scss';

/* ==========================================================
 helper
========================================================== */

@import './4_helper/hover.scss';
@import './4_helper/helper.scss';
@import './4_helper/animation.scss';

/* ==========================================================
 unique
========================================================== */

@import './5_unique/content_event.scss';
@import './5_unique/content_styles.scss';
@import './5_unique/content_campaign.scss';
@import './5_unique/content_help.scss';
@import './5_unique/googlemap.scss';
@import './5_unique/page_contents_hq10th.scss';
@import './5_unique/page_news.scss';
@import './5_unique/page_top.scss';
@import './5_unique/products_localfoods.scss';
@import './5_unique/unique.scss';
@import './5_unique/youtube.scss';
@import './5_unique/sbs.scss';

/* ==========================================================
 legacy
========================================================== */

@import './6_legacy/news.scss';
@import './6_legacy/event_spwpremium.scss';
@import './6_legacy/event_spw.scss';
@import './6_legacy/event_sscamp.scss';